import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoiService } from '@compass/pois/data-access-poi';

@Component({
  selector: 'poi-detail',
  templateUrl: './poi-detail.component.html',
  styleUrls: ['./poi-detail.component.scss']
})
export class PoiDetailComponent {
  poi$ = this.poiService.poi$;
  icon_class: string = 'icon-pois';
  router_link: string = '/pois';
  searchboxSetup = {
    class: 'poi-detail-searchbox',
    id: 'poi_detail_searchbox',
    width: 280
  };

  constructor(private route: ActivatedRoute, private poiService: PoiService) {
    this.poiService.clearPoi();
    this.route.paramMap.subscribe((params) => {
      const poiID = params.get('poiID');
      if (poiID) {
        this.poiService.getById(poiID).subscribe();
      }
    });
  }

}
