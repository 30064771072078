<div class="container padding__basic">

    <form [formGroup]="form" target="_blank" class="local-form" id="local_knowledge_form" name="local_knowledge_form"
        (ngSubmit)="onSubmit()">
        <div class="container">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Categoría:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="category">
                                <mat-option *ngFor="let option of options" [value]="option">
                                    {{option.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="50">
                    <div class="margin__top--xs container">

                        <p class="margin__bottom-xxs"><strong><small>Recinto:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Lugar</mat-label>
                            <mat-select formControlName="subcategory">
                                <mat-option *ngFor="let child of children" [value]="child">
                                    {{child.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
