<div class="container padding__basic" *ngIf="heritageExists">

  <form [formGroup]="heritageForm" target="_blank" class="heritage-form" id="heritage_form" name="heritage_form"
        autocomplete="off">

    <div class="container">
      <div fxFlex="100" fxLayout="row">
        <div class="margin__top--xs container">
          <mat-checkbox class="example-margin" formControlName="visibility" [checked]="true" color="primary">
            {{ checkboxText }}
          </mat-checkbox>
        </div>
      </div>
    </div>


    <section class="margin__top--m">

    </section>
    <div class="container">
      <div fxLayout="row" fxLayoutGap="4%">
        <div fxFlex="100">
          <div class="margin__top--xs container">
            <p class="margin__bottom-xxs"><strong><small>Proveedor:</small></strong></p>
            <mat-form-field appearance="fill">
              <mat-label>Proveedores</mat-label>
              <mat-select formControlName="proveedor" multiple>
                <mat-option #matOption *ngFor="let option of options.proveedor" [value]="option"
                            (click)="onClickMatOption('proveedor', matOption)">
                  {{option}}
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>


    <div class="container">
      <div fxLayout="row" fxLayoutGap="4%">

        <div fxFlex="100">
          <div class="margin__top--xs container">
            <p class="margin__bottom-xxs"><strong><small>Medio:</small></strong></p>
            <mat-form-field appearance="fill">
              <mat-label>Medio publicitario</mat-label>
              <mat-select formControlName="medio" multiple>
                <mat-option #matOption *ngFor="let option of options.medio" [value]="option"
                            (click)="onClickMatOption('medio', matOption)">
                  {{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div fxLayout="row" fxLayoutGap="4%">

        <div fxFlex="100">
          <div class="margin__top--xs container">
            <p class="margin__bottom-xxs"><strong><small>Tipo de soporte:</small></strong></p>
            <mat-form-field appearance="fill">
              <mat-label>Soportes publicitarios</mat-label>
              <mat-select formControlName="soporte" multiple>
                <mat-option #matOption *ngFor="let option of options.soporte" [value]="option"
                            (click)="onClickMatOption('soporte', matOption)">
                  {{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div fxFlex="100" fxLayout="row">
        <div class="margin__top--xs container">
          <p class="margin__bottom-xxs"><strong><small>Tipos de formato:</small></strong></p>
          <mat-form-field appearance="fill">
            <mat-label>Formatos</mat-label>
            <mat-select formControlName="sub_categoria" multiple>
              <mat-option #matOption *ngFor="let option of options.sub_categoria" [value]="option"
                          (click)="onClickMatOption('sub_categoria', matOption)">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="container ">
      <div fxLayout="row" fxLayoutGap="4%">
        <div fxFlex="50">
          <div class="margin__top--xs container">
            <p class="margin__bottom-xxs"><strong><small>Cliente:</small></strong></p>
            <mat-form-field appearance="fill">
              <mat-label>Cliente</mat-label>
              <mat-select formControlName="cliente" multiple>
                <mat-option #matOption *ngFor="let option of options.cliente" [value]="option"
                            (click)="onClickMatOption('cliente', matOption)">
                  {{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div fxFlex="50">
          <div class="margin__top--xs container">
            <p class="margin__bottom-xxs"><strong><small>&nbsp;&nbsp;</small>Estado:</strong></p>
            <mat-form-field appearance="fill">
              <mat-label>Estado del formato</mat-label>
              <mat-select formControlName="status" multiple>
                <mat-option #matOption *ngFor="let option of options.status" [value]="option"
                            (click)="onClickMatOption('status', matOption)">
                  {{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div class="container ">
      <div fxLayout="row" fxLayoutGap="4%">
        <div fxFlex="50">
          <div class="margin__top--xs container">
            <button
              mat-raised-button
              class="button--xs"
              [disabled]="!filteredPoisList?.length"
              (click)="downloadFiltered()"
              title="{{ !filteredPoisList ? 'Debe aplicar algún filtro para poder descargar los datos' : 'Descargar csv' }}"
            >
              Descargar CSV
            </button>
          </div>
        </div>


        <div fxFlex="50" fxFlexAlign="right" fxLayoutAlign="end end" dir="rtl">
          <div class="margin__top--xs  margin__bottom--s container">
            <button mat-flat-button type="button" class="button--xs" color="warn" (click)="resetForm()">Reiniciar
            </button>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>


<div class="container padding__basic" *ngIf="!heritageExists">
  <p>Lo sentimos, este área todavía no tiene asignados puntos de interés de patrimonio.</p>

</div>
