<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef> Evento </th>
        <td mat-cell *matCellDef="let element"> {{element['descripcion']}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
