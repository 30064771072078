<div class="ul__accordion">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            *ngFor="let l of list; let index = index" class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{l.nombre_medio}}
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngIf='l.tematica'>{{l.tematica}}</p>
            <p *ngIf='l.municipio'>{{l.municipio}}</p>
            <p *ngIf='l.provincia'>{{l.provincia}}</p>
            <p *ngIf='l.followers'>Seguidores {{l.followers}}</p>
            <p *ngIf='l.url_ig_perfil && l.medio'><a target='_blank' [attr.href]="l.url_ig_perfil">{{l.medio}}</a>
            </p>
            <p *ngIf='l.avg_interactions'>{{l.avg_interactions}}</p>
            <p *ngIf='l.engagement'>Engagement: {{l.engagement}}</p>
            <p *ngIf='l.audience_city'>Audiencia: {{l.audience_city}}</p>
            <p *ngIf='l.avg_age'>Edad: {{l.avg_age}}</p>

        </mat-expansion-panel>

    </mat-accordion>
</div>