import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BestTimesService } from '../best-times.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
// @ts-ignore
import {} from '@types/google.maps';


@Component({
  selector: 'compass-best-times-list',
  templateUrl: './best-times-list.component.html',
  styleUrls: ['./best-times-list.component.scss']
})
export class BestTimesListComponent implements OnInit, OnDestroy {
  options: any = [];
  poi: any;
  selectedOption: any;

  listSub: Subscription;

  @ViewChild('addresstext') addresstext: any;
  addressSearchText: string = '';
  autocomplete: any;
  autocompleteService: any | google.maps.MVCObject;
  manualSearch: boolean = false;

  searchCount: number = 0;

  constructor(
    public bestTimeService: BestTimesService,
    private snackbar: MatSnackBar
  ) {
    this.bestTimeService.loadAutocompleteImportScript();
  }

  ngOnInit(): void {
    this.poi = this.bestTimeService.selectedPoi$.value;
    this.bestTimeService.loading = true;

    setTimeout(() => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.getPlacePrediction();
    }, 600);
  }

  ngOnDestroy() {
    this.listSub?.unsubscribe();
  }

  getPlacePrediction() {
    this.bestTimeService.loading = true;
    this.addressSearchText = this.poi.properties.nombre + ' ' + this.poi.properties.direccion;
    // if not first execution, then clean the search string
    if (this.searchCount > 0) {
      this.addressSearchText = this.bestTimeService.getCleanDirection(this.poi.properties.nombre, this.poi.properties.direccion);
    }

    // search for results
    this.autocompleteService.getPlacePredictions({ input: this.addressSearchText }, (res) => {
      this.options = res ?? [];
      this.bestTimeService.loading = false;
      this.searchCount++;
    });
  }

  toggleManualSearch() {
    this.manualSearch = !this.manualSearch;
    this.addressSearchText = this.poi.properties.nombre;
    const input: HTMLInputElement = this.addresstext.nativeElement as HTMLInputElement;

    // if manual search active
    if (this.manualSearch) {
      setTimeout(() => {
        // focus search input
        input.focus();
        input.select();

        // if autocomplete not instanced, then do it
        if (!this.autocomplete) {
          // prepare autocomplete element
          this.autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
            componentRestrictions: { country: 'ES' }
          });

          // listen for user selection
          google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.selectedOption = {
              place_id: place?.place_id,
              description: place?.formatted_address,
              structured_formatting: {
                main_text: place?.name,
                secondary_text: place?.formatted_address
              }
            };

            // filter options to avoid duplicate values
            this.options = this.options.filter(o => o.place_id !== this.selectedOption.place_id);
            this.options.push(this.selectedOption);

            // update actual poi goo data
            this.poi.properties.goo = this.poi.properties.goo ?? {}; // set object data if don't exists
            this.poi.properties.goo = { ...this.poi.properties.goo, ...place }; // merge goo data with retrieved

            this.manualSearch = false;
            this.searchCount++;
          });
        }
      }, 600);
    } else {
      input.blur();
    }
  }

  assign() {
    this.bestTimeService.loading = true;
    this.poi.properties.place_id = this.selectedOption.place_id;
    this.poi.properties.venue_name = this.selectedOption?.structured_formatting?.main_text ?? this.poi.properties.nombre;
    this.poi.properties.venue_address = this.selectedOption?.structured_formatting?.secondary_text ?? this.poi.properties.direccion;

    this.bestTimeService.updatePoi({
      _id: this.poi._id,
      type: this.poi.type,
      properties: this.poi.properties,
      geometry: this.poi.geometry
    }).subscribe((res) => {
      this.bestTimeService.updatePopularTime(this.poi.properties.id).subscribe((poiData: any) => {
        if (poiData === 'Error') {
          this.bestTimeService.loading = false;
          this.snackbar.open('Ha ocurrido un error al sincronizar las horas puntas', 'cerrar', {
            duration: 8000,
            panelClass: 'danger'
          });
        }
      }, (e) => {
        this.bestTimeService.loading = false;
        console.error(e.message);
        if(e.error.message.includes('volume')){
          this.snackbar.open('Este punto no tiene el suficiente volumen de gente', 'cerrar', {
            duration: 8000,
            panelClass: 'info'
          });
        }else{
          this.snackbar.open('Ha ocurrido un error al sincronizar las horas puntas', 'cerrar', {
            duration: 8000,
            panelClass: 'danger'
          });
        }
      });
    }, (e) => {
      this.bestTimeService.loading = false;
      console.error(e);
      this.snackbar.open('Ha ocurrido un error al actualizar los datos del POI', 'cerrar', {
        duration: 8000,
        panelClass: 'danger'
      });
    });
  }

  selected(selected: any) {
    this.selectedOption = selected.option.value;
  }


}
