<div class="poi-select-container">
  <div class="select" [class.select-mode]="true" [matMenuTriggerFor]="menu"
       fxLayout="row" fxLayoutAlign=" center"
  >
    <compass-comparator-poi-title fxFlex="90" [poi]="selected"></compass-comparator-poi-title>
    <mat-icon *ngIf="true">arrow_drop_down</mat-icon>
  </div>
</div>


<mat-menu #menu="matMenu" class="poi-select-options">
  <div mat-menu-item class="option"
       *ngFor="let p of options"
       (click)="optionClick(p)"
       [title]="p.properties.nombre"
       [class.selected]="p.properties.id === selected.properties.id"
  >
    <compass-comparator-poi-title [poi]="p"></compass-comparator-poi-title>
  </div>
</mat-menu>
