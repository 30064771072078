<div class="container padding__basic">

    <form [formGroup]="idealistaForm" target="_blank" class="idealista-form" id="idealista_form" name="idealista_form"
        (ngSubmit)="onSubmit()">

        <div class="container">
            <div fxFlex="100" fxLayout="row">
                <div class="margin__top--xs container">
                    <mat-checkbox class="example-margin" formControlName="visibility" [checked]="true" color="primary">
                        {{ checkboxText }}
                    </mat-checkbox>
                </div>
            </div>
        </div>



        <section class="margin__top--m">

        </section>
        <div class="container">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Régimen:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Régimen</mat-label>
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let option of idealistaOptions" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Agente inmobiliario:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Gestores</mat-label>
                            <mat-select formControlName="agency">
                                <mat-option *ngFor="let option of agencyOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div fxFlex="100" fxLayout="row">
                <div class="margin__top--xs container">
                    <p class="margin__bottom-xxs"><strong><small>Tipo de inmueble:</small></strong></p>
                    <mat-form-field appearance="fill">
                        <mat-label>Categoría</mat-label>
                        <mat-select formControlName="category">
                            <mat-option *ngFor="let option of categoryOptions[0].children" [value]="option.value">
                                <span [style.background-color]='option.color' [ngClass]="'color'"></span>{{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="container ">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Precio (euros):</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Mínimo</mat-label>
                            <mat-select formControlName="price_min">
                                <mat-option *ngFor="let option of priceMinOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>&nbsp;&nbsp;</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Máximo</mat-label>
                            <mat-select formControlName="price_max">
                                <mat-option *ngFor="let option of priceMaxOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>


        <div class="container ">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Metros cuadrados:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Mínimo</mat-label>
                            <mat-select formControlName="size_min">
                                <mat-option *ngFor="let option of sqmMinOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>&nbsp;&nbsp;</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Máximo</mat-label>
                            <mat-select formControlName="size_max">
                                <mat-option *ngFor="let option of sqmMaxOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>



        <div class="container ">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Habitaciones:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Número</mat-label>
                            <mat-select formControlName="rooms">
                                <mat-option *ngFor="let option of roomsOptions[0].children" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div fxFlex="50" fxFlexAlign="right" fxLayoutAlign="end end" dir="rtl">
                    <div class="margin__top--xs  margin__bottom--s container">
                        <p class="margin__bottom-xxs"><strong><small>&nbsp;&nbsp;</small></strong></p>
                        <button mat-flat-button type="button" class='button--xs' color="warn"
                            (click)="resetForm()">Reiniciar</button>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>