import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';

import { StartLeftSidenavService } from '../start-left-sidenav.service';


@Component({
  selector: 'compass-start-left-sidenav-base',
  templateUrl: './start-left-sidenav-base.component.html',
  styleUrls: ['./start-left-sidenav-base.component.scss']
})
export class StartLeftSidenavBaseComponent implements OnInit {

  @Input() setup: any;
  compassIsOn: boolean = false;
  sidenavIsOpen: boolean = false;


  constructor(private startLeftSidenavService: StartLeftSidenavService) {
    this.startLeftSidenavService.getCompassStatus().subscribe((value) => {
      this.compassIsOn = value;
    });

    this.startLeftSidenavService.getSidenavIsOpen().subscribe((value) => {
      this.sidenavIsOpen = value;
    });
  }



  ngOnInit(): void {
  }

}
