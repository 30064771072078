import { Component, OnInit } from '@angular/core';
import {PopularTimesReshapeService} from '../services/popular-times-reshape.service';
import {PopularTimesFormService} from '../services/popular-times-form.service';
import {PoiService, SidenavPoisLevelsService} from '@compass/pois/data-access-poi';
import {BehaviorSubject} from 'rxjs';
import {CompassMapWrapperService} from '@compass/utils/leaflet';
import * as L from 'leaflet';

@Component({
  selector: 'compass-popular-times',
  templateUrl: './popular-times.component.html',
  styleUrls: ['./popular-times.component.scss']
})
export class PopularTimesComponent implements OnInit {
  public eightDaysData$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  heatMapData$ = this.popularTimesReshapeService.heatMapData$;
  eightDaysData: any;
  eightDaysForm: any;
  map: any;
  venuemarkers: L.LayerGroup = new L.LayerGroup();


  constructor(
    private popularTimesReshapeService: PopularTimesReshapeService,
    private sidenavPoisLevelService: SidenavPoisLevelsService,
    private popularTimesFormService: PopularTimesFormService,
    private  compassMapWrapperService:CompassMapWrapperService,
    private poiService: PoiService) {

    this.compassMapWrapperService.getMap().subscribe(map => {
      this.map = map;
    })
  }

  ngOnInit(): void {

      let poisData = this.sidenavPoisLevelService.getSelectedPlainNodes();
      poisData = this.popularTimesReshapeService.getGoogleDataPois(poisData);

    poisData = this.filterUniqueIds(poisData);
    poisData = this.filterPois(poisData,this.popularTimesFormService.form$.value);
    this.popularTimesFormService.setForm(this.popularTimesFormService.form$.value)
    this.eightDaysData = this.popularTimesReshapeService.getEightDaysData(poisData); // 7 days, 24 hours data + all week data sum
      this.eightDaysData$.next(this.eightDaysData); // seven days, 24 hours
      this.heatMapData$.next(this.eightDaysData[1]); // Monday, 24 hours



    //fires ngOnChanges on timeline component
    this.popularTimesFormService.getForm().subscribe(f => {
      this.eightDaysForm = f;
      poisData = this.sidenavPoisLevelService.getSelectedPlainNodes();
      poisData = this.popularTimesReshapeService.getGoogleDataPois(poisData);

      poisData = this.filterUniqueIds(poisData);
      this.eightDaysData = this.popularTimesReshapeService.getEightDaysData(this.filterPois(poisData,f));
      this.eightDaysData$.next(this.eightDaysData); // seven days, 24 hours
      this.heatMapData$.next(this.eightDaysData[1]); // Monday, 24 hours

      // map data for days (hours)
      if (this.eightDaysData && this.eightDaysForm) {
        this.popularTimesReshapeService.reshapeHeatMapData(this.eightDaysData, this.eightDaysForm);
      }

      this.drawCircles(poisData)
    });

  }

  filterPois(pois,form) {
    return pois.filter(poi => {
      if (poi.properties.goo.rating) {
        return Math.round(poi.properties.goo.rating) <= form.maxRating
          && Math.round(poi.properties.goo.rating) >= form.minRating;
      }
      return false;
    });

  }

  drawCircles(pois) {
    pois.map((poi: any) => {
       L.layerGroup([L.circleMarker([poi.geometry.coordinates[1], poi.geometry.coordinates[0]], {
        radius: 10,
        color: '#bf0000',
        fillOpacity: 0,
        opacity: 0
      }).on('click', () => {

        this.poiService.selectedPoi$.next({
          actualMarker: {
            info: poi
          }
        })
      })])
        .addLayer(this.venuemarkers).addTo(this.map.box);

    })

  }

  // removes duplicates
  filterUniqueIds(data: any) {
    const uniqueIDs: Array<number> = [];
    const result: Array<any> = [];

    data.forEach((d: any) => {
      if (uniqueIDs.indexOf(d.properties.id) < 0) {
        uniqueIDs.push(d.properties.id);
        result.push(d);
      }
    });
    return result;
  }
}
