<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<div style="width: 100%">
  <ul>
    <li *ngFor="let file of files" class="margin__bottom--s">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <span id="file-label"> </span>
    </li>
  </ul>
</div>
<management-title title="Puntos de interés" icon_class="icon-pois">
  <div class="margin__right--s" [ngClass]="{ errors: errors }">
    <mat-form-field>
      <input
        matInput
        #uploadComment
        name="uploadComment"
        placeholder="Comentario"
        required
      />

      <mat-hint [ngClass]="{ error: errors }"
        >Escribe un comentario antes de subir el fichero</mat-hint
      >
    </mat-form-field>
  </div>
  <div class="margin__right--s">
    <button mat-flat-button color="warn" class="button--m" (click)="onClick()">
      <mat-icon>publish</mat-icon>
      Fichero
    </button>

    <input
      type="file"
      #fileUpload
      id="fileUpload"
      name="fileUpload"
      multiple="multiple"
      accept=".csv"
      style="display: none"
    />
  </div>
  <div class="margin__right--s">
    <a href="/assets/templates/pois_upload.csv" target="_self" download>
      <button mat-flat-button color="warn" class="button--m">
        <mat-icon>get_app</mat-icon>
        Plantilla
      </button>
    </a>
  </div>
  <div>
    <button
      mat-flat-button
      color="warn"
      class="button--m"
      [routerLink]="['/pois/agregar/']"
    >
      <i class="material-icons sub-icons"> playlist_add </i>
      POI
    </button>
  </div>
</management-title>
<compass-table
  *ngIf="pois$ | async as pois; else loading"
  [data]="pois"
  [displayedColumns]="displayedColumns"
  toErase="este POI"
  url="pois"
  (dialogChange)="changeFromDialog($event)"
></compass-table>
