import { Component, OnInit } from '@angular/core';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { SideNavIndicatorsService } from '@compass/utils/navigation';
import { GroupedVerticalBarLayoutService } from '@compass/utils/d3';
import { PoiBasketService } from '../../../../shared/poi-basket/src/lib/poi-basket.service';


@Component({
  selector: 'compass-indicators-sidenav',
  templateUrl: './indicators-sidenav.component.html',
  styleUrls: ['./indicators-sidenav.component.scss']
})


export class IndicatorsSidenavComponent implements OnInit {
  brickEvent: Event;
  brickDataPortals: any;
  brick_id: string;
  brickGroup: any;
  areaGroup: any;
  chartData: any;
  fullTable: any;
  tableData;
  any;
  indicatorsDictionary = this.sideNavIndicatorsService.indicatorsDictionary$.value;
  public hour: Date | undefined = undefined;
  format: string;

  chartLayout: any = {};

  brick: any;
  inBasket: boolean = false;

  constructor(
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private GroupedVerticalBarLayoutService: GroupedVerticalBarLayoutService,
    public poiBasketService: PoiBasketService
  ) {
    this.compassMapIndicatorsService.getBrickEventToBounce().subscribe((brick) => {
      if (brick) {
        this.brick = brick['brick'].target.feature;

        this.hour = undefined;
        this.brickEvent = brick;
        this.brick_id = this.brick.properties.id;
        this.areaGroup = this.brick.properties.data_portals2019[brick['list']['grupo']];
        this.brickGroup = brick['areaPortals'][brick['list']['grupo']];
        this.fullTable = this.getDataTable(this.areaGroup, this.brickGroup);
        this.tableData = this.filterTableFormats(this.fullTable, brick['list'].formato);
        this.format = this.getTheFormat(this.tableData?.[0]?.format);
        this.chartData = this.getChartData(this.areaGroup, this.brickGroup, brick['list']);
        this.chartLayout = this.GroupedVerticalBarLayoutService.getLayout('indicators_bars')[0];
        this.chartLayout['data'] = this.chartData;
        this.hour = new Date();
        this.inBasket = this.poiBasketService.inBasket(this.brick);
      }
    });
  }

  filterTableFormats(table, format) {
    return table.filter((row) => row.format === format);
  }

  getDataTable(brickGroup, areaGroup) {
    let result = [];
    if (brickGroup && areaGroup) {
      Object.keys(brickGroup).forEach((brickKey) => {
        Object.keys(areaGroup).forEach((areaKey) => {
          if (brickKey === areaKey) {
            this.indicatorsDictionary.forEach((indicator) => {
              if (indicator.key === brickKey) {
                result.push({
                  key: brickKey,
                  group: areaGroup[brickKey],
                  brick: brickGroup[brickKey],
                  screen_name: indicator.screen_name,
                  format: indicator.formato
                });
              }
            });
          }
        });
      });
    }
    return result;
  }

  getChartData(brickGroup, areaGroup, list) {
    let result = {
      key: list.key,
      format: list.formato,
      categories: 'Áreas',
      children: [
        { category: 'brick', [list.key]: brickGroup?.[list.key] ?? 0 },
        { category: 'area', [list.key]: areaGroup?.[list.key] ?? 0 }
      ]

    };
    return result;
  }

  getTheFormat(d) {
    if (d === 'Volumen') {
      return 'Volúmen (nº)';
    }
    return d;
  }

  ngOnInit(): void {
  }

  toggleBasket() {
    const inBasket = this.poiBasketService.inBasket(this.brick);
    const event: any = this.brickEvent; // PUTA ÑAPA PORQUE NO SE ENVÍA EL EVENTO, SINO ALGO INVENTADO

    if (inBasket) {
      this.poiBasketService.remove(this.brick);
    } else {
      this.poiBasketService.add(this.brick);
    }

    this.inBasket = !inBasket;

    // set style
    event.brick.target.setStyle({
      weight: 5,
      opacity: this.inBasket ? 0.8 : 0,
      fillOpacity: this.inBasket ? 0.5 : 1,
      color: '#0036ff'
    });
  }
}
