<div class="container catastro-form padding__basic">
    <p></p>

    <div class="leaflet-control-layers-overlays">
        <form [formGroup]="castastroForm" target="_blank" class="geo-form" id="geo_form" name="geo_form"
            (ngSubmit)="onSubmit()">
            <!--             <div class="margin__top--xs">
                <p class="margin__bottom-xxs"><strong><small>Topografía:</small></strong></p>

                <mat-form-field appearance="fill">
                    <mat-label>Tipo de mapa</mat-label>
                    <mat-select formControlName="map">
                        <mat-option *ngFor="let map of maps" [value]="map.value">
                            {{map.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
 -->
            <div class="margin__top--xs opacity-container">
                <p class="margin__bottom-xxs"><strong><small>Opacidad:</small></strong></p>
                <mat-slider min="0" max="100" step="1" value="100" formControlName="opacity"
                    (input)="setLayerOpacity($event.value)"></mat-slider>
            </div>

            <div class="radio_button--container">
                <label>Sede Catastro</label>
                <mat-radio-group class="radio-group" formControlName="ovc">
                    <mat-radio-button class="radio-button" *ngFor="let option of ovc" name="ovc" [value]="option.value"
                        [id]="option.value" (click)='onClickOvcRadios($event)'>
                        {{option.name}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="margin__top--xs" *ngIf="ovcOn">
                <p class="margin__bottom-xxs"><strong><small>Capas Catastro:</small></strong></p>

                <mat-checkbox class="checkbox" *ngFor="let option of ovcLayers" [id]="option.value"
                    [value]="option.value" formControlName={{option.value}} (click)='onClickOvcCheckBoxes($event)'>
                    {{option.name}}
                </mat-checkbox>

            </div>

            <div class="margin__top--xs">
                <div class="radio_button--container">
                    <label>Navarra y País Vasco:</label>
                    <mat-radio-group class="radio-group" formControlName="noOvc">
                        <mat-radio-button class="radio-button" *ngFor="let option of noOvc" [value]="option.value"
                            [id]="option.value" (click)='onClickNoOvcRadios($event)'>
                            {{option.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

            </div>

            <div class="container ">
                <div fxLayout="row" fxLayoutGap="4%">
                    <div fxFlex="50">
                        <div class="margin__top--xs container">

                        </div>
                    </div>


                    <div fxFlex="50" fxFlexAlign="right" fxLayoutAlign="end end" dir="rtl">
                        <div class="margin__top--xs  margin__bottom--s container">
                            <p class="margin__bottom-xxs"><strong><small>&nbsp;&nbsp;</small></strong></p>
                            <button mat-flat-button type="button" class='button--xs' color="warn"
                                (click)="resetForm($event)">Borrar todo</button>
                        </div>
                    </div>
                </div>
            </div>


        </form>
    </div>

    <!-- columns -->
</div>