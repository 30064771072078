import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'compass-start-left-button-open',
  templateUrl: './start-left-button-open.component.html',
  styleUrls: ['./start-left-button-open.component.scss']
})
export class StartLeftButtonOpenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
