import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cruds } from '@compass/utils/navigation';
import { AuthService, Role } from '@compass/shared/feature-auth';
import { LogoutAppService } from '@compass/utils/navigation';

@Component({
  selector: 'gear-menu-nav',
  templateUrl: './crud-menu-nav.component.html',
  styleUrls: ['./crud-menu-nav.component.scss'],
})
export class CrudMenuNavComponent implements OnInit {
  @Input() crudList: Cruds;
  role = Role;
  currentUser$ = this.authService.currentUser$;

  constructor(
    private router: Router,
    private authService: AuthService,
    private logoutAppService: LogoutAppService
  ) {}

  gotoCrud(path: string): boolean {
    if (path) {
      this.logoutAppService.closeNavigation();
      this.router.navigate([path]);
      return true;
    }
    return false;
  }

  logOut() {
    this.logoutAppService.closeNavigation();
    this.authService.logout();
  }
  ngOnInit() {}
}
