import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatCalendar, MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import * as L from 'leaflet';
import {LatLng, LayerGroup} from 'leaflet';
import {CompassMapWrapperService} from '@compass/utils/leaflet';
import {EndRightSidenavService} from '@compass/page-navigation/end-right-sidenav';
import {OnclickStageShapeService} from '@compass/utils/navigation';
import {LocalKnowledgeApiService, LocalKnowledgeReshapeService} from '@compass/local-knowledge';

@Component({
  selector: 'compass-local-knowledge-calendar',
  templateUrl: './local-knowledge-calendar.component.html',
  styleUrls: ['./local-knowledge-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocalKnowledgeCalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data;
  @Input() datesToHighlight;
  selectedDate: any;
  showCalendar: boolean = false;
  information: any;
  monthInformation: any;
  dayInformationEnabled: any = false;
  view: any;
  categories = [
    {
      title: 'Autonómico',
      key: 'Autonomico'
    }, {
      title: 'Nacional',
      key: 'Nacional'
    }, {
      title: 'Local',
      key: 'Local'
    }, {
      title: 'Academico',
      key: 'academic'
    }, {
      title: 'Deportes',
      key: 'sports'
    }, {
      title: 'Conferencias',
      key: 'conferences'
    }, {
      title: 'Expos',
      key: 'expos'
    }, {
      title: 'Conciertos',
      key: 'concerts'
    }, {
      title: 'Festivales',
      key: 'festivals'
    }, {
      title: 'Artes Escénicas',
      key: 'performing-arts'
    }, {
      title: 'Comunidad',
      key: 'community'
    }
  ];

  map: any;
  eventsLayer: any = new LayerGroup();

  categoryFilter: FormControl = new FormControl([...this.categories.map(item => item.key), 0]);
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild(MatCalendar) private calendar: MatCalendar<Date>;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private endRightSidenavService: EndRightSidenavService,
    private onclickStageShapeService: OnclickStageShapeService,
    private localKnowledgeService: LocalKnowledgeReshapeService,
    private localKnowledgeApiService: LocalKnowledgeApiService
  ) {
    this.compassMapWrapperService.getMap().subscribe(map => {
      this.map = map;
    });
  }

  ngOnInit(): void {
    this.information = [];
    this.monthInformation = [];
    this.dayInformationEnabled = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (changes.data.currentValue) {
        this.showCalendar = true;
        this.calendar?.updateTodaysDate();
        this.eventsLayer.clearLayers();

        changes.data.currentValue.forEach((data) => {
          let popupContent = `
            <strong style="display: block;">${data['descripcion']}</strong>
            <i style="color: dimgrey; display: block;">${new Date(data.fecha).toLocaleDateString()}</i>
          `;

          if (data.phq_attendance) {
            popupContent += `<small style="color: ${data.color}" title="Predicción de asistentes">${data.phq_attendance} asistentes</small>`;
          }

          if (data.geo?.geometry) {
            const marker = L.circleMarker(new LatLng(data.geo.geometry.coordinates[1], data.geo.geometry.coordinates[0]), {
              radius: 20 * (data.rank / 100) + 5,
              fillColor: data.color ?? null,
              color: data.color ?? null,
              weight: 1,
              className: `event-circle event-${data.id}`
            }).bindPopup(popupContent)
              .addTo(this.eventsLayer)
              .on('mouseover', () => {
                marker.openPopup();
                this.toggleActive(data, true)
              })
              .on('mouseout', () => {
                marker.closePopup();
                this.toggleActive(undefined, false)
              })
              .on('click', (e) => {
                this.showDetails(data);
              });
          }
        });

        if (this.map) {
          this.eventsLayer.addTo(this.map?.box);
        }
      }
    }
  }

  // Listen for clicks on next and previous calendar buttons
  @HostListener('click', ['$event'])
  onClick(event: any) {
    // get the clicked element
    if (event.target.classList.contains('mat-calendar-previous-button') || event.target.classList.contains('mat-calendar-next-button')) {
      this.monthChange();
    }
  }

  showDetails(data) {
    if (data.categoria === 'predicthq') {
      this.endRightSidenavService.setSidenavStatus(true);
      this.endRightSidenavService.setTitle('Detalles de evento');
      this.onclickStageShapeService.shapeClicked$.next('event');
      this.localKnowledgeService.set(data);

      const closeSub = this.endRightSidenavService.endRightSidenavOn$.subscribe(res => {
        if (!res) {
          closeSub?.unsubscribe();
          this.endRightSidenavService.setTitle(null);
          this.localKnowledgeService.set(null);
          this.toggleActive(undefined, false)
        } else {
          setTimeout(() => this.toggleActive(data, true), 800);
        }
      });
    }
  }


  toggleActive(data: any, active: boolean) {
    if(!active) {
      for(var elements = document.getElementsByClassName('event-circle active'), i = 0, l = elements.length; l > i; i++) {
        elements[0].classList.remove("active");
      }
    } else {
      for(var elements = document.getElementsByClassName(`event-circle event-${data.id}`), i = 0, l = elements.length; l > i; i++) {
        elements[0].classList.add('active');
      }
    }
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (date.getDate() == 1) {
        setTimeout(() => {
          this.selectedDate = '';
        }, 0);
        this.switchView(false);
        this.monthInformation = [];
      }

      let indexDate;
      let highlightDate = false;

      if (this.datesToHighlight.length) {
        highlightDate = this.datesToHighlight
          .map((strDate) => {
            indexDate = this.datesToHighlight.indexOf(strDate);
            return strDate;
          })
          .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
      }

      let result = '';

      if (highlightDate) {
        result = 'national-festive';
        this.datesToHighlight.forEach((d) => {
          if (d.toLocaleDateString() == date.toLocaleDateString()) {
            indexDate = this.datesToHighlight.indexOf(d);
            this.monthInformation.push(this.data[indexDate]);

            switch (this.data[indexDate].tipo) {
              case 'Nacional': {
                result = 'national-festive';
              }
                break;
              case 'Local': {
                result = 'local-festive';
              }
                break;
              case 'Autonomico': {
                result = 'autonomic-festive';
              }
                break;
              case 'concerts': {
                result = 'concerts';
              }
                break;
              case 'academic': {
                result = 'academic';
              }
                break;
              case 'sports': {
                result = 'sports';
              }
                break;
              case 'conferences': {
                result = 'conferences';
              }
                break;
              case 'expos': {
                result = 'expos';
              }
                break;
              case 'festivals': {
                result = 'festivals';
              }
                break;
              case 'performing-arts': {
                result = 'performing-arts';
              }
                break;
              case 'community': {
                result = 'community';
              }
            }
          }
        });

      }
      return result;
    };
  }

  onSelect($event: any) {
    this.switchView(true);
    this.selectedDate = $event;
    this.information = [];
    this.datesToHighlight.forEach((date, index) => {
      if (date.toLocaleDateString() == this.selectedDate.toLocaleDateString() && this.data[index]) {
        this.information.push(this.data[index]);
      }
    });
  }

  monthChange() {
    this.localKnowledgeApiService.activeDate$.next(this.calendar.activeDate);
  }

  switchView(value?: boolean) {
    setTimeout(() => {
      this.dayInformationEnabled = value ?? !this.dayInformationEnabled;
      if (this.dayInformationEnabled == false) {
        this.selectedDate = '';
        this.information = this.monthInformation
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.localKnowledgeApiService.localKnowledge$.next({});
    if (this.map.box.hasLayer(this.eventsLayer)) {
      this.map.box.removeLayer(this.eventsLayer);
    }
  }
}
