<compass-top-toolbar-wrapper [toolbarSetUp]="toolbarSetUp">
  <ng-container right>
    <compass-current-user-name></compass-current-user-name>
    <button
      mat-icon-button mat-button class="navbar-btn"
      title="Ver mi plan" (click)="this.toggleBasket()"
    >
      <mat-icon [matBadge]="poiBasketService.count$ | async" matBadgeColor="warn">shopping_basket</mat-icon>
    </button>
    <gear-menu-nav [crudList]="crudMenuList$ | async"></gear-menu-nav>
  </ng-container>
</compass-top-toolbar-wrapper>
