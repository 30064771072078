import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';


export interface RangeSliderValue {
  range: number[];
  data?: any[];
  id: string;
}

@Injectable({ providedIn: "root" })
export class RangeSliderService {
  public rangeSlider$: BehaviorSubject<RangeSliderValue> = new BehaviorSubject<RangeSliderValue>(undefined);
  constructor(public http: HttpClient) { }

  // map observables
  setRangeSlider(range: RangeSliderValue) {
    this.rangeSlider$.next(range);
  }
  getRangeSlider(): Observable<RangeSliderValue> {
    return this.rangeSlider$.asObservable();
  }
}
