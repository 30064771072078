import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleEsService } from "./locale-es.service";
import { TooltipStyleService } from "./tooltipStyle.service";
import { DimensionsService } from "./dimensiones.service";
import { DefaultVarsService } from "./default-vars.service";
import { AxisTitleService } from "./axis-title.service";
import { MeasureService } from "./measure.service";
import { GroupedHorizontalBarchartComponent } from './grouped-horizontal-barchart/grouped-horizontal-barchart.component';
import { GroupedVerticalBarchartComponent } from './grouped-vertical-barchart/grouped-vertical-barchart.component';
import { GroupedHorizontalBarLayoutService } from "./layout-wrappers/grouped-horizontal-bar-layout.service";
import { GroupedVerticalBarLayoutService } from './layout-wrappers/grouped-vertical-bar-layout.service';


import { PieChartLayoutService } from './layout-wrappers/pie-chart-layout.service';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { HeatMatrixComponent } from './heat-matrix/heat-matrix.component';
import { HeatMatrixLayoutService } from './layout-wrappers/heat-matrix-layout.service';
import { HeatMatrixReshapeService } from './heat-matrix/heat-matrix-reshape.service';
import { RangeSliderLayoutService } from './layout-wrappers/range-slider-layout.service';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { RangeSliderService } from './range-slider/range-slider.service';
@NgModule({
  imports: [CommonModule],
  providers: [
    LocaleEsService,
    TooltipStyleService,
    DimensionsService,
    DefaultVarsService,
    AxisTitleService,
    MeasureService,
    GroupedHorizontalBarLayoutService,
    GroupedVerticalBarLayoutService,
    PieChartLayoutService,
    HeatMatrixLayoutService,
    HeatMatrixReshapeService,
    RangeSliderLayoutService,
    RangeSliderService
  ],
  declarations: [
    PieChartComponent,
    GroupedHorizontalBarchartComponent,
    GroupedVerticalBarchartComponent,
    HeatMatrixComponent,
    RangeSliderComponent

  ],
  exports: [
    PieChartComponent,
    GroupedHorizontalBarchartComponent,
    GroupedVerticalBarchartComponent,
    HeatMatrixComponent,
    RangeSliderComponent
  ]
})
export class UtilsD3Module { }
