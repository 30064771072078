import { Component, OnInit } from '@angular/core';
import { PoiService } from '@compass/pois/data-access-poi';
import { GroupedVerticalBarLayoutService } from '@compass/utils/d3';
import { PopularTimeDataService } from '@compass/pois/extended-data-preview';

@Component({
  selector: 'compass-popular-time',
  templateUrl: './popular-time.component.html',
  styleUrls: ['./popular-time.component.scss']
})

export class PopularTimeComponent implements OnInit {
  marker: any;
  hour = new Date();
  selection = '0';
  chartData;
  chartLayout;
  matrixPopularTimeData: any;

  constructor(
    private poiService: PoiService,
    private groupedVerticalBarLayoutService: GroupedVerticalBarLayoutService,
    private popularTimeService: PopularTimeDataService
  ) {

  }


  ngOnInit(): void {
    this.poiService.selectedPoi$.subscribe((data) => {
      this.marker = data.actualMarker.info.properties;
      this.matrixPopularTimeData = this.popularTimeService.getPoiPopularTimes(data.actualMarker.info);
      this.restartData();
    });
  }

  getChartData(data) {
    const toReturn =
      {
        key: 'total',
        format: 'visitas',
        categories: 'horas',
        children: []
      };
    if (data.goo?.popular_time?.days[this.selection] && data.goo.popular_time.days[this.selection] != 'closed') {
      for (let i = 0; i < 24; i++) {
        if (data.goo.popular_time.days[this.selection][i] != undefined) {
          toReturn.children.push({
            category: i,
            total: data.goo.popular_time.days[this.selection][i]
          });
        }
      }
    } else {
      toReturn.children.push({
        category: 'Cerrado',
        total: 0
      });
    }

    return toReturn;
  }

  selectOnChange() {
    this.chartData = this.getChartData(this.marker);
    this.chartLayout = this.groupedVerticalBarLayoutService.getLayout('indicators_bars')[0];
    this.chartLayout['data'] = this.getChartData(this.marker);
    this.hour = new Date();

  }

  restartData() {
    if (this.selection) {
      this.selection = '1';
      this.selectOnChange();
    }
  }

}
