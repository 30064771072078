import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: "root" })
export class CatastroStatusService {
  public layerOn$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor() { }

  setLayerOn(layer) {
    if (this.layerOn$.value !== layer) {
      this.layerOn$.next(layer);
    }
    return false;
  }
  getLayerOn(): Observable<string> {
    return this.layerOn$.asObservable();
  }

}