import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SlideToggleService {
  public slideToggleIsOn$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(true);
  constructor() {}

  public slideSetup = {
    placeholder: {
      on: '',
      off: '',
    },
    color: 'primary',
    checked: this.slideToggleIsOn$.value,
    disabled: false,
    disableRipple: false,
    id: 'clusters_slide',
    labelPosition: 'after',
    name: 'clusters_slide',
    required: true,
  };

  // true -- false
  setSlideToggle(status) {
    if (this.slideToggleIsOn$.value !== status) {
      this.slideToggleIsOn$.next(status);
    }
    return false;
  }
  getSlideToggle(): Observable<boolean> {
    return this.slideToggleIsOn$.asObservable();
  }
}
