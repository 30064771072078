import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MapBoxService {
  private TOKEN = 'pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg';

  constructor(private http: HttpClient) {
  }

  getIsochrone(profile: string, coordinates: string[], minutes: number) {
    const validProfiles = ['walking', 'cycling', 'driving'];
    const validMinutes = [5, 10, 15];
    if(!validProfiles.includes(profile)) {
      throw new Error(`Invalid profile (${profile}) for Isochrone API call. It should be: ${validProfiles.join(', ')}`);
    }

    if(!validMinutes.includes(minutes)) {
      throw new Error(`Invalid minutes (${profile}) for Isochrone API call. It should be: ${validMinutes.join(', ')}`);
    }

    if(coordinates.length !== 2) {
      throw new Error(`Invalid coordinates for Isochrone API call. It should be an array [long: string, lat: string]`);
    }
    // @ts-ignore
    coordinates = `${coordinates[0]},${coordinates[1]}`;
    const generalize = 100;

    return this.http.get(`https://api.mapbox.com/isochrone/v1/mapbox/${profile}/${coordinates}.json?contours_minutes=${minutes}&denoise=1&generalize=${generalize}&access_token=${this.TOKEN}`);
  }

  // basic, light, dark
  getMapBoxUrl = (style) => {
    switch (style) {
      case 'light':
        return [
          {
            key: 'light',
            value: `https://api.mapbox.com/styles/v1/adegracia/cjacly1ud4fg52rpkca3b85hj/tiles/256/{z}/{x}/{y}?access_token=${this.TOKEN}`
          }
        ];
        break;
      case 'dark':
        return [
          {
            key: 'dark',
            value: `https://api.mapbox.com/styles/v1/adegracia/ckc5y66vk0rqq1io1mjiiyq0i/tiles/256/{z}/{x}/{y}?access_token=${this.TOKEN}`
          }
        ];
        break;
      case 'transport_light':
        return [
          {
            key: 'transport_light',
            value: 'https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=0b5c3b0e9ca944fc86d8fb7d1d88da4c'
          }
        ];
        break;
      default:
        return [
          {
            key: 'basic',
            value:
              'https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw'
          }
        ];


    }

  };


}

//  TOKENS from the past
// basic
// "https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token=",
// pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw

// Light
// "https://api.mapbox.com/styles/v1/adegracia/cjacly1ud4fg52rpkca3b85hj/tiles/256/{z}/{x}/{y}?access_token=",
// "pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg",

// Dark
// https://api.mapbox.com/styles/v1/adegracia/ckc5y66vk0rqq1io1mjiiyq0i.html?fresh=true&title=view&access_token=
// pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg



