import { Injectable } from "@angular/core";


export interface Options {
    value: string | number,
    name: string,
    children?: Array<Options>,
    color?: string
}

@Injectable({ providedIn: "root" })
export class InfluencersFormOptionsService {

    /// google 10c colors
    options: Array<Options> = [
        { value: 'activewear', name: 'Activewear', color: '#3366cc' },
        { value: 'arte_y_diseno', name: 'Arte y diseño', color: '#dc3912' },
        { value: 'belleza_y_cosmeticos', name: 'Belleza y cosmeticos', color: '#ff9900' },
        { value: 'beer_wine_spirits', name: 'Beer, Wine & Spirits', color: '#dd4477' },
        { value: 'fotografia', name: 'Fotografía', color: '#dd4477' },
        { value: 'automocion', name: 'Automoción', color: '#109618' },
        { value: 'moda', name: 'Moda', color: '#109618' },
        { value: 'cafe_y_te', name: 'Café y té', color: '#109618' },
        { value: 'electronics_computers', name: 'Electronics & Computers', color: '#109618' },
        { value: 'fitness_yoga', name: 'Fitness & Yoga', color: '#109618' },
        { value: 'familia_amigos_relaciones', name: 'Familia, amigos y relaciones', color: '#109618' },
        { value: 'gaming', name: 'Gaming', color: '#109618' },
        { value: 'healthy_lifestyle', name: 'Healthy Lifestyle', color: '#109618' },
        { value: 'home_decor_furniture_garden', name: 'Home Decor, Furniture & Garden', color: '#109618' },
        { value: 'joyas', name: 'Joyas', color: '#109620' },
        { value: 'lujo', name: 'Lujo', color: '#109618' },
        { value: 'musica', name: 'Musica', color: '#109618' },
        { value: 'mascotas', name: 'Mascotas', color: '#109618' },
        { value: 'restaurants_food_grocery', name: 'Restaurants, Food & Grocery', color: '#109618' },
        { value: 'shopping_retail', name: 'Shopping & Retail', color: '#109618' },
        { value: 'deportes', name: 'Deportes', color: '#109618' },
        { value: 'tv', name: 'TV', color: '#109618' },
        { value: 'juguetes_ninos_bebes', name: 'Juguetes,niños y bebés', color: '#109618' },
        { value: 'viajes', name: 'Viajes', color: '#109618' }

    ];


    public get(options) {
        return this[options];
    }
}