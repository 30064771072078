<compass-stage-breadcrumbs></compass-stage-breadcrumbs>

<ul class="stage ul__menu">
  <li *ngFor="let item of media; let i = index" (click)="onClickMedia(item, i, $event)">
    <a href="" [class.active]="parentIndex === i" class="parent" [attr.data-cy]="item.key + '_link'">
      <span class="material-icons parent">
        {{ item.icon }}
      </span>

      <span class="parent">
        {{ item.value }}
      </span>
    </a>
    <ul class="stage ul__menu">
      <li *ngFor="let child of item.children; let j = index" (click)="onClickChild(child, j, item.key)"
        [attr.data-cy]="child.key + '_link'">
        <a href="" [class.active]="childIndex === j && parentIndex === i">{{
          child.value
          }}</a>
      </li>
    </ul>
  </li>
</ul>