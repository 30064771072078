<h2 mat-dialog-title>¡Atención!</h2>

<mat-dialog-content>
    <p>Lo sentimos.</p>
    <p>La búsqueda que está realizando no devuelve resultados.</p>
    <p>Por favor, realice otra selección.</p>
</mat-dialog-content>

<mat-dialog-actions>

    <button mat-flat-button class='button--xl' type="cancel" [disabled]=false (click)="close($event)">
        <mat-icon>cancel</mat-icon>Cerrar
    </button>
</mat-dialog-actions>