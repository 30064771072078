import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

export const BRICK_DATA_ATTR = 'data_portals2019';

@Injectable({ providedIn: 'root' })
export class BricksApiService {
  // dev urls
  // private fullStagesUrl = 'assets/data/indicators/query_bricks_by_area_821.json';
  private fullStagesUrl = '/abacusgis/core/glocally/collection/area_brick_hexagon_medium';

  // PRO SERVICES
  public bricks$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public areaPortals$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public areaTransit$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  public localMedia$: BehaviorSubject<any> = new BehaviorSubject(undefined); // to be done

  public fullStages$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public dataSB: any;
  public study: any;

  constructor(public http: HttpClient, private router: Router) {
  }

  // PRO API
  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  // MAIN API:
  public getDataSB() {
    return this.dataSB;
  }

  public async setDataSB(data: any) {
    this.dataSB = data;
  }

  getById(id: number) {
    return new Observable((observer) => {
      let watchId: number;

      if (!this.bricks$.value) {
        this.getData();
      } else {
        if (this.bricks$.value.length) {
          this.bricks$.value.forEach(brick => {
            if (brick.properties.id === id) {
              observer.next(brick);
            }
          });
        }
      }

      return {
        unsubscribe() {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    });
  }

  async getAreaName(data, route) {
    if (data && data.properties) {
      const areasData = data.properties[route];
      if (!areasData) {
        if (data.properties.areas) {
          // initialize bricks_areas
          if (!this.dataSB.properties?.bricks_areas?.length) {
            this.dataSB.properties.bricks_areas = [];
          }

          // can be multiple areas in case of MultiPolygon
          await this.getFullStagesData(data).subscribe((data) => {
            this.dataSB.properties.bricks_areas = this.dataSB.properties.bricks_areas.concat(data);
          });
        }
      }

      return areasData ? Object.keys(areasData)[0] : '';
    } else {
      throw 'Glocally Error => data or data.properties don´t exists';
    }
  }

  filterBricksAreas(data, bricksAreasName) {
    if (bricksAreasName) {
      if (data?.properties?.bricks_areas[bricksAreasName]) {
        let bricks = data['properties'].bricks_areas[bricksAreasName].filter((brick) => {
          return brick.properties?.data_portals2019 && brick.geometry?.type === 'Polygon';
        });

        return bricks;
      } else {
        throw (
          'Glocally Error => bricks_areas don´t exists or area ' +
          bricksAreasName +
          'is not accesible'
        );
      }
    }
  }

  getAreaProperties(data, areasName) {
    if (data.properties.areas && data.properties.areas[areasName]) {
      let area_properties = data.properties.areas[areasName]?.properties;
      return area_properties;
    } else {
      throw (`Glocally Error => data.properties don´t exists or area ${areasName} is not accesible`);
    }
  }

  public async getData() {
    let data = this.getDataSB();
    let bricksAreasName: string = await this.getAreaName(data, 'bricks_areas');
    let areasName: string = await this.getAreaName(data, 'areas');
    // properties --> BRICK_AREAS
    let bricks: any = this.filterBricksAreas(data, bricksAreasName);
    // properties --> AREAS
    let area_properties: any = this.getAreaProperties(data, areasName);

    // SET ALL THE DATA
    this.bricks$.next(bricks);
    try {
      if (area_properties.data_portals2019) {
        this.areaPortals$.next(area_properties.data_portals2019);
      }
    } catch (e) {
      console.error('Glocally Error => It has been imposible to process area_properties on the try catch block', e);

      // SET fullSTAGES data
    } finally {
      this.fullStages$.next(data);
    }
  };

  public getFullStagesData(study) {
    return this.http.get(`${this.fullStagesUrl}?study_id=${study.properties.id}`).pipe(
      catchError(this.handleError),
      map((bricks: any) => {
        // add to BRICK_DATA_ATTR (properties.data_portals2019) new calculated attributes by Jose Luis
        bricks = bricks.map((brick: any) => {
          brick.properties[BRICK_DATA_ATTR].data_others = brick.properties.data_others;
          brick.properties[BRICK_DATA_ATTR].calc = brick.properties.calc;

          return brick;
        });

        this.bricks$.next(bricks);

        // add to study.properties.areas.[manual|waling|wathever].properties.data_portals2019 new calculated
        // attributes average if not calculated
        if (this.dataSB?.properties?.id) {
          const areas = this.dataSB.properties?.areas;
          if (areas) {

            /**
             * Return average of each property of calculated attributes
             * @param attr
             */
            const getAverage = (attr: string, actualAverage: any) => {
              const average = { ...(actualAverage ?? {}) };

              bricks.forEach(brick => {
                if (brick.properties?.[attr]) {
                  Object.keys(brick.properties[attr]).forEach(subAttr => {
                    // when no data in actualAverage
                    if (!actualAverage?.[subAttr]) {
                      average[subAttr] = average?.[subAttr] ?? 0;
                      average[subAttr] += brick.properties[attr][subAttr];
                    }
                  });
                }
              });

              Object.keys(average).forEach(subAttr => {
                // when no data in actualAverage
                if (!actualAverage?.[subAttr]) {
                  average[subAttr] = (average[subAttr] ?? 0) / (bricks.length ?? 1);
                }

              });

              return average;
            };

            Object.keys(areas).forEach(areaType => {
              // foreach new calculated attributes, get the average an insert it
              if (areas[areaType]?.properties?.[BRICK_DATA_ATTR]) {
                areas[areaType].properties[BRICK_DATA_ATTR].calc = getAverage('calc', areas[areaType].properties[BRICK_DATA_ATTR].calc);
                areas[areaType].properties[BRICK_DATA_ATTR].data_others = getAverage('data_others', areas[areaType].properties[BRICK_DATA_ATTR].data_others);
              }
            });
          }
        }

        return bricks;
      })
    );
  };

  // PRO API

  public resetAllBrickLists() {
    this.fullStages$.next(undefined);
    this.bricks$.next(undefined);
    this.areaPortals$.next(undefined);
    this.areaTransit$.next(undefined);
    this.localMedia$.next(undefined);
  }

  public getFullStages(): Observable<any> {
    return this.fullStages$.asObservable();
  }

  public getFullBricks(): Observable<any> {
    return this.bricks$.asObservable();
  }

  public getAreaTransit(): Observable<any> {
    return this.areaTransit$.asObservable();
  }

  public getAreaPortals(): Observable<any> {
    return this.areaPortals$.asObservable();
  }

  //  // to be done --> localmediaApiService
  public getLocalMedia() {
    return this.localMedia$.asObservable();
  }

  public setLocalMedia(media) {
    this.localMedia$.next(media);
  }

}
