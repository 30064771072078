import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { TomtomStylesService } from './tomtom-styles.service';
import { TomtomMapService } from './tomtom-map.service';

import { TomtomTrafficSelectComponent } from './tomtom-traffic-select/tomtom-traffic-select.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [TomtomTrafficSelectComponent],
  exports: [TomtomTrafficSelectComponent],
  providers: [TomtomStylesService, TomtomMapService]
})
export class TrafficModule { }
