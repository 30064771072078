import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { DefaultVarsService, DimensionsService } from '@compass/utils/d3';
import * as d3 from 'd3';
import { ColorsService } from '@compass/utils/misc';


interface WeekDot {
  x: any; // day
  y: string; // hour
  value: number;
  selected?: boolean; // selected flag
}

@Component({
  selector: 'compass-popular-time-week-matrix',
  templateUrl: './popular-time-week-matrix.component.html',
  styleUrls: ['./popular-time-week-matrix.component.scss']
})
export class PopularTimeWeekMatrixComponent {
  @Input() data: WeekDot[];
  @Input() legendActive: boolean = true;
  @Input() baseZero: boolean = false;
  @Input() inputScale: any[];
  @Input() inputLegendText: any[];
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();

  tooltipValues = {
    font_size: '12px',
    background_color: this.colorsService.colors.white,
    padding: '8px',
    position: 'absolute',
    z_index: 99999,
    font_weight: 'bold',
    text_anchor: 'middle',
    fill_text: this.colorsService.colors.black,
    opacity_lowest: 0.5,
    high_opacity: 1
  };

  layout = {
    // vertical bar chart
    key: 'popular_times_heatmap',
    class: 'heatmap popular-times',
    section: 'popular-times',
    innerRadius: 2,
    height: 424,
    width: null,
    margin: { top: 24, right: 16, bottom: 64, left: 56 },
    stroke: {
      width: 0.2,
      stroke: this.colorsService.colors.black
    },
    //white, green, yellow, red n
    colors: ['#ffffff', '#eff8a9', '#e1f3a0', '#a7dba1', '#fed080', '#fcb668', '#f58450', '#ef6e4a', '#e0504a'],
    paddingInner: 0.01,
    padding: 0.01,
    tickSize: 4, // 0 to 100 to see de ticks
    removeDomain: false, // false if you want to see the axis
    borderRadius: 2,
    align: 0.1,
    averages: {},
    legend: {
      fontSize: '10px',
      visible: true,
      width: 200,
      height: 16,
      steps: 4,
      margin: { top: 24, right: 8, bottom: 8, left: 8 },
      text: ['Poco ocupado', 'Muy ocupado']
    },

    xLabels: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
    //yLabels: ["6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM", "12AM", "1AM", "2AM", "3AM", "4AM", "5AM"],
    yLabels: ['6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM', '12AM'],
    lang: 'es',
    tooltip: {
      id: 'popular_times_tip',
      style: this.tooltipValues,
      header: null//   key [string, "personas_hogar"], value [number], category[string, "Resto"] or index[string, "tienda_3_Resto"]
    },
    default_time: this.defaultVarsService.default_time
  };

  win: any = window; // in use

  // data
  style: any;
  rawData: WeekDot[];
  xLabels: Array<string>;
  yLabels: Array<string>;
  // var
  timeout: any = false;
  resize_delay = 0;
  default_time: number;
  key: string;
  class: string;

  //containers
  container: any;
  svg: any;
  chartLegend: any;
  legendColors: any;
  legendTexts: any;
  chartOuter: any;
  chartInner: any;

  // sizes
  margin: any;
  height = 500;
  width: number;
  // Scales;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  colors: Array<string>;
  colorScale: any;
  // axis
  xAxis: any;
  yAxis: any;
  tickSize: number;
  removeDomain: boolean;
  borderRadius: number;

  // lang and dictionries
  dictionary: Array<any>;
  lang: string;
  // color max min
  maxVal: number | undefined;
  minVal: number | undefined;

  // color legend
  rectData: any;
  legend: any;
  legendScale: any;
  lAxis: any;

  // tooltip
  vBody: any;
  tipLayout: any;
  tipHtml: any;

  event: any;

  constructor(
    private dimensionsService: DimensionsService,
    private colorsService: ColorsService,
    private defaultVarsService: DefaultVarsService
  ) {
    // generate a dynamic key to use multiple graphs on same view
    this.key = this.layout.key + Math.floor(Math.random() * 10000000);
    this.layout.tooltip.id += this.key;
  }


  ngAfterViewInit(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.init();
    }, this.resize_delay);

    //window.dispatchEvent(new Event('resize'));
  }

  public init(): void {
    //this.destroyTooltip();
    this.destroyChart();
    this.runAll();
  }

  public destroyChart = (): void => {
    d3.select('#' + this.key).html('');
    // Stop resize events
    d3.select(this.win).on('resize', null);
  };

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    //console.log('changes', changes, this.svg, this.chartSelected, this.key, this.margin);
    const changeObj = Object.keys(changes);

    this.vBody = d3.select('body');
    // this.key = this.layout.key;
    this.class = this.layout.class;
    this.height = this.layout.height;
    this.margin = this.layout.margin;
    this.legend = this.layout.legend;

    this.resize_delay = this.layout.default_time;
    this.colors = this.inputScale ?? this.layout.colors ? this.layout.colors : ['#ff0000', '#ffb200', '#00A100'];

    // not in use
    this.tickSize = this.layout.tickSize === 0 ? 0 : this.layout.tickSize ? this.layout.tickSize : 4;
    this.borderRadius = this.layout.borderRadius ? this.layout.borderRadius : 0;

    this.removeDomain = this.layout.removeDomain;
    this.tipLayout = this.layout.tooltip;


    if (changes.data && changeObj.length === 1) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reshapedata();
      }, this.resize_delay / 4);

    }
  }


  reshapedata = (): void => {
    this.rawData = this.data.sort((a: WeekDot, b: WeekDot) => d3.ascending(a.x, b.x));
    this.maxVal = d3.max(this.rawData, (d: WeekDot) => d.value);
    this.minVal = !this.baseZero ? d3.min(this.rawData, (d: WeekDot) => d.value) : 0;

    // for scale
    this.xDomain = Array.from(new Set(this.rawData.map(d => d.x)));
    this.yDomain = Array.from(new Set(this.rawData.map(d => d.y)));

    // labels
    this.xLabels = this.layout.xLabels ? this.layout.xLabels : this.xDomain;
    this.yLabels = this.layout.yLabels ? this.layout.yLabels : this.yDomain;

    this.yAxis?.tickFormat((d: any, i: number) => this.yLabels[i]);
    this.xAxis?.tickFormat((d: any, i: number) => this.xLabels[i]);

    this.render();
  };

  private drawAxis() {
    this.chartOuter
      .selectAll('.x.axis')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(this.default_time)
      .call(this.xAxis);


    this.chartOuter
      .selectAll('.y.axis')
      .attr('transform', 'translate(0, 0)')
      .transition()
      .duration(this.default_time)
      .call(this.yAxis);

    if (this.removeDomain) {
      this.chartOuter
        .selectAll('.domain').remove();
    }
  }

  public mouseover(d: any, i: number, arr: []): void {
    //console.log('overrr', this);
    d3.select(arr[i]).style('opacity', 0.8);
    if (d.value) {
      this.tipHtml
        .style('cursor', 'pointer')
        .style('width', 'auto')
        .style('height', 'auto')
        .style('display', null)
        .style('box-shadow', '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)')
        .style('opacity', 0.95);
    }
  }


  public mousemove(d: any): void {
    if (this.event && d.value) {
      this.tipHtml
        .html(d.value)
        .style('left', this.event.pageX - 40 + 'px')
        .style('top', this.event.pageY - 40 + 'px');
    }
  }


  public mouseout(d: any, i: number, arr: []): void {
    d3.select(arr[i]).style('opacity', 1);
    this.tipHtml.style('opacity', 0).style('display', 'none');
  }


  private drawCells(): void {
    let cells = this.chartInner
      .selectAll('rect.cell')
      .data(this.rawData, (d: WeekDot): string => {
        return d.x + ':' + d.y;
      });

    cells
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    cells
      .enter()
      .append('svg:rect')
      .attr('class', 'cell')
      .attr('x', (d: WeekDot): number => this.xScale(d.x))
      .attr('y', (d: WeekDot): number => this.yScale(d.y))
      .attr('rx', this.borderRadius)
      .attr('ry', this.borderRadius)
      .attr('width', this.xScale.bandwidth())
      .attr('height', this.yScale.bandwidth())
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0)
      .attr('fill', (d: WeekDot): string => this.colorScale(d.value));

    cells = this.chartInner.selectAll('rect.cell');

    cells
      .transition()
      .duration(this.default_time)
      .on('start', (d: any, i: number, arr: []) => {
        d3.select(arr[i])
          .on('mouseover', null)
          .on('mousemove', null)
          .on('mouseout', null)
          .on('click', null);
      })
      .attr('x', (d: WeekDot): number => this.xScale(d.x))
      .attr('y', (d: WeekDot): number => this.yScale(d.y))
      .attr('height', this.yScale.bandwidth())
      .attr('width', this.xScale.bandwidth())
      .attr('fill-opacity', 1)
      .attr('cursor', 'pointer')
      .attr('stroke-opacity', 1)
      .attr('stroke-width', (dot: any) => dot?.selected ? 2 : 0)
      .attr('stroke', (dot: any) => dot?.selected ? '#0055ff' : 'white')
      .attr('fill', (d: WeekDot): string => d.value > 0 ? this.colorScale(d.value) : 'white')
      //.attr('fill', (d: WeekDot) => this.colorScale(d.value))
      .on('end', (d: any, i: number, arr: []) => {
        d3.select(arr[i])
          .on('mouseover', () => this.mouseover(d, i, arr))
          .on('mousemove', (event) => this.mousemove(d))
          .on('mouseout', () => this.mouseout(d, i, arr))
          .on('click', (data: any, someVar: any, cell: any) => {
            this.cellClicked.emit({ data, cell});
          });
      });
  }

  private drawLegendColors(): void {
    const width = this.layout.legend.width;
    const height = this.layout.legend.height;
    const steps = this.colors.length;
    const colors = this.inputScale ?? this.colors;

    let legendRects = this.legendColors
      .selectAll('rect.legendRect')
      .data(colors);

    legendRects
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendRects
      .enter()
      .append('svg:rect')
      .attr('class', 'legendRect')
      .attr('x', (d: any, i: number) => (width / steps) * i)
      .attr('y', 0)
      .attr('height', height)
      .attr('width', width / steps)
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0)
      .attr('fill', (d: any, i: number) => colors[i]);

    legendRects = this.legendColors.selectAll('rect.legendRect');
    legendRects
      .transition()
      .duration(this.default_time)
      .attr('x', (d: any, i: number) => (width / steps) * i)
      .attr('y', 0)
      .attr('height', height)
      .attr('width', width / steps)
      .attr('fill-opacity', 1)
      .attr('stroke-opacity', 1)
      .attr('fill', (d: any, i: number) => colors[i]);
  }

  private drawLegendTexts(): void {
    const width = this.layout.legend.width;
    const height = this.layout.legend.height;

    let legendTxt = this.legendTexts
      .selectAll('text.legendTxt')
      .data(this.inputLegendText ?? this.layout.legend.text);

    legendTxt
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendTxt
      .enter()
      .append('svg:text')
      .attr('class', 'legendTxt')
      .attr('x', (d: any, i: number) => {
        return width * i;
      })
      .attr('y', +this.layout.legend.margin.top)
      .attr('dy', '.35em')
      .attr('text-anchor', (d: string, i: number) => {
        return i === 0 ? 'start' : 'end';
      })
      .attr('font-size', this.layout.legend.fontSize)
      .text((d: string) => d);


    legendTxt = this.legendTexts.selectAll('text.legendTxt');
    legendTxt
      .transition()
      .duration(this.default_time)
      .attr('x', (d: any, i: number) => width * i)
      .attr('y', +this.layout.legend.margin.top)
      .attr('dy', '.35em')
      .attr('text-anchor', (d: string, i: number) => {
        return i === 0 ? 'start' : 'end';
      })
      .attr('font-size', this.layout.legend.fontSize)
      .text((d: string) => d);

  }

  private drawLegend(): void {
    this.drawLegendColors();
    this.drawLegendTexts();
  }

  destroyTooltip(): void {
    this.tipHtml = null;
    this.vBody
      .select('#' + this.tipLayout.id)
      .html('')
      .remove();
  }

  public drawTooltip = (): void => {
    this.tipHtml = this.vBody
      .append('div')
      .attr('class', 'tooltip')
      .attr('id', this.tipLayout.id)
      .style('z-index', this.tipLayout.style.z_index)
      .style('font-size', this.tipLayout.style.font_size)
      .style('background-color', this.tipLayout.style.background_color)
      .style('padding', this.tipLayout.style.padding)
      .style('position', this.tipLayout.style.position)
      .style('opacity', 0); //  --> tooltip
  };


  public render(): void {
    if(!this.svg) {
      this.runAll();
    }

    const dimensions: any = this.dimensionsService.getDimensions(
      this.svg,
      '#' + this.key,
      this.margin
    );

    if(dimensions?.width && dimensions?.height) {
      this.width = dimensions?.width;
      this.height = dimensions?.height;

      this.xScale.domain(this.xDomain).range([0, this.width]);
      this.yScale.domain(this.yDomain).range([0, this.height]);
      this.colorScale.domain([this.minVal, this.maxVal]).range(this.inputScale ?? this.colors);

      // Apply to svg
      this.svg
        .attr('width', this.width + this.margin.right + this.margin.left)
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .attr(
          'viewBox',
          '0 0 ' +
          (this.width + this.margin.left + this.margin.right) +
          ' ' +
          (this.height + this.margin.top + this.margin.bottom)
        )
        .attr('preserveAspectRatio', 'xMaxYMax meet');

      this.destroyTooltip();
      this.drawTooltip();
      this.drawAxis();
      this.drawCells();
      if (this.legendActive) {
        this.drawLegend();
      }
    }
  }


  public runAll(): void {
    // SCALES HERE
    this.xScale = d3.scaleBand().paddingInner(this.layout.paddingInner);
    this.yScale = d3.scaleBand().padding(this.layout.padding);
    this.xAxis = d3.axisBottom(this.xScale).tickSize(this.tickSize);
    this.yAxis = d3.axisLeft(this.yScale).tickSize(this.tickSize);

    //   this.colorScale.domain([this.minVal, this.maxVal]).range(this.colors);
    this.colorScale = d3.scaleQuantize();


    // ELEMENTS
    this.container = d3.select('#' + this.key); // placeholder div for svg
    this.svg = this.container
      .selectAll('svg')
      .data([{}])
      .enter()
      .append('svg:svg');

    this.chartLegend = this.svg
      .selectAll('g.chartLegend')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartLegend')
      .attr('transform', 'translate(' + this.margin.left + ',' + (this.height - this.margin.bottom) + ')');

    this.legendColors = this.chartLegend
      .selectAll('g.legendColors') // chart without axis to clipPath
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'legendColors')
      .attr('transform', 'translate(' + 0 + ',' + this.legend.margin.top + ')');

    this.legendTexts = this.chartLegend
      .selectAll('text.legendText') // chart without axis to clipPath
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'legendText')
      .attr('transform', 'translate(' + 0 + ',' + this.legend.margin.top + ')');


    this.chartOuter = this.svg
      .selectAll('g.chartOuter')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartOuter')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );

    this.chartInner = this.chartOuter
      .selectAll('g.chartInner') // chart without axis to clipPath
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartInner');

    // Axis groups
    this.chartOuter
      .selectAll('.x.axis')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'x axis');
    this.chartOuter
      .selectAll('.y.axis')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'y axis');
    this.win.addEventListener('resize', () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.render();
      }, this.resize_delay);
    });

    this.reshapedata();
  };

  setEvent(event: any) {
    this.event = event;
  }
}
