import { Component, OnDestroy } from '@angular/core';

import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';

import { CreateStudyService } from '@compass/feature-create-study';
import {
  CompassMapDrawerService,
  CompassMapWrapperService
} from '@compass/utils/leaflet';
import { SideNavStatusService } from '@compass/utils/navigation';
import { MapBoxService } from '@compass/utils/misc';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-form-point',
  templateUrl: './form-point.component.html',
  styleUrls: ['./form-point.component.css']
})
export class FormPointComponent implements OnDestroy {
  @Output() activeModeEmitter = new EventEmitter<any>();
  @Output() activeDistanceEmitter = new EventEmitter<any>();

  disabled: boolean = false;
  form: FormGroup;
  selectedMode: string = 'foot';
  selectedDistance: string = '00_05';
  selectedLatitude: number = 40.41711;
  selectedLongitude: number = -3.703553;
  selectedDirection: string = 'Puerta del Sol';
  selectedLocality: string = '';
  studyServiceSubscription: Subscription;

  constructor(
    private createStudyService: CreateStudyService,
    private compassMapDrawerService: CompassMapDrawerService,
    private compassMapWrapperService: CompassMapWrapperService,
    private sideNavStatusService: SideNavStatusService,
    private mapBoxService: MapBoxService
  ) {
  }

  ngOnInit(): void {
    this.compassMapWrapperService.createMakerMap(true);
    let coordsCenter = this.createStudyService.getCoordsMarker();
    this.selectedLatitude = coordsCenter[0];
    this.selectedLongitude = coordsCenter[1];
    this.selectedDirection = this.createStudyService.getAddressMarker();
    this.selectedLocality = this.createStudyService.getLocalityMarker();

    if (this.createStudyService.getModeStudy()) {
      this.selectedMode = this.createStudyService.getModeStudy();
    }
    if (this.createStudyService.getDistanceStudy()) {
      this.selectedDistance = this.createStudyService.getDistanceStudy();
    }

    this.form = new FormGroup({
      direction: new FormControl(this.selectedDirection),
      latitude: new FormControl(this.selectedLatitude),
      longitude: new FormControl(this.selectedLongitude),
      mode: new FormControl(this.selectedMode),
      distance: new FormControl(this.selectedDistance)
    });

    this.createStudyService.setFormStudy(this.form);

    this.drawArea();

    this.form.valueChanges.subscribe(() => this.drawArea());

    this.studyServiceSubscription = this.createStudyService.studyParamsFormChanged$.subscribe((params) => {
      this.setParams(params);
      this.drawArea();
    });
  }

  ngOnDestroy() {
    this.studyServiceSubscription.unsubscribe();
    this.compassMapWrapperService.removeLayer('isochrone');
  }

  /**
   * Set form params.
   *
   * @param params
   */
  setParams(params: any) {
    this.selectedLatitude = params.y;
    this.selectedLongitude = params.x;
    this.selectedDirection = params.address;
    this.selectedLocality = params.locality;

    this.createStudyService.setAddressMarker(this.selectedDirection);

    this.createStudyService.setLocalityMarker(this.selectedLocality);

    this.form.get('direction').setValue(this.selectedDirection);
    this.form.get('latitude').setValue(this.selectedLatitude);
    this.form.get('longitude').setValue(this.selectedLongitude);
    this.form.get('mode').setValue(this.selectedMode);
    this.form.get('distance').setValue(this.selectedDistance);

    this.createStudyService.setFormStudy(this.form);
  }

  drawArea() {
    const formData = this.form.value;
    const profile = formData.mode === 'foot' ? 'walking' : (formData.mode === 'car' ? 'driving' : formData.mode);
    const coords = [formData.longitude, formData.latitude];
    const minutes = parseInt(formData.distance.replace('00_', ''));
    const isocrhoneSubscription = this.mapBoxService.getIsochrone(profile, coords, minutes).subscribe((res: any) => {
      isocrhoneSubscription.unsubscribe();
      const feature = res.features[0];
      this.createStudyService.setAreaPainted(JSON.stringify(feature.geometry.coordinates));

      this.compassMapWrapperService.drawFeatureLayer('isochrone', feature);
    });
  }

  emitActiveMode(value) {
    this.selectedMode = value;
    this.form.controls['mode'].setValue(this.selectedMode);
    this.createStudyService.setModeStudy(this.selectedMode);
    this.activeModeEmitter.emit({ selectedMode: this.selectedMode });
  }

  emitActiveDistance(value) {
    this.selectedDistance = value;
    this.form.controls['distance'].setValue(this.selectedDistance);
    this.createStudyService.setDistanceStudy(this.selectedDistance);
    this.activeDistanceEmitter.emit({
      selectedDistance: this.selectedDistance
    });
  }

  submit(value) {
    const result = value;
    // send value
  }


}
