import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidenavTransitService {
  public transitIsOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  constructor() {}
  setTransit(status) {
    if (this.transitIsOn$.value !== status) {
      this.transitIsOn$.next(status);
      localStorage.setItem('transitIsOn', JSON.stringify(status));
    }
    return false;
  }
  getTransit(): Observable<boolean> {
    return this.transitIsOn$.asObservable();
  }
}
