<div class="padding__basic">
  <div class="indicator" *ngFor="let indicator of indicators" fxLayout="column">
    <div class="indicator-title" [matTooltip]="indicator.name" matTooltipPosition="right">
      <strong>{{ indicator.screen_name }}</strong>
    </div>

    <div fxLayout="row" fxFlex="95" fxLayoutAlign="center center">
      <div fxFlex="15">Peso</div>
      <div fxFlex="60">
        <mat-slider
          thumbLabel tickInterval="5" step="5" min="1" max="100" aria-label="units"
          (ngModelChange)="calculateBricksIndex()"
          [(ngModel)]="indicator.weight"
          [displayWith]="sliderLabel"
        ></mat-slider>
      </div>
      <div fxFlex="25" class="text-center">
        <input
          type="number" class="range-input" min="1" max="100"
          [(ngModel)]="indicator.weight"
          (ngModelChange)="calculateBricksIndex()"
        >
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="30">Influencia</div>
      <div fxFlex="70">
        <mat-radio-group
          [(ngModel)]="indicator.influence"
          (ngModelChange)="calculateBricksIndex()"
          fxLayout="row" fxLayoutAlign="center center"
        >
          <mat-radio-button [value]="0" fxFlex="1 1 auto">Positiva</mat-radio-button>
          <mat-radio-button [value]="100" fxFlex="1 1 auto">Inversa</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div class="indicator" fxLayout="column">
    <div class="indicator-title accent" fxLayout="row" fxLayoutAlign="space-between center">
      <span>Índice</span>
      <span>{{ totalPercent }}%</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <span fxFlex="10" class="text__align--center">0</span>
      <div fxFlex="80" class="padding__basic">
        <mat-slider
          thumbLabel
          [(ngModel)]="globalIndex"
          (ngModelChange)="calculateBricksIndex()"
          tickInterval="5"
          step="5"
          min="0"
          max="100"
        ></mat-slider>
      </div>
      <span fxFlex="10" class="text__align--center">100</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="50">
      <mat-slide-toggle
        [(ngModel)]="removeExcludedBricks"
        matTooltip="Eliminar los bricks no activos, en caso de estar añadidos"
        matTooltipPosition="above"
      >
        <small>Solo activos</small>
      </mat-slide-toggle>
    </div>
    <div fxFlex="50">
      <button
        mat-raised-button color="warn"
        class="float-right margin__top--xl"
        (click)="addBricksToBasket()"
      >
        Añadir al plan
      </button>
    </div>
  </div>


</div>
