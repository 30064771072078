import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';

import { CreateStudyService } from '@compass/feature-create-study';
import { CompassMapMarkerService } from '@compass/utils/leaflet';

@Component({
  selector: 'compass-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.css']
})
export class FormButtonsComponent implements OnInit {
  @Output() activeFormEmitter = new EventEmitter<any>();
  selectedForm: string = 'point';
  letsDraw: boolean = false;
  cpsShape: boolean = false;

  constructor(
    private createStudyService: CreateStudyService,
    private compassMapMarkerService: CompassMapMarkerService
  ) {
  }

  emitActiveForm(value) {
    this.activeFormEmitter.emit({ selectedForm: this.selectedForm });
    this.createStudyService.setStudyTabSelected(this.selectedForm);

    this.letsDraw = this.selectedForm == 'manual';
    this.cpsShape = this.selectedForm == 'cp';

    this.createStudyService.setDrawShape(this.cpsShape);
    this.createStudyService.drawShapeCps(this.cpsShape);
    setTimeout(() => {
      this.createStudyService.drawShape(this.letsDraw);
    }, 200);

  }

  ngOnInit(): void {
    let tab = this.createStudyService.getStudyTabInit();
    if (tab) {
      this.selectedForm = tab;
      this.activeFormEmitter.emit({ selectedForm: this.selectedForm });
      this.emitActiveForm(tab);
    } else {
      this.selectedForm = 'point';
    }
    this.createStudyService.setStudyTabSelected(this.selectedForm);
    this.createStudyService.studyCanceledChanged$.subscribe((params) => {
      //this.selectedForm = "cp";
      this.selectedForm = 'point';
      this.createStudyService.setStudyTabSelected(this.selectedForm);
      this.activeFormEmitter.emit({ selectedForm: this.selectedForm });
      this.compassMapMarkerService.removeMarker();
    });

    //.
  }
}
