import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EgmAimcDataService } from './egm-aimc-data.service';
import { EgmAimcStatusService } from './egm-aimc-status.service';

@NgModule({
  imports: [CommonModule],
  providers: [EgmAimcDataService, EgmAimcStatusService],
})
export class EgmAimcDataAccessModule { }
