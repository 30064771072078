import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { SidenavTransitService } from '@compass/utils/navigation';
import { CompassMapWrapperService, Map } from '@compass/utils/leaflet';
import { LineTransitService } from '@compass/line-transit/data-access';

@Component({
  selector: 'compass-line-transit',
  templateUrl: './line-transit.component.html',
  styleUrls: ['./line-transit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineTransitComponent implements OnChanges {
  @Input() areaTransit: any;
  public checkboxText: string = 'Ocultar tránsito';
  percentileScale = this.lineTransitService.percentileScale;
  map$ = this.compassMapWrapperService.map$;
  setTransit = this.sidenavTransitService.setTransit;
  transitIsOn$ = this.sidenavTransitService.transitIsOn$;

  constructor(
    private sidenavTransitService: SidenavTransitService,
    private compassMapWrapperService: CompassMapWrapperService,
    private lineTransitService: LineTransitService
  ) {}

  onClickCheckbox(event: Event) {
    event.stopPropagation();
  }

  onChangeCheckbox(event: Event, state: boolean) {
    if (event) {
      if (state) {
        this.checkboxText = 'Ocultar tránsito';
        this.setTransit(state); // pdf observable
        this.lineTransitService.drawLayers(this.map$.value, this.areaTransit);
      } else {
        if (!state) {
          this.checkboxText = 'Ver tránsito';
          this.setTransit(state); // pdf observable
          this.lineTransitService.removeLayers();
        }
      }
    }
    if (!event) {
      return null;
    }
  }

  ngOnChanges(): void {
    this.lineTransitService.drawLayers(this.map$.value, this.areaTransit);
  }
}
