<!--Graphic-->
<div>
  <compass-popular-time-week-matrix *ngIf='matrixPopularTimeData' [data]='matrixPopularTimeData'></compass-popular-time-week-matrix>
</div>
<div>
  <h3>Horas punta</h3>
  <mat-form-field *ngIf='marker'>
    <mat-label>Día de la semana</mat-label>
    <mat-select [(value)]='selection' (selectionChange)='selectOnChange()' required>
      <mat-option value="1">
        Lunes
      </mat-option>
      <mat-option value="2">
        Martes
      </mat-option>
      <mat-option value="3">
        Miércoles
      </mat-option>
      <mat-option value="4">
        Jueves
      </mat-option>
      <mat-option value="5">
        Viernes
      </mat-option>
      <mat-option value="6">
        Sábado
      </mat-option>
      <mat-option value="0">
        Domingo
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div>
  <compass-grouped-vertical-barchart [hour]='hour' [chartLayout]='chartLayout'></compass-grouped-vertical-barchart>
</div>



<!--/Graphic-->
