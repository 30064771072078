import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PoiInterface } from '@compass/pois/data-access-poi';

@Component({
  selector: 'compass-poi-select',
  templateUrl: './poi-select.component.html',
  styleUrls: ['./poi-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PoiSelectComponent implements OnChanges {
  @Input() options: PoiInterface[] = [];

  @Input() selected: PoiInterface;
  @Output() selectedChange: EventEmitter<PoiInterface> = new EventEmitter<PoiInterface>();

  selectMode: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.options) {
      this.selectMode = this.options?.length > 1;
    }
  }

  optionClick(p) {
    this.selected = p;
    this.selectedChange.emit(p)
  }

}
