import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoisTableComponent } from './pois-table/pois-table.component';
import { MaterialModule } from '@compass/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PoisSearcherComponent } from './pois-searcher/pois-searcher.component';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, MatAutocompleteModule, MatProgressSpinnerModule, FormsModule],
  declarations: [PoisTableComponent, PoisSearcherComponent],
  exports: [PoisTableComponent, PoisSearcherComponent],
  providers: []

})
export class PoisPoisTableModule { }
