<div class='business-wrapper'>
  <section>
    <div class="container">
      <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
    </div>
  </section>


  <section>
    <compass-business-filter *ngIf="mapIsOn && business;" [data]='business'></compass-business-filter>
  </section>

  <!-- MAP -->
<!--  <compass-map-loader *ngIf="mapIsOn && business$ | async as business;" [setup]="business" style="width: 100%;"
      style="width: 100%;"></compass-map-loader>-->


  <!-- TABLE -->
  <!--<section>
    <div class="container container-start&#45;&#45;tight">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">
        <div fxFlex="100%">
          <compass-business-table *ngIf="!mapIsOn" style="width: 100%;">
          </compass-business-table>
        </div>
      </div>
    </div>
  </section>


    <ng-template #loading_business>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>-->
    <!-- MENU OPEN CONTENT -->
   <!-- <compass-business-form *ngIf="isFilterStage(activeStage) && business$ | async as business"
                           [setup]="business" style="width: 100%;"></compass-business-form>

    <compass-business-indicators *ngIf="!isFilterStage(activeStage)" style="width: 100%;">
    </compass-business-indicators>



    <compass-indicators-sidenav *ngIf="clickedShape && clickedShape === 'indicators'">
    </compass-indicators-sidenav>-->

</div>
