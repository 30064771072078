<div class="padding-l">
  <h3>
    <i class="material-icons popUp"> {{ data.dialogIcon }} </i
    ><strong>{{ data.dialogTitle }}</strong>
  </h3>
  <div mat-dialog-content>
    <p>{{ data.dialogMessage }}</p>

    <h2 class="margin__auto">
      <i class="material-icons sub-icons"> person_add_disabled </i
      ><strong>{{ data.dialogUsername }}</strong>
    </h2>
  </div>
  <div mat-dialog-actions class="margin__top--xl">
    <button
      mat-flat-button
      (click)="closeAndSend(true)"
      cdkFocusInitial
      class="lightgray"
    >
      Sí
    </button>
    <button mat-flat-button color="accent" (click)="closeAndSend(false)">
      No
    </button>
  </div>
</div>
<div>&nbsp;</div>
