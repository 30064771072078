import { Component, EventEmitter, HostListener, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as d3 from 'd3';
import * as dc from 'dc';

import { Subscription } from 'rxjs';
import { CrossfilterService } from '../crossfilter.service';

@Component({
  selector: 'compass-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
@Injectable({providedIn: 'root'})

export class BarChartComponent implements OnInit, OnDestroy {
  @Output() brushEnd = new EventEmitter<any>();
  @Input() corssfilterDimensionFunc = null;
  @Input() chartId = '';
  @Input() title = '';
  @Input() valueSuffix = '';

  min: any = 0;
  max: any = 0;

  barChart: any; // generated barchart element
  barChartId: string = 'barChart'; // component id

  filtered: any; // brush values [min, max]

  crossFilter: any;

  dimension: any;
  group: any;

  resizeTimeout: any;

  crossfilterSub: Subscription;

  constructor(private crossfilterService: CrossfilterService) {
  }

  ngOnInit(): void {
    this.barChartId = this.chartId + this.barChartId;

    this.crossfilterSub = this.crossfilterService.crossfilter$.subscribe((crossfilter) => {
      this.crossFilter = crossfilter;
      this.drawBrush();

      this.crossFilter.onChange(changed => {
        this.barChart.redraw();
      });
    });
  }



  clearFilter() {
    this.dimension.filter(null);
    this.min = null;
    this.max = null;

    this.drawBrush();
  }

  ngOnDestroy() {
    this.crossfilterSub?.unsubscribe();
  }

  private drawBrush() {
    // let allGroup = this.crossfilterService.all();
    this.dimension = this.crossfilterService.createDimension(this.corssfilterDimensionFunc);
    this.group = this.dimension.group();
    this.barChart = new dc.BarChart(`#${this.barChartId}`);
    this.barChart.margins({ top: 10, right: 10, bottom: 20, left: 40 });

    this.barChart
      .dimension(this.dimension)
      .group(this.group)
      .height(140)
      .x(d3.scaleLinear().domain(this.crossfilterService.getDimDomain(this.dimension, this.corssfilterDimensionFunc)))
      // .xUnits(dc.units.integers)
      .centerBar(true)
      .elasticY(true)
      .brushOn(true)
      .on('renderlet', (chart) => {
        chart.selectAll('g.x text')
          //.attr('dx', '-30')
          .attr('transform', 'translate(-10,10) rotate(315)');
      });

    // this.barChart.x().domain(this.crossfilterService.mapping.range())
    // bar.on('preRedraw', chart => chart.rescale());
    dc.renderAll();

    // if filtered saved, use it to redraw barchart
    if (!this.filtered) {
      // this.barChart.filter(dc.filters.RangedFilter(this.filtered[0], this.filtered[1]));
    }

    this.barChart.addFilterHandler((filters, filter) => {
      if (filter) {
        this.min = Math.ceil(filter[0]);
        this.max = Math.floor(filter[1]) ?? this.dimension.top(1)?.[0];
        this.setMinMax();

        this.filtered = [this.min, this.max];
        this.brushEnd.emit([this.min, this.max]);
      } else {
        this.setMinMax(0, 0);
      }

      filters.push(filter);

      return filters;
    });
  }


  setMinMax(min?: number, max?: number) {
    min = min ?? this.min ?? 0;
    max = max ?? this.max ?? this.dimension.top(1)?.[0] ?? 0;

    // fix negative zero
    if (min <= 0 && min > -1) {
      min = +0;
    }

    this.min = min.toLocaleString('es-ES');
    this.max = max.toLocaleString('es-ES');
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      const width = document.getElementById(this.barChartId).offsetWidth;
      this.barChart.width(width);
      this.barChart.render();
    }, 680);
  }
}


