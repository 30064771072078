import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/*
export interface Searchbox {
  type: string;
  properties: PoiProperty;
  geometry: PoiGeometry;
}
*/


@Injectable({
  providedIn: 'root'
})
export class SearchboxService {
  public visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private searchboxDescriptive$ = new Subject<any>();
  searchboxDescriptiveChanged$ = this.searchboxDescriptive$.asObservable();

  constructor() { }

  searchboxDescriptive(searchBoxData: any) {
    this.searchboxDescriptive$.next(searchBoxData);
  }

  public getVisible() {
    return this.visible$.asObservable();
  }

  public toggle(visible: boolean): void {
    this.visible$.next(visible);
  }


}
