import { Component } from '@angular/core';

@Component({
  selector: 'glo-stage-heritage',
  templateUrl: './stage-heritage.component.html',
  styleUrls: ['./stage-heritage.component.css'],
})
export class StageHeritageComponent {
  accordionSetup = {
    title: 'Patrimonio',
    opened: true,
    closed: false,
    expanded: true,
  };

  constructor() { }
}
