<article>
  <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="2rem">
    <div fxFlex="100%">
      <div class="content">
        <div fxLayout="row wrap" fxLayoutGap="4px grid">
          <div [fxFlex]="(100/cards.length) + '%'" fxFlex.xs="100%" *ngFor="let key of cards; let i = index">
            <div class="key">
              <div class="key-head">
                <div class="inline key-icon">
                  <svg height="17.99999" viewBox="0 0 13.31494 17.99999" width="13.31494"
                       xmlns="http://www.w3.org/2000/svg">
                    <g [attr.fill]="key.color" stroke="#49565d" stroke-linecap="round"
                       stroke-width=".75">
                      <path d="m2.6573 17.625h8" />
                      <path
                        d="m6.67.375h-.025a6.27042 6.27042 0 0 0 -6.27 6.27 6.18415 6.18415 0 0 0 1.62207 4.198c1.07393 1.262 4.65993 4.407 4.65993 4.407s3.58606-3.145 4.66-4.407a6.18035 6.18035 0 0 0 1.62292-4.198 6.27031 6.27031 0 0 0 -6.26992-6.27zm-.013 8.57751a2.392 2.392 0 1 1 2.39185-2.392 2.39195 2.39195 0 0 1 -2.39185 2.392z" />
                    </g>
                  </svg>
                </div>

                <div class="inline key-title">
                  <p class="title" style="text-overflow: ellipsis;" [title]="key.title">
                    {{key.title}}
                  </p>
                  <p class="address" style="text-overflow: ellipsis;" [title]="key.address">
                    {{key.address}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</article>
