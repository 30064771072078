import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';
import { SideNavStatusService } from '@compass/utils/navigation';

@Component({
  selector: 'glo-estudio-sidenav-loader',
  templateUrl: './estudio-sidenav-loader.component.html',
  styleUrls: ['./estudio-sidenav-loader.component.scss']
})
export class EstudioSidenavLoaderComponent implements OnInit {
  // all false on init
  compassIsOn: boolean = false;
  studyFormNavOn: boolean = false;
  stagesNavOn: boolean;
  stageViewerOn: boolean;
  subcategoriesNavOn: boolean;
  companiesNavOn: boolean;
  placesNavOn: boolean;
  stagesNavOnLoading: boolean;
  setup: any = {
    class: 'glocally'
  }

  constructor(
    private startLeftSidenavService: StartLeftSidenavService,
    private sideNavStatusService: SideNavStatusService
  ) {

    this.startLeftSidenavService.getCompassStatus().subscribe((value) => {
      this.compassIsOn = value;
    });

    this.sideNavStatusService.getStudyFormStatus().subscribe((value) => {
      this.studyFormNavOn = value;
    });

    this.sideNavStatusService.getStagesNavStatus().subscribe((value) => {
      this.stagesNavOn = value;
    });
    this.sideNavStatusService.getStagesNavOnLoading().subscribe((value) => {
      this.stagesNavOnLoading = value;
    });


    this.sideNavStatusService.getStageViewerStatus().subscribe((value) => {
      this.stageViewerOn = value;
    });

    this.sideNavStatusService.getSubcategoriesNavStatus().subscribe((value) => {
      this.subcategoriesNavOn = value;
    });
    this.sideNavStatusService.getCompaniesNavStatus().subscribe((value) => {
      this.companiesNavOn = value;
    });

    this.sideNavStatusService.getPlacesNavStatus().subscribe((value) => {
      this.placesNavOn = value;
    });
  }

  ngOnInit(): void { }

}