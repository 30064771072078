import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WeatherDataService } from '../weather-data.service';

@Component({
  selector: 'glo-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComponent {
  @Input() municipalityId: any;
  sevenDaysWeather$ = this.weatherDataService.sevenDaysWeather$;
  constructor(private weatherDataService: WeatherDataService) { }

  ngOnChanges() {
    this.weatherDataService.getSevenDaysWeather(this.municipalityId);
  }
}
