import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { IdealistaFormComponent } from './idealista-form/idealista-form.component';
import { IdealistaFormOptionsService } from './idealista-form/idealista-form-options.service';
import { IdealistaReshapePoisService } from './idealista-form/idealista-reshape-pois.service';
import { IdealistaSidenavComponent } from './idealista-sidenav/idealista-sidenav.component';
import { IdealistaSidenavService } from './idealista-sidenav/idealista-sidenav.service';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [IdealistaFormComponent, IdealistaSidenavComponent],
  exports: [IdealistaFormComponent, IdealistaSidenavComponent],
  providers: [
    IdealistaFormOptionsService,
    IdealistaReshapePoisService,
    IdealistaSidenavService,
  ],
})
export class IdealistaModule { }
