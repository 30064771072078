import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

@Injectable()
export class InmueblesService {
  private idealistaUrl = '/abacusgis/core/glocally/collection/point_idealista/';
  public idealistaPois$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  regExEuro = /^(\d+)(\.)(\d+)(\s+)?(\u20AC\/mes|\u20AC)?/gi;
  regExRooms = /^(\d+)(\s+)?(\w+\.?)?/gi;
  regExSize = /^(\d+)?(\.)?(\d+)(\s+)?(\w?)(\s+)?(\u00B2)?/gi;
  //regExPriceSqm = /^(\d+)?(\.)?(\d+)?(\,)?(\d+)?(\u20AC\/m\u00B2)?/gi;
  regExPriceSqm = /^(\d+)?\.?(\d+)?\,?(\d+)?/gi;

  constructor(
    public http: HttpClient,
    private geoCoordsService: GeoCoordsService
  ) { }

  // PRO API
  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  private getPoiPrice(poi) {
    if (isNaN(poi || poi === '')) {
      let result: number = parseInt(poi.trim().replace(this.regExEuro, '$1$3'));
      return isNaN(result) ? 0 : result;
    }
    return parseInt(poi);
  }

  private getPoiRooms(rooms) {
    if (isNaN(rooms) || rooms === '') {
      let result: number = parseInt(
        rooms.trim().replace(this.regExRooms, '$1')
      );
      return isNaN(result) ? 0 : result;
    }
    return parseInt(rooms);
  }

  private getPoiSize(poi) {
    if (isNaN(poi || poi === '')) {
      let result: number = parseInt(poi.trim().replace(this.regExSize, '$1$3'));
      return isNaN(result) ? 0 : result;
    }
    return parseInt(poi);
  }

  private getPoiPriceSqm(priceSqm) {
    if (isNaN(priceSqm) || priceSqm === '') {
      let temp = priceSqm.replace(/ +?/g, '');
      temp = temp.replace(/\u20AC\/m\u00B2/gi, '');
      temp = temp.replace(/[.]/g, '');
      temp = parseInt(temp.replace(/[,]/g, '.'));
      return isNaN(temp) ? 0 : temp;
    }
    return parseInt(priceSqm);
  }

  private mapProperty(value: any, property: string) {
    if (value) {
      if (property === 'priceSqm') {
        return this.getPoiPriceSqm(value);
      }
      if (property === 'rooms') {
        return this.getPoiRooms(value);
      }
    }
    return undefined;
  }

  public setIdealistaPois(pois) {
    this.idealistaPois$.next(pois);
  }

  getByArea(study: any) {
    this.idealistaPois$.next(undefined);
    const areaValue = Object.entries(study.properties.areas)[0][1] as any;
    return this.http
      .get(`${this.idealistaUrl}?${this.geoCoordsService.getFrom(study)}`)
      .subscribe((idealistaPois: any[]) => {
        const inmuebles = idealistaPois.map((poi) => {
          poi.properties['price'] = this.getPoiPrice(poi.properties['price']);
          poi.properties['rooms'] = this.mapProperty(poi.rooms, 'rooms');
          poi.properties['size'] = this.getPoiSize(poi.properties['size']);
          poi.properties['price_sqm'] = this.mapProperty(
            poi.properties.price_sqm,
            'price_sqm'
          );

          return poi;
        });
        this.idealistaPois$.next(inmuebles);
      });
  }
}
