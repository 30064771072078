<compass-stage-breadcrumbs></compass-stage-breadcrumbs>


<section>
    <div class="container">

        <compass-local-knowledge-loader *ngIf="startLeftSidenavService.sidenavIsOpen.value">
        </compass-local-knowledge-loader>
    </div>

</section>
