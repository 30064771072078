<div class="container">
  <form [formGroup]="layerForm">
    <mat-form-field appearance="fill">
      <mat-label>{{ setup.parentLabel }}</mat-label>
      <mat-select
        formControlName="layer"
        required
        (selectionChange)="onChange($event)"
      >
        <mat-option *ngFor="let option of setup.options" [value]="option.key">
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
