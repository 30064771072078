import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { CatastroMenuComponent } from './catastro-menu/catastro-menu.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [CatastroMenuComponent],
  exports: [CatastroMenuComponent],
  providers: []
})
export class CatastroModule { }
