import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessApiService } from '../../../../../libs/business/src/lib/business-api.service';
import { LayerGroup } from 'leaflet';
import { OnclickStageShapeService } from '@compass/utils/navigation';
import { BusinessViewerService } from '../../../../../libs/business/src/lib/business-viewer.service';
import { StudyService } from '@compass/studies/data-access-study';
import { ActivatedRoute } from '@angular/router';
import { PoiBusiness } from '../../../../../libs/business/src/lib/business-poi.type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lab-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit, OnDestroy {
  mapIsOn: boolean = true;
  business: PoiBusiness[] = [];
  activeStage: any;
  clickedShape: string;
  lateral_menu: any[] = [
    {
      'key': 'filter',
      'name': 'Fitros',
      'icon': 'filter_alt',
      'active': true,
      'path': '/business'
    },
    {
      'key': 'map',
      'name': 'Mapa',
      'icon': 'map',
      'active': false,
      'path': '/business'
    },
    {
      'key': 'table',
      'name': 'Tabla',
      'icon': 'table_chart',
      'active': false,
      'path': '/business'
    },
    {
      'key': 'indicators',
      'name': 'Indicadores',
      'icon': 'data_exploration',
      'active': false,
      'path': '/business'
    }
  ];
  map: any;
  businessLayer: LayerGroup = new LayerGroup<any>();
  studyID: number;
  private businessLoadedSub: Subscription;


  constructor(
    private businessApiService: BusinessApiService,
    private businessViewerService: BusinessViewerService,
    private onclickStageShapeService: OnclickStageShapeService,
    private studyService: StudyService,
    private router: ActivatedRoute
  ) {
    this.studyID = this.router.snapshot.params.studyID;
    this.businessViewerService.getMapIsOn().subscribe((d) => {
      this.mapIsOn = d;
    });


    this.businessViewerService.getActiveStage$().subscribe((stage: any) => {
      this.activeStage = stage;
    });

    this.onclickStageShapeService.getShape().subscribe((d: any) => {
      this.clickedShape = d;
    });
  }

  isFilterStage(stage) {
    return stage.key === 'filter' || stage.key === 'map' || stage.key === 'table';

  }

  ngOnInit(): void {
    this.businessViewerService.setActiveStage$(this.lateral_menu[0]);

    this.businessApiService.getBusinessInStudy(this.studyService.study$.value);
    this.businessLoadedSub = this.businessApiService.business$.subscribe(business => {
      if (business?.length) {
        this.business = business;
      }
    });


  }
  ngOnDestroy() {
    this.businessLoadedSub.unsubscribe();
  }

}
