import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, filter } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';

export interface Influencer {
    "key": string,
    "class_": string,
    "geo_level": string,
    "id": number,
    "date": string,
    "medio": string,
    "nombre_medio": string,
    "tematica": string,
    "url_ig_perfil": string,
    "followers": string,
    "avg_interactions": string,
    "engagement": string,
    "audience_city": string,
    "avg_age": string,
    "municipio": string,
    "provincia": string
}



@Injectable({ providedIn: 'root' })
export class InfluencersApiService {

    private influencersUrl = 'assets/data/influencers/influencers_sevilla.json';
    public influencers$: BehaviorSubject<Influencer[]> = new BehaviorSubject(undefined);

    constructor(public http: HttpClient, private router: Router) { }

    // PRO API
    handleError(error: HttpErrorResponse) {
        const kk = null;
        return of(kk);
    }

    // DEV API fn
    public getInfluencersData = () => {
        return this.http.get(`${this.influencersUrl}`).pipe(
            catchError(this.handleError),
            map((data: Influencer[]) => {
                // map data here if needed
                data = data.map((influencer: Influencer) => {
                    return influencer;
                });
                this.influencers$.next(data);
                return data;
            })
        );
    }

    public getInfluencers() {
        return this.influencers$.asObservable();
    }
    public setInfluencers(influencers: Influencer[]) {
        this.influencers$.next(influencers);
    }
}
