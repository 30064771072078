import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { CreateStudyService } from '@compass/feature-create-study';

@Component({
  selector: 'glo-study-sidenav',
  templateUrl: './study-sidenav.component.html',
  styleUrls: ['./study-sidenav.component.scss'],
  animations: [],
})
export class StudySidenavComponent implements OnInit {
  selectedForm: string = 'point';

  constructor(public createStudyService: CreateStudyService) {
  }

  getSelectedForm(event) {
    this.selectedForm = event.selectedForm;
  }

  ngOnInit(): void {}
}
