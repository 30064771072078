import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import {
  CompassMapPoisService,
  CompassMapWrapperService,
} from '@compass/utils/leaflet';
import { SelectLayerGroupService } from './select-layer-group.service';

@Component({
  selector: 'compass-select-layer-group',
  templateUrl: './select-layer-group.component.html',
  styleUrls: ['./select-layer-group.component.css'],
})
export class SelectLayerGroupComponent implements OnChanges {
  @Input() setup: any;
  layerForm: FormGroup;
  map$ = this.compassMapWrapperService.map$;
  layerGroup$ = this.selectLayerGroupService.layerGroup$;
  categoriesList;

  constructor(
    private selectLayerGroupService: SelectLayerGroupService,
    private formBuilder: FormBuilder,
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapPoisService: CompassMapPoisService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService
  ) {

    this.sidenavPoisLevelsService.getCategoryList().subscribe((d) => {
      //console.log(d)
      this.categoriesList = d;

    })
  }

  onChange(layer) {
    this.selectLayerGroupService.setLayergroup(layer.value);
    this.compassMapPoisService.drawMarkersFromNestedList(
      this.map$.value,
      this.categoriesList,
      this.layerGroup$.value
    );
  }

  ngOnChanges() {
    this.layerForm = this.formBuilder.group({
      layer: [this.setup.options[0].key, Validators.required],
    });
  }
}
