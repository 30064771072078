import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OldCrossfilterService {
  private _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _pois: any;

  // callbacks queue
  private _onFilterCallbackQueue: any = [];
  private _onCloseCallbackQueue: any = [];

  constructor(private http: HttpClient) {
  }

  setStatus(status) {
    if (this._status.value !== status) {
      // fix exception when try to change value before init it
      setTimeout(() => {
        if (status) {
          this.loadData();
        } else {
          this._onCloseCallbackQueue.forEach((callbackFunction) => {
            callbackFunction();
          });
        }

        this._status.next(status);
      }, 100);
    }
    return false;
  }

  getStatus(): Observable<boolean> {
    return this._status.asObservable();
  }

  getPois(): any {
    return this._pois;
  }

  filterCallback(filteredPois) {

    console.log('FILTERED', this._onFilterCallbackQueue, filteredPois);

    // call callback
    this._onFilterCallbackQueue.forEach((callbackFunction) => {
      callbackFunction(filteredPois);
    });
  }

  public onFilterCallback(callableFunc): void {
    this.addToQueue(callableFunc, this._onFilterCallbackQueue);
  }

  public onCloseCallback(callableFunc): void {
    this.addToQueue(callableFunc, this._onCloseCallbackQueue);
  }

  /**
   * Add the callable function into specified queue.
   *
   * @param callableFunc
   * @param queue
   * @private
   */
  private addToQueue(callableFunc, queue) {
    // add into to the queue only if is a function
    if (typeof callableFunc === 'function' && !queue.includes(callableFunc)) {
      queue.push(callableFunc);
    }
  }

  public loadData() {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'text'
    };

    this.http.get('/assets/data/zaragoza_mobility_csv.txt', httpOptions)
      .subscribe((res) => {
        const csv = this.csvJSON(res);
        this._pois = this.formatPois(csv);
      });
  }

  private formatPois(pois) {
    pois = pois.filter((el) => {
      return el.Latitude != undefined && el.Longitude != undefined && el.Datetime != undefined && el.Maid != undefined;
    });

    pois = pois.map((el) => {
      function getRoundedDate(date) {
        date = new Date(date);
        if (date.getSeconds() < 30) {
          date.setSeconds(0);
        } else {
          date.setSeconds(30);
        }

        return date;
      }

      return {
        geometry: {
          coordinates: [
            el.geometry?.coordinates[0] || el.Longitude,
            el.geometry?.coordinates[1] || el.Latitude
          ],
          type: el.geometry?.type || 'Point'
        },
        name: el.properties?.name || `Device <br>${el.Maid}`,
        date: getRoundedDate(el.properties?.date || el.Datetime),
        timestamp: el.properties?.date || el.Datetime,
        markerOptions: {
          realIconUrl: 'assets/img/icons/cyan_dot.svg',
          iconSize: [7, 7]
        }
      };
    });


    return pois;
  }

  private csvJSON(csv) {
    const lines = csv.split('\n');

    const result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    const headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {

      const obj = {};
      const currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.parse(JSON.stringify(result)); //JSON
  }
}
