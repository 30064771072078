<div
  class="poi-title-container"
  [class.sync]="canUpdate && !poi?.properties?.has_popular_time"
  fxLayout="row" fxLayoutAlign="start center"
>
  <ng-container *ngIf="poi">
    <mat-icon
      class="sync-button"
      matTooltip="Actualizar datos de horas puntas de {{ poi.properties.nombre }}"
      (click)="showBestimes($event)"
      [style.background]="poi.properties.color"
      *ngIf="canUpdate && !poi.properties.has_popular_time"
    >
      sync
    </mat-icon>
    <mat-icon [style.color]="poi.properties.color">place</mat-icon>
    <div class="text">
      <h5 [style.color]="poi.properties.color" [title]="poi.properties.nombre">
        {{ poi.properties.nombre }}
      </h5>
      <small [title]="poi.properties.direccion">{{ poi.properties.direccion }}</small>
    </div>
  </ng-container>

  <ng-container *ngIf="!poi && compared.length">
    <svg height="21.76348" viewBox="0 0 21 21.76348" width="21" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke="#49565d" stroke-linecap="round">
        <path
          d="m.5 4.77609v10.48739a1.00294 1.00294 0 0 0 1 1h9a1.00294 1.00294 0 0 0 1-1v-10.4942a1 1 0 0 0 -.40743-.80552l-4.44419-3.26928a1 1 0 0 0 -1.17638-.00636l-4.55582 3.27609a1 1 0 0 0 -.41618.81188z" />
        <circle cx="6" cy="4.26348" r="1" />
        <path
          d="m9.5 18.26348v2a1.00294 1.00294 0 0 0 1 1h9a1.00294 1.00294 0 0 0 1-1v-10.4942a1 1 0 0 0 -.40743-.80552l-4.44419-3.26928a1 1 0 0 0 -1.17638-.00636l-1.1379.81827" />
        <circle cx="15" cy="9.26348" r="1" />
        <path d="m5.501 7.636 2.689 2.566-2.566 2.689" />
        <path d="m7.25 10.26348h-4" />
        <path d="m15.999 18.891-2.689-2.566 2.566-2.689" />
        <path d="m14.25 16.26348h4" />
      </g>
    </svg>
    <div class="mx-1 text">
      <h5>Diferencia total</h5>
      <small [title]="comparedTitle">{{ comparedTitle }}</small>
    </div>
  </ng-container>
</div>

