<section>
  <div class="container">
    <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
  </div>
</section>

<section>
  <div class="container">
    <compass-select-layer-loader></compass-select-layer-loader>
  </div>
</section>

<section>
  <div class="container">
    <compass-pois-searcher (selectionList)='getSearchSelection($event)' ></compass-pois-searcher>

    <compass-pois-accordion [setup]="accordionSetup">
      <ng-template #loading_categories>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>

      <compass-pois-table
        *ngIf="sidenavPoisLevelsService.categoriesList$ | async as categoriesList; else loading_categories"
        [poisTableList]="categoriesList"
        (selectionList)='getSearchSelection($event)'
        style="width: 100%"
      >
      </compass-pois-table>
    </compass-pois-accordion>
  </div>
</section>
