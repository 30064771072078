import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glo-stage-influencers',
  templateUrl: './stage-influencers.component.html',
  styleUrls: ['./stage-influencers.component.scss']
})
export class StageInfluencersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
