import { Injectable } from '@angular/core';

import { SideNavStatusService } from './sidenav-status.service';
import { EgmAimcStatusService } from "@compass/egm-aimc/data-access";
import { WeatherStatusService } from '@compass/weather';
import { SideNavStagesService } from './sidenav-stages.service';
import { SidenavPoisLevelsService } from '../../../../pois/data-access-poi/src/lib/sidenav-pois-levels.service';
import { CompassMapWrapperService, Map } from '../../../leaflet/src/lib/compass-map-wrapper.service';
import { PatrimonioService } from '../../../../patrimonio/data-access/src/lib/patrimonio.service';

import { BricksApiService } from '@compass/brick-api';
// we are here inmuebles service
import { InmueblesService } from '@compass/inmuebles/data-access';

@Injectable()
export class LogoutAppService {
  map: Map = this.compassMapWrapperService.map$.value;

  constructor(
    private sideNavStatusService: SideNavStatusService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private egmAimcStatusService: EgmAimcStatusService,
    private weatherStatusService: WeatherStatusService,
    private bricksApiService: BricksApiService,
    private inmueblesService: InmueblesService,
    private compassMapWrapperService: CompassMapWrapperService,
    private sideNavStagesService: SideNavStagesService,
    private patrimonioService: PatrimonioService

  ) {
    this.compassMapWrapperService.getMap().subscribe((map) => {
      this.map = map;
    });
  }

  closePoisServices() {
    this.sidenavPoisLevelsService.setIsFirstLoad(true);
    this.sidenavPoisLevelsService.resetAllCategoriesList();
    this.sidenavPoisLevelsService.resetParentCategories();

  }
  closeNavigation() {
    this.closePoisServices();
    this.bricksApiService.resetAllBrickLists();
    this.sideNavStatusService.resetAllSidenavs();
    this.sideNavStagesService.setStages(undefined);
    this.egmAimcStatusService.setAimcOn(false);
    this.egmAimcStatusService.setEgmOn(false);
    this.weatherStatusService.setWeatherOn(false);
    this.inmueblesService.setIdealistaPois(undefined);
    this.patrimonioService.setHeritagePois(undefined);
    if (this.map && this.map.pois && this.map.pois['idealistaLayer']) {
      this.map.box.removeLayer(this.map.pois['idealistaLayer']);
      this.map.pois['idealistaLayer'] = [];
    }
  }
}


