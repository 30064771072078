import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: "root" })
export class IdealistaSidenavService {
  public housingInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor() { }

  setHousingInfo(info) {
    this.housingInfo$.next(info);
  }
  getHousingInfo(): Observable<boolean> {
    return this.housingInfo$.asObservable();
  }
}