<div class="studies-crud-menu">
  <p>
    <span class="material-icons primary">create_new_folder</span>
    {{study_name}}
    <span class="mini-mat-button">
      <button
        data-cy="study-crud-button"
        mat-button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Menú del estudio"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </span>
  </p>

  <mat-menu #menu="matMenu">
    <button mat-menu-item data-cy="save" (click)="studyCrudAction($event, 'save')">
      <span class="material-icons primary"> save</span>Guardar
    </button>
    <button mat-menu-item data-cy="create" (click)="studyCrudAction($event, 'edit')">
      <span class="material-icons primary"> create</span>Editar
    </button>
    <button mat-menu-item data-cy="print" (click)="studyCrudAction($event, 'print')">
      <span class="material-icons primary"> print</span>Informe
    </button>
    <button mat-menu-item data-cy="delete" (click)="studyCrudAction($event, 'delete')">
      <span class="material-icons primary"> delete</span>Eliminar
    </button>
    <button mat-menu-item data-cy="exit" (click)="studyCrudAction($event, 'close')">
      <span class="material-icons primary">exit_to_app</span>Cerrar
    </button>
  </mat-menu>

</div>
