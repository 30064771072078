import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material'
import { MatDialogDeleteComponent } from './mat-dialog-delete/mat-dialog-delete.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [MatDialogDeleteComponent],
})
export class SharedMatDialogDeleteModule { }
