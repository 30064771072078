import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: "root" })
export class WeatherStatusService {

  public weatherOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() { }

  setWeatherOn(status) {
    if (this.weatherOn$.value !== status) {
      this.weatherOn$.next(status);
    }
    return false;
  }
  getWeatherOn(): Observable<boolean> {
    return this.weatherOn$.asObservable();
  }

}