import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';
import { Map } from './compass-map-wrapper.service';
import { IdealistaFormOptionsService } from '../../../../idealista/src/lib/idealista-form/idealista-form-options.service';
import { EndRightSidenavService } from '../../../../page-navigation/end-right-sidenav/src/lib/end-right-sidenav.service';
import { IdealistaSidenavService } from '../../../../idealista/src/lib/idealista-sidenav/idealista-sidenav.service';
import { CompassMapWrapperService } from '../../../../utils/leaflet/src/lib/compass-map-wrapper.service';
import { OnclickStageShapeService } from '@compass/utils/navigation';


@Injectable({ providedIn: 'root' })
export class CompassMapIdealistaService {
  map: Map;
  public activeStage: string;

  constructor(
    private idealistaFormOptionsService: IdealistaFormOptionsService,
    private endRightSidenavService: EndRightSidenavService,
    private idealistaSidenavService: IdealistaSidenavService,
    private compassMapWrapperService: CompassMapWrapperService,
    private onclickStageShapeService: OnclickStageShapeService

  ) {
    //this.map = this.compassMapWrapperService.map;
  }

  public removeIdealistaLayer(layer): void {
    if (this.map[layer]) {
      this.map.box.removeLayer(this.map[layer]);
    }
  }

  public fitBoundsCategoryGroup(layer): void {
    if (this.map['pois'][layer]) {
      let bounds = this.map['pois'][layer].getBounds();
      if (bounds && bounds._northEast && bounds._northEast.lng && bounds._southWest && bounds._southWest.lat) {
        this.map.box.fitBounds(bounds);
      }

    }
  }

  public setViewToMapCenter(): void {
    this.map.box.setView(
      new L.LatLng(
        this.map.properties.center[0],
        this.map.properties.center[1]
      ),
      this.map.properties.zoom
    );
  }
  getCategoryColor(poi) {
    let color: string = '';
    let options = this.idealistaFormOptionsService.category_options;
    options.forEach((option) => {
      if (option.value === poi.properties.category) color = option.color;
    });
    return color;
  }

  public buildCircleMarkers(pois: any) {
    const circles = pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];

      const circle = {
        circleMarker: L.circleMarker([lat, long], {
          color: '#333333', // border color
          weight: 1, // border weight in px
          fillColor: this.getCategoryColor(poi), // circle color
          fillOpacity: 0.8,
          radius: 8, // Radius of the circle marker, in pixels
          className: 'inmuebleCircle'
        }),
        popUp: this.popUpText(poi),
      };
      circle.circleMarker['data'] = poi; // bind the data for right info window
      circle.circleMarker.bindPopup(circle.popUp); // bind the popUp
      circle.circleMarker.on('mouseover', (e) => {
        this.mouseoverCircleMarker(e);
        e.sourceTarget.openPopup();
      });
      circle.circleMarker.on('mouseout', (e) => {
        this.mouseoutCircleMarker(e);
        e.sourceTarget.closePopup();
      });
      circle.circleMarker.on('click', (e) => {
        this.clickCircleMarker(e);
      });
      return circle;
    });
    return circles;
  }

  public clickCircleMarker(marker) {
    // open right window, pass info, get more data from API
    // opens right end sidenav
    this.onclickStageShapeService.setShape('real_state');
    this.endRightSidenavService.setSidenavStatus(true);
    this.idealistaSidenavService.setHousingInfo(marker.target.data);
    // we are here
    return false;
  }

  public mouseoverCircleMarker(marker) {
    return false;
  }

  public mouseoutCircleMarker(marker) {
    return false;
  }
  public addMarkersToMap(markers: any, layer: string) {
    this.map[layer] = L.featureGroup([]);
    markers.forEach((marker) => {
      marker.circleMarker.addTo(this.map[layer]);
    });
    this.map[layer].addTo(this.map.box);
  }

  public drawPois(pois: any, layer: string, type: string) {
    switch (type) {
      case 'featureGroup':
        {
          const markers = this.buildCircleMarkers(pois);
          this.addMarkersToMap(markers, layer);

        }
        break;

      default: {
        return null;
      }
    }
  }

  public popUpText = (poi) => {
    let text =
      '<p data-id="popUpText"><strong>' +
      poi.properties.title +
      '</strong></p>';
    if (poi.properties && poi.properties.address) {
      text += '<p>' +
        (poi.properties.address) +
        '</p>';
    }
    if (poi && poi.properties && poi.properties.type) {
      text += '<p>';
      text += poi.properties.type === "Comprar" ? "En venta" : "En alquiler";
      text += '</p>';
    }
    return text;
  };

  public innerControlHelpers = {

    setAvg: (data) => {
      let count = 0, price_sqm = 0, price = 0, size = 0, i = 0;
      for (i; i <= data.length; i++) {
        if (data[i] && data[i].properties && data[i].properties.price_sqm && data[i].properties.price && data[i].properties.size) {
          price_sqm += data[i].properties.price_sqm;
          price += data[i].properties.price;
          size += data[i].properties.size;
          count += 1;
        }

      }
      return {
        sqm:
          (price_sqm / count)
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €/m<sup>2</sup>',

        price: (price / count)
          .toFixed(0) // always two decimal digits
          .replace('.', ',') // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €',
        size: (size / count)
          .toFixed(0) // always two decimal digits
          .replace('.', ',') + ' m<sup>2</sup>'// replace decimal point character with ,
      }
    }
  }
  public getControlInnerHTML(data) {
    let number_of_pois = data.length;
    let getAvg = this.innerControlHelpers.setAvg(data);

    let innerHTML = "<p>" + number_of_pois + " inmuebles</p>";

    if (getAvg.sqm.indexOf('NaN') < 0) {
      innerHTML += "<p>" + getAvg.sqm + "</p>";
    }
    if (getAvg.price.indexOf('NaN') < 0) {
      innerHTML += "<p>" + getAvg.price + "</p>";
    }

    if (getAvg.size.indexOf('NaN') < 0) {
      innerHTML += "<p>" + getAvg.size + "</p>";
    }

    return innerHTML;
  }

  public drawIdealistaControl(data) {
    let div: HTMLElement;
    this.removeIdealistaControl();
    this.map['idealistaControl'] = new L.Control({ position: "bottomright" });
    // ON ADD
    this.map["idealistaControl"].onAdd = () => {
      div = L.DomUtil.create("div", "info idealista");
      div.innerHTML = '';
      div.innerHTML = this.getControlInnerHTML(data);
      return div;
    };

    // ON UPDATE
    this.map['idealistaControl'].update = (data?: any) => {
      div.innerHTML = '';
      div.innerHTML = this.getControlInnerHTML(data);
    };

    this.map["idealistaControl"].addTo(this.map.box);
    this.compassMapWrapperService.setMap(this.map);
  }

  public reshapeIdealistaControl(data) {
    this.map['idealistaControl'].update(data);
  }


  public removeIdealistaControl() {
    if (this.map["idealistaControl"]) {
      this.map.box.removeControl(this.map["idealistaControl"]);

    }
  }



}
