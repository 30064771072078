import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapComponent } from './map.component';

import { MapService } from './map.service';
import { SideNavStatusService } from '@compass/utils/navigation';
import { MarkerService } from './marker.service';
import { MapBoxService } from '@compass/utils/misc';
import { ColorsService } from '@compass/utils/misc';
import { Mathematics } from '@compass/utils/misc';
import { ScrollIntoViewService } from '@compass/utils/misc';
import { ThematicKeySymbol } from '@compass/utils/misc';
import { SearchboxService } from '@compass/feature-searchbox';
// bricks data
import { BricksApiService } from '@compass/brick-api';
import { IndicatorService } from '@compass/utils/navigation';

// brick drawer
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';

@NgModule({
  declarations: [MapComponent],
  exports: [MapComponent],
  imports: [CommonModule],
  providers: [
    MapBoxService,
    ColorsService,
    MapService,
    SideNavStatusService,
    MarkerService,
    Mathematics,
    ScrollIntoViewService,
    ThematicKeySymbol,
    SearchboxService,
    BricksApiService,
    IndicatorService,
    CompassMapIndicatorsService,
  ],
})
export class SharedMapFeatureMapModule { }
