import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TomtomStylesService, Key, Style } from '../tomtom-styles.service';
import { CompassMapWrapperService, Map } from '@compass/utils/leaflet';
import { TomtomMapService } from '../tomtom-map.service'
@Component({
  selector: 'compass-tomtom-traffic-select',
  templateUrl: './tomtom-traffic-select.component.html',
  styleUrls: ['./tomtom-traffic-select.component.scss']
})
export class TomtomTrafficSelectComponent implements OnInit {
  @Input() coordsCenter: Array<number>;

  // map and layers
  map$ = this.compassMapWrapperService.map$;
  map: Map;

  //form
  formGroup: FormGroup;
  checkboxText: string = 'Ocultar tráfico';

  // map styles
  styles: Array<Style>;

  // keys
  style: Style;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private tomtomStylesService: TomtomStylesService,
    private tomtomMapService: TomtomMapService
  ) { }

  onSubmit() {
    return false;
  }

  setControlValue(control, value) {
    this.formGroup.controls[control].setValue(value);
  }


  setSpeedKeys(style) {
    this.style = style;
  }


  initTrafficForm() {
    this.formGroup = new FormGroup({
      visibility: new FormControl(true),
      map: new FormControl(this.styles[2])
    });
    this.formGroup.get('map').valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }
      this.setSpeedKeys(value);
      this.drawTomTomFlow();
    });
    this.formGroup.get('visibility').valueChanges.subscribe((value) => {
      if (!value) {
        this.tomtomMapService.removeGeojsonLayer(this.map, 'ttTileLayer');
        this.checkboxText = 'Ver tráfico';
        this.compassMapWrapperService.setMap(this.map);
      } else {
        this.checkboxText = 'Ocultar tráfico';
        this.drawTomTomFlow();
      }
    });

  }

  initServices() {
    this.map = this.map$.value;
    this.styles = this.tomtomStylesService.get('traffic_flow');
    return false;
  }

  drawTomTomFlow() {

    this.tomtomMapService.removeGeojsonLayer(this.map, 'ttTileLayer');
    this.tomtomMapService.setTomtomTileLayer(this.map, this.style.value)
    this.compassMapWrapperService.setMap(this.map);


  }

  ngOnInit(): void {
    this.initServices();
    this.initTrafficForm();
    this.setSpeedKeys(this.styles[2]);
    if (!this.map['ttTileLayer']) {
      this.drawTomTomFlow();
    }
  }

}
