import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface Options {
  value: string | number;
  name: string;
  children?: Array<Options>;
  color?: string;
}

@Injectable({ providedIn: 'root' })
export class HeritageFormOptionsService {



  // mapping options
  getOptions(list) {
    let options = {
      visibility: true,
      proveedor: ["Todos"],
      medio: ["Todos"],
      soporte: ["Todos"],
      sub_categoria: ["Todos"], // formato
      cliente: ["Todos"],
      status: ["Todos"],
    }

    list.forEach(element => {
      Object.keys(options).forEach(key => {
        if (key !== 'visibility') {
          if (options[key].indexOf(element.properties[key]) === -1) {
            if (element.properties[key] && element.properties[key] !== "")
              options[key].push(element.properties[key])
          }
        }
      });
    });

    return options;
  }


  public setControlValue(
    form: FormGroup,
    control: string,
    value: number | string | [] | boolean
  ) {
    form.get(control).setValue(value);
  }

}
