import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

export interface BasketPoiInterface {
  category: string;
  properties: {
    id: string;
  };
}

interface BasketBrickInterface {
  type: string;
  properties: {
    id: string;
    class_: string;
  };
}

export type BasketItem = BasketPoiInterface & BasketBrickInterface;

@Injectable({
  providedIn: 'root'
})
export class PoiBasketService {
  public POI = 'point_poi';
  public BRICK = 'area_brick_hexagon_medium';
  public PATRIMONY = 'point_patrimonio';
  public EVENT = 'events';

  public basket$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public empty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public count$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public hours$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  // flag to show only basket elements
  public showPlanElementsOnly$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  // flag for other elements to know if basketWas updated
  public basketUpdated$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  constructor() {
    this.basket$.subscribe((basket: any) => {
      this.countSelectedElements();
    });

    this.hours$.subscribe((hours: any) => {
      this.countSelectedElements();
    });
  }

  countSelectedElements() {
    let length = 0;
    Object.values(this.basket$.value).map((values: any[]) => length += values.length);
    length += this.getHours()?.length ?? 0;

    this.empty$.next(!length);
    this.count$.next(length);
  }

  toggle() {
    this.active$.next(!this.active$.value);
  }

  /**
   * Check if poi is in basket.
   *
   * @param poi
   */
  inBasket(poi: BasketItem) {
    let response = false;
    const basket = this.getBasket();

    // if poi has category, exists category in basket and id is basket.category
    if (poi && basket[this._getBasketName(poi)] && poi.properties?.id) {
      response = basket[this._getBasketName(poi)].findIndex(el => el.properties.id === poi.properties.id) >= 0;
    }

    return response;
  }

  /**
   * Add poi to basket.
   *
   * @param poi
   */
  add(poi: any) {
    const basket = this.getBasket();

    // if not added in basket
    if (!this.inBasket(poi)) {
      if (!basket[this._getBasketName(poi)]) {
        basket[this._getBasketName(poi)] = [];
      }

      basket[this._getBasketName(poi)].push(poi);

      this.basket$.next(basket);
    }
  }

  /**
   * Add or remove hour for a comparation level.
   *
   * @param data
   * @param hour
   * @param vsTitle
   */
  toggleHour(hour: string, vsTitle: any) {
    let hours = this.getHours();

    if(hours.includes(hour)) {
      hours = hours.filter(d => d !== hour);
    } else {
      hours.push(hour);
    }

    this.hours$.next(hours);
  }

  removeHour(hour: any) {
    let hours = this.getHours();
    hours = hours.filter(d => d !== hour);
    this.hours$.next(hours);
  }


  getHours() {
    return this.hours$.value ?? [];
  }
  /**
   * Removes an element from basket
   *
   * @param poi
   * @param updateAfter
   */
  remove(poi: BasketItem, updateAfter: boolean = false) {
    const basket = this.getBasket();
    if (basket[this._getBasketName(poi)]) {
      const poiIndex = basket[this._getBasketName(poi)].findIndex(item => item.properties.id === poi.properties.id);

      // if found index
      if (poiIndex >= 0) {
        basket[this._getBasketName(poi)].splice(poiIndex, 1);
        if (!basket[this._getBasketName(poi)].length) {
          delete basket[this._getBasketName(poi)];
        }

        // remove .inBasket class from map pois
        Array.from(document.getElementsByClassName(`${poi.properties.class_}${poi.properties.id}`))
          .forEach((poiElement) => {
            poiElement.classList.remove('inBasket');
          });

        /// if updateAfter setted then should fire basketUpdated flag
        if (updateAfter) {
          this.basketUpdated$.next(true);
          this.basketUpdated$.next(false);
        }
      }

      this.basket$.next(basket);
    }
  }

  /**
   * Returns the basket
   */
  getBasket() {
    return this.basket$.value;
  }

  /**
   * Returns html button for poi, checking if elementi n basket or not.
   *
   * @param poi
   */
  getButton(poi) {
    let buttonHtml = '';
    const isInBasket = this.inBasket(poi);

    const basketText = isInBasket ? 'Quitar del Plan' : 'Añadir al Plan';
    const basketIcon = isInBasket ? 'clear' : 'add';
    const basketClass = isInBasket ? 'secondary' : 'primary';

    buttonHtml += `
      <button id="basketBtn" style="display: block; width: 100%; margin: 1rem: 0;" class="${basketClass}" title="${basketText}">
        <span class="material-icons">${basketIcon}</span>
        ${basketText}
      </button>
    `;

    return buttonHtml;
  }

  /**
   * Callback binded to 'popupopen' of map markers.
   *
   * @param poi
   * @param onEventEndedCallback - callback function when logic is complented
   */
  onPopupOpen(poi, onEventEndedCallback) {
    if (poi) {
      // add click listener of "basketBtn" if exists
      const basketBtn = document.getElementById('basketBtn');

      if (basketBtn) {
        // subscribe to click event
        fromEvent(basketBtn, 'click').subscribe(e => {
          const isInBasket = this.inBasket(poi);
          // toggle poi
          if (isInBasket) {
            this.remove(poi);
          } else {
            this.add(poi);
          }

          onEventEndedCallback(!isInBasket);
        });
      }
    }
  }

  /**
   * clears selected pois of poi's type.
   *
   * @param poi
   */
  clearPoiBasket(poi) {
    const poiBasket = this._getBasketName(poi);
    const basket = this.getBasket();

    basket[poiBasket] = [];

    this.basket$.next(basket);
  }

  clearBasket() {
    this.basket$.next({});
  }

  /**
   * Returns the basket name.
   *
   * @param poi
   * @private
   */
  private _getBasketName(poi): string {
    let name = this.POI; // default is simple poi

    if (poi.properties.class_ === 'point_patrimonio') { // check class_ property
      name = this.PATRIMONY;
    } else if (poi.properties.centroid) { // if has centroid is brick
      name = this.BRICK;
    }else if (poi.properties.category == 'events'){
      name = this.EVENT;
    }

    return name;
  }


  /**
   * Returns formatted basket data to be stored in study object.
   */
  getFormattedBasket(): any {
    const basket = this.basket$.value;
    const formattedBasket = {};

    for (const basketName in this.basket$.value) {
      // extract the id of basket group
      formattedBasket[basketName] = basket[basketName].map(element => {
        let result;
        if (element.properties.category == 'events'){
          result = element;
        }else{
          result = element.properties.id;
        }
        return result;
      });
    }
    console.log(formattedBasket)
    return formattedBasket;
  }

  /**
   * Returns polain basket, without categorization
   */
  getPlainBaket(): any {
    const basket = this.basket$.value;
    const plainBaket = [];

    for (const basketName in basket) {
      // extract the id of basket group
      basket[basketName].forEach(poi => {
        plainBaket.push(poi);
      });
    }

    return plainBaket;
  }

}
