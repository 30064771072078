<table mat-table [dataSource]='dataSource' class='mat-elevation-z0'>
  <!-- Checkbox Column -->
  <ng-container matColumnDef='select'>
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)='onChangeMasterToggleCheckbox($event)'
        [checked]='allPoisSelected()'
        [indeterminate]='somePoisSelected()'
        [aria-label]='checkboxLabel()'
        color='warn'
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef='let row'>
      <span [ngClass]='row.level'>
        <mat-checkbox
          (click)='onClickCheckbox($event)'
          (change)='onChangeCheckbox($event, $event.checked, row)'
          [checked]='row.selected'
          [aria-label]='checkboxLabel(row)'
          color='primary'
        >
        </mat-checkbox>
      </span>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef='name'>
    <th mat-header-cell *matHeaderCellDef>Grupos de interés</th>
    <td mat-cell *matCellDef='let element'>
      <p *ngIf="this.activeTableRoot == 'global_pois' && this.activeTableLevel == 3">
        {{ poisSearcherService.getRatingStars(element?.values[0]?.properties?.ensenya_desc) }}
      </p>
      <p *ngIf="this.activeTableRoot == 'global_pois' && this.activeTableLevel != 3">
        <span>{{ element.name || 'Sin Categoría' }}
          <ng-container *ngIf='element.totalPois'>({{ element.totalPois }})</ng-container> </span>
      </p>
      <p *ngIf="this.activeTableRoot != 'global_pois'">
        <span>{{ element.name }}</span>
      </p>
      <p *ngIf='!element.values && element.properties.direccion'>
        <span><small>{{ element.properties.direccion }}</small></span>
      </p>
    </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef='more'>
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef='let element'>
      <button
        *ngIf='element.values'
        mat-flat-button
        class='button--xxs basic'
        (click)='onClickGoToNextLevelButton($event, element)'
      >
        <mat-icon>read_more</mat-icon>
      </button>
    </td>
  </ng-container>
  <!--/ Action Column -->

  <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
  <tr
    mat-row
    *matRowDef='let row; columns: displayedColumns'
    (click)='onClickCheckbox($event)'
  ></tr>
</table>


