import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Poi, SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { PoisSearcherService } from '../pois-searcher.service';

import * as chroma from 'chroma-js';
import { SelectionModel } from '@angular/cdk/collections';
import { CompassMapPoisService, CompassMapWrapperService } from '@compass/utils/leaflet';
import { SelectLayerGroupService } from '@compass/shared/widgets';

@Component({
  selector: 'compass-pois-searcher',
  templateUrl: './pois-searcher.component.html',
  styleUrls: ['./pois-searcher.component.scss']
})
export class PoisSearcherComponent implements OnInit {
  @ViewChild('searchInput') searchInputElement: ElementRef;
  searchFormControl: FormControl = new FormControl({ value: '', disabled: true });
  foundOptions: any[] = [];
  loading: boolean = false;

  @Output() selectionList = new EventEmitter<any>();
  selection: SelectionModel<Poi> = new SelectionModel<Poi>(true, []);
  searchTimeout: any;
  searchSubscription: Subscription;

  categoriesList: any[] = [];
  filteredCategories: any = [];

  subcategoriesList: any[] = [];
  filteredSubcategories: any[] = [];

  map$ = this.compassMapWrapperService.map$;
  layerGroup$ = this.selectLayerGroupService.layerGroup$;

  colorScale = chroma.scale(['#FF4646FF', '#ffcb46', '#5fff46'])
    .mode('lch').colors(6);

  selectedAll: boolean = false;
  activeTableLevel: any;
  private drawPoisTimeout: number;


  constructor(
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    public poisSearcherService: PoisSearcherService,
    private compassMapWrapperService: CompassMapWrapperService,
    private selectLayerGroupService: SelectLayerGroupService,
    private compassMapPoisService: CompassMapPoisService
  ) {

    this.selection = new SelectionModel<Poi>(true, []);


    this.sidenavPoisLevelsService
      .getCategoryList()
      .subscribe((categoryList: any) => {
        this.subcategoriesList = [];
        if (categoryList?.length) {
          this.categoriesList = categoryList;
          this.searchFormControl.enable();

          categoryList.forEach((category: any) => {
            if (category.values.length) {
              category.values.forEach((subcategory: any) => {
                this.subcategoriesList.push(subcategory);
              });
            }
          });
        }
      });

    this.sidenavPoisLevelsService
      .getActiveTableLevel()
      .subscribe((value: number) => {
        this.activeTableLevel = value;
      });
  }

  ngOnInit(): void {

  }

  toggleSearcherStatus(status) {
    this.poisSearcherService.toggleStatus(status);
  }

  search() {
    let searchString = this.searchFormControl?.value?.trim();
    this.searchCategories(searchString);
    this.searchSubcategories(searchString);
    this.searchPois(searchString);
    this.allPoisSelected();
  }

  searchSubcategories(searchString: string) {
    this.filteredSubcategories = [];

    if (searchString.length) {
      searchString = searchString.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      //search category
      this.subcategoriesList.forEach((category: any) => {
        // only 3 results
        if (this.filteredSubcategories.length < 3) {
          const categoryName = category.name?.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          if (categoryName.startsWith(searchString)) {
            this.filteredSubcategories.unshift(category);
          } else if (categoryName.includes(searchString)) {
            this.filteredSubcategories.push(category);
          }
        }
      });
    }
  }

  searchCategories(searchString: string) {
    this.filteredCategories = [];

    if (searchString.length) {
      searchString = searchString.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      //search category
      this.categoriesList.forEach((category: any) => {
        // only 3 results
        if (this.filteredCategories.length < 3) {
          const categoryName = category.name?.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          if (categoryName.startsWith(searchString)) {
            this.filteredCategories.unshift(category);
          } else if (categoryName.includes(searchString)) {
            this.filteredCategories.push(category);
          }
        }
      });
    }
  }

  searchPois(searchString: string) {
    this.loading = true;
    clearTimeout(this.searchTimeout);
    this.searchSubscription?.unsubscribe();

    // if there is something to search
    if (searchString.length) {
      searchString = this.normalize(searchString);
      // extract rating variable
      const ratingRegex = /rating([=><]{1,2})([0-9]?\.?[0-9])+/i;
      const rating = searchString.match(ratingRegex);

      // remove rating stuff from searchString
      searchString = searchString.replace(ratingRegex, '').trim();

      // extract location variable
      const locationRegex = /en (.*)$/i;
      const location = searchString.match(locationRegex);
      searchString = searchString.replace(locationRegex, '').trim();


      this.searchTimeout = setTimeout(() => {
        this.searchSubscription = new Observable(observer => {
          try {
            const filteredNodes = this.sidenavPoisLevelsService.getPlainNodes(this.categoriesList, (node) => {
              var isValidNode = true;

              // if there is something to search in name
              if (searchString.length && node.name) {
                isValidNode = !!this.normalize(node.name).match(this.generateRegex(searchString));
              }

              // if valid node and rating requested
              if (isValidNode && rating?.length == 3) {
                let operator = rating[1];
                if (operator === '=') {
                  operator = '==';
                }

                const ratingNumber = parseFloat(rating[2]);
                const nodeRating = node?.properties?.rating;

                isValidNode = nodeRating && eval(parseFloat(nodeRating) + operator + ratingNumber);
              }

              // if valid and location requested
              if (isValidNode && location?.length == 2) {
                let searchDir = this.normalize(location[1]).toLowerCase();
                let nodeDir = node?.properties?.direccion;
                if (nodeDir?.length) {
                  nodeDir = this.normalize(nodeDir).toLowerCase();

                  isValidNode = nodeDir?.length && nodeDir.includes(searchDir);
                } else {
                  isValidNode = false;
                }
              }


              return (node.level === 'places' || node.level === 'subcategories') && isValidNode;
            });

            observer.next(filteredNodes);
          } catch (e) {
            console.error('Poi Search Error', e);
            observer.next([]);
          }

          observer.complete();
        }).subscribe((pois: any) => {
          this.foundOptions = pois;
          if (this.foundOptions.length > 50) {
            this.foundOptions.length = 50;
          }
          this.loading = false;
          this.searchSubscription?.unsubscribe();
          this.allPoisSelected();
        });
      }, 900);
    } else {
      this.foundOptions = [];
      this.loading = false;
    }
  }

  optionClicked(event) {
    if (event.option.selected) {
      this.selection.select(event.option.value);
    } else {
      this.selection.deselect(event.option.value);
    }
    this.sidenavPoisLevelsService.updateRoot(event.option.value, this.selection.selected, this.categoriesList);
    this.selection.clear();
    this.drawMarkers();
    this.allPoisSelected();
  }

  /**
   * Wrap the searched text with <i> tag for visual hint.
   *
   * @param text
   */
  getHighlightedText(text) {
    const searchString = this.searchFormControl.value?.trim();
    const regex = new RegExp(searchString.split(' ').join('|'), 'i');
    const noAccentsRegex = new RegExp(this.normalize(searchString).split(' ').join('|'), 'i');

    text = text.replace(regex, '<i>$&</i>');
    text = text.replace(this.generateRegex(noAccentsRegex), '<i>$&</i>');

    return text;
  }

  /**
   * Returns a color for the rating
   */
  getRatingColor(rating) {
    rating = parseInt(rating);
    return this.colorScale?.[rating] ?? 'lightgrey';
  }

  /**
   * Generate search regex expression.
   *
   * @param searchString
   * @private
   */
  private generateRegex(searchString) {
    return new RegExp(searchString, 'ig');
  }

  /**
   * Substitutes the accents and stranger punctuation from string.
   *
   * @param string
   * @private
   */
  private normalize(string): string {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  drawMarkers() {
    clearTimeout(this.drawPoisTimeout);
    this.drawPoisTimeout = setTimeout(() => {
      this.compassMapPoisService.drawMarkersFromNestedList(
        this.map$.value,
        this.categoriesList,
        this.layerGroup$.value
      );
      this.allPoisSelected();
    }, 250);

  }


  onChangeMasterToggleCheckbox(event: any) {
    if (event) {
      // mat-table func
      if (event.checked) {
        this.foundOptions.forEach(poi => {
          this.selection.select(poi);
          this.sidenavPoisLevelsService.updateRoot(poi, this.selection.selected, this.categoriesList);
          this.selection.clear();
        });
      } else {
        this.foundOptions.forEach(poi => {
          this.selection.deselect(poi);
          this.sidenavPoisLevelsService.updateRoot(poi, this.selection.selected, this.categoriesList);
          this.selection.clear();
        });
      }
      this.drawMarkers();
      this.allPoisSelected();
    }
  }

  allPoisSelected() {

    if (this.foundOptions?.length) {
      this.selectedAll = true;

      for (let i = 0; i < this.foundOptions.length && this.selectedAll; i++) {

        if (!this.foundOptions[i].selected) {
          this.selectedAll = false;
        }

      }
    }
    return this.selectedAll
  }

  somePoisSelected() {

    if (this.foundOptions?.length) {
      let someSelected = false;

      for (let i = 0; i < this.foundOptions.length && !this.selectedAll; i++) {

        if (this.foundOptions[i].selected) {
          someSelected = true;
        }

      }
      return  someSelected;
    }

  }
}
