import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {
  private apiUrl = '/abacusgis/core/glocally/collection/area_municipality';

  constructor(
    private http: HttpClient
  ) {
  }

  getAll(params?: any) {
    return this.http.get(this.apiUrl, { params: this.stringifyParams(params) });
  }

  private stringifyParams(params: any) {
    if (typeof params === 'object') {
      Object.keys(params).forEach(key => {
        params[key] = JSON.stringify(params[key]);
      });
    }

    return params;
  }
}
