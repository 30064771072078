import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PoiInterface } from '@compass/pois/data-access-poi';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PresenceService } from './presence.service';
import {
  BottomContainerService
} from '../../../../../apps/glocally/src/app/shared/bottom-container/bottom-container.service';

@Injectable({
  providedIn: 'root'
})
export class PoisComparatorService {
  public selected$: BehaviorSubject<PoiInterface[]> = new BehaviorSubject<PoiInterface[]>([]);
  public compareMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private maxSelection = 6;

  private colors = ['#2E86C1', '#E74C3C', '#F1C40F', '#2ECC71', '#7D3C98', '#E67E22'];

  constructor(
    private snackBar: MatSnackBar,
    private presenceService: PresenceService,
    private bottomContainerService:BottomContainerService
  ) {
    //this.selected$.next(JSON.parse(localStorage.getItem('comparator')) ?? []);
    //this.compareMode$.next(true);
  }

  public async add(poi: PoiInterface) {
    const selected = this.getSelected();

    if (selected.length >= this.maxSelection) {
      this.snackBar.open(`Sólo se pueden añadir ${this.maxSelection} POIs como máximo al comparador`, 'cerrar', {
        panelClass: 'danger',
        duration: 4600
      });

      return;
    }

    selected.push(poi);

    if (selected?.length) {
      // localStorage.setItem('comparator', JSON.stringify(this.selected$.value));
    }

    // set actual data
    this.selected$.next(selected);

    // parse the pois
    this.parsePois(selected).then((parsed) => this.selected$.next(parsed));
    this.checkBottomContainer();
  }

  public updatedPoi(poi: any) {
    let selected: any = this.getSelected();
    selected = selected?.map(p => {
      if (p.properties.id === poi.properties.id) {
        p = poi;
      }
      return p;
    });

    this.selected$.next(selected);
    this.checkBottomContainer();
  }

  public async remove(poi: PoiInterface) {
    let selected = this.getSelected();
    selected = selected.filter((p) => {
      return p.properties.id !== poi.properties.id;
    });

    this.selected$.next(await this.parsePois(selected));
    this.checkBottomContainer();
  }

  public toggle(poi: PoiInterface) {
    this.isSelected(poi) ? this.remove(poi) : this.add(poi);
  }

  public isSelected(poi: PoiInterface) {
    return !!this.getSelected().find((p) => p.properties.id === poi.properties.id);
  }

  public getSelected() {
    return this.selected$.value;
  }

  public initComparation() {
    this.bottomContainerService.relativePos$.next(true)
     this.compareMode$.next(true);
  }

  public stopComparation() {
    this.bottomContainerService.relativePos$.next(false)
     this.compareMode$.next(false);
    this.checkBottomContainer()
  }

  private async parsePois(pois: PoiInterface[]) {
    const parsed = [];
    let index = 0;

    for (const poi of pois) {
      poi.properties.color = this.colors?.[index] ?? 'grey';

      if (!poi.properties.presence) {
        const res = await this.presenceService.getPresence(poi).toPromise();
        if(typeof res !== 'string') {
          poi.properties = { ...poi.properties, ...res };
        }
      }

      parsed.push(poi);
      index++;
    }

    return parsed;
  }

  checkBottomContainer(){
    if(this.selected$.value.length){
      this.bottomContainerService.show();
    }else {
      this.bottomContainerService.hide();
    }
  }
}
