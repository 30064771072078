import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Injectable({ providedIn: 'root' })
export class BusinessFormOptionsService {

  cnae_all: string = 'Todas';

  getDescripcionCnae(setup) {

    const temp = [];

    setup.forEach((business) => {
      if (temp.indexOf(business.properties.descripcion_cnae) === -1) {
        temp.push(business.properties.descripcion_cnae)
      }
    });

    temp.unshift(this.cnae_all);
    return temp;
  }



  // not in use here
  public resetForm(form: FormGroup, event) {
    event.preventDefault();
    form.reset();
  }

  public setControlValue(
    form: FormGroup,
    control: string,
    value: number | string | Array<any>
  ): void {
    form.get(control).setValue(value);
  }

}
