<nav class="margin__top--s">
    <div class="breadcrumbs-icons">
        <span class="material-icons">
            {{activeStage.icon}}
        </span>

    </div>
    <ul class="ul__horizontal ul__menu margin__top--m">
        <li><a href="" (click)="returnToStagesViewer($event)">Volver</a></li>
        <li *ngIf='activeStage'>> {{activeStage.value}}</li>
        <li *ngIf='parentCategory && activeTableLevel !== 1'>> {{parentCategory.name}}</li>

    </ul>
    <p><small>

            <span
                *ngIf='parentSubcategory && (activeTableLevel === 3 || activeTableLevel === 4)'>{{parentSubcategory.name}}</span>
            <span *ngIf='parentCompany && activeTableLevel === 4'> > {{parentCompany.name}}</span>
        </small></p>
</nav>