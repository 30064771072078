import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { SelectLayerGroupService } from '@compass/shared/widgets';
import { SideNavStatusService } from '@compass/utils/navigation';
import {
  CompassMapPoisService,
  CompassMapWrapperService,
  CompassMapDrawerService,
  Map
} from '@compass/utils/leaflet';
import { MapBoxService } from '@compass/utils/misc';
import { MatTableService } from '@compass/utils/misc';
import {Poi, PoiService, SidenavPoisLevelsService} from '@compass/pois/data-access-poi';
import { PoisSearcherService } from '../pois-searcher.service';
import { EndRightSidenavService } from '@compass/page-navigation/end-right-sidenav';

@Component({
  selector: 'compass-pois-table',
  templateUrl: './pois-table.component.html',
  styleUrls: ['./pois-table.component.scss']
})
export class PoisTableComponent implements OnInit, OnChanges {
  @Output() selectionList = new EventEmitter<any>();
  @Input() poisTableList: any;
  selection: SelectionModel<Poi> = new SelectionModel<Poi>(true, []);


  displayedColumns: string[] = ['select', 'name', 'more'];
  dataSource: MatTableDataSource<any>;

  map: Map;
  activeTableLevel: number;
  activeTableRoot: string;
  isFirstLoad: boolean;
  categoriesList: any;
  parentCategory: any;
  selectedAll: boolean = false;

  // mat-table service in HTML
  isAllSelected = this.matTableService.isAllSelected;
  masterToggle = this.matTableService.masterToggle;
  checkboxLabel = this.matTableService.checkboxLabel;

  // drawPois
  fitBoundsCategoryGroup = this.compassMapPoisService.fitBoundsCategoryGroup;
  getHeatMapData = this.compassMapPoisService.getHeatMapData;
  popUpText = this.compassMapPoisService.popUpText;
  removePoisLayer = this.compassMapPoisService.removePoisLayer;

  getTileLayerOptions = this.compassMapDrawerService.getTileLayerOptions;

  hour: Date;

  map$ = this.compassMapWrapperService.map$;
  layerGroup$ = this.selectLayerGroupService.layerGroup$;
  private drawTimeout: number;

  constructor(
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private sideNavStatusService: SideNavStatusService,
    private matTableService: MatTableService,
    private compassMapPoisService: CompassMapPoisService,
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private mapBoxService: MapBoxService,
    private selectLayerGroupService: SelectLayerGroupService,
    public poisSearcherService: PoisSearcherService,
  ) {


    this.sidenavPoisLevelsService
      .getActiveTableLevel()
      .subscribe((value: number) => {
        this.activeTableLevel = value;
      });

    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((tableRoot: string) => {
        this.activeTableRoot = tableRoot;
      });

    this.sidenavPoisLevelsService
      .getIsFirstLoad()
      .subscribe((value: boolean) => {
        this.isFirstLoad = value;
      });

    this.sidenavPoisLevelsService
      .getCategoryList()
      .subscribe((categoryList: string) => {
        this.categoriesList = categoryList;
      });

    this.sidenavPoisLevelsService
      .getParentCategory()
      .subscribe((category: string) => {
        this.parentCategory = category;
      });

    this.compassMapWrapperService.map$.subscribe((map: Map) => {
      this.map = map;
      if (['global_pois', 'local_pois'].includes(this.activeTableRoot)) {
        this.map.access_token = this.mapBoxService.getMapBoxUrl('light')[0].value;
      }

      if (this.activeTableRoot === 'transport') {
        this.map.access_token = this.mapBoxService.getMapBoxUrl('transport_light')[0].value;
      }
    });


    // when searcher toggled point
    this.poisSearcherService.toggledPoi$.subscribe(point => {
      if (point) {
        this.selection.select(point);
        this.categoriesList = this.sidenavPoisLevelsService.setSelectedNodesTree(
          this.sidenavPoisLevelsService.toggleNode(this.categoriesList, point)
        );
        this.checkRowsSelected();
        this.selection.toggle(point);
        this.drawMarkers();
      }
    });

  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<Poi>(this.poisTableList);
    if (this.dataSource) {
      this.checkRowsSelected();
    }

    if (this.activeTableLevel === 1 && this.isFirstLoad) {
      this.drawMarkers(this.isFirstLoad);
      this.sidenavPoisLevelsService.setIsFirstLoad(false);

    }
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    //this.selection.selected.forEach((row)=> this.selection.toggle(row))
    if(changes.poisTableList){
      this.drawMarkers();
    }

  }


  // ROWS --> pois
  // ROW --> poi

  // On click button, checkbox is checked if it hasn´t been checked before.
  rowIsSelected(row: Poi): boolean {
    return this.selection.isSelected(row);
  }

  checkRowsSelected() {
    this.dataSource.data.forEach((row) => {
      if (row.selected) {
        this.selection.select(row);
      }
    });
  }

  // CHECKBOXES
  // first event: click event fires first, change event fires next
  onClickCheckbox(event: Event) {
    event.stopPropagation();
  }

  onChangeCheckbox(event: any, state, row: Poi) {
    if (event) {
      this.selection.clear();

      if (event.checked) {
        this.selection.select(row);
      } else {
        this.selection.deselect(row);
      }
      //this.sidenavPoisLevelsService.toggleNode()
      this.sidenavPoisLevelsService.updateRoot(row, this.selection.selected, this.categoriesList);
      //this.checkRowsSelected();
      this.drawMarkers();
    }
  }

  onChangeMasterToggleCheckbox(event: any) {
    if (event) {
      if (event.checked) {
        this.dataSource.data.forEach(poi => {
          this.selection.select(poi);
          this.sidenavPoisLevelsService.updateRoot(poi, this.selection.selected, this.categoriesList);
          this.selection.clear();
        });
      } else {
        this.dataSource.data.forEach(poi => {
          this.selection.deselect(poi);
          this.sidenavPoisLevelsService.updateRoot(poi, this.selection.selected, this.categoriesList);
          this.selection.clear();
        });
      }
      this.drawMarkers()
      // mat-table func
      /*this.masterToggle();
      // compass logic state
      this.sidenavPoisLevelsService.toggleRoot(
        this.selection.selected,
        this.categoriesList,
        this.activeTableLevel
      );

      // draw func
      if (this.allPoisSelected() && this.categoriesList.length > 0) {
        this.drawMarkers();
        this.fitBoundsCategoryGroup();
      } else {
        this.compassMapPoisService.removePoisLayer(this.activeTableRoot);
      }*/
    }
  }

  onClickGoToNextLevelButton(event: Event, row: Poi) {
    event.preventDefault();
    event.stopPropagation();
    this.sidenavPoisLevelsService.setActiveListByLevel(row);
    this.sidenavPoisLevelsService.setParentRowByLevel(row); // for breadcrumps
    this.sidenavPoisLevelsService.setActiveTableLevel(
      this.activeTableLevel + 1
    );
    //this.sidenavPoisLevelsService.setActiveTableRoot(this.activeTableRoot);

    // sidenav status func
    this.updateSidenavStatus();
  }

  updateSidenavStatus() {
    if (this.activeTableLevel === 2) {
      this.sideNavStatusService.setStageViewerStatus(false);
      this.sideNavStatusService.setSubcategoriesNavStatus(true);
    }

    if (this.activeTableLevel === 3) {
      this.sideNavStatusService.setSubcategoriesNavStatus(false);
      this.sideNavStatusService.setCompaniesNavStatus(true);
    }

    if (this.activeTableLevel === 4) {
      this.sideNavStatusService.setCompaniesNavStatus(false);
      this.sideNavStatusService.setPlacesNavStatus(true);
    }
  }

  drawMarkers(isFirstLoad?: boolean) {
    const poisToDraw = isFirstLoad ? this.poisTableList : this.categoriesList;
    clearTimeout(this.drawTimeout);
    this.drawTimeout = setTimeout(() => {
      this.compassMapPoisService.drawMarkersFromNestedList(
        this.map$.value,
        poisToDraw,
        this.layerGroup$.value
      );
    }, 300);
  }

  allPoisSelected() {

    if (this.poisTableList?.length) {
      this.selectedAll = true;

      for (let i = 0; i < this.poisTableList.length && this.selectedAll; i++) {

        if (!this.poisTableList[i].selected) {
          this.selectedAll = false;
        }

      }
    }
    //console.log(this.selectedAll)
    return this.selectedAll;
  }


  somePoisSelected() {

    if (this.poisTableList?.length) {
      let someSelected = false;

      for (let i = 0; i < this.poisTableList.length && !this.selectedAll; i++) {

        if (this.poisTableList[i].selected) {
          someSelected = true;
        }

      }
      return  someSelected;
    }

  }
}
