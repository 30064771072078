import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';
import { CompassMapWrapperService, Map } from './compass-map-wrapper.service';
import { PoiService, SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { PoiBasketService } from '@compass/shared/poi-basket';
import { MarkerOptionsApiService, OnclickStageShapeService } from '@compass/utils/navigation';
import { CompassMapIndicatorsService } from './compass-map-indicators.service';
import { EndRightSidenavService } from '@compass/page-navigation/end-right-sidenav';
import { PoisComparatorService } from '@compass/pois/comparator';
import { LocalKnowledgeReshapeService } from '@compass/local-knowledge';
import { HasEvents } from '../../../../map/src/lib/classes/has-events';
import { fromEvent } from 'rxjs';
import {CompassMapComponent} from '../../../../../apps/glocally/src/app/shared/compass-map/compass-map.component';

// http://osiris-indoor.github.io/tutorial/2016/06/16/Adding-and-Showing-Points-of-Interest.html

@Injectable({providedIn: 'root'})
export class CompassMapPoisService extends HasEvents{
  studyFormNavOn: boolean;
  map: Map;
  activeTableRoot: string;

  constructor(
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private markerOptionsApiService: MarkerOptionsApiService,
    private poiBasketService: PoiBasketService,
    private mapIndicatorsService: CompassMapIndicatorsService,
    private endRightSidenavService: EndRightSidenavService,
    private onclickStageShapeService: OnclickStageShapeService,
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private poiService: PoiService,
    private poisComparatorService: PoisComparatorService,
    private localKnowledgeService: LocalKnowledgeReshapeService,
    private compassMapWrapperService:CompassMapWrapperService
  ) {
    super();
    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((d: string) => {
        this.activeTableRoot = d;
      });

    this.poiBasketService.basket$.subscribe(basket => {
      if (this.map?.pois) {
        const layer = 'BASKET';
        if (this.map.pois[layer]) {
          this.map.box.removeLayer(this.map.pois[layer]);
        }

        this.map.pois[layer] = L.featureGroup([]);

        for (var group in basket) {
          switch (group) {
            case 'area_brick_hexagon_medium':
              basket[group].forEach(brick => {
                this.mapIndicatorsService.drawBrick(brick, layer, this.map);
              });
              break;
            case 'point_poi':
              this.drawFlatPoisLayer(basket[group], layer, 'featureGroup');
              break;
            case 'point_patrimonio':
              this.drawFlatPoisLayer(basket[group], layer, 'featureGroup');
              break;
            case 'events':
              this.drawFlatPoisLayer(basket[group], layer, 'featureGroup');
              break;
          }
        }
      }
    });
  }

  setMap(map) {
    this.map = map;
  }

  public removePoisLayer(layer): void {
    if (this.map && this.map.pois[this.activeTableRoot]) {
      this.map.box.removeLayer(this.map.pois[this.activeTableRoot]);
    } else if (this.map && this.map.pois[layer]) {
      this.map.box.removeLayer(this.map.pois[layer]);
    }
  }

  public fitBoundsCategoryGroup(): void {
    if (this.map['pois'][this.activeTableRoot]) {
      let bounds = this.map['pois'][this.activeTableRoot].getBounds();
      if (bounds && bounds._northEast && bounds._northEast.lng && bounds._southWest && bounds._southWest.lat) {
        this.map.box.fitBounds(bounds);
      }
    }
  }

  public setViewToMapCenter(): void {
    this.map.box.setView(
      new L.LatLng(
        this.map.properties.center[0],
        this.map.properties.center[1]
      ),
      this.map.properties.zoom
    );
  }

  /**
   * Converts the tree list of categories to an array of regular POIS.
   *
   * @param list
   */
  public setFlatPoisFromNestedList(list: any) {
    let points = [];
    if (['local_pois', 'transport', 'heritage'].includes(this.activeTableRoot)) {
      list.forEach((poi) => {
        poi.values?.forEach((place) => {
          if (place.selected) {
            points.push(place);
          }
        });
      });
    } else {
      list.forEach((poi) => {
        poi.values?.forEach((subpois) => {
          subpois.values?.forEach((companies) => {
            companies.values?.forEach((place) => {
              if (place.selected) {
                points.push(place);
              }
            });
          });
        });
      });
    }

    return points;
  }

  public buildIconMarkers(pois: any) {
    return pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];
      let markerOptions = poi.markerOptions || null;


      let marker;

      if (poi.properties && poi.properties.fillColor) {
        marker = {
          circleMarker: L.circleMarker([lat, long], {
            color: '#333333', // border color
            weight: 1, // border weight in px
            fillColor: poi.properties.fillColor || '#0055FF', // circle color
            fillOpacity: 0.8,
            radius: 8, // Radius of the circle marker, in pixels
            className: 'poi-circle'
          }),
          popUp: poi.popUpText ? poi.popUpText(poi) : this.popUpText(poi)
        };

        let timeout;

        marker.circleMarker.on( 'mouseover', (e) => {
          //this.fireEvent('mouseover', poi);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            //marker.openPopup()
            e.sourceTarget.openPopup();
          }, 600)
        });

        marker.circleMarker.on('mouseout', (e) => {
          //this.fireEvent('mouseout', poi);
          clearTimeout(timeout);

          if (poi.dontCloseOnMouseOut) {
            poi.dontCloseOnMouseOut = false;
          } else {
            e.sourceTarget.closePopup();
          }
        });

        marker.circleMarker.on('click', (e) => {
          //this.fireEvent('click', poi);
          e.sourceTarget.openPopup();
          poi.dontCloseOnMouseOut = true;
        });

      } else {
        let markerHtml: string;

        // set basket status to poi
        poi.inBasket = this.poiBasketService.inBasket(poi);
        poi.compare = this.poisComparatorService.isSelected(poi);

        if (!markerOptions) {
          markerOptions = this.markerOptionsApiService.getMarkerOptions(poi);
        }

        // check if real icon url to build the icon html
        if (markerOptions.realIconUrl) {
          markerHtml = `<img src="${markerOptions.realIconUrl}">`;
        } else {
          markerOptions.iconUrl = markerOptions.iconUrl || 'assets/img/icons/0';
          if(this.activeTableRoot === 'global_pois'){
            markerHtml = `<img src='${markerOptions.iconUrl}.svg' onerror="this.onerror=null;this.src='${markerOptions.iconUrl}.png'" width='10px'>`;

          }else {
            markerHtml = `<img src="${markerOptions.iconUrl}.png" onerror="this.onerror=null;this.src='/icons/${poi.properties.icono}.png'" width="10px">`;
          }
        }
        let extraClass = poi.inBasket ? 'inBasket' : '';
        extraClass += poi.compare ? ' compare' : '';

        const icon = L.divIcon({
          iconSize: markerOptions.iconSize || [24, 24],
          iconAnchor: markerOptions.iconAnchor || 'auto', // auto size
          popupAnchor: markerOptions.popupAnchor || [0, 0],
          html: markerHtml,
          className: `abacus-poi-marker ${poi.properties.class_}${poi.properties.id} poi-id_${poi.properties.id} ` + extraClass
        });

        return {
          actualMarker: L.marker([lat, long], {
            draggable: false,
            icon: icon
          }),
          popUp: this.popUpText(poi),
          // add callback to handle when popup is oppened
          popupopenCallback: (event, marker) => {
            // update content based on actual poi info
            //this.poiService.selectedPoi$.next(marker)
            marker.actualMarker.setPopupContent(this.popUpText(poi));
            marker.actualMarker.info = poi;


            this.poiBasketService.onPopupOpen(poi, (isInBasket) => {
              // close and open popup to rebind click events
              marker.actualMarker.closePopup();

              // update icon class
              if (marker.actualMarker._icon) {
                if (isInBasket) {
                  marker.actualMarker._icon.classList.add('inBasket');
                } else {
                  marker.actualMarker._icon.classList.remove('inBasket');
                }
              }
            });

            const infoBtn = document.getElementById('infoBtn');
            if (infoBtn) {
              fromEvent(infoBtn, 'click').subscribe(() => {
                if (poi.properties.category == 'events') {
                  this.endRightSidenavService.endRightSidenavOn$.next(true);
                  this.endRightSidenavService.setTitle('Detalles de evento');
                  this.onclickStageShapeService.shapeClicked$.next('event');
                  this.localKnowledgeService.set(poi);

                  marker.actualMarker.closePopup()
                } else {
                  this.poiService.selectedPoi$.next(marker);
                  marker.actualMarker.closePopup();
                }
              });
            }
            const compareBtn = document.getElementById('compareBtn');
            if (compareBtn) {
              fromEvent(compareBtn, 'click').subscribe(() => {
                this.poisComparatorService.toggle(poi);
                marker.actualMarker.closePopup();
              });
            }
          }
        };
        /*poi.markerOptions.iconUrl = poi.markerOptions?.iconUrl || 'assets/marker/blue_dot';

        marker = {
          actualMarker: L.marker([lat, long], {
            draggable: false,
            icon: L.divIcon({
              iconSize: poi.markerOptions?.iconSize || [24, 24],
              iconAnchor: poi.markerOptions?.iconAnchor || [24, 24],
              popupAnchor: poi.markerOptions?.popupAnchor || [-4, -24],
              html: `<img width="24" src="${poi.markerOptions?.iconUrl}.svg" onerror="this.onerror=null;this.src='${poi.markerOptions?.iconUrl}.png'">`,
              className: 'abacus-poi-marker'
            })
          }),
          popUp: poi.popUpText ? poi.popUpText(poi) : this.popUpText(poi)
        };*/
      }


      return marker;
    });

    /*return pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];
      let markerOptions = poi.markerOptions || null;

      let markerHtml: string;

      // set basket status to poi
      poi.inBasket = this.poiBasketService.inBasket(poi);
      poi.compare = this.poisComparatorService.isSelected(poi);

      if (!markerOptions) {
        markerOptions = this.markerOptionsApiService.getMarkerOptions(poi);
      }

      // check if real icon url to build the icon html
      if (markerOptions.realIconUrl) {
        markerHtml = `<img src="${markerOptions.realIconUrl}">`;
      } else {
        markerOptions.iconUrl = markerOptions.iconUrl || 'assets/img/icons/0';
        markerHtml = `<img src="${markerOptions.iconUrl}.svg" onerror="this.onerror=null;this.src='${markerOptions.iconUrl}.png'" width="10px">`;
      }

      let extraClass = poi.inBasket ? 'inBasket' : '';
      extraClass += poi.compare ? ' compare' : '';

      const icon = L.divIcon({
        iconSize: markerOptions.iconSize || [24, 24],
        iconAnchor: markerOptions.iconAnchor || 'auto', // auto size
        popupAnchor: markerOptions.popupAnchor || [0, 0],
        html: markerHtml,
        className: `abacus-poi-marker ${poi.properties.class_}${poi.properties.id} poi-id_${poi.properties.id} ` + extraClass
      });

      return {
        actualMarker: L.marker([lat, long], {
          draggable: false,
          icon: icon
        }),
        popUp: this.popUpText(poi),
        // add callback to handle when popup is oppened
        popupopenCallback: (event, marker) => {
          // update content based on actual poi info
          //this.poiService.selectedPoi$.next(marker)
          marker.actualMarker.setPopupContent(this.popUpText(poi));
          marker.actualMarker.info = poi;


          this.poiBasketService.onPopupOpen(poi, (isInBasket) => {
            // close and open popup to rebind click events
            marker.actualMarker.closePopup();

            // update icon class
            if (marker.actualMarker._icon) {
              if (isInBasket) {
                marker.actualMarker._icon.classList.add('inBasket');
              } else {
                marker.actualMarker._icon.classList.remove('inBasket');
              }
            }
          });

          const infoBtn = document.getElementById('infoBtn');
          if (infoBtn) {
            fromEvent(infoBtn, 'click').subscribe(() => {
              if (poi.properties.category == 'events') {
                this.endRightSidenavService.endRightSidenavOn$.next(true);
                this.endRightSidenavService.setTitle('Detalles de evento');
                this.onclickStageShapeService.shapeClicked$.next('event');
                console.log(poi)
                this.localKnowledgeService.set(poi);

                marker.actualMarker.closePopup()
              } else {
                this.poiService.selectedPoi$.next(marker);
                marker.actualMarker.closePopup();
              }
            });
          }
          const compareBtn = document.getElementById('compareBtn');
          if (compareBtn) {
            fromEvent(compareBtn, 'click').subscribe(() => {
              this.poisComparatorService.toggle(poi);
              marker.actualMarker.closePopup();
            });
          }
        }
      };
    });*/
  }

  public addMarkersToMap(markers: any, layer: string) {
    markers.map((marker) => {
      if(marker.actualMarker){
        marker.actualMarker.addTo(this.map.pois[layer])
          .bindPopup(marker.popUp);

        // bind when popupopen
        marker.actualMarker.on('popupopen', (event) => {

          if (marker.popupopenCallback && typeof marker.popupopenCallback === 'function') {
            marker.popupopenCallback(event, marker);
          }
        });
      }else{
        marker.circleMarker.addTo(this.map.pois[layer]).bindPopup(marker.popUp);
      }


    });
  }

  //' drawFlatPoisLayer: main function drawer: It draws the pois by layer
  //'
  //' Layer is the stage we are (global pois, local pois, transport, heritage)
  //' Markerclusters only on Global pois
  //' @param pois: flat array of pois.
  //' @param layer: Layer
  //' @return void

  getHeatMapData(pois) {
    let locations = [];
    pois.forEach((poi) => {
      let coords = poi.geometry.coordinates;
      let location = coords.slice().reverse();
      let intensity = 1;
      location.push(intensity);
      locations.push(location);
    });
    return locations;
  }

  public drawFlatPoisLayer(pois: any, layer: string, layerGroup: string) {
    switch (layerGroup) {
      case 'featureGroup': {
        this.map.pois[layer] = L.featureGroup([]);
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
        this.map.pois[layer].addTo(this.map.box);
      }
        break;

      case 'markerClusterGroup': {
        this.map.pois[layer] = L.markerClusterGroup();
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
        this.map.box.addLayer(this.map['pois'][layer]);
      }
        break;
      case 'heatMapGroup': {
        this.map['locations'] = this.getHeatMapData(pois);
        this.map['heatLayer'] = {
          radius: 24,
          blur: 16,
          maxZoom: 8,
          gradient: {
            0.0: '#3e4a89',
            0.5: '#1f9e89',
            1.0: '#fde725'
          }
        };
        this.map['pois'][layer] = L.heatLayer(
          this.map['locations'],
          this.map['heatLayer']
        ).addTo(this.map.box);
      }
        break;
      default: {
        return null;
      }
    }
  }

  public drawMarkers(pois: any, layer: string = 'poisLayer', map: Map = undefined) {

    this.map = map || this.map;
    this.removeLayer(layer);
    // if the map isset (delay on init componente)
    if (this.map) {
      this.map.pois[layer] = L.featureGroup([]);
      const markers = this.buildIconMarkers(pois);

      this.addMarkersToMap(markers, layer);
      this.map.pois[layer].addTo(this.map.box);
    }
  }

  /**
   * Set flat pois from nested lists and pass the array to the draw function.
   *
   * @param map
   * @param list
   * @param layerGroup
   */
  public drawMarkersFromNestedList(map: any, list: any, layerGroup: string) {
    this.map = map;
    this.removePoisLayer(this.activeTableRoot);
    let pois = this.setFlatPoisFromNestedList(list);
    this.drawFlatPoisLayer(pois, this.activeTableRoot, layerGroup);
  }

  public popUpText = (poi) => {
    let text = '<p data-id="popUpText"><strong>' + poi.name + '</strong></p>';
    if (poi.properties && poi.properties.direccion) {
      text += '<p>' + poi.properties.direccion + '</p>';
    }
    if (poi && poi.properties && poi.properties.descripcion) {
      text += '<p> ' + poi.properties.descripcion + '</p>';
    }

    if (poi && poi.properties && poi.properties.valoracion) {
      text += '<p>Valoración: ' + poi.properties.valoracion + '</p>';
    }
    if (poi && poi.properties && poi.properties.puntuacion) {
      text += '<p>Puntuación: ' + poi.properties.puntuacion + '</p>';
    }
    if (poi && poi.properties && poi.properties.impactos) {
      text += '<p>Impactos: ' + poi.properties.impactos.toLocaleString() + '</p>';
    }
    if (poi && poi.properties && poi.properties.individuos) {
      text += '<p>Individuos: ' + poi.properties.individuos.toLocaleString() + '</p>';
    }

    text += this.poiBasketService.getButton(poi);

    // add flex div for extra buttons
    text += '<div class="popup-buttons">';
    text += `
        <button id="infoBtn" title="Ver más información">
          <span class="material-icons">info</span> Info
        </button>
      `;

    // comparator button
    const hasCompare = this.poisComparatorService.getSelected().length > 0;
    const isSelected = this.poisComparatorService.isSelected(poi);

    const title = isSelected ? 'Quitar de la comparación' : (hasCompare ? 'Añadir a la comapración' : 'Comaprar POI');
    if (poi.properties.category != 'events') {
      text += `
      <button id="compareBtn" class="${isSelected ? 'secondary' : ''}" title="${title}">
          <span class="material-icons">${isSelected ? 'clear' : 'difference'}</span>
          ${isSelected ? 'Quitar' : 'Comparar'}
      </button>
    `;
    }

    text += '</div>';
    // close flex div

    return text;
  };

  public removeLayer(layer): void {
    if (this.map && this.map.pois[this.activeTableRoot]) {
      this.map.box.removeLayer(this.map.pois[this.activeTableRoot]);
    }
    if (this.map && this.map.pois[layer]) {
      this.map.box.removeLayer(this.map.pois[layer]);
    }
  }




}


