import { Component, OnInit } from '@angular/core';
import { InfluencersApiService } from './influencers-api.service';

@Component({
  selector: 'compass-influencers-loader',
  templateUrl: './influencers-loader.component.html',
  styleUrls: ['./influencers-loader.component.scss']
})
export class InfluencersLoaderComponent implements OnInit {
  influencers$ = this.influencersApiService.influencers$;

  constructor(
    private influencersApiService: InfluencersApiService
  ) { }

  ngOnInit(): void {
    this.influencersApiService.getInfluencersData().subscribe((d) => {
      this.influencers$.next(d);
    });
  }
}
