import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, Event } from '@angular/router';
import { SideNavStatusService } from '@compass/utils/navigation';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'glocally-app';
  showLayout: boolean;
  //DEV API -> change environment dev (localhost) or pre
  env = 'pre';
  //env = 'dev';



  constructor(
    private router: Router,
    private startLeftSidenavService: StartLeftSidenavService,
    private sideNavStatusService: SideNavStatusService,
    private titleService: Title
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showLayout = this.router.url !== '/login';
      }
    });

    this.startLeftSidenavService.setSidenavIsOpen(false);
    this.startLeftSidenavService.setCompassStatus(false);
    this.sideNavStatusService.setStudyFormStatus(false);
    this.sideNavStatusService.setStagesNavStatus(false);
    this.sideNavStatusService.setStageViewerStatus(false);
    this.sideNavStatusService.setSubcategoriesNavStatus(false);
    this.sideNavStatusService.setCompaniesNavStatus(false);
    this.sideNavStatusService.setPlacesNavStatus(false);
    // DEV API
    if (this.env === "dev") {
      this.startLeftSidenavService.setSidenavIsOpen(true);
      this.startLeftSidenavService.setCompassStatus(true);
      this.sideNavStatusService.setStudyFormStatus(false);
      this.sideNavStatusService.setStagesNavStatus(true);
      this.sideNavStatusService.setStageViewerStatus(false);
      this.sideNavStatusService.setSubcategoriesNavStatus(false);
      this.sideNavStatusService.setCompaniesNavStatus(false);
      this.sideNavStatusService.setPlacesNavStatus(false);
    }
    this.titleService.setTitle(environment.title);
    this.sideNavStatusService.setStagesNavOnLoading(false);
  }

}
