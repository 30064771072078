<section>
  <div class="container margin__bottom--s">
    <p>
      <span class="material-icons primary">create_new_folder</span>
      <span *ngIf="study">
        Estudio <strong>{{ study.properties?.study?.name }}</strong>
      </span>
      <span *ngIf="!study">Crear estudio</span>
    </p>
    <mat-divider></mat-divider>
    <form [formGroup]="formGroup" class="container">
      <mat-form-field appearance="fill" data-cy="new-study-name">
        <mat-label>Nombre del estudio</mat-label>
        <input
          matInput
          formControlName="name"
          (keydown.enter)="$event.preventDefault()"
        />
        <mat-error>El nombre es olbigatorio</mat-error>
      </mat-form-field>
    </form>
  </div>
</section>
