import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import { LocalMediaApiService } from './local-media-api.service';

@Component({
  selector: 'compass-local-media-loader',
  templateUrl: './local-media-loader.component.html',
  styleUrls: ['./local-media-loader.component.scss']
})
export class LocalMediaLoaderComponent implements OnInit {
  localMedia$ = this.bricksApiService.localMedia$;
  constructor(
    private bricksApiService: BricksApiService,
    private localMediaApiService: LocalMediaApiService
  ) { }

  ngOnInit(): void {
    // fake api, to be done on brick.api.service
    this.localMediaApiService.getLocalMediaData().subscribe((d) => {
      this.localMedia$.next(d);
    });
  }

}