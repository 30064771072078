import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import * as dc from 'dc';
import * as d3 from 'd3';

import * as crossfilter from 'crossfilter2';
import { OldCrossfilterService } from '../old-crossfilter.service';

@Component({
  selector: 'compass-crossfilter',
  templateUrl: './crossfilter.component.html',
  styleUrls: ['./crossfilter.component.scss']
})
export class CrossfilterComponent {
  _pois: any = [];
  barChart: any;

  @Output() filtered: EventEmitter<any> = new EventEmitter<any>(); //<---- On this line!

  constructor(private crossfilterService: OldCrossfilterService) {
  }

  @Input()
  set pois(val: any) {
    this._pois = val;
    if (this._pois) {
      this.crossFilter();
    }
  }


  // use getter setter to define the property
  get pois(): any {
    return this._pois;
  }


  public onChange() {
    this.filtered.emit(this._pois); // emit filtered in case the callback is passed as 'filtered' attribute
    this.crossfilterService.filterCallback(this._pois);
  }

  private crossFilter() {
    /*
      Datetime,Latitude,Longitude,Maid
      2021-01-27T23:18:28Z,41.6566146,-0.8673799,023bca37-0c93-44e3-a591-d476a3f70468
      2021-01-27T23:25:48Z,41.6566146,-0.8673799,023bca37-0c93-44e3-a591-d476a3f70468
      2021-01-27T23:30:56Z,41.6566146,-0.8673799,023bca37-0c93-44e3-a591-d476a3f70468
      2021-01-27T23:36:13Z,41.6566674,-0.8676817,023bca37-0c93-44e3-a591-d476a3f70468
     */

    this.barChart = dc.barChart('#dateChart');

    const ndx = crossfilter(this._pois);
    const all = ndx.groupAll();

    const dateDim = ndx.dimension((d:any) => {
      return d.date;
    });
    const dateGroup = dateDim.group();

    this.barChart
      .width(document.getElementById('charts').offsetWidth)
      .height(70)
      .dimension(dateDim)
      .group(dateGroup)
      .x(d3.scaleTime().domain([dateGroup.all()[0].key, dateGroup.all()[dateGroup.all().length - 1].key]))
      .outerPadding(0)
      .filterHandler((dimension, filters) => {
        // begin -- DEFAULT FILTER HANDLER
        if (filters.length === 0) {
          // the empty case (no filtering)
          dimension.filter(null);
        } else if (filters.length === 1 && !filters[0].isFiltered) {
          // single value and not a function-based filter
          dimension.filterExact(filters[0]);
        } else if (filters.length === 1 && filters[0].filterType === 'RangedFilter') {
          // single range-based filter
          dimension.filterRange(filters[0]);
        } else {
          // an array of values, or an array of filter objects
          dimension.filterFunction(function(d) {
            for (let i = 0; i < filters.length; i++) {
              const filter = filters[i];
              if (filter.isFiltered && filter.isFiltered(d)) {
                return true;
              } else if (filter <= d && filter >= d) {
                return true;
              }
            }
            return false;
          });
        }
        // end -- DEFAULT FILTER HANDLER

        if (filters.length) {
          // send to parent filtered pois
          this.filtered.emit(dimension.top(Infinity));
        } else {
          this.filtered.emit([dateGroup.all()[0].key, dateGroup.all()[10].key]);
        }

        // return the actual filter value
        return filters;
      })
      .elasticY(true)
      .centerBar(true)
      .renderHorizontalGridLines(true)
      .brushOn(true);

    dc.renderAll();

    const brushFrom = 0;
    const brushTo = 30;

    setTimeout(() => {
      this.barChart
        .filter(dc.filters.RangedFilter(dateGroup.all()[brushFrom].key, dateGroup.all()[brushTo].key))
    },300);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.barChart.width(document.getElementById('charts').offsetWidth);
    dc.renderAll();
  }
}
