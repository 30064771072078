import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Period {
  value: number;
  periodo: string;
  descripcion?: string;
}
export interface Wind {
  direccion: string;
  velocidad: string;
  periodo: string;
}
export interface Dato {
  value: number;
  hora: string;
}
export interface Temperatura {
  maxima: number;
  minima: number;
  dato: Array<Dato>;
}
export interface OneDayWeather {
  probPrecipitacion: Array<Period>;
  cotaNieveProv: Array<Period>;
  estadoCielo: Array<Period>;
  viento: Array<Wind>;
  rachaMax: Array<Period>;
  temperatura: Temperatura;
  sensTermica: Temperatura;
  humedadRelativa: Temperatura;
  uvMax: number;
  fecha: string;
}
export interface SevenDaysWeather {
  origen: {
    productor: string;
    web: string;
    enlace: string;
    language: string;
    copyright: string;
    notaLegal: string;
  };
  elaborado: string;
  nombre: string;
  provincia: string;
  prediccion: {
    dia: Array<OneDayWeather>;
  };
  id: number;
  version: number;
}

@Injectable({ providedIn: 'root' })
export class WeatherDataService {
  private weatherUrl = '/abacusgis/weather/glocally/municipality/';
  private dummyUrl = 'assets/data/weather/weather-079.json'; // madrid 7 days forecast
  public sevenDaysWeather$: BehaviorSubject<
    SevenDaysWeather[]
  > = new BehaviorSubject<SevenDaysWeather[]>(undefined);
  private prevMuncipalityId: string;

  constructor(private http: HttpClient) {}

  getSevenDaysWeather(muncipalityId: string) {
    if (this.prevMuncipalityId !== muncipalityId) {
      this.sevenDaysWeather$.next(undefined);
      return (
        this.http
          .get(`${this.weatherUrl}${muncipalityId}`)
          // .get(`${this.dummyUrl}?query={"key":""}`)
          .pipe(map((data: SevenDaysWeather[]) => data))
          .subscribe((data: SevenDaysWeather[]) => {
            this.sevenDaysWeather$.next(data);
            this.prevMuncipalityId = muncipalityId;
          })
      );
    }
  }
}
