import { Component } from '@angular/core';
@Component({
  selector: 'glo-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent {

  constructor() {
  }
}
