import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'compass-mat-dialog-not-found',
  templateUrl: './mat-dialog-not-found.component.html',
  styleUrls: ['./mat-dialog-not-found.component.scss']
})
export class MatDialogNotFoundComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MatDialogNotFoundComponent>) { }
  close(e) {
    this.dialogRef.close(false);
  }
  ngOnInit(): void {
  }

}
