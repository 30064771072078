import { Injectable } from '@angular/core';
import * as d3 from "d3";

// NEST NEW CATEGORIES


@Injectable({ providedIn: 'root' })
export class LocalKnowledgeOptionsService {


    constructor() { }

    nestCategories(data) {
        return d3.nest()
            .key((d) => {
                return d.categoria;
            })
            .sortKeys(d3.ascending)
            .key((d) => { return d.subcategoria; })
            .sortKeys(d3.ascending)
            .entries(data);
    };
    // options
    public getOptions(setup) {

        let temp = {
            key: "Todos", values: [{ key: "Todos" }]
        }
        let nestOptions = this.nestCategories(setup);

        nestOptions.forEach((option) => {
            option.values.forEach((children) => {
                let index = temp.values.findIndex(x => x.key === children.key);
                if (index === -1) { temp.values.push({ key: children.key }) }
            })
        })
        nestOptions.push(temp);
        return nestOptions;
    };

}



