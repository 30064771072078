import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { LocalMediaLoaderComponent } from './local-media-loader/local-media-loader.component';
import { LocalMediaFormComponent } from './local-media-form/local-media-form.component';
import { LocalMediaApiService } from './local-media-loader/local-media-api.service';
import { LocalMediaFormOptionsService } from './local-media-form/local-media-form-options.service';
import { LocalMediaAccordionComponent } from './local-media-accordion/local-media-accordion.component';
import { LocalMediaReshapeService } from './local-media-reshape.service';


@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [LocalMediaLoaderComponent, LocalMediaFormComponent, LocalMediaAccordionComponent],
  exports: [LocalMediaLoaderComponent, LocalMediaAccordionComponent],
  providers: [LocalMediaApiService, LocalMediaFormOptionsService, LocalMediaReshapeService]
})
export class LocalMediaModule { }
