<section>
  <div class="container">
    <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
  </div>
</section>

<section>
  <div class="container">
    <compass-pois-accordion [setup]="accordionSetup">
      <compass-heritage-loader></compass-heritage-loader>
    </compass-pois-accordion>
  </div>
</section>