import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Indicator, SideNavIndicatorsService } from './sidenav-indicators.service';
import { BricksApiService } from '@compass/brick-api';
import { DictionaryEntryInterface } from './sidenav-indicators.service';

@Injectable()
export class IndicatorService {
  public activeIndicator$: BehaviorSubject<Indicator> = new BehaviorSubject<Indicator>(undefined);
  public indicatorsList$ = new BehaviorSubject<any>(undefined);

  constructor(
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private bricksApiService: BricksApiService
  ) {
  }

  getActiveIndicator() {
    return this.activeIndicator$.asObservable();
  }

  setActiveIndicator(indicator: Indicator): void {
    if (indicator) {
      this.activeIndicator$.next(indicator);
    }
  }

  setAnalyticsIndicators(indicators: Indicator[], dic: any): void {
    const fullIndicators = indicators.map((i) => {
      let found = dic.find((d) => d.key === i.key);
      i.screen_name = found?.screen_name;
      i.grupo = found?.grupo;
      i.formato = found?.formato;
      return i;
    });

    this.indicatorsList$.next(indicators);
  }

  /**
   * Returns actual bricks with loop of it and another loop inside having each indicator
   * with actual value in it.
   *
   * @param onEachIndicator - callback function to handle brick for each feature
   * @param onBeforeTransform - callback function to handle at the beginning
   * @param filterBricks - filter function before results is sent
   */
  getBricks(
    onEachIndicator: (indicator, indicatorBrickValue, brick) => any = undefined,
    onBeforeTransform: (bricks) => any = undefined,
    onEachBrick: (brick) => any = undefined
  ) {
    let bricks = this.bricksApiService.bricks$.value;
    // callback on before transform
    if (typeof onBeforeTransform === 'function') {
      bricks = onBeforeTransform(bricks) || bricks;
    }

    bricks = bricks.map(brick => {
      this.indicatorsList$.value?.forEach((indicator) => {
        // get indicator value in this brick
        const indicatorBrickValue = brick.properties['data_portals2019']?.[indicator.grupo]?.[indicator.key];
        // set excluded if not in range
        brick.properties.excluded = brick.properties.excluded || !(indicator.minValue <= indicatorBrickValue && indicator.maxValue >= indicatorBrickValue);

        // if value is setted
        if (typeof indicatorBrickValue !== 'undefined') {
          if (typeof onEachIndicator === 'function') {
            brick = onEachIndicator(indicator, indicatorBrickValue, brick) || brick;
          }
        }
      });

      if (typeof onEachBrick === 'function') {
        brick = onEachBrick(brick) || brick;
      }

      return brick;
    });

    this.bricksApiService.bricks$.next(bricks);

    return bricks;
  }

  /**
   * Removes the indicator from indicatorList and change if required activeIndicator
   * @param indicator
   */
  removeIndicatorFromList(indicator: Indicator): void {
    const index = this.indicatorsList$.value.indexOf(indicator);
    let indicatorList = this.indicatorsList$.value;
    if (index >= 0) {
      indicatorList.splice(index, 1);

      // control if remvoed active indicator
      const indicators = this.indicatorsList$.value;
      const activeIndex = indicators.indexOf(this.activeIndicator$.value);
      if (activeIndex !== -1) {
        const lastIndiactor = indicators?.length ? indicators[indicators.length - 1] : undefined;
        this.activeIndicator$.next(lastIndiactor);
      }
    }

    this.indicatorsList$.next(indicatorList);
  }

  /**
   * Transform and store indicators, putting extra information and returns it.
   *
   * @param [transformIndicatorCallback(parsedIndicator, allIndicators)] - callback function to transform
   */
  getIndicators(transformIndicatorCallback: any = null) {
    const fullDataIndicators = this.indicatorsList$.value.map((indicator: any, index: number) => {
      const seriesSum = indicator.series?.reduce((previous, current) => previous + current, 0);
      const average = seriesSum / indicator.series.length;

      const name = this.sideNavIndicatorsService.indicatorsDictionary$.value
        .find((d: any) => d.key === indicator.key)?.indicador;

      let data = {
        ...indicator,
        ...{
          index,
          name,
          average,
          active: indicator.key == this.activeIndicator$.value.key,
          indKey: indicator.key,
          minValue: typeof indicator.minValue !== 'undefined' ? indicator.minValue : indicator.values.floor,
          maxValue: typeof indicator.maxValue !== 'undefined' ? indicator.maxValue : indicator.values.ceil
        }
      };

      if (typeof transformIndicatorCallback === 'function') {
        data = transformIndicatorCallback(data, this.indicatorsList$.value);
        if (typeof data === 'undefined') {
          throw new Error('parseCallback function needs to return indicator data');
        }
      }

      return data;
    });

    this.indicatorsList$.next(fullDataIndicators);
    const activeIndicator = fullDataIndicators.find(i => i.active);
    if (activeIndicator) {
      this.activeIndicator$.next(activeIndicator);
    }

    return fullDataIndicators;
  }

  /**
   * Recieving the full information of the categories, set the new categories in
   * brick.properties.data_portals2019 as new indicator.
   *
   * Also extends the indicatorsDictionary$ of SideNavIndicatorsService, to have
   * all the info to paint the fucking selects and list of list-indicator.component.
   *
   * @param fullDataCategories
   */
  getBricksWithPoiCategoriesData(fullDataCategories) {
    if (fullDataCategories && fullDataCategories.length) {
      const actualDictionary: DictionaryEntryInterface[] = this.sideNavIndicatorsService.indicatorsDictionary$.value;
      const poisDictionary: DictionaryEntryInterface[] = [];

      const bricks = this.getBricks(undefined,
        (bricks => {
          return bricks?.map(brick => {
            brick.properties.data_portals2019.pois = brick.properties.data_portals2019.pois ?? {};
            // set to 0 as default value all categories
            fullDataCategories.forEach(category => {
              brick.properties.data_portals2019.pois[category.key] = brick.properties.data_portals2019.pois[category.key] ?? 0;
            });

            if (brick.properties.num_point_poi_by_category) {
              // extract the bricks categories
              Object.entries(brick.properties.num_point_poi_by_category).forEach(entry => {
                const [key, val] = entry;
                brick.properties.data_portals2019.pois[key] = val ?? 0;

                // check if key wasn't added to dictionary
                if (!actualDictionary.find(p => p.key === key)) {
                  // get full data of category and insert it into dictionary
                  const categoryData = fullDataCategories.find((cat) => cat.key === key);
                  if (categoryData) {
                    const dictionaryData: DictionaryEntryInterface = {
                      active: true,
                      formato: 'Volumen',
                      categoria: 'Grupos/POI\'s',
                      categoria_key: 'pois',
                      subcategoria: 'Global Pois',
                      subcategoria_key: 'pois_categories',
                      grupo: 'pois',
                      key: key,
                      screen_name: categoryData.name,
                      indicador: categoryData.name
                    };

                    actualDictionary.push(dictionaryData);
                    poisDictionary.push(dictionaryData);
                  }
                }
              });
            }

            return brick;
          });
        })
      );

      this.sideNavIndicatorsService.parseGeoindicators(actualDictionary);

      return bricks;
    }
  }
}
