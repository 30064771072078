<div class="container" *ngIf="createStudyService.creating$ | async">
  <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</div>

<compass-form-name></compass-form-name>

<compass-form-buttons (activeFormEmitter)="getSelectedForm($event)">
</compass-form-buttons>

<compass-form-cp *ngIf="selectedForm === 'cp'"></compass-form-cp>
<compass-form-point *ngIf="selectedForm === 'point'"></compass-form-point>
<compass-form-area *ngIf="selectedForm === 'manual'"></compass-form-area>
