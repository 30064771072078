<section *ngIf="brickEvent && hour">
  <!--"-->
  <button mat-button class="primary margin__top--s margin__bottom--s margin__auto"
          (click)="toggleBasket()"
          [class.primary]="!this.inBasket"
          [class.secondary]="this.inBasket"
  >
    <ng-container *ngIf="!this.inBasket">
      <mat-icon>add</mat-icon>
      <span>añadir al plan</span>
    </ng-container>

    <ng-container *ngIf="this.inBasket">
      <mat-icon>remove</mat-icon>
      <span>eliminar del plan</span>
    </ng-container>
  </button>

  <p>Brick: {{brick_id}}</p>
  <p>{{brickEvent.list.categoria}} | {{brickEvent.list.indicador}} </p>

  <compass-grouped-vertical-barchart [hour]="hour" [chartLayout]="chartLayout"></compass-grouped-vertical-barchart>

  <table class="brick-table">
    <tr>
      <th>{{format}}</th>
      <th>Brick</th>
      <th>Área</th>
    </tr>
    <tr *ngFor="let row of tableData">

      <td>{{row.screen_name}}</td>
      <td *ngIf="row.format === 'Media' || row.format === 'Porcentaje'">
        {{row.brick | number: "1.2-2":"es-ES"}}
      </td>

      <td *ngIf="row.format === 'Media' || row.format === 'Porcentaje'">
        {{row.group | number: "1.2-2":"es-ES"}}
      </td>

      <td *ngIf="row.format === 'Euros' || row.format === 'Volumen' || row.format === 'Indice'">
        {{row.brick |number: "1.0-0":"es-ES"}}
      </td>

      <td *ngIf="row.format === 'Euros' || row.format === 'Volumen'  || row.format === 'Indice'">
        {{row.group | number: "1.0-0":"es-ES"}}
      </td>

      <td *ngIf="row.format === 'Fecha'">{{row.brick | date:'y'}}</td>
      <td *ngIf="row.format === 'Fecha'">{{row.group | date:'y'}}</td>
    </tr>

  </table>
</section>
