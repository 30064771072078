import {Component, Input, OnInit} from '@angular/core';
import HeatmapOverlay from 'leaflet-heatmap';
import * as L from 'leaflet';
import {IndicatorService} from '@compass/utils/navigation';
import {CompassMapWrapperService} from '@compass/utils/leaflet';
import {PoiService, SidenavPoisLevelsService} from '@compass/pois/data-access-poi';
import {PopularTimesReshapeService} from '../services/popular-times-reshape.service';

@Component({
  selector: 'compass-popular-times-timeline',
  templateUrl: './popular-times-timeline.component.html',
  styleUrls: ['./popular-times-timeline.component.scss']
})
export class PopularTimesTimelineComponent implements OnInit {
  @Input() form: any = {
    timeline: 0,
    days: null,
    day: 0,
    startHour: 0,
    endHour: 23,
    speed: 500,
  };
  //@Input() pois:any;
  @Input() heatMapData:any;

  heatmap_config: any = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    radius: 32,
    maxOpacity: 0.6,// 0.6,
    minOpacity: 0.3,//0.4,
    blur: 1,
    // scales the radius based on map zoom
    scaleRadius: false,
    // backhround color for whole heatmap layer
    //backgroundColor: '#13ae4778',
    // custom gradient colors
    gradient: {
      // enter n keys between 0 and 1 here
      // for gradient color customization
      '0.0': 'green',
      0.5: 'orange',
      0.8: 'red'
    },
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries
    //   (there will always be a red spot with useLocalExtremas true)
    useLocalExtrema: false,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'lng',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
  };

  choices_days = this.popularTimesReshapeService.choices_days;
  choices_hours = this.popularTimesReshapeService.choices_hours;
  /*choices_days = [[0, 'Sunday'], [1, 'Monday'], [2, 'Tuesday'], [3, 'Wednesday'], [4, 'Thursday'], [5, 'Friday'], [6, 'Saturday']];

  choices_hours = [
    [0, '6AM', 6],
    [1, '7AM', 7],
    [2, '8AM', 8],
    [3, '9AM', 9],
    [4, '10AM', 10],
    [5, '11AM', 11],
    [6, '12PM', 12],
    [7, '1PM', 13],
    [8, '2PM', 14],
    [9, '3PM', 15],
    [10, '4PM', 16],
    [11, '5PM', 17],
    [12, '6PM', 18],
    [13, '7PM', 19],
    [14, '8PM', 20],
    [15, '9PM', 21],
    [16, '10PM', 22],
    [17, '11PM', 23],
    [18, '12AM', 0],
    [19, '1AM', 1],
    [20, '2AM', 2],
    [21, '3AM', 3],
    [22, '4AM', 4],
    [23, '5AM', 5]
  ];*/

  heatmapLayer: any;
  poisData: any; // original pois
  player: any;

  map: any;

  selectedPois: any;
  venuemarkers: L.LayerGroup = new L.LayerGroup(); //new Array();

  //player
  currentFrame = 0;
  heatmapData3: any;

  heatmap: any;
  data: any;
  interval: any;
  animationSpeed: number;
  wrapperEl: any;
  playButton: any;
  isPlaying: boolean;
  timeWindow: any;

  openSidenav = (poi: any) => {
    console.log(poi);
  }


  constructor(
    private indicatorService: IndicatorService,
    private compassMapWrapperService: CompassMapWrapperService,
    private sidenavPoisLevelService: SidenavPoisLevelsService,
    private poiService: PoiService,
    private popularTimesReshapeService: PopularTimesReshapeService,

  ) {

    this.compassMapWrapperService.getMap().subscribe(map => {
      this.map = map;
    })

    //this.drawCircles()
  }



  ngOnInit(): void {
    //this.drawCircles()
    this.drawHeatMap();
  }

  ngOnChanges() {
    this.stop();
    //this.drawCircles()
    this.drawHeatMap()


  }

  /*drawCircles() {
    let circles;
    this.pois.map((poi: any) => {
      circles = L.layerGroup([L.circleMarker([poi.geometry.coordinates[1], poi.geometry.coordinates[0]], {
        radius: 10,
        color: '#bf0000',
        fillOpacity: 0,
        opacity: 0
      }).on('click', () => {

        this.poiService.selectedPoi$.next({
          actualMarker: {
            info: poi
          }
        })
      })])
        .addLayer(this.venuemarkers).addTo(this.map.box);

    })

  }*/

  getAllDaysDataByHours(data: any) {
    const temp: Array<Array<Array<any>>> = []; // day,hour, pois

    this.choices_days.forEach((day: any, day_index: number) => {
      temp.push([]); // one day
      this.choices_hours.forEach((hour: any, hour_index) => {
        temp[day_index].push([]); // 24 hours

        data.forEach((d: any) => {

          temp[day_index][hour_index].push({
            lat: d.lat,
            lng: d.lng,
            count: this.isPrimitive(d.days[day_index]) ? 0 : d.days[day_index][hour[2]] ? d.days[day_index][hour[2]] : 0
          });
        });
      });
    });
    return temp;
  }

  /*filterPois(){
    this.selectedPois = this.pois.filter(poi=> Math.round(poi.properties.rating) == this.form.controls.rating.value )

  }*/

  isPrimitive(o: any) {
    return typeof o !== 'object' || null
  };

  sumWeekDaysData(data: any) {
    const result: any = []

    data.forEach((day: [][], day_index: number) => {
      day.forEach((hour: [], hour_index: number) => {
        if (day_index === 0) {
          result.push([]);
        }
        hour.forEach((poi: any, poi_index: number) => {
          if (day_index === 0) {
            result[hour_index].push(
              {
                lat: data[day_index][hour_index][poi_index].lat,
                lng: data[day_index][hour_index][poi_index].lng,
                count: data[day_index][hour_index][poi_index].count
              }
            )
          } else {
            result[hour_index][poi_index].count += data[day_index][hour_index][poi_index].count
          }
        });
      });
    })

    return result;
  }

  mediaWeekDaysData(hours: [][]) {
    return hours.map((hour: any[]) => {
      hour.map((poi) => {
        poi.count = Math.round(poi.count / 7);
        return poi;
      })
      return hour;
    });
  }

  reshapeData(data: any) {

    // return only lat, lng and days
    const googleDays = data.map((d: any) => {
      return {
        lat: d.geometry.coordinates[1],
        lng: d.geometry.coordinates[0],
        days: d.properties.goo.popular_time.days
      }

    });
    // return [ DAY [ HOURS [ POIS ] ] ]
    // DAYS start at 0 is SUNDAY
    // [ HOURS [ POIS ] ] is used to animate map
    // HOURS start at 0 is 6:00am.
    const daysData = this.getAllDaysDataByHours(googleDays);
    const weekData = this.sumWeekDaysData(daysData);
    const weekMediaData = this.mediaWeekDaysData(weekData);
    daysData.push(weekMediaData); // 7 index is the sum of all week day and divided by 7
    return daysData; // all 7 days week [0 Sunday - 6 Saturday] data by hours [0-23] and pois [lat, lgn, count]

  }

  /*drawHeatMap(setView = true) {
    // data: Array<LatLngCount>
    // Data points defined as an array of LatLng objects
    // https://developers.google.com/maps/documentation/javascript/heatmaplayer?hl=nl

    const sevenDaysData = this.reshapeData(this.pois); // 7 days, 24 hours data

    const heatmapData2 = sevenDaysData.map(day => {
      return day.slice(+this.form.startHour, +this.form.endHour + 1)
    })//this.sliceHoursfromData(timeWindow, sevenDaysData);
    //let heatmapData: { max: number; min:number; data: Array<any> };
    this.data = heatmapData2;
    const dayIndex = +this.form.day;

    if (sevenDaysData) {

      // Set map position and zoom
      if (setView == true) {
        // set view
        // to be done
      }

      // Clear existing (invisble) markers with tooltips
      if (this.map.box.hasLayer(this.venuemarkers)) {
        this.venuemarkers.clearLayers();
      }
      // Clear existing heatmaps
      if (this.map.box.hasLayer(this.heatmapLayer)) {
        this.map.box.removeLayer(this.heatmapLayer);
      }

      const heatmapData = {
        max: 100,
        data: heatmapData2[dayIndex][0]
      };

      this.heatmapLayer = new HeatmapOverlay(this.heatmap_config);

      this.heatmapLayer.setData(heatmapData);

      this.heatmapLayer.addTo(this.map.box);

      // Add leaflet markers (if needeed)
      //this.addVenueMarkers(this.pois);
      //this.addVenueMarkers(this.pois);
      // Update map location and zoom in url params on map drag and zoom

      // animate
      this.init();

    }

  }*/

  drawHeatMap(setView = true) {
    // data: Array<LatLngCount>
    // Data points defined as an array of LatLng objects
    // https://developers.google.com/maps/documentation/javascript/heatmaplayer?hl=nl


    this.data = this.heatMapData;


    if (this.data) {

      // Set map position and zoom
      if (setView == true) {
        // set view
        // to be done
      }

      // Clear existing (invisble) markers with tooltips
      /*
      if (this.map.box.hasLayer(this.venuemarkers)) {
        this.venuemarkers.clearLayers();
      }
      */
      // Clear existing heatmaps
      if (this.heatmapLayer && this.map.box && this.map.box.hasLayer(this.heatmapLayer)) {
        this.map.box.removeLayer(this.heatmapLayer);
      }

      const heatmapData = {
        max: 100,
        data: this.heatMapData[0]
      };

      this.heatmapLayer = new HeatmapOverlay(this.heatmap_config);

      this.heatmapLayer.setData(heatmapData);

      this.heatmapLayer.addTo(this.map.box);

      // Add leaflet markers (if needeed)
      //this.addVenueMarkers(this.pois);
      // Update map location and zoom in url params on map drag and zoom

      // animate
      this.init();
    }
  }

  init() {
    const dataLen = this.data.length;
    let frame;
    this.wrapperEl = document.querySelector('.timeline-wrapper');

    this.wrapperEl.innerHTML = '';

    this.playButton = this.playButton ? this.playButton : document.createElement('button');

    this.playButton.onclick = () => {
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
      this.isPlaying = !this.isPlaying;
    };
    this.playButton.innerText = 'play';

    this.wrapperEl.appendChild(this.playButton);

    const events = document.createElement('div');
    events.className = 'heatmap-timeline';
    events.innerHTML = '<div class="line"></div>';


    for (let i = 0; i < this.data.length; i++) {
      // Generate timeline points
      const xOffset = 100 / (dataLen - 1) * i;
      const ev = document.createElement('div');

      ev.className = 'time-point';
      ev.style.left = xOffset + '%';
      ev.onclick = (function (i: number) {
        return function () {
          this.isPlaying = false;
          this.stop();
          this.setFrame(i);
        }.bind(this);
      }.bind(this))(i);

      events.appendChild(ev);

    }

    this.wrapperEl.appendChild(events);

    // HOURS
    if (+this.form.timeline === 0) {
      // check if time_local_index is in user set hour_min/ max range.
      if (0 >= +this.form.startHour &&
        0 <= +this.form.endHour) {
        // local venue time is within hour_min/max range so setting the animation frame to venue current local hour.
        // The local hour index needs to be subtracted with the time window start index, since the local hour index is based
        // on an array from 0 to 23.
        frame = 0 - +this.form.startHour;
      } else {
        // local vanue time is outside range, so setting the frame to the hour_min value
        // The local hour index needs to be subtracted with the time window start index, since the local hour index is based
        // on an array from 0 to 23.

        frame = +this.form.startHour - +this.form.startHour;
      }
    }
    // DAYS
    if (+this.form.timeline === 1) {
      frame = 0;
    }

    this.setFrame(frame);
  };

  public play() {
    // Only play when Live/ Now mode not enabled
    const dataLen = this.data.length;

    this.playButton.innerText = 'pause';
    this.interval = setInterval(() => {
      this.setFrame(++this.currentFrame % dataLen);
    }, +this.form.speed);

  };

  public stop() {
    clearInterval(this.interval);
    if (this.playButton) this.playButton.innerText = 'play';
  };

  /*public setFrame(frame: number) {
    this.currentFrame = frame;
    const snapshot = this.data[this.form.day][frame];

    this.heatmapData3 = {
      max: 100,
      data: snapshot
    };
    //Set new data
    this.heatmapLayer.setData(this.heatmapData3);

    const timePoints = document.querySelectorAll('.heatmap-timeline .time-point');
    //remove all active points
    timePoints.forEach(point => {
      point.classList.remove('active');
      point.innerHTML = '';
    })

    // Hours
    if (+this.form.timeline === 0) {

      //Text of the first point
      timePoints[0].innerHTML =
        `<div style="margin-top:4px; color:#a7a7a7;font-size:11px">
            ${this.choices_hours[+this.form.startHour][1]}
      </div>`;

      //Text of the last point
      timePoints[this.data.length - 1].innerHTML =
        `<div style="margin-top:4px;color:#a7a7a7;font-size:11px">
            ${this.choices_hours[+this.form.endHour][1]}
      </div>`;

      timePoints[frame].classList.add('active');
      //Text of the pointer
      timePoints[frame].innerHTML =
        `<div style="margin-top:4px; color:#7367F0;font-size:11px">
            ${this.choices_hours[frame + +this.form.startHour][1]}
      </div>`;

    }else
    // days
    if (+this.form.timeline === 1) {
      //Text of the first point
      timePoints[0].innerHTML =
        `<div style="margin-top:4px; color:#a7a7a7;font-size:11px">
            ${this.choices_days[+this.form.days[0]][2]}
      </div>`;

      //Text of the last point
      timePoints[this.data.length - 1].innerHTML =
        `<div style="margin-top:4px;color:#a7a7a7;font-size:11px">
              ${this.choices_days[+this.form.days[this.form.days.length - 1]][2]}
      </div>`;

      timePoints[frame].classList.add('active');
      //Text of the pointer
      timePoints[frame].innerHTML =
        `<div style="margin-top:4px; color:#7367F0;font-size:11px">
            ${this.choices_days[this.setHtmlDayFrame(+this.form.days[0], frame)][2]}
      </div>`;

    }
  };*/

  // magic fuction
  public setFrame(frame: number) {
    this.currentFrame = frame;
    const snapshot = this.data[frame];

    this.heatmapData3 = {
      max: 100,
      data: snapshot
    };
    //Set new data
    this.heatmapLayer.setData(this.heatmapData3);

    const timePoints = document.querySelectorAll('.heatmap-timeline .time-point');
    //remove all active points
    timePoints.forEach(point => {
      point.classList.remove('active');
      point.innerHTML = '';
    })

    // Hours
    if (+this.form.timeline === 0) {

      //Text of the first point
      timePoints[0].innerHTML =
        `<div style="margin-top:4px; color:#a7a7a7;font-size:11px">
            ${this.choices_hours[+this.form.startHour][1]}
      </div>`;

      //Text of the last point
      timePoints[this.data.length - 1].innerHTML =
        `<div style="margin-top:4px;color:#a7a7a7;font-size:11px">
            ${this.choices_hours[+this.form.endHour][1]}
      </div>`;

      timePoints[frame].classList.add('active');
      //Text of the pointer
      timePoints[frame].innerHTML =
        `<div style="margin-top:4px; color:#7367F0;font-size:11px">
            ${this.choices_hours[frame + +this.form.startHour][1]}
      </div>`;

    }
    // days
    if (+this.form.timeline === 1) {
      //Text of the first point
      timePoints[0].innerHTML =
        `<div style="margin-top:4px; color:#a7a7a7;font-size:11px">
            ${this.choices_days[+this.form.days[0]][2]}
      </div>`;

      //Text of the last point
      timePoints[this.data.length - 1].innerHTML =
        `<div style="margin-top:4px;color:#a7a7a7;font-size:11px">
              ${this.choices_days[+this.form.days[this.form.days.length - 1]][2]}
      </div>`;

      timePoints[frame].classList.add('active');
      //Text of the pointer
      timePoints[frame].innerHTML =
        `<div style="margin-top:4px; color:#7367F0;font-size:11px">
            ${this.choices_days[this.setHtmlDayFrame(+this.form.days[0], frame)][2]}
      </div>`;

    }

  };

  // Because Sunday is 0 value on the days array and frame is on  position 6
  // When choice_days position is higher than 6 (7), we turn back position to 0 (Sunday).
  setHtmlDayFrame(day: number, frame: number): number {
    if ((day + frame) > 6) {
      return 0;
    }
    return day + frame;
  }

  public setAnimationData(data: any) {
    this.isPlaying = false;
    this.stop();
    this.data[this.form.day] = data;
    this.init();
  };

  public setAnimationSpeed(speed: number) {
    this.isPlaying = false;
    this.stop();
    this.animationSpeed = speed;
  }

  ngOnDestroy() {
    if (this.map.box.hasLayer(this.heatmapLayer)) {
      this.map.box.removeLayer(this.heatmapLayer)
    }
    this.stop();
  }

}
