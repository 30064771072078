import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
//import { StudyService } from '@compass/studies';
import { StudyService } from '@compass/studies/data-access-study';
import { SideNavIndicatorsService } from '@compass/utils/navigation';

@Component({
  selector: 'compass-business-indicators',
  templateUrl: './business-indicators.component.html',
  styleUrls: ['./business-indicators.component.scss']
})
export class BusinessIndicatorsComponent {
  public bricks;
  indicatorsGroups$ = this.sideNavIndicatorsService.indicatorsGroups$;
  bricks$ = this.bricksApiService.bricks$;

  constructor(
    private bricksApiService: BricksApiService,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private studyService : StudyService
  ) {
    if(this.studyService.study$.value) {
      this.bricksApiService.getFullStagesData(this.studyService.study$.value).subscribe((d) => {
        this.bricks = d;
      });
    }

    this.sideNavIndicatorsService.getGeoIndicatorsApi();
  }

}

