<article class="max--width">
  <section class="section">
    <div
      class="margin__top--l container"
      [fxLayout]="layout_direction"
      fxLayout.lt-md="column"
    >
      <div
        fxFlex="50"
        [fxLayout]="layout_direction"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
      >
        <compass-crud-icons [icon_class]="icon_class"></compass-crud-icons>
        <h1 class="h1-page--title">
          {{ title }}
        </h1>
      </div>
      <div fxFlex="50" fxLayoutAlign="end center">
        <ng-content></ng-content>
      </div>
    </div>
  </section>
</article>
