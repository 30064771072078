import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export interface AimcDb {
  key: string;
  geo_level: string;
  id: string;
  estudio: string;
  tipologia: string;
  medio: string;
  geo_level_id: string;
  geo: string;
  target: string;
  valor: number;
  class_: string;
  source: string;
}

@Injectable()
export class AimcService {
  private aimcUrl = '/abacusgis/core/glocally/collection/data_aimc/';
  public aimcCountry$ = new BehaviorSubject(undefined);
  public aimcCcaa$ = new BehaviorSubject(undefined);
  private prevCcaa: string;

  public surveyGeo$ = new BehaviorSubject(undefined);
  public surveyType$ = new BehaviorSubject<any>(undefined);

  constructor(private http: HttpClient) {}

  getCountry() {
    return this.http
      .get(`${this.aimcUrl}?query={"key":"data_aimc_area_country_ES"}`)
      .subscribe((aimc: AimcDb[]) => {
        this.aimcCountry$.next(aimc);
      });
  }

  getCcaa(ccaa: string) {
    if (this.prevCcaa !== ccaa) {
      this.aimcCcaa$.next(undefined);
      return this.http
        .get(`${this.aimcUrl}?query={"key":"data_aimc_area_ccaa_${ccaa}"}`)
        .subscribe((aimc: AimcDb[]) => {
          this.aimcCcaa$.next(aimc);
          this.prevCcaa = ccaa;
        });
    }
  }

  setSurveyGeo(type) {
    this.surveyGeo$.next(type);
  }

  setSurveyType(type) {
    this.surveyType$.next(type);
  }
}
