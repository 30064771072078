<form [formGroup]="this.form">
  <mat-form-field appearance="outline" class="autocomplete-wrapper">
    <mat-label>Buscar en {{ this.options.length }} {{ this.objectName }}</mat-label>
    <input #searcher matInput type="text"
           [placeholder]="'Introduce el nombre de ' + this.objectName"
           [matAutocomplete]="auto"
           formControlName="search"
    >

    <button
      matSuffix mat-icon-button
      *ngIf="form.get('search').value?.length"
      (click)="clearSearcher($event)"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption()">
      <mat-option [value]="null" title="SELECCIONAR TODO">- TODO -</mat-option>
      <mat-option *ngFor="let option of this.filteredOptions"
                  [value]="option"
                  [matTooltip]="option.toString()"
                  matTooltipPosition="right"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <div class="results-wrapper">
    <button mat-stroked-button color="warn" *ngIf="this.selectedOptions.length > 1"
            (click)="this.removeAllOptions()"
            class="remove-all-btn">
      <mat-icon>clear</mat-icon>
      Quitar todas las opciones
    </button>

    <mat-checkbox *ngIf="!this.selectedOptions.length" [checked]="true" [disabled]="true">
      - TODO -
    </mat-checkbox>
    <mat-checkbox
      *ngFor="let option of this.selectedOptions; let i = index"
      [checked]=true
      [value]="option"
      (change)="this.removeOption(i)"
      [matTooltip]="option"
      matTooltipPosition="right"
    >
      {{ option }}
    </mat-checkbox>
  </div>
</form>
