import { Component, OnInit } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-places-sidenav',
  templateUrl: './places-sidenav.component.html',
  styleUrls: ['./places-sidenav.component.css'],
})
export class PlacesSidenavComponent implements OnInit {
  activeTableRoot: string = 'pois';

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    this.sidenavPoisLevelsService.getActiveTableRoot().subscribe((value) => {
      this.activeTableRoot = value;
    });
  }

  ngOnInit(): void {}
}
