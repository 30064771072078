import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LocalKnowledgeApiService } from './local-knowledge-api.service';
import { ActivatedRoute } from '@angular/router';
import { bbox, centroid, distance } from '@turf/turf';
import { StudyService } from '@compass/studies/data-access-study';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, collapseAnimation } from 'angular-calendar';
import { Subscription } from 'rxjs';
import { CompassMapWrapperService } from '@compass/utils/leaflet';
import { EndRightSidenavService } from '@compass/page-navigation/end-right-sidenav';
import { OnclickStageShapeService } from '@compass/utils/navigation';
import { LocalKnowledgeReshapeService } from '../local-knowledge-reshape.service';
import * as L from 'leaflet';
import { LatLng, LayerGroup } from 'leaflet';
import {
  BottomContainerService
} from '../../../../../apps/glocally/src/app/shared/bottom-container/bottom-container.service';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';
import { PoisComparatorService } from '@compass/pois/comparator';
import { state, style, trigger } from '@angular/animations';


@Component({
  selector: 'compass-local-knowledge-loader',
  templateUrl: './local-knowledge-loader.component.html',
  styleUrls: ['./local-knowledge-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [collapseAnimation,
    trigger('localKnowledgeWrapper', [
      state('true', style({
        opacity: 0.5
      })),
      state('false', style({
        opacity: 1
      }))
    ])]
})
export class LocalKnowledgeLoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  localKnowledge$ = this.localKnowledgeApiService.localKnowledge$;
  municipalityID: any = 1;
  municipalityData: any;

  refreshTimeout: any;

  map: any;

  study: any;
  categories = [];

  view: any = 'month';
  viewDate: Date = this.localKnowledgeApiService.activeDate$.value ?? new Date();

  @ViewChild('allSelected') private allSelected: MatOption;

  categoryFilterSelect: FormControl = new FormControl(this.localKnowledgeApiService.localKnowledge$.value);

  loadingFilters: boolean = false;
  dayDetailsOpened: boolean = false;

  localKnowledgeSub: Subscription;

  eventsLayer: any = new LayerGroup();
  information: any = [];

  private routeSubcription: Subscription;
  regularDistribution;


  constructor(
    public localKnowledgeApiService: LocalKnowledgeApiService,
    private compassMapWrapperService: CompassMapWrapperService,
    private endRightSidenavService: EndRightSidenavService,
    private onclickStageShapeService: OnclickStageShapeService,
    private localKnowledgeService: LocalKnowledgeReshapeService,
    private route: ActivatedRoute,
    private studyService: StudyService,
    public bottomContainerService: BottomContainerService,
    private startLeftSidenavService: StartLeftSidenavService,
    private poisComparatorService: PoisComparatorService
  ) {

    this.categories = localKnowledgeApiService.categories;
    this.compassMapWrapperService.getMap().subscribe(map => {
      this.map = map;
    });

    if (!this.bottomContainerService.visible$.value) {
      this.regularDistribution = '100%';
    } else {
      this.regularDistribution = 100 / 3 + '%';
    }

  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let coordinates;
    this.loadingFilters = true;
    this.localKnowledgeApiService.selectedCategories$.subscribe((s) => {
      this.categoryFilterSelect.setValue(s);
    });

    this.routeSubcription = this.route.params.subscribe((params) => {
      if (params.studyID) {
        this.studyService
          .getInfoStudy(Number(params.studyID))
          .subscribe(async (study) => {
            this.study = study;
            this.localKnowledgeApiService.study = this.study;
            this.municipalityID = study.properties.area_municipality_id;
            this.municipalityData = await this.localKnowledgeApiService.getMunicipallities(this.municipalityID).toPromise();

            //this.localKnowledge$.next([])
            this.loadHolidays();
            //this.localKnowledgeApiService.getLocalKnowledgeData(this.municipalityID ?? 1, [...this.categories.map(item=>item.key)], this.study);

            this.localKnowledge$.subscribe((data) => {
              setTimeout(() => {
                this.information = [];
                if (this.categoryFilterSelect.value.length) {
                  if (this.map.box.hasLayer(this.localKnowledgeApiService.eventsLayer$.value)) {
                    this.map.box.removeLayer(this.localKnowledgeApiService.eventsLayer$.value);
                  }
                  this.eventsLayer.clearLayers();
                  data.forEach((data) => {
                    if (data.start && this.viewDate?.getMonth() == data.start?.getMonth()) {
                      this.information.push(data);
                    }

                    let popupContent = `
                      <strong style='display: block;'>${data['descripcion']}</strong>
                      <i style='color: dimgrey; display: block;'>${new Date(data.fecha).toLocaleDateString()}</i>
                    `;

                    if (data.phq_attendance) {
                      popupContent += `<strong style='color: ${data.colorRange}' title='Predicción de asistentes'>${data.phq_attendance} asistentes</strong>`;
                    }

                      if (data.geo?.geometry && data.geo?.geometry?.coordinates?.length<=2) {

                        if (data.geo.geometry.coordinates[1] == undefined && typeof data.geo.geometry.coordinates[0] == 'object') {

                          const polygon = L.polygon(data.geo.geometry.coordinates[0], {
                            //radius: 20 * (data.rank / 100) + 5,
                            fillColor: '#ff0000' ?? data.colorRange ?? null,
                            color: 'red' ?? data.colorRange ?? null,
                            weight: 1,
                            className: `event-circle event-${data.id}`
                          });
                          coordinates = polygon.getBounds().getCenter();
                          coordinates = new LatLng(coordinates.lng, coordinates.lat);

                        } else {
                          coordinates = new LatLng(data.geo.geometry.coordinates[1], data.geo.geometry.coordinates[0]);
                        }
                        const marker = L.circleMarker(coordinates, {
                          radius: 20 * (data.rank / 100) + 5,
                          fillColor: data.colorRange ?? null,
                          color: data.colorRange ?? null,
                          weight: 1,
                          className: `event-circle event-${data.id}`
                        }).bindPopup(popupContent)
                          .addTo(this.eventsLayer)
                          .on('mouseover', () => {
                            marker.openPopup();
                            this.toggleActive(data, true);
                          })
                          .on('mouseout', () => {
                            marker.closePopup();
                            this.toggleActive(undefined, false);
                          })
                          .on('click', (e) => {
                            this.showDetails(data);
                          });
                      }


                  });


                  if (this.map && !this.map.box.hasLayer(this.localKnowledgeApiService.eventsLayer$.value)) {
                    this.localKnowledgeApiService.eventsLayer$.next(this.eventsLayer);
                    this.localKnowledgeApiService.eventsLayer$.value.addTo(this.map?.box);
                  }
                }

                this.loadingFilters = false;
              });

            });


          });
      }
    });
  }


  ngOnDestroy(): void {
    // this.localKnowledgeApiService.activeDate$.next(new Date())
    if (this.map.box.hasLayer(this.eventsLayer)) {
      this.map.box.removeLayer(this.eventsLayer);
    }
    // this.localKnowledge$.next([])
    this.routeSubcription.unsubscribe();
  }

  togglePerOne() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();

    } else if (this.categoryFilterSelect.value.length == this.categories.length + 1)
      this.allSelected.select();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.categoryFilterSelect
        .patchValue([...this.categories.map(item => item.key), 0]);
    } else {
      this.categoryFilterSelect.patchValue([]);
    }
  }

  refreshData() {
    clearTimeout(this.refreshTimeout);
    this.localKnowledgeSub?.unsubscribe();

    this.refreshTimeout = setTimeout(() => {
      // this.localKnowledgeApiService.getLocalKnowledgeData(this.municipalityID ?? 1, this.categoryFilterSelect.value, this.study)
      this.loadHolidays();
    }, 1000);

  }

  /* NEW CALENDAR */
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.dayDetailsOpened = !(isSameDay(this.viewDate, date) && this.dayDetailsOpened === true || events.length === 0);
      this.viewDate = date;
    }
  }


  showDetails(data) {
    if (data?.categoria === 'predicthq') {
      /*this.endRightSidenavService.setSidenavStatus(true);
      this.endRightSidenavService.setTitle('Detalles de evento');
      this.onclickStageShapeService.shapeClicked$.next('event');*/
      this.localKnowledgeService.set(data);

      /*const closeSub = this.endRightSidenavService.endRightSidenavOn$.subscribe(res => {
        if (!res) {
          closeSub?.unsubscribe();
          this.endRightSidenavService.setTitle(null);
          this.localKnowledgeService.set(null);
          this.toggleActive(undefined, false);
        } else {
          setTimeout(() => this.toggleActive(data, true), 800);
        }
      });*/
    }
  }


  toggleActive(data: any, active: boolean) {
    if (!active) {
      for (var elements = document.getElementsByClassName('event-circle active'), i = 0, l = elements.length; l > i; i++) {
        elements[0].classList.remove('active');
      }
    } else {
      for (var elements = document.getElementsByClassName(`event-circle event-${data.id}`), i = 0, l = elements.length; l > i; i++) {
        elements[0].classList.add('active');
      }
    }
  }

  setView(view: any) {
    this.view = view;
  }

  reloadActiveDate() {
    // this.loadingFilters = true;

    this.localKnowledgeApiService.activeDate$.next(this.viewDate);
    this.refreshData();
    this.dayDetailsOpened = false;
  }

  sortInformation(e: any) {
    if (e == 'attendance') {
      this.information.sort((a, b) => {
        return a.phq_attendance - b.phq_attendance;
      }).reverse();
    } else if (e == 'date') {
      this.information.sort((a, b) => {
        return a.start.getTime() - b.start.getTime();
      });
    } else if (e == 'location') {
      this.information.sort((a, b) => {
        if (a.categoria == 'predicthq' && b.categoria == 'predicthq') {
          return distance([a.location[1], a.location[0]], [this.study.geometry.coordinates[1], this.study.geometry.coordinates[0]]) -
            distance([b.location[1], b.location[0]], [this.study.geometry.coordinates[1], this.study.geometry.coordinates[0]]);
        } else {
          return 99999999999999999;
        }

      });
    }
  }

  loadHolidays() {
    // this.localKnowledgeApiService.getLocalKnowledgeData(this.municipalityID,this.categoryFilterSelect.value, this.study)
    if (this.categoryFilterSelect.value?.length) {
      this.localKnowledge$.next([]);
      this.loadingFilters = true;

      this.localKnowledgeApiService.getHolidays(this.municipalityID, this.categoryFilterSelect.value)
        .subscribe((data: any[]) => {
          this.localKnowledge$.next(data);
          this.loadEvents();
          this.loadingFilters = false;
        }, (error) => {
          this.loadingFilters = false;
          this.loadEvents();
          console.error(error);
        });

    } else {
      this.localKnowledge$.next([]);
    }

  }

  async loadEvents() {
    if (this.categoryFilterSelect.value.length && (this.study != undefined || this.municipalityData != undefined)) {
      this.loadingFilters = true;

      // extract center and radius from municipality or study
      const center = centroid(this.municipalityData?.geometry ?? this.study?.geometry);
      const studyAreas: any = Object.values(this.study?.properties?.areas);
      const square = bbox(this.municipalityData?.geometry ?? studyAreas?.[0]?.geometry);
      const radius = distance([square[0], square[1]], [square[2], square[3]]);

      const events = await this.localKnowledgeApiService.events(null, this.categoryFilterSelect.value, center, radius);

      this.localKnowledge$.next([...this.localKnowledge$.value, ...events]);

      this.loadingFilters = false;
    }
  }

  flyto(info: any) {
    if (info.location?.[1]) {
      this.map.box.flyTo([info.location[1], info.location[0]]);
    }
  }

  expandCalendar() {
    this.poisComparatorService.selected$.next([]);
    if (!this.bottomContainerService.visible$.value) {
      this.startLeftSidenavService.close();
      this.bottomContainerService.visible$.next(true);
    } else {
      this.startLeftSidenavService.open();
      this.bottomContainerService.visible$.next(false);
      this.localKnowledgeApiService.eventsLayer$.value.addTo(this.map.box);
    }

    // this.bottomContainerService.relativePos$.next(true)
    // this.startLeftSidenavService.setSidenavIsOpen(true);
    // this.poisComparatorComponent.compareMode = false;

  }
}






