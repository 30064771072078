import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PoiInterface } from '@compass/pois/data-access-poi';
import { PopularTimeDataService } from '@compass/pois/extended-data-preview';
import { MunicipalityService, PopularTimesService } from '@compass/api-services';
import { PoiBasketService } from '@compass/shared/poi-basket';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-busiest-hours-comparator',
  templateUrl: './busiest-hours-comparator.component.html',
  styleUrls: ['./busiest-hours-comparator.component.scss']
})
export class BusiestHoursComparatorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() pois: PoiInterface[] = [];

  mainPoi?: PoiInterface;
  selected?: PoiInterface;
  otherPois: PoiInterface[] = [];
  comparation: any = null;
  comparationColors: string[] = ['#fff6f3', '#feebe2', '#fcc5c0', '#fa9fb5', '#f768a1', '#c51b8a', '#7a0177'];

  public ensenyaMode: boolean = false;
  public loading: boolean = false;

  public basketSub: Subscription;

  constructor(
    private popularTimeService: PopularTimesService,
    private popularTimeDataService: PopularTimeDataService,
    private municipalityService: MunicipalityService,
    private basketService: PoiBasketService
  ) {
    this.basketSub = basketService.hours$.subscribe(() => {
      this.parseData();
    });
  }

  ngOnDestroy() {
    this.basketSub?.unsubscribe();
  }

  ngOnInit() {
    this.parseData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pois) {
      this.parseData();
    }
  }


  public generateComparation() {
    this.comparation = JSON.parse(JSON.stringify(this.mainPoi));

    if (this.mainPoi.properties?.goo?.popular_time?.days) {
      // foreach day
      Object.keys(this.mainPoi.properties.goo.popular_time.days).forEach(day => {
        // foreach hour
        Object.keys(this.mainPoi.properties.goo.popular_time.days[day]).forEach(hour => {
          let value = 0;
          const mainVal = this.mainPoi.properties.goo?.popular_time?.days?.[day]?.[hour] ?? 0;

          // si hay valor para comparar
          if (mainVal > 0) {
            const selectedVal = this.selected?.properties.goo?.popular_time?.days?.[day]?.[hour] ?? 0;
            const calculatedValue = mainVal - selectedVal;
            if (calculatedValue < 0) {
              value = Math.abs(calculatedValue);
            }
          }

          if(this.comparation.properties.goo.popular_time.days[day] == 'closed') {
            this.comparation.properties.goo.popular_time.days[day] = {};
          }

          this.comparation.properties.goo.popular_time.days[day][hour] = value;
        });
      });
    }

    this.comparation.properties.popularTimeMatrix = this.popularTimeDataService.getPoiPopularTimes(this.comparation, this.basketService.getHours());
  }

  public async parseData() {
    this.loading = true;

    // when ensenya mode active
    if (this.ensenyaMode) {
      // foreach poi search ensenya
      for (const poi of this.pois) {
        // if not calculated ensenya_popular_time yet
        if (!poi.properties.ensenya_popuar_time) {
          const municipality = (await this.municipalityService.getAll({
            query: {
              geometry: { $geoIntersects: { $geometry: poi.geometry } },
              'properties.id': { $exists: true }
            }
          }).toPromise())?.[0];
          const ensenyaPopularTime = (await this.popularTimeService.get(municipality.properties.id, poi.properties.id_ensenya).toPromise())?.[poi.properties.id_ensenya]?.popular_time;

          poi.properties.ensenya_popuar_time = ensenyaPopularTime ?? {};
          poi.properties.ensenya_NMUN = municipality?.properties?.NMUN ?? null;
        }
      }
    }

    // clone pois for use
    let parsed = JSON.parse(JSON.stringify(this.pois));

    parsed = parsed.map(p => {
      if (this.ensenyaMode) {
        // override goo.popilar_time with ensenya
        p.properties.goo = p.properties.goo ?? {};
        p.properties.goo.popular_time = p.properties.ensenya_popuar_time;

        if (p.properties.ensenya_NMUN) {
          p.properties.nombre = p.properties.ensenya_desc ?? p.properties.nombre;
          p.properties.direccion = 'Municipio de ' + p.properties.ensenya_NMUN.toUpperCase();
        }
      }

      p.properties.has_popular_time = !!p.properties.goo?.popular_time?.days;
      p.properties.popularTimeMatrix = this.popularTimeDataService.getPoiPopularTimes(p, this.basketService.hours$.value);
      return p;
    });


    this.mainPoi = parsed.shift();
    this.otherPois = parsed;
    // set previous or first
    const previousSelected = parsed.find(p => p.properties.id === this.selected?.properties?.id);
    this.selected = previousSelected?.properties.id ? previousSelected : this.otherPois[0];

    if(this.mainPoi) {
      this.generateComparation();
    }

    // false loading effect
    setTimeout(() => {
      this.loading = false;
    }, 400);
  }

  hourSelected(data: any) {
    const vsTitle = this.mainPoi.properties.nombre + ' vs ' + this.selected.properties.nombre;
    const hourTitle = this.popularTimeDataService.parsePopularTimeValue(data.data);

    this.basketService.toggleHour(hourTitle, vsTitle);

    this.parseData();
  }
}
