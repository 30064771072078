import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glo-stage-local-media',
  templateUrl: './stage-local-media.component.html',
  styleUrls: ['./stage-local-media.component.scss']
})
export class StageLocalMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
