import { Component } from '@angular/core';
import { SideNavStagesService } from '@compass/utils/navigation';

@Component({
  selector: 'glo-stage-viewer',
  templateUrl: './stage-viewer.component.html',
  styleUrls: ['./stage-viewer.component.css'],
})
export class StageViewerComponent {
  activeStage$ = this.sideNavStagesService.activeStage$;

  constructor(private sideNavStagesService: SideNavStagesService) {}
}
