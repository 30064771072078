import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PoiInterface } from '@compass/pois/data-access-poi';

@Component({
  selector: 'compass-popular-time-metrics',
  templateUrl: './popular-time-metrics.component.html',
  styleUrls: ['./popular-time-metrics.component.scss']
})
export class PopularTimeMetricsComponent implements OnChanges {
  @Input() pois: PoiInterface[] = [];

  displayedColumns: string[] = ['title', 'rating', 'new_reviews', 'sector'];
  dataSource = [];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pois) {
      this.dataSource = this.pois;
    }
  }

  getStars(poi: any) {
    return this.getRatingStars(poi.properties?.goo?.rating ?? 0);
  }

  getRatingStars(n) {
    let response;

    switch (Math.round(n)) {
      case 0:
        response = '';
        break;
      case 1:
        response = '⭐';
        break;
      case 2:
        response = '⭐⭐';
        break;
      case 3:
        response = '⭐⭐⭐';
        break;
      case 4:
        response = '⭐⭐⭐⭐';
        break;
      case 5:
        response = '⭐⭐⭐⭐⭐';
        break;
      default:
        response = 'Sin Puntuación';
        break;
    }

    return response;
  }

}
