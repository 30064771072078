import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface Options {
  value: string | number;
  name: string;
  children?: Array<Options>;
  color?: string;
}

@Injectable({ providedIn: 'root' })
export class IdealistaFormOptionsService {
  agency_options = [
    { value: 1, name: 'Profesional' },
    { value: 2, name: 'Particular' },
    { value: 3, name: 'Todos' },
  ];


  category = "Locales o naves"


  /// google 10c colors
  category_options: Array<Options> = [
    { value: 'edificios', name: 'Edificios', color: '#3366cc' },
    { value: 'garajes', name: 'Garajes', color: '#dc3912' },
    { value: 'habitacion', name: 'Habitación', color: '#ff9900' },
    { value: 'locales', name: 'Locales', color: '#109618' },
    { value: 'obranueva', name: 'Obra Nueva', color: '#990099' },
    { value: 'oficinas', name: 'Oficinas', color: '#0099c6' },
    { value: 'terrenos', name: 'Terrenos', color: '#dd4477' },
    { value: 'trasteros', name: 'Trasteros', color: '#66aa00' },
    { value: 'viviendas', name: 'Viviendas', color: '#b82e2e' },
    { value: 'all', name: 'Todos', color: '#316395' }
  ];



  top_value: number = 1000001;

  price_options: Array<Options> = [
    { value: 0, name: '0' },
    { value: 50000, name: '50.000' },
    { value: 100000, name: '100.000' },
    { value: 150000, name: '150.000' },
    { value: 200000, name: '200.000' },
    { value: 250000, name: '250.000' },
    { value: 300000, name: '300.000' },
    { value: 350000, name: '350.000' },
    { value: 400000, name: '400.000' },
    { value: 450000, name: '450.000' },
    { value: 500000, name: '500.000' },
    { value: 550000, name: '550.000' },
    { value: 600000, name: '600.000' },
    { value: 650000, name: '650.000' },
    { value: 700000, name: '700.000' },
    { value: 750000, name: '750.000' },
    { value: 800000, name: '800.000' },
    { value: 850000, name: '850.000' },
    { value: 900000, name: '900.000' },
    { value: 950000, name: '950.000' },
    { value: 1000000, name: '950.000' },
    { value: this.top_value, name: 'Más de 1 millón' },
  ];
  rent_options: Array<Options> = [
    { value: 0, name: '0' },
    { value: 400, name: '400' },
    { value: 600, name: '600' },
    { value: 800, name: '800' },
    { value: 1000, name: '1.000' },
    { value: 1250, name: '1.250' },
    { value: 1500, name: '1.500' },
    { value: 1750, name: '1.750' },
    { value: 2000, name: '2.000' },
    { value: 2500, name: '2.500' },
    { value: 3000, name: '3.000' },
    { value: 5000, name: '5.000' },
    { value: 6000, name: '6.000' },
    { value: this.top_value, name: 'Más de 6.000' },
  ];
  sqm_options: Array<Options> = [
    { value: 0, name: '0' },
    { value: 50, name: '50' },
    { value: 75, name: '75' },
    { value: 100, name: '100' },
    { value: 125, name: '125' },
    { value: 150, name: '150' },
    { value: 175, name: '175' },
    { value: 200, name: '200' },
    { value: 250, name: '250' },
    { value: 300, name: '300' },
    { value: 400, name: '400' },
    { value: 600, name: '600' },
    { value: 800, name: '800' },
    { value: 1000, name: '1.000' },
    { value: 2000, name: '2.000' },
    { value: 5000, name: '5.000' },
    { value: this.top_value, name: 'Más de 5.000' },
  ];
  room_options: Array<Options> = [
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
    { value: 4, name: '4' },
    { value: 5, name: '5' },
    { value: 6, name: 'Más de 5' },
    { value: 'all', name: 'Todas' },
  ];

  type: Array<Options> = [
    {
      value: 'Comprar',
      name: 'Comprar',
      children: [
        {
          value: 'agency',
          name: 'Agente inmobiliario',
          children: this.agency_options,
        },
        {
          value: 'type',
          name: 'Tipo de vivienda',
          children: this.category_options,
        },

        {
          value: 'price_min',
          name: 'Precio mínimo (€)',
          children: this.price_options,
        },
        {
          value: 'price_max',
          name: 'Precio máximo (€)',
          children: this.price_options,
        },
        {
          value: 'size_min',
          name: 'Metros mínimo',
          children: this.sqm_options,
        },
        {
          value: 'size_max',
          name: 'Metros máximo',
          children: this.sqm_options,
        },
        {
          value: 'rooms',
          name: 'Habitaciones',
          children: this.room_options,
        },
      ],
    },

    {
      value: 'Alquilar',
      name: 'Alquilar',
      children: [
        {
          value: 'agency',
          name: 'Agente inmobiliario',
          children: this.agency_options,
        },
        {
          value: 'type',
          name: 'Tipo de vivienda',
          children: this.category_options,
        },

        {
          value: 'price_min',
          name: 'Precio mínimo (€)',
          children: this.rent_options,
        },
        {
          value: 'price_max',
          name: 'Precio máximo (€)',
          children: this.rent_options,
        },
        {
          value: 'size_min',
          name: 'Metros mínimo',
          children: this.sqm_options,
        },
        {
          value: 'size_max',
          name: 'Metros máximo',
          children: this.sqm_options,
        },
        {
          value: 'rooms',
          name: 'Habitaciones',
          children: this.room_options,
        },
      ],
    },
  ];

  public getType(type) {
    return this.type.filter((d) => d.value === type);
  }
  public getAll() {
    return this.type;
  }
  public onSubmit() {
    return false;
  }
  // not in use here
  public resetForm(form: FormGroup, event) {
    event.preventDefault();
    form.reset();
  }
  public setMaxOptions(options: Array<any>, valueToFilter) {
    options[1][0].children = options[0][0].children;
    let result = options[1].map((option) => {
      option = Object.assign({}, option);
      option.children = option.children.filter((o) => o.value > valueToFilter);
      return option;
    });
    return result;
  }
  public setControlValue(
    form: FormGroup,
    control: string,
    value: number | string
  ) {
    form.get(control).setValue(value);
  }
  public minIsHigher(form: FormGroup, control: Array<string>) {
    let min = form.get(control[0]).value;
    let max = form.get(control[1]).value;
    if (min >= max) {
      return true;
    }
    return false;
  }
}
