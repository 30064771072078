import { ElementRef, Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ColorsService, MapBoxService, ZIndexService } from '@compass/utils/misc';
import { Map, MarkerIcon, MarkerOptions } from './interfaces';
import 'leaflet-draw';
import {
  CompassMapDrawerService,
  CompassMapIndicatorsService,
  CompassMapMarkerService,
  CompassMapPoisService, CompassMapWrapperService,
  MapSetUp
} from '@compass/utils/leaflet';
import { BehaviorSubject, Subject } from 'rxjs';
import { CreateStudyService } from '@compass/feature-create-study';
import { HasEvents } from './classes/has-events';
import { MarkerOptionsApiService } from '@compass/utils/navigation';

@Injectable({
  providedIn: 'root'
})
export class MapService extends HasEvents {
  _events = ['zoomed', 'moveend'];

  public map: Map;
  public _mapRef: ElementRef;

  public visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(undefined);

  private clickMap$ = new Subject<any>();
  clickMapChanged$ = this.clickMap$.asObservable();

  markerIcon: MarkerIcon = {
    iconUrl: '../../../../assets/img/markers/glocally-brick-purple.svg',
    iconSize: [40, 80],
    iconAnchor: [25, 40],
    popupAnchor: [7, 100]
  };

  markerOptions: MarkerOptions = {
    name: 'marker_pane',
    zIndex: this.zIndexService.$z__index_top,
    width: 80
  };

  constructor(
    private mapBoxService: MapBoxService,
    private colorsService: ColorsService,
    private zIndexService: ZIndexService,
    public drawerService: CompassMapDrawerService,
    public markerService: CompassMapMarkerService,
    public studyService: CreateStudyService,
    public poiService: CompassMapPoisService,
    public indicatorService: CompassMapIndicatorsService,
    private markerOptionsApiService: MarkerOptionsApiService,
    private compassMapWrapperService:CompassMapWrapperService
  ) {
    super();

    this.map = {
      id: 'map_elementRef',
      change: '',
      access_token: this.mapBoxService.getMapBoxUrl('transport_light')[0].value,
      box: null,
      geojsonLayer: null,
      transitLayer: null,
      studyLayer: null,
      cpsLayer: null,
      bounds: null,
      properties: {
        center: [40.416729, -3.703339], // madrid
        minZoom: 6,
        zoom: 14,
        maxZoom: 20,
        maxNativeZoom: 19,
        maxBounds: L.latLngBounds([L.latLng(45, 27), L.latLng(4.333333, -25)]),
        dragging: true,
        touchZoom: true,
        scrollWheelZoom: true,
        boxZoom: true,
        zoomControl: false,
        address: 'any',
        locality: 'any',
        province: 'any'
      },
      zoom: {
        position: 'topright',
        zoomInText: '+',
        zoomOutText: '-'
      },
      zoomCtrl: null,
      tileLayer: null,
      attribution: {
        attribution:
          'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
          '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="http://mapbox.com">Mapbox</a>'
      },
      marker: {
        icon: this.markerIcon,
        options: this.markerOptions,
        l: null
      },
      popUp: {
        visible: false,
        options: {
          direction: 'right',
          permanent: false,
          offset: [0, 0],
          opacity: 0,
          className: 'customPopUp',
          maxWidth: 0
        },
        info: {
          on: ``,
          off: ``
        }
      },
      area: {
        style: {
          default: {
            color: this.colorsService.colors.orange,
            weight: 2,
            fillOpacity: 0.32,
            opacity: 0.65,
            dashArray: '0'
          },
          mouseover: {
            fillOpacity: 0.4
          },
          mouseout: {
            fillOpacity: 0.32
          },
          click: {
            color: this.colorsService.colors.red,
            weight: 2,
            opacity: 0.32,
            fillOpacity: 0.24,
            dashArray: '0'
          }
        },
        prevLayerClicked: null,
        layerClicked: null
      },
      data: null,
      info_control: null,
      minMaxScales: [],
      color_key: null,
      pois: []
    };
    this.map = this.compassMapWrapperService.map$.value

    // instance service with map data and subscribe to changes
    this.drawerService.setMap(this.map);
    this.drawerService.map$.subscribe(drawerMapUpdated => {
      this.map = drawerMapUpdated;
    });


    /*this.markerService.map$.subscribe((map) => {
      this.map = map || this.map;
      this.map.box = this.map.box || null;
    });
    this.markerService.setMap(this.map);*/

    this.poiService.setMap(this.map);

    this.indicatorService.setMap(this.map);
    this.indicatorService.map$.subscribe(map => {
      if (map) {
        this.map = map;
      }
    });

  }

  setMapRef(mapRef: ElementRef) {
    this._mapRef = mapRef;
  }

  /**
   * Initialize map properties received as MapSetUp.
   *
   * @param properties
   * @param options
   */
  public initMap(properties: MapSetUp): Map {
    // initialize required properties if not recieved
    this.map.id = properties?.id || this.map.id;
    this.map.access_token = this.mapBoxService.getMapBoxUrl(properties?.theme || 'light')[0].value;
    this.map.properties.center = properties?.center || this.map.properties.center;
    this.map.marker.icon.iconUrl = properties?.iconUrl || this.map['marker']['icon'].iconUrl;
    this.map.marker.icon.iconSize = properties?.iconSize || this.map['marker']['icon'].iconSize;
    this.map.marker.icon.iconAnchor = properties?.iconAnchor || this.map['marker']['icon'].iconAnchor;
    this.map.marker.icon.popupAnchor = properties?.popupAnchor || this.map['marker']['icon'].popupAnchor;
    this.map.properties.zoom = properties?.zoom || this.map.properties.zoom;
    this.map.popUp = properties?.popUp || this.map.popUp;

    // reset marker
    this.map.marker = {
      icon: this.markerIcon,
      options: this.markerOptions,
      l: null
    };

    // set center position of map
    this.setMapCenter([this.map.properties.center[0], this.map.properties.center[1]]);

    // draw map with controls
    this.drawerService.drawMap(this._mapRef);
    this.drawerService.drawZoomCtrl();
    this.drawerService.drawTopoCtrl();
    this.drawerService.drawAttributionLayer();
    this.drawerService.fitGeojsonBounds();
    this.drawerService.drawCtrl(false, null);

    // marker stuff
    this.drawerService.removeGeojsonLayer(this.map.marker.options.name);
    // this.markerService.drawMarker();

    // once drawer service instanced, listen for map.box events
    this.handleMapEvents();

    this.setMap(this.map);

    return this.map;
  }

  public destroy() {
    this.drawerService.removeMap(this._mapRef);
  }

  public setMapCenter(center: Array<any>): void {
    this.map.properties.center[0] = center[0];
    this.map.properties.center[1] = center[1];
  }

  public setId(id): void {
    this.map.id = id;
  }

  public createMakerMap(object: any): void {
    this.clickMap$.next(object);
  }

  public setZoom(value: number): void {
    this.map.zoom = value;
    this.map.box.setZoom(value);
  }

  public getMap() {
    return this.map$.asObservable();
  }

  public setMap(map: Map): void {
    this.map$.next(map);
  }

  public getVisible() {
    return this.visible$.asObservable();
  }

  public setVisible(visible: boolean): void {
    this.visible$.next(visible);
  }

  public removeLayer(layer): Map {
    if (this.map[layer]) {
      this.map.box.removeLayer(this.map[layer]);
    }

    return this.map;
  }

  public refresh(map: Map = null): void {
    const mapData = map || this.map;

    this.drawerService.setView(mapData);
  }

  /*public drawMarker(marker) {
    this.markerService.drawMarker(marker);
  }

  public drawMarkers(markers, createdCallback = null, completedCallback?: any) {
    this.markerService.drawMarkerList(markers);
  }*/

  public drawPois(pois, layer, layerGroup) {
    pois.map((poi: any) => {
      poi.markerOptions = this.markerOptionsApiService.getMarkerOptions(poi);
    });

    this.poiService.removeLayer(layer);
    this.poiService.drawFlatPoisLayer(pois, layer, layerGroup);
  }

  /*public createPopUp(content, layer, callbacks = {}) {
    this.markerService.createPopUp(content, layer, callbacks);
  }*/

  public minMaxIndicatorsScaleExist(indicator): boolean {
    return this.map.minMaxScales.find((x) => x.key === indicator['key']);
  }


  private handleMapEvents() {
    // fire zoomed event
    this.drawerService.on('zoomed', (e, map) => {
      this.fireEvent('zoomed', e, map);
    });

    // fire moveend events
    this.drawerService.on('moveend', (e, map) => {
      this.fireEvent('moveend', e, map);
    });
  }

}
