import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, filter } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalMediaApiService {

    private localMediaUrl = 'assets/data/local-media/local-media-sevilla.json';
    public localMedia$: BehaviorSubject<any> = new BehaviorSubject(undefined);



    constructor(public http: HttpClient, private router: Router) { }

    // PRO API
    handleError(error: HttpErrorResponse) {
        const kk = null;
        return of(kk);
    }



    // DEV API fn

    public getLocalMediaData = () => {
        return this.http.get(`${this.localMediaUrl}`).pipe(
            catchError(this.handleError),
            map((data: any) => {
                // map data here if needed
                data = data.map((poi) => {

                    return poi;

                });
                this.localMedia$.next(data);
                return data;
            })
        );
    }


    public getLocalMedia() {
        return this.localMedia$.asObservable();
    }
    public setLocalMedia(media) {
        this.localMedia$.next(media);
    }
}
