import { Component, OnDestroy, OnInit } from '@angular/core';
import { OldCrossfilterService } from '../../../../../../../../libs/crossfilter/src/lib/old-crossfilter.service';

@Component({
  selector: "glo-stage-mobility",
  templateUrl: "./stage-mobility.component.html",
  styleUrls: ["./stage-mobility.component.scss"]
})
export class StageMobilityComponent implements OnInit, OnDestroy {

  constructor(private crossfilterService: OldCrossfilterService) {
  }

  ngOnInit(): void {
    this.crossfilterService.setStatus(true);
  }

  ngOnDestroy() {
    this.crossfilterService.setStatus(false);
  }
}
