<div class="p-3" *ngIf="data">
  <h2>
    {{ data.title }}<br>
  </h2>
  <small [style.color]="data.color" *ngIf="data.phq_attendance">{{ data.phq_attendance }}
    <small>asistentes</small></small>

  <button  (click)="addEventToBasket()">{{poiBasketService.inBasket(formatedEvent) ? 'Quitar de la cesta': 'Añadir a la cesta'}}</button>


  <ng-container *ngIf="data.labels">
    <h5 class="pb-3">Categorías</h5>
    <mat-chip-list>
      <mat-chip *ngFor="let label of data.labels">{{label}}</mat-chip>
    </mat-chip-list>
  </ng-container>

  <div  fxLayout="row">
    <div fxFlex="50" *ngIf="data.rank">
      <h5 class="pb-3">Ranking global</h5>
      <mat-progress-spinner mode="determinate" diameter="75" value="{{data.rank}}"></mat-progress-spinner>
      <div class="position-relative top--50 left-27">
        <strong>{{data.rank}}</strong>
      </div>
    </div>
    <div fxFlex="50" *ngIf="data.local_rank">
      <h5 class="pb-3">Ranking local</h5>
      <mat-progress-spinner mode="determinate" diameter="75" value="{{data.local_rank}}"></mat-progress-spinner>
      <div class="position-relative top--50 left-27" >
        <strong class="pl-1">{{data.local_rank}}</strong>
      </div>
    </div>
  </div>

  <ng-container *ngIf="data.entities.length">
    <h5>Ubicación</h5>
    <strong *ngIf="data.entities[0]?.name">{{ data.entities[0].name }}</strong>
    <p class="text-muted" *ngIf="data.entities[0].formatted_address">{{ data.entities[0].formatted_address }}</p>
  </ng-container>

  <h5>Fechas</h5>
  <div fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign=" center">
    <div>
      <p>{{ data.start | date }}</p>
      <p class="secondary">{{ data.start | date:"HH:mm" }}</p>
    </div>
    <mat-icon>trending_flat</mat-icon>
    <div>
      <p>{{ data.end | date }}</p>
      <p class="secondary">{{ data.end | date:"HH:mm" }}</p>
    </div>
  </div>

  <h5>Calendario</h5>
  <mat-calendar  [dateClass]="dateClass()" [startAt]="calendarStartMonth.start" [selected]="calendarStartMonth"></mat-calendar>

</div>
