import { Component, OnInit } from '@angular/core';
import { InfluencersReshapeService } from '../influencers-reshape.service';
import { Influencer } from '../influencers-loader/influencers-api.service'

@Component({
  selector: 'compass-influencers-accordion',
  templateUrl: './influencers-accordion.component.html',
  styleUrls: ['./influencers-accordion.component.scss']
})
export class InfluencersAccordionComponent implements OnInit {
  list: Array<Influencer>;
  constructor(private influencersReshapeService: InfluencersReshapeService) {
    this.influencersReshapeService.get().subscribe((d: Array<Influencer>) => {
      this.list = d;
    });
  }
  ngOnInit(): void {
  }
}

