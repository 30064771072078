import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LocalKnowledgeReshapeService} from '@compass/local-knowledge';
import {Subscription} from 'rxjs';
import {DateRange, MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {
  BottomContainerService
} from "../../../../../apps/glocally/src/app/shared/bottom-container/bottom-container.service";
import {PoiBasketService} from "@compass/shared/poi-basket";

@Component({
  selector: 'compass-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventDetailComponent implements OnDestroy, OnInit {
  data: any;
  dataSub: Subscription;
  calendarStartMonth: any;
  formatedEvent: any;
  button: any;

  constructor(private localKnowledgeService: LocalKnowledgeReshapeService,
              private bottomContainerService: BottomContainerService,
              public poiBasketService: PoiBasketService) {

  }

  ngOnInit(): void {
    this.dataSub = this.localKnowledgeService.get().subscribe(d => {
      this.data = d;
      if (d) {
        if (
          d.start &&
          d.end
        ) {
          this.calendarStartMonth = new DateRange(
            d.start,
            d.end
          );
        } else {
          this.calendarStartMonth = new DateRange(d.start, null);
        }
      }
      if(this.data) {
        this.formatedEvent = {
          ...this.data.geo,
          name: this.data.title,
          properties: {
            category: 'events',
            direccion: this.data.entities[0].formatted_address,
            key_categoria: 'events',
            nombre: this.data.title,
            id: this.data.id
          },
          eventInfo: {
            ...this.data
          }
        }
      }
      // this.calendarStartMonth = d?.start ? new Date(d.start) : null;
    });
    if (this.bottomContainerService.visible$.value) {
      this.bottomContainerService.relativePos$.next(true);
    }
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
    if (this.bottomContainerService.visible$.value) {
      this.bottomContainerService.relativePos$.next(false);
    }
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      date = new Date(date); // <<< My edit
      const highlightDate = [this.data.start, this.data.end]
        .map(strDate => new Date(strDate))
        .some(d => {
          return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        });

      return highlightDate ? 'highlight-event-days' : '';
    };
  }

  addEventToBasket() {
    if (this.poiBasketService.inBasket(this.formatedEvent)) {
      this.poiBasketService.remove(this.formatedEvent);
    } else {
      this.poiBasketService.add(this.formatedEvent);
    }
  }

}
