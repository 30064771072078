<ng-template #loading_stages>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<section class="margin__top--m">
  <div class="container">
    <nav role="navigation">
      <div class="sidebar stages-mat-tab">


        <mat-tab-group #tabGroup (focusChange)="tabChanged($event)" [selectedIndex]="selectedIndex"
          animationDuration="0ms">
          <mat-tab>
            <div>
              <ng-template mat-tab-label>
                <img data-cy="tab_label_intelligence" class='brand'
                  src="/assets/img/brand/intelligence-icon.svg">
                <span></span>
              </ng-template>
            </div>

            <glo-stages-menu-tab [setup]='intelligenceSetup'></glo-stages-menu-tab>
          </mat-tab>
          <mat-tab>
            <div>
              <ng-template mat-tab-label>
                <img data-cy="tab_label_precision" class='brand'
                  src="/assets/img/brand/precision-icon.svg">
                <span></span>
              </ng-template>
            </div>

            <glo-stages-menu-tab [setup]='precisionSetup'></glo-stages-menu-tab>
          </mat-tab>

        </mat-tab-group>

      </div>
    </nav>
  </div>
</section>
