<div fxLayout="row" fxLayoutAlign=" center">
  <h3>Horas con más afluencia</h3>
  <mat-form-field class="popular-time-level" appearance="outline">
    <mat-label>a nivel de</mat-label>
    <mat-select
      [(ngModel)]="ensenyaMode"
      (selectionChange)="parseData()"
      [disabled]="loading"
    >
      <mat-option [value]="false">POI</mat-option>
      <mat-option [value]="true">Enseña</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="busiest-hours-comparator"
     fxLayout="row"
     fxLayout.lt-sm="column"
     fxLayoutAlign="space-between stretch"
     fxLayoutGap="0.5rem"
>
  <div class="loader-container" *ngIf="loading">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>

  <!-- MAIN -->
  <div>
    <compass-comparator-poi-title [poi]="mainPoi"></compass-comparator-poi-title>
    <compass-popular-time-week-matrix
      *ngIf="mainPoi?.properties?.popularTimeMatrix"
      [data]="mainPoi?.properties?.popularTimeMatrix"
      (cellClicked)="hourSelected($event)"
    ></compass-popular-time-week-matrix>
  </div>
  <!--/ MAIN -->

  <!-- SELECT -->
  <div>
    <compass-poi-select [(selected)]="selected" (selectedChange)="generateComparation()"
                        [options]="otherPois"></compass-poi-select>
    <compass-popular-time-week-matrix
      [legendActive]="false"
      *ngIf="selected?.properties?.popularTimeMatrix"
      [data]="selected?.properties?.popularTimeMatrix"
      (cellClicked)="hourSelected($event)"
    ></compass-popular-time-week-matrix>
  </div>
  <!--/ SELECT -->

  <!-- RESULT -->
  <div>
    <compass-comparator-poi-title
      [compared]="[mainPoi?.properties?.nombre, selected?.properties?.nombre]"
    ></compass-comparator-poi-title>
    <compass-popular-time-week-matrix
      [baseZero]="true"
      [inputScale]="comparationColors"
      [inputLegendText]="['Poca diferencia', 'Mucha diferencia']"
      [data]="comparation?.properties?.popularTimeMatrix"
      (cellClicked)="hourSelected($event)"
    ></compass-popular-time-week-matrix>
  </div>
  <!--/ RESULT -->
</div>
