import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})

export class PieChartReshapeService {

    constructor(
    ) { }

    public reshape = (table: any, selector: Array<any>): Array<any> => {

        let target: any = table.filter(d => d.target === selector[0]["target"].key && d.medio === selector[1])[0];
        let rest = { valor: 100 - target.valor, medio: "Resto" };
      return [target, rest];

    };
}
