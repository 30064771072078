import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import '@angular/common/locales/global/es';

import { HttpClientModule } from '@angular/common/http';

import { MaterialModule } from '@compass/material';

import { SharedFeatureAuthModule } from '@compass/shared/feature-auth';
import { BrickApiModule, BricksApiService } from '@compass/brick-api';
import { PoisModule } from './pois/pois.module';
import { GlocallyAuthDataAccessModule } from '@compass/glocally/auth/data-access';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BusinessModule } from '@compass/business';
import { DataModule } from '@compass/data';
import { FormModule } from '@compass/form';

registerLocaleData(localeES, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    SharedFeatureAuthModule,
    BrickApiModule,
    PoisModule,
    BusinessModule,
    DataModule,
    FormModule,
    GlocallyAuthDataAccessModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
    BricksApiService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
