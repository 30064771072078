import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SideNavIndicatorsService,
  Indicator,
  IndicatorsGroups,
  IndicatorGroupSelected
} from '@compass/utils/navigation';
import {
  CompassMapIndicatorsService,
  CompassMapWrapperService,
  CompassMapDrawerService,
  Map
} from '@compass/utils/leaflet';

@Component({
  selector: 'compass-select-indicators',
  templateUrl: './select-indicators.component.html',
  styleUrls: ['./select-indicators.component.scss']
})
export class SelectIndicatorsComponent implements OnInit {
  @Input() parentLabel: string;
  @Input() childrenLabel: string;

  options: IndicatorsGroups = this.sideNavIndicatorsService.indicatorsGroups$.value;
  children: IndicatorsGroups;
  indicatorGroupSelected: IndicatorGroupSelected = this.sideNavIndicatorsService.indicatorGroupSelected$.value;

  removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
  formGroup: FormGroup;
  map: Map;

  onChange() {
    return;
  }


  constructor(
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService) {

    this.formGroup = new FormGroup({
      category: new FormControl(this.indicatorGroupSelected.category),
      indicator: new FormControl(this.indicatorGroupSelected.indicator)
    });

    this.compassMapWrapperService.map$.subscribe((map: Map) => {
      this.map = map;
    });

  }

  ngOnInit(): void {
    this.children = this.formGroup.value.category.values;
    this.formGroup.get('category').valueChanges.subscribe((value) => {
      this.children = value.values;
      this.formGroup.controls['indicator'].setValue(this.children[0]);
    });
    this.formGroup.valueChanges.subscribe(indicatorGroup => {
      this.removeGeojsonLayer('geojsonLayer');
      this.removeGeojsonLayer('color_key');
      this.sideNavIndicatorsService.setIndicatorGroupSelected(indicatorGroup);
    });
  }

}
