import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectLayerGroupService {
  public layerGroup$ = new BehaviorSubject<string>('featureGroup');
  constructor() {}

  setLayergroup(status) {
    this.layerGroup$.next(status);
  }

  getOptions(options: string): Array<any> {
    let result: Array<any> = [];

    switch (options) {
      case 'layerGroup':
        {
          result = [
            { key: 'featureGroup', value: 'Puntos de interés' },
            { key: 'markerClusterGroup', value: 'Clústers de puntos' },
            { key: 'heatMapGroup', value: 'Mapa de calor' },
          ];
        }
        break;

      default: {
        result = [{}];
      }
    }
    return result;
  }
}
