import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'glo-stages-crud',
  templateUrl: './stages-crud.component.html',
  styleUrls: ['./stages-crud.component.css'],
})
export class StagesCrudComponent implements OnInit {

  constructor() { }
  ngOnInit(): void { }
}
