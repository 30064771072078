import { Injectable } from "@angular/core";
import { TooltipStyleService } from "../tooltipStyle.service";
import { ColorsService } from "@compass/utils/misc";

@Injectable()
export class PieChartLayoutService {

    colors: any = this.colorsService.colors;
    tooltipValues: any = this.tooltipStyleService.tooltipValues;
    layoutSelected: any;

    constructor(
        private colorsService: ColorsService,
        private tooltipStyleService: TooltipStyleService
    ) { }

    public layouts: any[] = [
        {
            // vertical bar chart
            key: "pie_chart",
            section: "egm",
            design: {
                innerRadius: 2,
                height: 240,
                width: null,
                margin: { top: 8, right: 8, bottom: 8, left: 8 },
                stroke: {
                    width: 0.2,
                    stroke: this.colors.black,
                },
                style: {
                    colors: [
                        this.colors.purple,
                        this.colors.gray_light,
                        this.colors.red,
                        this.colors.sky
                    ],
                    paddingInner: 0.2,
                    padding: 0.05,
                    align: 0.1,
                    legend: {
                        fontSize: "10px",
                    },
                    averages: {},
                },
            },
            viewChartLegend: false,
            properties: {
                key: 'valor',
                value: 'medio'
            },
            dictionary: [
                {
                    name: null,
                    key: "screen_name",
                },
            ],
            tooltip: {
                id: "pie_chart_tooltip",
                style: this.tooltipValues,
                header: null//   key [string, "personas_hogar"], value [number], category[string, "Resto"] or index[string, "tienda_3_Resto"]


            },
        }
    ];

    public getLayout(key: string): any {
        let layout = this.layouts.filter((l) => {
            return l.key === key;
        });
        return layout;
    }
}
