import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { Router } from '@angular/router';

import * as L from 'leaflet';

import { Map } from './compass-map-wrapper.service';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';
import { SideNavStatusService } from '@compass/utils/navigation';
import { CompassMapDrawerService } from './compass-map-drawer.service';
import { CompassMapWrapperService } from './compass-map-wrapper.service';

import { CreateStudyService } from '@compass/feature-create-study';
import { BricksApiService } from '@compass/brick-api';

import { StudyService } from '@compass/studies/data-access-study';
import { MapBoxService } from '@compass/utils/misc';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HasEvents } from '../../../../map/src/lib/classes/has-events';

@Injectable({ providedIn: 'root' })
export class CompassMapMarkerService extends HasEvents{
  compassIsOn: boolean;
  studyFormNavOn: boolean;
  stagesNavOn: boolean;

  map: Map = this.compassMapWrapperService.map$.value;
  map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(undefined);


  removeGeojsonLayer: (layer: any) => void;
  setView = this.compassMapDrawerService.setView;
  setZoomMap = this.compassMapWrapperService.setZoomMap;
  drawAttributionLayer = this.compassMapDrawerService.drawAttributionLayer;
  getTileLayerOptions = this.compassMapDrawerService.getTileLayerOptions;

  drawCtrl: (draw: boolean, study: any) => void;

  coords: Array<number>;
  areaPainted: string;
  study: any;
  cpStudyOption: any;
  mainMarkerCords: any = null;

  constructor(
    private startLeftSidenavService: StartLeftSidenavService,
    private sideNavStatusService: SideNavStatusService,
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private createStudyService: CreateStudyService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private bricksApiService: BricksApiService,
    private studyService: StudyService,
    private mapBoxService: MapBoxService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    super();

    this.startLeftSidenavService.getCompassStatus().subscribe((value) => {
      this.compassIsOn = value;
    });

    this.createStudyService.getDataFormStudy().subscribe((params) => {
      this.study = params;
    });

    this.sideNavStatusService.getStudyFormStatus().subscribe((value) => {
      this.studyFormNavOn = value;
      this.removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
      this.drawCtrl = this.compassMapDrawerService.drawCtrl;
    });
    this.sideNavStatusService.getStagesNavStatus().subscribe((value) => {
      this.stagesNavOn = value;
    });
  }

  dataStudySeeInitParams(studyObject: any): void {
    let editStudy = studyObject;
    this.createStudyService.setIdStudySelected(editStudy.properties.id);
    this.startLeftSidenavService.setCompassStatus(true);
    this.startLeftSidenavService.setSidenavIsOpen(true);
    this.sideNavStatusService.setStudyFormStatus(false);

    this.createStudyService.setCPSelected(null);
    this.sidenavPoisLevelsService.setIsFirstLoad(true);
    //diable create button and if manual disable related buttons:
    if (editStudy.properties.area_zip_code_ids_areas) {
      this.createStudyService.setCPSelected(
        JSON.stringify(editStudy.properties.area_zip_code_ids_areas)
      );
      this.setZoomMap(16);
    } else {
      if (editStudy.properties.areas.manual) {
        this.createStudyService.setAreaPainted(
          JSON.stringify(
            editStudy.properties.areas.manual.geometry.coordinates[0]
          )
        );
        this.sideNavStatusService.setStudyButtonCreateStatus(true);
      } else {
        this.createStudyService.setAreaPainted(null);
        this.sideNavStatusService.setStudyButtonCreateStatus(false);
      }
    }
    //provide params to new study:
    this.createStudyService.setStudyName(editStudy.properties.study.name);
    this.createStudyService.setDataFormStudy(editStudy);

    this.createStudyService.setCoordsMarker([
      editStudy.geometry.coordinates[1],
      editStudy.geometry.coordinates[0]
    ]);
    this.map.properties.center[0] = editStudy.geometry.coordinates[1];
    this.map.properties.center[1] = editStudy.geometry.coordinates[0];
    this.setView(this.map);
    this.createStudyService.reloadData([
      editStudy.geometry.coordinates[1],
      editStudy.geometry.coordinates[0]
    ]);
    let keyAreas = Object.keys(editStudy.properties.areas)[0];
    if (editStudy.properties.area_zip_code_ids_areas) {
      this.createStudyService.setStudyTabInit('cp');
      this.setZoomMap(16);
    } else {
      if (!keyAreas.includes('manual')) {
        this.createStudyService.setStudyTabInit('point');
        if (keyAreas.includes('car')) {
          this.createStudyService.setModeStudy('car');
        } else {
          this.createStudyService.setModeStudy('foot');
        }
        if (keyAreas.includes('00_15')) {
          this.createStudyService.setDistanceStudy('00_15');
        } else if (keyAreas.includes('00_10')) {
          this.createStudyService.setDistanceStudy('00_10');
        } else {
          this.createStudyService.setDistanceStudy('00_05');
        }
      } else {
        this.createStudyService.setStudyTabInit('manual');
      }
    }

    this.seeDataStudy();
  }

  seeDataStudy(): void {
    this.startLeftSidenavService.setCompassStatus(false);
    this.sideNavStatusService.setStudyFormStatus(false);
    this.sideNavStatusService.setStageViewerStatus(false);
    this.sideNavStatusService.setSubcategoriesNavStatus(false);
    this.sideNavStatusService.setCompaniesNavStatus(false);
    this.sideNavStatusService.setPlacesNavStatus(false);
    this.sideNavStatusService.setStudyButtonCreateStatus(false);
    this.bricksApiService.resetAllBrickLists();
    this.removeLayerStudy();

    this.coords = this.createStudyService.getCoordsMarker();
    this.cpStudyOption = this.createStudyService.getCPSelected();
    this.createStudyService.setFeatureStudy(null);
    this.createStudyService.drawShape(false);
    this.createStudyService.drawShapeCps(false);
    this.createStudyService.setDrawShape(false);
    this.createStudyService.setFeatureStudyInitCps(null);
    this.sidenavPoisLevelsService.resetAllCategoriesList();
    this.sidenavPoisLevelsService.resetParentCategories();

    if (this.study) {
      this.createStudyService.setFeatureStudy(this.study);
      this.createStudyService.setFeatureStudyInitCps(null);
      this.study.properties.study.name = this.createStudyService.getStudyName();

      if (this.study.properties.areas.manual) {
        this.study.properties.areas.manual.type = 'Feature';
        this.drawStudyLayer(this.study.properties.areas.manual);
      } else {
        //console.log(JSON.stringify(this.study.properties.areas));
        this.study.properties.areas[
          Object.keys(this.study.properties.areas)[0]].type = 'Feature';
        this.drawStudyLayer(
          this.study.properties.areas[
            Object.keys(this.study.properties.areas)[0]
            ]
        );
      }

      if (this.study.properties.area_zip_code_ids_areas) {
        this.createStudyService.setCPSelected(
          JSON.stringify(this.study.properties.area_zip_code_ids_areas)
        );
        this.cpStudyOption = this.createStudyService.getCPSelected();
      } else {
        this.areaPainted = this.createStudyService.getAreaPainted();
        if (this.areaPainted) {
          this.study.properties.areas.manual.geometry.coordinates[0] = eval(
            '(' + this.areaPainted + ')'
          );
        }
      }
      this.createStudyService.setFeatureStudy(this.study);
      this.createStudyService.setIdStudySelected(this.study.properties.id);
      this.bricksApiService.setDataSB(this.study);
      this.startLeftSidenavService.setCompassStatus(true);
      this.startLeftSidenavService.setSidenavIsOpen(true);
      this.sideNavStatusService.setStudyFormStatus(false);
      this.sideNavStatusService.setStagesNavOnLoading(false);
      this.sideNavStatusService.setStagesNavStatus(true);
      this.sidenavPoisLevelsService.resetAllCategoriesList();
      this.sidenavPoisLevelsService.resetParentCategories();
      this.sidenavPoisLevelsService.setIsFirstLoad(true);
      this.bricksApiService.getData();
    }
  }

  studyPoint(result_data_point): void {
    this.createStudyService.setFeatureStudy(result_data_point);
    this.createStudyService.setIdStudySelected(result_data_point.properties.id);
    if (result_data_point.properties.areas.manual) {
      result_data_point.properties.areas.manual.type = 'Feature';
      this.drawStudyLayer(result_data_point.properties.areas.manual);
    } else {
      result_data_point.properties.areas[
        Object.keys(result_data_point.properties.areas)[0]
        ].type = 'Feature';
      this.drawStudyLayer(
        result_data_point.properties.areas[
          Object.keys(result_data_point.properties.areas)[0]
          ]
      );
    }
    this.studyService.getListStudies().subscribe((result_studies) => {
      this.drawMarkerList(result_studies);
    });
    this.bricksApiService.setDataSB(result_data_point);
    this.sideNavStatusService.setStagesNavOnLoading(false);
    this.sideNavStatusService.setStagesNavStatus(true);
    this.bricksApiService.getData();
  }

  studyCp(result_data_cp): void {
    if (this.cpStudyOption) {
      if (this.map.cpsLayer) {
        this.removeGeojsonLayer('cpsLayer');
      }

      this.createStudyService.setFeatureStudy(result_data_cp);
      this.createStudyService.setIdStudySelected(result_data_cp.properties.id);
      if (result_data_cp.properties.areas.manual) {
        result_data_cp.properties.areas.manual.type = 'Feature';
        this.drawStudyLayer(result_data_cp.properties.areas.manual);
      } else {
        result_data_cp.properties.areas[
          Object.keys(result_data_cp.properties.areas)[0]
          ].type = 'Feature';
        this.drawStudyLayer(
          result_data_cp.properties.areas[
            Object.keys(result_data_cp.properties.areas)[0]
            ]
        );
      }
      this.studyService.getListStudies().subscribe((result_studies) => {
        this.drawMarkerList(result_studies);
      });
      this.bricksApiService.setDataSB(result_data_cp);
      this.sideNavStatusService.setStagesNavOnLoading(false);
      this.sideNavStatusService.setStagesNavStatus(true);
      this.bricksApiService.getData();
    }
  }

  onCreateButtonClick(): void {
    this.snackBar.dismiss();
    const studyName = this.createStudyService.getStudyName();

    // if no name, stop execution and show error
    if (!studyName.trim().length) {
      this.snackBar.open('Debes nombrar el estudio', 'vale', {
        duration: 8000,
        panelClass: 'danger'
      });

      return;
    }

    this.createStudyService.toggleCreating(true);
    this.coords = this.createStudyService.getCoordsMarker();
    this.areaPainted = this.createStudyService.getAreaPainted();
    this.cpStudyOption = this.createStudyService.getCPSelected();

    let studyType = this.createStudyService.getStudyTabSelected();

    switch (studyType) {
      case 'point':
      case 'manual':
        if (this.study) {
          this.createStudyService
            .getCreateStudyAnew('manual', this.study, this.areaPainted)
            .subscribe((study) => {
              this.onStudyCreatedCallback(study);
            }, (err) => {
              this.onStudyCreateError();
            });
        } else {
          this.createStudyService
            .getCreateBasicAreaStudy(
              this.areaPainted,
              this.createStudyService.getStudyName(),
              this.createStudyService.getAddressMarker(),
              this.coords[1],
              this.coords[0]
            )
            .subscribe((study) => {
              this.onStudyCreatedCallback(study, true);
            }, (err) => {
              this.onStudyCreateError();
            });
        }
        break;
      case 'cp':
        if (this.study) {
          this.createStudyService
            .getCreateStudyAnew('cp', this.study, this.cpStudyOption)
            .subscribe((study) => {
              this.onStudyCreatedCallback(study);
            }, (err) => {
              this.onStudyCreateError();
            });
        } else {
          this.createStudyService
            .getCreateCPStudy(
              this.cpStudyOption,
              this.createStudyService.getStudyName(),
              this.coords[1],
              this.coords[0]
            )
            .subscribe((study) => {
              this.onStudyCreatedCallback(study, true);
            }, (err) => {
              this.onStudyCreateError();
            });
        }
        break;
    }

  }

  private onStudyCreateError() {
    this.createStudyService.toggleCreating(false);
    this.snackBar.open('Ha ocurrido un error', undefined, {
      duration: 6400,
      panelClass: 'danger'
    });
  }

  private onStudyCreatedCallback(study, newStudy: boolean = false) {
    this.removeMarker();
    this.sidenavPoisLevelsService.resetAllCategoriesList();
    this.sidenavPoisLevelsService.resetParentCategories();

    this.startLeftSidenavService.setCompassStatus(false);
    this.sideNavStatusService.setStudyFormStatus(false);
    this.sideNavStatusService.setStagesNavStatus(false);
    this.sideNavStatusService.setStagesNavOnLoading(true);
    this.sideNavStatusService.setStageViewerStatus(false);
    this.sideNavStatusService.setSubcategoriesNavStatus(false);
    this.sideNavStatusService.setCompaniesNavStatus(false);
    this.sideNavStatusService.setPlacesNavStatus(false);
    this.sideNavStatusService.setStudyButtonCreateStatus(false);
    this.bricksApiService.resetAllBrickLists();
    this.removeLayerStudy();

    this.createStudyService.setFeatureStudy(null);
    this.createStudyService.drawShape(false);
    this.createStudyService.drawShapeCps(false);
    this.createStudyService.setDrawShape(false);
    this.createStudyService.setFeatureStudyInitCps(null);
    this.sidenavPoisLevelsService.resetAllCategoriesList();
    this.sidenavPoisLevelsService.resetParentCategories();
    this.compassMapWrapperService.removeLayer('isochrone');
    this.createStudyService.toggleCreating(false);

    this.router.navigateByUrl('/estudio');
    setTimeout(() => {
      this.router.navigateByUrl(`/estudio/${study.properties.id}`);
    });

    const message = newStudy ? 'Estudio creado correctamente' : 'Estudio actualizado correctamente';
    this.snackBar.open(message, undefined, {
      duration: 3600,
      panelClass: 'success'
    });
  }

  public createPane(): void {
    this.map[this.map.marker.options.name] = this.map.box.createPane(
      this.map.marker.options.name
    );
  }

  public stylePane(): void {
    if(this.map.box) {
      this.map.box.getPane(this.map.marker.options.name)['style'][
        'zIndex'
        ] = this.map.marker.options.zIndex;
      this.map.box.getPane(this.map.marker.options.name)['style']['width'] =
        this.map.marker.options.width + 'px';
    }
  }

  public addMarker(): void {
    this.map['marker']['l'] = L.marker(this.map.properties.center, {
      icon: L.icon(this.map.marker.icon),
      pane: this.map.marker.options.name,
      title: '',
      alt: this.map.marker.options.name,
      draggable: !this.stagesNavOn
    }).addTo(this.map.box);

    this.bindPopUp();

    if (!this.stagesNavOn) {
      this.map['marker']['l'].on('dragend', (e) => {
        var latlng = e.target.getLatLng();
        this.map.properties.center[0] = latlng.lat;
        this.map.properties.center[1] = latlng.lng;

        this.createStudyService.setCoordsMarker(this.map.properties.center);
        this.createStudyService.reloadData(this.map.properties.center);

        this.compassMapWrapperService.setMapCenter([
          this.map.properties.center[0],
          this.map.properties.center[1]
        ]);
        this.setView(this.map);
      });
    }
  }

  public drawMarker(): void {
    this.removeMarker();
    this.createPane();
    this.stylePane();
    this.addMarker();
  }

  public drawMarkerList(list: any): void {
    this.removeMarkerList();

    this.createPane();
    this.stylePane();
    this.addMarkerList(list);
  }

  public drawMarkerCrud(list: any, map: Map): void {
    this.map = map;
    if (this.map) {
      this.removeMarkerCrud();
      this.createPane();
      this.stylePane();
      this.addMarkerCrud(list);
    } else {
      this.router.navigate(['/estudio']);
    }
  }

  public bindPopUp(): void {
    if (this.stagesNavOn) {
      return;
    }
    const popupInfo = !this.studyFormNavOn
      ? this.map.popUp.info.off
      : this.map.popUp.info.on;
    if (this.map.popUp.visible) {
      this.map.marker.l
        .bindPopup(popupInfo, this.map.popUp.options)
        .on('popupopen', (e) => {
          if (!this.studyFormNavOn) {
            this.onPopUpOpen(this.map);
          }
          return false;
        });
    }
  }

  // add click to button on popUpOpen
  public onPopUpOpen = (map: Map): void => {
    this.map = map;
    this.createStudyService.setIdStudySelected(null);
    this.createStudyService.setAreaPainted(null);
    let popUpButton = document.getElementById('popUpButton');
    let clicks$ = fromEvent(popUpButton, 'click');

    let bounds = this.map.box.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;
    this.createStudyService.setBoundsStudy(lngmin, latmin, lngmax, latmax);

    if (popUpButton) {
      let editStudy = this.map['data_study_edit'];
      if (editStudy) {
        delete this.map['data_study_edit'];
        let subscription = clicks$.subscribe((e) => {
          this.router.navigate(['/estudio/', +editStudy.properties.id]);
          this.createStudyService.setIdStudySelected(editStudy.properties.id);
          // this.localKnowledgeApiService.localKnowledge$.next([]);
          // this.localKnowledgeApiService.activeDate$.next(new Date());
          /* this.studyService
             .getInfoStudy(Number(editStudy.properties.id))
             .subscribe((study) => {
               this.map.box.closePopup();
               this.removeGeojsonLayer(this.map.marker.options.name);
               this.dataStudySeeInitParams(study);
             });*/
        });

        //
      } else {
        this.sideNavStatusService.resetAllSidenavs();
        this.createStudyService.cancelStudy(true);
        this.createStudyService.drawShape(false);
        this.createStudyService.drawShapeCps(false);
        this.createStudyService.setDrawShape(false);
        this.createStudyService.setStudyName('');
        this.createStudyService.setIdStudySelected(null);
        this.createStudyService.setDataFormStudy(null);
        this.createStudyService.setCPSelected(null);
        let subscription = clicks$.subscribe((e) => {
          this.startLeftSidenavService.setSidenavIsOpen(true);
          this.startLeftSidenavService.setCompassStatus(true);
          this.sideNavStatusService.setStudyFormStatus(true);
          this.sideNavStatusService.setStudyButtonCreateStatus(false);
          //set init params to new study:
          this.createStudyService.setCoordsMarker(this.map.properties.center);
          this.createStudyService.setAddressMarker(this.map.properties.address);
          this.createStudyService.setLocalityMarker(
            this.map.properties.locality
          );
          this.createStudyService.setProvinceMarker(
            this.map.properties.province
          );
          map.box.closePopup();
          this.removeGeojsonLayer(map.marker.options.name);
          this.drawMarker();
          this.setZoomMap(15);
        });
      }
    }
  };

  public removeMarker(): void {
    if (this.map.marker.options.name) {
      this.removeGeojsonLayer(this.map.marker.options.name);
    }
  }

  public removeMarkerList(): void {
    if (this.map['studies_list']) {
      this.map.box.removeLayer(this.map['studies_list']);
    }
  }

  public removeMarkerCrud(): void {
    if (this.map['study_crud']) {
      this.map.box.removeLayer(this.map['study_crud']);
    }
  }

  public removeLayerStudy(): void {
    this.map.access_token = this.mapBoxService.getMapBoxUrl('light')[0].value;
    if (this.map.tileLayer) {
      this.removeGeojsonLayer('tileLayer');
      this.map['tileLayer'] = null;
      this.drawAttributionLayer();
    }

    if (this.map.geojsonLayer) {
      this.removeGeojsonLayer('geojsonLayer');
      this.removeGeojsonLayer('color_key');
    }

    if (this.map.transitLayer) {
      this.removeGeojsonLayer('transitLayer');
    }
    if (this.map.studyLayer) {
      this.removeGeojsonLayer('studyLayer');
    }
    if (this.map.cpsLayer) {
      this.removeGeojsonLayer('cpsLayer');
    }
    if (Object.keys(this.map.pois).length > 0) {
      const _this = this;
      Object.keys(this.map.pois).forEach(function(layer) {
        _this.map.box.removeLayer(_this.map.pois[layer]);
      });
    }
  }

  public clickOnMap(clickOnMap: boolean) {
    this.map['box'].on('click', (e) => {
      e.preventDefault();
      e.disableClickPropagation();
      this.removeGeojsonLayer(this.map.marker.options.name);
      this.map.properties.center[0] = e.latlng.lat;
      this.map.properties.center[1] = e.latlng.lng;
      this.createStudyService.setCoordsMarker(this.map.properties.center);
      this.createStudyService.reloadData(this.map.properties.center);
      this.compassMapWrapperService.setMapCenter([
        this.map.properties.center[0],
        this.map.properties.center[1]
      ]);
      this.setView(this.map);
      this.drawMarker();
    });

    if (!clickOnMap) {
      this.map['box'].off('click');
    }
  }

  public addMarkerList(list: any): void {
    let icon = L.icon({
      iconUrl: '/assets/img/markers/brick-marker.svg',
      iconSize: [30, 70],
      iconAnchor: [25, 40],
      popupAnchor: [-10, 0]
    });
    var geojsonMarkerOptions = {
      draggable: false,
      icon: icon
    };
    let geojsonMarkerList = L.geoJSON(list, {
      pointToLayer: function(feature, latlng) {
        return L.marker(latlng, geojsonMarkerOptions);
      },
      onEachFeature: this.clickFeatureStudy.bind(this)
    });
    this.map['studies_list'] = geojsonMarkerList;
    this.map['studies_list'].addTo(this.map.box);
  }

  public addMarkerCrud(list: any): void {
    let icon = L.icon({
      iconUrl: '/assets/img/markers/brick-marker.svg',
      iconSize: [30, 70],
      iconAnchor: [25, 40],
      popupAnchor: [7, 100]
    });
    var geojsonMarkerOptions = {
      draggable: false,
      icon: icon
    };
    let geojsonMarkerList = L.geoJSON(list, {
      pointToLayer: function(feature, latlng) {
        return L.marker(latlng, geojsonMarkerOptions);
      },
      onEachFeature: this.clickFeatureStudy.bind(this)
    });
    this.map['study_crud'] = geojsonMarkerList;
    this.map['study_crud'].addTo(this.map.box);
  }

  public clickFeatureStudy(feature: any, layer: any) {
    let popupContent = `
      <div style="margin__bottom-s">
          <span>¿Desea ver el estudio ${feature.properties.id} en este punto?</span>
      </div>
      <button #popUpButton id="popUpButton" class="button--xl primary">
        <span class="material-icons">create_new_folder</span>Ver
      </button>
    `;

    const actualStudy = this.studyService.study$.value;
    if (feature.properties.id == actualStudy?.properties?.id) {
      popupContent = '<h3>Estudio actual</h3>';
    }

    layer.bindPopup(popupContent)
      .on({
        popupopen: this.clickOnButtonStudyEdit.bind(this)
      });
  }

  public clickOnButtonStudyEdit(e) {
    let feature = e.target.feature;
    this.map['data_study_edit'] = feature;
    let id_study = feature._id;
    this.onPopUpOpen(this.map);
  }

  drawStudyLayer(list) {
    if (this.map.studyLayer) {
    }

    if (this.map.studyLayer) {
      this.map.box.removeLayer(this.map.studyLayer);
    }

    this.map.studyLayer = L.geoJSON(list, {
      style: {
        weight: 2,
        opacity: 0.65,
        dashArray: '3',
        fillOpacity: 0
      }
    }).addTo(this.map.box);
  }

  drawCpsLayer(drawShape: boolean, list: any) {
    if (this.map.cpsLayer) {
      this.map.box.removeLayer(this.map.cpsLayer);
    }
    if (drawShape) {
      this.map.cpsLayer = L.geoJSON(list, {
        style: {
          weight: 2,
          opacity: 0.65,
          dashArray: '3',
          fillOpacity: 0,
          color: 'green'
        },
        onEachFeature: this.cpsFeatureStudy.bind(this)
      }).addTo(this.map.box);
      this.map.cpsLayer.on('mouseout', function(this, e) {
        e.layer.unbindTooltip();
      });
      this.map.cpsLayer.on('click', function(this, e) {
        this;
        e;
      });
    }
  }

  public cpsFeatureStudy(feature: any, layer: any) {
    const _this = this;
    layer.on('mouseover', function(e) {
      _this;
      e;
      let popup: any = L.popup()
        .setLatLng(e.latlng)
        .setContent(feature.properties.COD_POSTAL)
        .openOn(_this.map.box);
    });
  }
}
