import { Component } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import {
  PoiService,
  SidenavPoisLevelsService,
} from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-stage-transport',
  templateUrl: './stage-transport.component.html',
  styleUrls: ['./stage-transport.component.css'],
})
export class StageTransportComponent {
  accordionSetup = {
    title: 'Transporte',
    opened: true,
    closed: false,
    expanded: true,
  };
  transportList$ = this.sidenavPoisLevelsService.transportList$;
  constructor(
    private bricksApiService: BricksApiService,
    private poiService: PoiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService
  ) {
    this.poiService.getAreaTransport(this.bricksApiService.fullStages$.value);
  }
}
