<section>
  <div class="container">
    <p class="margin__top--s">
      <span class="material-icons primary">create</span>
      Dibuje con puntos sobre el mapa el área deseada para el estudio.
    </p>
  </div>
  <div class="container mt-2">
    <div fxFlex="50">
      <glo-cancel-study-button></glo-cancel-study-button>
    </div>
    <div fxFlex="50">
      <glo-create-study-button [disabled]="disabled"></glo-create-study-button>
    </div>
  </div>
</section>
