<div class='pois-searcher' [class.searching]='poisSearcherService.status$ | async' xmlns='http://www.w3.org/1999/html'>
  <div class='title'>
    <h3 class='text'>Buqueda avanzada</h3>
    <button
      mat-icon-button
      matTooltip='Cerrar buscador'
      matTooltipPosition='right'
      (click)='toggleSearcherStatus(false)'
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form (submit)='$event.preventDefault()'>
    <mat-form-field class='search-container' appearance='outline'>
      <mat-label>
        <mat-icon>search</mat-icon>
        Buscar POI
      </mat-label>
      <input
        #searchInput
        type='text'
        matInput
        [formControl]='searchFormControl'
        (focus)='toggleSearcherStatus(true)'
        (ngModelChange)='search()'
        placeholder='Buscar POI'
      >
      <mat-icon
        matSuffix
        style='cursor: pointer'
        matTooltip='Limpiar búsqueda'
        matTooltipPosition='right'
        *ngIf='searchFormControl.value'
        (click)="searchFormControl.setValue('')"
      >backspace
      </mat-icon>
    </mat-form-field>
  </form>

  <!-- LOADER -->
  <div class='search-loader' *ngIf='loading'>
    <mat-spinner mode='indeterminate'></mat-spinner>
    <small>Buscando POIs</small>
  </div>
  <!--/ LOADER -->

  <div class='options-wrapper' *ngIf='!loading'>
    <!-- SEARCH HINT -->
    <p class='hint' *ngIf='!searchFormControl.value?.trim().length'>
      <small>
        <strong>Nombre</strong> <br>
        Introduce el nombre del lugar para encontrar coincidencias en los POIs. <br>
        Por ejemplo: "Corte Inglés", "Metro Sol" o "Teatro Gran Vía"
      </small>
      <br><br>
      <small>
        <strong>Rating</strong> <br>
        Introduce la palabra clave "rating" seguido de un operador de comparación y el valor a comparar.<br>
        Por ejemplo: <br>
        - "rating=4" (sitios con 4 estrellas) <br>
        - "rating<=2" (sitios con 2 o menos estrellas)
      </small>
      <br><br>
      <small>
        <strong>Dirección</strong> <br>
        Añade al final de la búsqueda la palabra clave "en" seguido del nombre de la calle.<br>
        Por ejemplo: <br>
        - "corte inglés <strong>en Gran Vía</strong>" (corte inglés en la calle Gran Vía) <br>
        - "cine rating=2 <strong>en preciados</strong>" (cines con 2 estrellas en Preciados)
      </small>

      <!--/ SEARCH HINT -->

      <!-- NO RESULTS -->
    <p class='hint'
       *ngIf='searchFormControl.value?.trim().length && !foundOptions.length && !filteredCategories.length'>
      No se han encontrado resultados para <strong>"{{ searchFormControl.value }}"</strong>
    </p>
    <!--/ NO RESULTS -->

    <!-- CATEGORIES LIST -->
    <mat-selection-list *ngIf='filteredCategories.length' (selectionChange)='optionClicked($event)'>
      <div mat-subheader>Categorías</div>
      <mat-list-option
        *ngFor='let option of filteredCategories'
        [selected]='option.selected'
        [value]='option'
        matTooltipPosition='right'
        matTooltip="{{ option.selected ? 'Quitar' : 'Añadir' }} {{ option.totalPois ? option.totalPois + ' POIs de ' : '' }} {{ option.name }}"
      >
        <strong>
          <span [innerHTML]='getHighlightedText(option.name)'></span>
          <span *ngIf='option.totalPois'>({{ option.totalPois }})</span>
        </strong>
      </mat-list-option>
      <mat-divider></mat-divider>
    </mat-selection-list>
    <!--/ CATEGORIES LIST -->
    <!-- SUBCATEGORIES LIST -->
    <mat-selection-list *ngIf='filteredSubcategories.length' (selectionChange)='optionClicked($event)'>
      <div mat-subheader>Subcategorías</div>
      <mat-list-option
        *ngFor='let option of filteredSubcategories'
        [selected]='option.selected'
        [value]='option'
        matTooltipPosition='right'
        matTooltip="{{ option.selected ? 'Quitar' : 'Añadir' }} {{ option.totalPois ? option.totalPois + ' POIs de ' : '' }} {{ option.name }}"
      >
        <strong>
          <span [innerHTML]='getHighlightedText(option.name)'></span>
          <span *ngIf='option.totalPois'>({{ option.totalPois }})</span>
        </strong>
      </mat-list-option>
      <mat-divider></mat-divider>
    </mat-selection-list>
    <!--/ SUBCATEGORIES LIST -->

    <!-- POIS LIST -->
    <mat-selection-list *ngIf='foundOptions.length' (selectionChange)='optionClicked($event)'>
      <div mat-subheader>POIs
        <mat-checkbox id='checkAllPois' (change)='onChangeMasterToggleCheckbox($event)' [checked] = 'allPoisSelected()'
                      [indeterminate] = 'somePoisSelected()' color='warn'></mat-checkbox>
      </div>
      <mat-list-option
        *ngFor='let option of foundOptions'
        [selected]='option.selected'
        [value]='option'
        matTooltipPosition='right'
        matTooltip="{{ option.selected ? 'Quitar' : 'Añadir' }} '{{ option.properties?.nombre || option.name }}'"
      >
        <strong [innerHTML]='getHighlightedText(option.properties?.nombre || option.name)'></strong>
        <strong><small>{{ option.properties.category || '' }} <span
          *ngIf='option.properties.sub_categoria || option.properties.subcategory'> > {{ option.properties.sub_categoria || option.properties.subcategory }}</span>
        </small></strong>
        <small *ngIf='option.properties?.rating'>
          <strong class='total-reting'
                  [style.background]='getRatingColor(option.properties.rating)'>{{ option.properties.rating }}</strong>
          {{ poisSearcherService.getRatingStars(option?.properties?.ensenya_desc) }}
          <br>
        </small>
        <small
          *ngIf='option.properties?.direccion'
          [innerHTML]="getHighlightedText(option.properties?.direccion || '')">
        </small>
      </mat-list-option>
    </mat-selection-list>
    <!--/ POIS LIST -->
  </div>
</div>
