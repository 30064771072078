import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeoCoordsService {
  constructor() {}

  getFrom(study: any) {
    return `study_id=${study.properties.id}`;
  }
}
