import { Component, OnInit } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { marker } from 'leaflet';

@Component({
  selector: 'glo-subpois-loader',
  templateUrl: './subpois-loader.component.html',
  styleUrls: ['./subpois-loader.component.css'],
})
export class SubpoisLoaderComponent implements OnInit {
  subcategoriesList: any;
  activeTableRoot: string = 'pois';

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((tableRoot) => {
        this.activeTableRoot = tableRoot;
      });
    this.sidenavPoisLevelsService.getSubcategoryList().subscribe((d: any) => {
      this.subcategoriesList = d;
    });
  }

  ngOnInit(): void {}
}
