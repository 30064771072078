import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalMediaFormOptionsService, Options } from './local-media-form-options.service';
import { LocalMediaReshapeService } from '../local-media-reshape.service';

@Component({
  selector: 'compass-local-media-form',
  templateUrl: './local-media-form.component.html',
  styleUrls: ['./local-media-form.component.scss']
})
export class LocalMediaFormComponent implements OnInit {
  @Input() setup: Array<any>;
  mediaData: Array<any>;
  form: FormGroup;
  media_options: Array<Options>



  constructor(
    private localMediaFormOptionsService: LocalMediaFormOptionsService,
    private localMediaReshapeService: LocalMediaReshapeService
  ) { }

  onSubmit() {
    return false;
  }

  initForm() {
    this.form = new FormGroup({
      media: new FormControl(this.media_options[0].value)
    });
    this.form.valueChanges.subscribe(target => {
      this.localMediaReshapeService.reshape(this.setup, target['media']);
    })
  }


  initServices() {
    return false;
  }

  initOptions() {
    this.media_options = this.localMediaFormOptionsService.get('media_options');
  }

  ngOnInit(): void {
    this.initServices();
    this.initOptions();
    this.initForm();
    this.localMediaReshapeService.reshape(this.setup, 'internet');
  }

}
