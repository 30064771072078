import { Component, OnDestroy, OnInit } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import { IndicatorService } from '@compass/utils/navigation';
import { SideNavIndicatorsService } from '@compass/utils/navigation';
import { PoiService } from '@compass/pois/data-access-poi';
import { Subscription } from 'rxjs';
import { StudyService } from '@compass/studies/data-access-study';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';

@Component({
  selector: 'glo-stage-analytics',
  templateUrl: './stage-analytics.component.html',
  styleUrls: ['./stage-analytics.component.scss']
})
export class StageAnalyticsComponent implements OnInit, OnDestroy {
  indicatorsList$ = this.indicatorService.indicatorsList$;
  indicatorsGroups$ = this.sideNavIndicatorsService.indicatorsGroups$;
  bricks = undefined;
  isChecked = false;
  selectedTab = 0; // selected tab index used to rerender the components with *ngIf directive

  categoriesSubscription: Subscription;

  constructor(
    private indicatorService: IndicatorService,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private bricksApiService: BricksApiService,
    private poiService: PoiService,
    private studyService: StudyService,
    private compassMapIndicatorsService: CompassMapIndicatorsService
  ) {
    // loads pois in brick
    this.poiService.getAreaGlobal(this.bricksApiService.fullStages$.value);
    this.bricks = this.indicatorService.getBricksWithPoiCategoriesData(this.poiService.categoriesList$.value);

    this.categoriesSubscription = this.poiService.categoriesList$.subscribe((categories) => {
      if (categories?.length && this.bricksApiService.bricks$.value.length) {
        this.categoriesSubscription?.unsubscribe();

        this.bricks = this.indicatorService.getBricksWithPoiCategoriesData(categories);
        this.loadStoredAnalytics();
      }
    });
  }

  ngOnDestroy() {
    this.categoriesSubscription?.unsubscribe();

    // restet the dictionary withouth pois
    // const actualDictionary: DictionaryEntryInterface[] = this.sideNavIndicatorsService.indicatorsDictionary$.value;
    // this.sideNavIndicatorsService.parseGeoindicators(actualDictionary.filter(dic => dic.grupo !== 'pois'));
  }

  loadStoredAnalytics() {
    const analyticsData = this.studyService.study$.value?.properties?.analytics;

    // extract from study old analytics data
    if (analyticsData) {
      if (analyticsData.indicatorsList) {
        this.indicatorService.setAnalyticsIndicators(
          analyticsData.indicatorsList,
          this.sideNavIndicatorsService.indicatorsDictionary$.value
        );
      }

      if (analyticsData.activeIndicator) {
        this.indicatorService.setActiveIndicator(analyticsData.activeIndicator);

        const indicators = this.indicatorService.indicatorsList$.value;
        const excludedBricks = this.bricks.filter((brick) => brick.properties.excluded).map((brick) => brick.properties.id);

        // draw on map each one to avoid shitty exception
        indicators.forEach((ind) => {
          this.compassMapIndicatorsService.filterGeojsonLayer(ind, indicators, excludedBricks);
        });

        // draw the active one
        this.compassMapIndicatorsService.filterGeojsonLayer(analyticsData.activeIndicator, indicators, excludedBricks);
      }

    }
  }

  removeAllIndicators() {
    this.indicatorService.indicatorsList$.value.forEach(indicator => {
      setTimeout(() => {
        this.remove(indicator);
      });
    });
  }

  remove(indicator) {
    this.indicatorService.removeIndicatorFromList(indicator);
    // if removed active indicator, select another one
    if (indicator.key === this.indicatorService.activeIndicator$.value?.key) {
      this.indicatorService.activeIndicator$.next(
        this.indicatorService.indicatorsList$.value.at(-1)
      );
    }
  }

  ngOnInit(): void {
    this.sideNavIndicatorsService.getGeoIndicatorsApi();
  }
}
