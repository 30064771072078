import { Component, OnInit } from '@angular/core';
import { EndRightSidenavService } from '../end-right-sidenav.service'

@Component({
  selector: 'compass-end-right-button-close',
  templateUrl: './end-right-button-close.component.html',
  styleUrls: ['./end-right-button-close.component.scss']
})
export class EndRightButtonCloseComponent implements OnInit {

  constructor(private endRightSidenavService: EndRightSidenavService) { }

  closeEndRightSidenav(e) {
    e.preventDefault();
    this.endRightSidenavService.setSidenavStatus(false);

  }
  ngOnInit(): void {
  }

}
