import { Component, OnInit } from '@angular/core';
import { Toolbar } from '@compass/page-structure/top-toolbar-wrapper';
import { BehaviorSubject } from 'rxjs';
import { CrudMenuService, Cruds } from '@compass/utils/navigation';
import { PoiBasketService } from '../../../../../../libs/shared/poi-basket/src/lib/poi-basket.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'glo-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent implements OnInit {
  crudMenuList$: BehaviorSubject<Cruds> = this.crudMenuService.crudMenuList$;

  toolbarSetUp: Toolbar = {
    title: environment.title,
    style: {
      height: '40px'
    },
    class: 'mat-toolbar primary-deep--bkg deep-color',
    img: {
      class: 'trademark',
      src: 'assets/img/brand/logo-white.svg',
      alt: environment.title,
      width: '120'
    }
  };

  constructor(
    private crudMenuService: CrudMenuService,
    public poiBasketService: PoiBasketService
  ) {
    this.crudMenuService.getCrudApi();
  }

  ngOnInit(): void {
  }

  toggleBasket() {
    this.poiBasketService.toggle();
  }
}

