import {Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {PoisComparatorService} from '@compass/pois/comparator';
import {Subscription} from 'rxjs';
import {Card, PresenceService} from '../presence.service';
import {PoiInterface} from '@compass/pois/data-access-poi';

@Component({
  selector: 'compass-pois-comparator',
  templateUrl: './pois-comparator.component.html',
  styleUrls: ['./pois-comparator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PoisComparatorComponent implements OnDestroy {
  public selectedPois: PoiInterface[] = [];
  public cards: Card[] = [];

  public compareMode: boolean;

  private selectedSub?: Subscription;
  private modeSub?: Subscription;

  @ViewChild('header') header: ElementRef;
  public headerPosition: any;
  public isSticky: boolean;

  constructor(
    public poisComparatorService: PoisComparatorService,
    public prsenceService: PresenceService
  ) {
    this.isSticky = false;
    this.compareMode = false
    this.selectedSub = this.poisComparatorService.selected$.subscribe((selected) => {
      if (this.poisComparatorService.compareMode$.value) {
        if (selected.length === 1) {
          this.poisComparatorService.stopComparation();
        }
      }
      this.selectedPois = selected;
      this.cards = this.prsenceService.getCards(selected);
    });

    this.modeSub = this.poisComparatorService.compareMode$.subscribe((active) => {
      this.compareMode = active;
      if (active) {

        setTimeout(() => this.headerPosition = this.header?.nativeElement?.getBoundingClientRect().y + 10, 500);
      }
    });
  }

  ngOnDestroy() {
    this.selectedSub?.unsubscribe();
    this.modeSub?.unsubscribe();
  }


  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (this.poisComparatorService.compareMode$.value && this.headerPosition) {
      this.isSticky = window.scrollY >= this.headerPosition;
    } else {
      this.isSticky = false;
    }
  }

  public removeComparation() {
    this.poisComparatorService.selected$.next([]);
    this.poisComparatorService.stopComparation();
  }


}
