<div [@localKnowledgeWrapper]="this.bottomContainerService.relativePos$.value.toString()"
     class="local-knowledge-loader-wrapper">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingFilters"></mat-progress-bar>

  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div>
      <button
        mat-icon-button
        [title]="'Minimizar'"
        *ngIf="this.bottomContainerService.visible$.value"
        (click)="expandCalendar()"
      >
        <mat-icon class="mr-0">assignment_return</mat-icon>
      </button>
    </div>
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es-ES' }}

    </h3>
    <div>
      <button *ngIf="!this.bottomContainerService.visible$.value" [title]="'Maximizar'" mat-icon-button
              (click)="expandCalendar()">
        <mat-icon class="mr-0">open_in_new</mat-icon>
      </button>
      <button *ngIf="this.bottomContainerService.visible$.value"
              [title]="this.bottomContainerService.relativePos$.value ?'Ver eventos': 'Ver mapa'" mat-icon-button
              (click)="this.bottomContainerService.toggleRelativePos()">
        <mat-icon
          class="mr-0">{{this.bottomContainerService.relativePos$.value ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </div>
  </div>

  <mat-form-field class="p-3">


    <mat-label>Categorías</mat-label>
    <mat-select [formControl]="categoryFilterSelect" multiple (selectionChange)="refreshData()">
      <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">Todos</mat-option>
      <mat-option *ngFor="let option of categories"
                  [value]="option.key"
                  style="font-weight: bold;"
                  [style.color]="option?.color"
                  (click)="togglePerOne()">{{option.title}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="space-around center">
    <button
      mat-icon-button
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="reloadActiveDate()"
    >
      <mat-icon class="mr-0">chevron_left</mat-icon>
    </button>
    <button
      mat-button
      class="btn btn-outline-secondary"
      mwlCalendarToday
      [(viewDate)]="viewDate"
      (viewDateChange)="reloadActiveDate()"
    >
      Hoy
    </button>
    <button
      mat-icon-button
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="reloadActiveDate()"

    >
      <mat-icon class="mr-0">chevron_right</mat-icon>
    </button>
  </div>

  <br />
  <div>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Calendario">
        <mwl-calendar-month-view
          (dayClicked)="dayClicked($event.day)"
          [activeDayIsOpen]="dayDetailsOpened"
          [viewDate]="viewDate"
          [events]="this.information"
          [headerTemplate]="header"
          [openDayEventsTemplate]="customOpenDayEventsTemplate"
          (eventClicked)="showDetails($event.event)"
          weekStartsOn="1"
        ></mwl-calendar-month-view>
      </mat-tab>
      <mat-tab label="Lista">
        <div mat-subheader fxLayout="row" fxLayoutAlign="center center">

          <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
            <mat-button-toggle (click)="sortInformation('date')" value="date" title="Ordenar por Fecha">
              Fecha
            </mat-button-toggle>
            <mat-button-toggle (click)="sortInformation('attendance')" value="attendance"
                               title="Ordenar por Asistencia">
              Asistencia
            </mat-button-toggle>
            <mat-button-toggle (click)="sortInformation('location')" value="attendance" title="Ordenar por Proximidad">
              Proximidad
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <mat-list>
          <div>
            <mat-list-item *ngIf="!information.length">
              <div mat-line class="aligncenter">Eventos</div>
            </mat-list-item>
            <div fxLayout="row wrap" fxLayout.xs="column wrap" *ngIf="this.information">
              <mat-list-item fxFlex.gt-xs="100%" [fxFlex.gt-md]="regularDistribution"
                             *ngFor="let info of this.information"
                             (mouseover)="toggleActive(info, true)"
                             (mouseout)="toggleActive(info, false)"
                             (click)="flyto(info)"
              >
                <button mat-icon-button (click)="flyto(info)" *ngIf="info.categoria === 'predicthq'">
                  <mat-icon class="mr-0" mat-list-icon>map</mat-icon>
                </button>
                <button mat-icon-button (click)="showDetails(info)" *ngIf="info.categoria === 'predicthq'">
                  <mat-icon class="mr-0" mat-list-icon>visibility</mat-icon>
                </button>

                <div mat-line [title]="info.descripcion || info.title">
                  <strong>{{ info.descripcion || info.title }}</strong>
                </div>
                <div mat-line [title]="info.fecha | date">
                  {{ info.fecha | date }}
                </div>
                <small mat-line *ngIf="info.categoria === 'predicthq' && info.entities.length"
                       [title]="info.entities[0].formatted_address">
                  {{ info.entities[0].formatted_address}}
                </small>
                <small mat-line *ngIf="info.phq_attendance" title="Predicción de asistentes"
                       [style.color]="info.color || null">
                  {{ info.phq_attendance }} asistentes
                </small>

              </mat-list-item>
            </div>
          </div>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>


  <ng-template #header>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <h3>L</h3>
      <h3>M</h3>
      <h3>X</h3>
      <h3>J</h3>
      <h3>V</h3>
      <h3>S</h3>
      <h3>D</h3>
    </div>
  </ng-template>

  <ng-template
    #customOpenDayEventsTemplate
    let-events="events"
    let-isOpen="isOpen">

    <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
      <div *ngFor="let event of events" [ngClass]="event?.cssClass">
        <span class="cal-event" [style.backgroundColor]="event.color?.primary"></span>
        <mwl-calendar-event-title
          [event]="event"
          (mouseover)="toggleActive(event, true)"
          (mouseout)="toggleActive(event, false)"
          (mwlClick)="showDetails(event)"
          view="month">
        </mwl-calendar-event-title>
      </div>
    </div>

  </ng-template>

  <ng-template #eventTitleTemplate
               let-events="events"
               let-isOpen="isOpen">

    <!-- The [@collapse] is causing the error. -->
    <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
      <div *ngFor="let event of events" [ngClass]="event?.cssClass">
        <span class="cal-event" [style.backgroundColor]="event.color?.primary"></span>
        <mwl-calendar-event-title [event]="event"
                                  view="month"
        >
        </mwl-calendar-event-title>
      </div>
    </div>

  </ng-template>
</div>
