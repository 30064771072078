import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoisExtendedDataPreviewComponent } from './pois-extended-data-preview/pois-extended-data-preview.component';
import { UtilsD3Module } from '@compass/utils/d3';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { PopularTimeComponent } from './components/popular-time/popular-time.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PopularTimesModule } from '@compass/popular-times';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule,
    UtilsD3Module,
    MatSelectModule,
    ReactiveFormsModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    PopularTimesModule, MatButtonModule, MatTooltipModule],
  declarations: [PoisExtendedDataPreviewComponent, PopularTimeComponent],
  exports: [PoisExtendedDataPreviewComponent, PopularTimeComponent]
})
export class PoisExtendedDataPreviewModule {

}
