import { Component, OnInit } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
@Component({
  selector: 'glo-companies-sidenav',
  templateUrl: './companies-sidenav.component.html',
  styleUrls: ['./companies-sidenav.component.css'],
})
export class CompaniesSidenavComponent implements OnInit {
  activeTableRoot: string = 'pois';

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    this.sidenavPoisLevelsService.getActiveTableRoot().subscribe((value) => {
      this.activeTableRoot = value;
    });
  }

  ngOnInit(): void {}
}
