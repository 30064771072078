import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';


@Injectable({ providedIn: 'root' })
export class BusinessViewerService {

  private stage: any = {
    key: 'filter',
    name: 'Fitros',
    icon: 'filter_alt',
    active: true,
    path: '/business'
  };
  public mapIsOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public activeStage$: BehaviorSubject<any> = new BehaviorSubject<any>(this.stage);

  constructor(public http: HttpClient) {
  }

  // map observables
  setMapIsOn(bool) {
    if (bool !== this.mapIsOn$.value) {
      this.mapIsOn$.next(bool);
    }
  }

  getMapIsOn(): Observable<boolean> {
    return this.mapIsOn$.asObservable();
  }

  setActiveStage$(stage: any) {
    if (stage.key !== this.activeStage$.value.key) {
      this.activeStage$.next(stage);
    }
  }

  getActiveStage$(): Observable<any> {
    return this.activeStage$.asObservable();
  }

}
