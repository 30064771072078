<ng-container *ngIf="active">
  <section>
    <p *ngIf="basketEmpty">Ningún elemento añadido al plan.</p>
  </section>

  <ng-container *ngIf="!basketEmpty">
    <div class="toggle-wrapper">
      <mat-slide-toggle
        [checked]="this.showPlanOnly"
        (toggleChange)="this.poiBasketService.showPlanElementsOnly$.next(!this.showPlanOnly)"
        [title]="showPlanOnly ? 'Mostrar elementos del plan': 'Mostrar todo'"
      >
        Mostrar solo elementos del plan
      </mat-slide-toggle>
    </div>
    <mat-list *ngIf="basket" class="basket-list">
      <ng-container *ngFor="let category of basket | keyvalue">
        <div mat-subheader class="section-header" (click)="console(category)">
          {{ category.key === this.poiBasketService.POI ? 'POIs' : '' }}
          {{ category.key === this.poiBasketService.BRICK ? 'Bricks' : '' }}
          {{ category.key === this.poiBasketService.PATRIMONY ? 'Patrimonios' : '' }}
          {{ category.key === this.poiBasketService.EVENT ? 'Eventos' : '' }}
        </div>
        <mat-divider></mat-divider>

        <mat-list-item *ngFor="let poi of category.value">
          <!-- POI -->
          <ng-container *ngIf="category.key === this.poiBasketService.POI">
            <div mat-line [title]="poi.properties.nombre" class="title">
              <div>
                <mat-icon *ngIf="poi.properties.categoria !== 'Transporte'">place</mat-icon>
                <mat-icon *ngIf="poi.properties.key_sub_categoria === 'estacion_de_tren'">train</mat-icon>
                <mat-icon *ngIf="poi.properties.key_sub_categoria === 'parada_bus'">directions_bus</mat-icon>
              </div>
              <div>
                {{ poi.properties.nombre }} <br>
                <small [title]="poi.properties.direccion">{{ poi.properties.direccion }}</small>
              </div>
            </div>
          </ng-container>
          <!--/ POI -->

          <!-- BRICK -->
          <ng-container *ngIf="category.key === this.poiBasketService.BRICK">
            <div mat-line [title]="'Brick ' + poi.properties.id" class="title">
              <mat-icon>bar_chart</mat-icon>
              {{'Brick ' + poi.properties.id }}
            </div>
          </ng-container>
          <!--/ BRICK -->

          <!-- HERITAGE -->
          <ng-container *ngIf="category.key === this.poiBasketService.PATRIMONY">
            <div mat-line [title]="poi.properties.nombre" class="title">
              <mat-icon>sms</mat-icon>
              <div>
                {{ poi.properties.nombre }} <br>
                <small [title]="poi.properties.direccion">{{ poi.properties.direccion }}</small>
              </div>
            </div>
          </ng-container>
          <!--/ HERITAGE -->

          <ng-container *ngIf="category.key === this.poiBasketService.EVENT">
            <div mat-line [title]="poi.properties.nombre" class="title">
              <mat-icon>sms</mat-icon>
              <div>
                {{ poi.properties.nombre }} <br>
                <small [title]="poi.properties.direccion">{{ poi.properties.direccion }}</small>
              </div>
            </div>
          </ng-container>

          <button
            mat-icon-button
            (click)="this.removeFromBasket(poi)"
            [title]="'Eliminar ' + poi.name + ' del plan'"
            class="delete-btn"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-list-item>
      </ng-container>

      <!-- HOURS -->
      <ng-container *ngIf="hours?.length">
        <div mat-subheader class="section-header">
          Horas seleccionadas
        </div>
        <ng-container *ngFor="let hour of hours">
          <mat-list-item>
            <div mat-line class="title" [title]="hour">
              {{ hour }}
            </div>

            <button
              mat-icon-button class="delete-btn"
              (click)="poiBasketService.removeHour(hour)"
              [title]="'Eliminar hora del plan'"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </ng-container>
      </ng-container>
      <!--/ HOURS -->
    </mat-list>

  </ng-container>

  <div class="actions">
    <button mat-flat-button
            class="primary"
            title="Guardar el plan"
            [disabled]="loading"
            (click)="this.saveBasket()"
    >
      <mat-icon>save</mat-icon>
      Guardar
    </button>

    <button mat-flat-button
            class="secondary"
            title="Descargar CSV"
            [disabled]="loading"
            (click)="this.downloadCsv()"
    >
      <mat-icon>download</mat-icon>
      CSV
    </button>
  </div>


  <div class="loader" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
