<section>
  <div class="container" *ngIf="activeStage$ | async as activeStage">
    <nav>
      <glo-stage-indicators *ngIf="activeStage.key === 'indicators'"></glo-stage-indicators>
      <glo-stage-transit *ngIf="activeStage.key === 'transit'"></glo-stage-transit>
      <glo-stage-traffic-flow *ngIf="activeStage.key === 'traffic-flow'"></glo-stage-traffic-flow>
      <glo-stage-egm *ngIf="activeStage.key === 'egm'"></glo-stage-egm>
      <glo-stage-pois *ngIf="activeStage.key === 'global_pois'"></glo-stage-pois>
      <glo-stage-local-pois *ngIf="activeStage.key === 'local_pois'"></glo-stage-local-pois>
      <glo-stage-local-media *ngIf="activeStage.key === 'local_media'"></glo-stage-local-media>
      <glo-stage-local-knowledge *ngIf="activeStage.key === 'local_knowledge'"></glo-stage-local-knowledge>
      <glo-stage-transport *ngIf="activeStage.key === 'transport'"></glo-stage-transport>
      <glo-stage-heritage *ngIf="activeStage.key === 'heritage'"></glo-stage-heritage>
      <glo-stage-housing *ngIf="activeStage.key === 'real_state'"></glo-stage-housing>
      <glo-stage-cadastre *ngIf="activeStage.key === 'cadastre'"></glo-stage-cadastre>
      <glo-stage-analytics *ngIf="activeStage.key === 'analytics'"></glo-stage-analytics>
      <glo-stage-weather *ngIf="activeStage.key === 'weather'"></glo-stage-weather>
      <glo-stage-influencers *ngIf="activeStage.key === 'influencers'"></glo-stage-influencers>
      <glo-stage-mobility *ngIf="activeStage.key === 'mobility'"></glo-stage-mobility>
      <glo-stage-popular-times *ngIf="activeStage.key === 'popular_time'"></glo-stage-popular-times>
      <lab-business *ngIf="activeStage.key === 'business'"></lab-business>

    </nav>
  </div>
</section>
