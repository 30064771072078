import { Injectable } from '@angular/core';

export interface Key {
  style: string;
  key: number;
  relation_to_speed: string;
  unit: string;
  intervals: string;
  intervals_es: string;
  line_outline_colors: string;
  line_main_colors: string;
  dash_colors: string;
}
export interface Style {
  value: string;
  name: string;
  children: Array<Key>;
  description: string;

}

@Injectable({ providedIn: 'root' })
export class TomtomStylesService {
  constructor() { }

  styles = {
    traffic_flow: [{
      "value": "absolute", "name": "Absoluta", "children": [
        { "style": "absolute", "key": 0, "relation_to_speed": "absolute", "unit": "Velocidad absoluta (km/h)", "intervals": "0 ≤ speed < 1", "intervals_es": "Entre 0 y 0,9 km/h", "line_outline_colors": "#3C3C3C", "line_main_colors": "#777777", "dash_colors": "No dashes" },
        { "style": "absolute", "key": 1, "relation_to_speed": "absolute", "unit": "Velocidad absoluta (km/h)", "intervals": "1 ≤ speed < 60", "intervals_es": "Entre 1 y 59  km/h", "line_outline_colors": "#801212", "line_main_colors": "#FF2323", "dash_colors": "No dashes" },
        { "style": "absolute", "key": 2, "relation_to_speed": "absolute", "unit": "Velocidad absoluta (km/h)", "intervals": "60 ≤ speed < 120", "intervals_es": "Entre 60 y 119 km/h", "line_outline_colors": "#80801C", "line_main_colors": "#FFFF37", "dash_colors": "No dashes" },
        { "style": "absolute", "key": 3, "relation_to_speed": "absolute", "unit": "Velocidad absoluta (km/h)", "intervals": "120 ≤ speed", "intervals_es": "Más de 119 km/h", "line_outline_colors": "#166416", "line_main_colors": "#2BC82B", "dash_colors": "No dashes" }], "description": "Cuando el estilo es absoluto, los colores reflejan la velocidad absoluta medida en el segmento de carretera dado. Se recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    },
    {
      "value": "relative", "name": "Relativa", "children": [{ "style": "relative", "key": 0, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0 ≤ speed < 0.01", "intervals_es": "Entre 0 y 0,01", "line_outline_colors": "#3C3C3C", "line_main_colors": "#777777", "dash_colors": "No dashes" },
      { "style": "relative", "key": 1, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.01 ≤ speed < 0.8", "intervals_es": "Entre 0,01 y 0,7", "line_outline_colors": "#801212", "line_main_colors": "#FF2323", "dash_colors": "No dashes" },
      { "style": "relative", "key": 2, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.8 ≤ speed < 1", "intervals_es": "Entre 0,8 y 0,9", "line_outline_colors": "#80801C", "line_main_colors": "#FFFF37", "dash_colors": "No dashes" },
      { "style": "relative", "key": 3, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "speed = 1", "intervals_es": "1", "line_outline_colors": "#166416", "line_main_colors": "#2BC82B", "dash_colors": "No dashes" }], "description": "Las llamadas que usan los estilos relativo, relativo0 y relativo0 oscuro devuelven la velocidad relativa al flujo libre, destacando las áreas de congestión. \nSe recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    },
    {
      "value": "relative0", "name": "Relativa 0", "children": [{ "style": "relative0", "key": 0, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "road closed", "intervals_es": "Carretera cerrada", "line_outline_colors": "#666666", "line_main_colors": "#C1272D", "dash_colors": "#F2F2F2" },
      { "style": "relative0", "key": 1, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0 ≤ speed < 0.15", "intervals_es": "De 0 a 0,14", "line_outline_colors": "#A50704", "line_main_colors": "#E70704", "dash_colors": "#CC0704" },
      { "style": "relative0", "key": 2, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.15 ≤ speed < 0.35", "intervals_es": "De 0,15 a 0,34", "line_outline_colors": "#DF4B15", "line_main_colors": "#F18237", "dash_colors": "#EC6736" },
      { "style": "relative0", "key": 3, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.35 ≤ speed < 0.75", "intervals_es": "De 0,35 a 0,74", "line_outline_colors": "#E87B3D", "line_main_colors": "#F1BF40", "dash_colors": "#F1A828" },
      { "style": "relative0", "key": 4, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.75 ≤ speed", "intervals_es": "Más de 0,75", "line_outline_colors": "#245723", "line_main_colors": "#2EAB30", "dash_colors": "No dashes" }], "description": "Las llamadas que usan los estilos relativo, relativo0 y relativo0 oscuro devuelven la velocidad relativa al flujo libre, destacando las áreas de congestión. Se recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    },
    {
      "value": "relative0-dark", "name": "Realtiva 0 Oscura", "children": [{ "style": "relative0-dark", "key": 0, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "road closed", "intervals_es": "Carretera cerrada", "line_outline_colors": "#474747", "line_main_colors": "#861B1F", "dash_colors": "#A8A8A8" },
      { "style": "relative0-dark", "key": 1, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0 ≤ speed < 0.15", "intervals_es": "De 0 a 0,14", "line_outline_colors": "#730402", "line_main_colors": "#A10402", "dash_colors": "#8E0402" },
      { "style": "relative0-dark", "key": 2, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.15 ≤ speed < 0.35", "intervals_es": "De 0,15 a 0,34", "line_outline_colors": "#9B340E", "line_main_colors": "#A85A26", "dash_colors": "#A54825" },
      { "style": "relative0-dark", "key": 3, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.35 ≤ speed < 0.75", "intervals_es": "De 0,35 a 0,74", "line_outline_colors": "#A2562A", "line_main_colors": "#A9862C", "dash_colors": "#A9751C" },
      { "style": "relative0-dark", "key": 4, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.75 ≤ speed", "intervals_es": "Más de 0,75", "line_outline_colors": "#193C18", "line_main_colors": "#207721", "dash_colors": "No dashes" }], "description": "Las llamadas que usan los estilos relativo, relativo0 y relativo0 oscuro devuelven la velocidad relativa al flujo libre, destacando las áreas de congestión. Se recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    },
    {
      "value": "relative-delay", "name": "Relativa sólo retrasos", "children": [{ "style": "relative-delay", "key": 0, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0 ≤ speed < 0.01", "intervals_es": "Entre 0 y 0,01", "line_outline_colors": "#3C3C3C", "line_main_colors": "#777777", "dash_colors": "No dashes" },
      { "style": "relative-delay", "key": 1, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.01 ≤ speed < 0.8", "intervals_es": "Entre 0,01 y 0,7", "line_outline_colors": "#801212", "line_main_colors": "#FF2323", "dash_colors": "No dashes" },
      { "style": "relative-delay", "key": 2, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.08 ≤ speed < 1", "intervals_es": "Entre 0,8 y 0,9", "line_outline_colors": "#80801C", "line_main_colors": "#FFFF37", "dash_colors": "No dashes" },
      { "style": "relative-delay", "key": 3, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "speed = 1", "intervals_es": "1", "line_outline_colors": "#166416", "line_main_colors": "#2BC82B", "dash_colors": "No dashes" }], "description": "Muestra velocidades relativas solo donde son diferentes de la velocidad de flujo libre (sin segmentos verdes). \nSe recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    },
    {
      "value": "reduced-sensitivity", "name": "Sensibilidad reducida", "children": [{ "style": "reduced-sensitivity", "key": 0, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0 ≤ speed < 0.01", "intervals_es": "Entre 0 y 0,01", "line_outline_colors": "#3C3C3C", "line_main_colors": "#777777", "dash_colors": "No dashes" },
      { "style": "reduced-sensitivity", "key": 1, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.01 ≤ speed < 0.53", "intervals_es": "Entre 0,01 y 0,52", "line_outline_colors": "#801212", "line_main_colors": "#FF2323", "dash_colors": "No dashes" },
      { "style": "reduced-sensitivity", "key": 2, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.53 ≤ speed < 0.67", "intervals_es": "Entre 0,53 y 0,67", "line_outline_colors": "#80801C", "line_main_colors": "#FFFF37", "dash_colors": "No dashes" },
      { "style": "reduced-sensitivity", "key": 3, "relation_to_speed": "relative to free-flow", "unit": "Velocidad relativa al flujo", "intervals": "0.67 ≤ speed", "intervals_es": "Más de 0,67", "line_outline_colors": "#166416", "line_main_colors": "#2BC82B", "dash_colors": "No dashes" }], "description": "Muestra velocidades relativas de sensibilidad reducida, pero se requiere una mayor diferencia con el flujo libre para que el segmento cambie el color. \nSe recomienda utilizar los estilos relativo0 y relativo0-oscuro."
    }]
  }


  public get(style: string): Array<Style> {
    if (style === 'traffic_flow') {
      return this.styles.traffic_flow;
    }
    return [];
  }
}
