/**
 * Generates a slugified text.
 *
 * @param text
 */
export function slugify(text) {
  let response = null;

  if (text) {
    response = text
      .toString()
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '_')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '_');
  }

  return response;
}
