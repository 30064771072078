import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'compass-business-sidenav',
  templateUrl: './business-sidenav.component.html',
  styleUrls: ['./business-sidenav.component.scss']
})
export class BusinessSidenavComponent {

  constructor() { }



}
