import { Component, OnInit, Input } from '@angular/core';
import { BusinessViewerService } from '../business-viewer.service';

@Component({
  selector: 'compass-business-menu',
  templateUrl: './business-menu.component.html',
  styleUrls: ['./business-menu.component.scss']
})

export class BusinessMenuComponent {
  @Input() setup: any[];


  constructor(
    private businessViewerService: BusinessViewerService,
  ) {
  }

  openLateralSidenav(e) {
    //this.StartTightSidenavService.setIsOpen(true);
    return false;
  }

  goTo(event, stage) {
    event.stopPropagation();
    event.preventDefault();
    this.businessViewerService.setActiveStage$(stage);

    switch (stage.key) {
      case 'filter': {
        this.openLateralSidenav(event);

        break;
      }
      case 'map': {
        this.businessViewerService.setMapIsOn(true);

        break;
      }
      case 'table': {
        this.businessViewerService.setMapIsOn(false);
        break;
      }
      case 'indicators': {
        this.businessViewerService.setMapIsOn(true);

        break;
      }
      default: {
        return false;
      }
    }
    return false;
  }


}


