import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { CategoryService } from '@compass/categories/data-access-category';
import { catchError, map } from 'rxjs/operators';
import { SidenavPoisApiService } from './sidenav-pois-api.service';
import { SidenavPoisLevelsService } from './sidenav-pois-levels.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

export interface PoiDb {
  type: string;
  properties: PoiProperty;
  geometry: PoiGeometry;
}

export interface PoiProperty {
  id: string;
  class_: string;
  id_alimarket: null;
  categoria: string;
  id_categoria: number;
  key_categoria: string;
  sub_categoria: string;
  key_sub_categoria: string;
  nombre: string;
  direccion: string;
  id_cc: null;
  nombre_cc: null;
  sup_m2: null;
  comentarios: string;
}

export interface PoiGeometry {
  type: string;
  coordinates: number[];
}

export interface PoiForm {
  key_sub_categoria: string;
  nombre: string;
  direccion: string;
  comentarios: string;
  x: number;
  y: number;
}

@Injectable()
export class PoiService {
  private fileUploadUrl: string = '/abacusvalidator/file_val/glocally/collection/point_poi/upload/';
  private poisUrl = '/abacusgis/core/glocally/collection/point_poi/';
  public pois$ = new BehaviorSubject(undefined);
  public poi$ = new BehaviorSubject(undefined);
  categories$ = this.categoryService.categories$;
  public studyPois$ = new BehaviorSubject(undefined);
  categoriesList$ = this.sidenavPoisLevelsService.categoriesList$;
  transportList$ = this.sidenavPoisLevelsService.transportList$;
  localCategoriesList$ = this.sidenavPoisLevelsService.localCategoriesList$;

  totalCount: number = 0;
  limit = 500;
  skip = 0;

  public selectedPoi$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private categoryService: CategoryService,
    private sidenavPoisApiService: SidenavPoisApiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private geoCoordsService: GeoCoordsService
  ) {
  }


  getAll(clearBefore = true): Observable<any> {
    if (clearBefore) {
      this.pois$.next(undefined);
    }

    return new Observable((subscriber: Subscriber<any>) => {
      let countSubscriber = this.getCount();
      if (this.totalCount) {
        countSubscriber = new Observable<any>((subscriber) => {
          subscriber.next(this.totalCount);
        });
      }

      // count all elements
      countSubscriber.subscribe((res) => {
        this.totalCount = res;
        // load data while totalCount is lower than skip
        this.http
          .get(`${this.poisUrl}?query={"properties.id_categoria": 20}&skip=${this.skip}&limit=${this.limit}`)
          .pipe(
            map((pois: PoiDb[]) => pois.map((p: any) => {
              const poi = p.properties;
              return {
                ...poi, ...{
                  id: poi.id,
                  class_: poi.class_,
                  categoria: poi.categoria ?? poi.category,
                  id_categoria: poi.id_categoria,
                  key_categoria: poi.key_categoria,
                  sub_categoria: poi.sub_categoria,
                  key_sub_categoria: poi.key_sub_categoria,
                  nombre: poi.nombre,
                  direccion: poi.direccion,
                  comentarios: poi.comentarios
                }
              };
            }))
          ).subscribe((mappedPois: PoiProperty[]) => {
          let stop = false;

          // if not recieved data then stop the execution
          if (mappedPois.length) {
            const actualPois = this.pois$.value ?? [];
            this.pois$.next(actualPois.concat(mappedPois));
            this.skip += this.limit;
            // if limit is initial value
            this.limit = this.limit === 500 ? 12000 : this.limit;

            subscriber.next(this.pois$.value);
            // while not passed total elements, search agains
            if (this.skip < (this.totalCount + this.limit)) {
              const anotherAllRequest = this.getAll(false).subscribe(() => {
                anotherAllRequest.unsubscribe();
              });
            } else {
              stop = true;
            }
          } else {
            stop = true;
          }

          if (stop) {
            subscriber.next(this.totalCount);
            subscriber.complete();
            this.skip = 0;
            this.totalCount = 0;
            this.limit = 500;
          }
        });
      });
    });
  }

  getCount() {
    return this.http
      .get(`${this.poisUrl}?query={"properties.id_categoria": 20}&count=true`)
      .pipe(map((res: any) => this.totalCount = res?.count));
  }

  getById(id: string | any[]) {
    this.poi$.next(undefined);

    return this.http
      .get(
        `${this.poisUrl}?query={"properties.id": {"$in":${JSON.stringify([
          id
        ])}} }`
      )
      .pipe(
        map((pois: PoiDb[]) => {
          let poi = null;

          if (pois.length) {
            poi = pois?.shift();
            poi.name = poi.properties?.nombre;
            this.poi$.next(poi);
          }

          return poi;
        })
      );
  }

  /**
   * Returns the POIs which uses the passed keySubCategory.
   *
   * @param keySubCategory
   * @param excludeDeleted
   */
  getBySubCategoryKey(keySubCategory: string, excludeDeleted: boolean = true) {
    let query = `"properties.key_sub_categoria":"${keySubCategory}"`;

    if (excludeDeleted) {
      query += `,"properties.remove":{"$exists": false}`;
    }

    return this.http
      .get(`${this.poisUrl}?query={${query}}`)
      .pipe(map((pois: PoiDb[]) => pois));
  }

  /**
   * Get an array of all used categories in all the POIs (including deleted ones)
   */
  getUsedCategories(): Observable<any> {
    return this.http.get(
      `${this.poisUrl}?distinct=properties.key_sub_categoria`
    );
  }

  buildPoi(formValues: any): PoiDb {
    const { x, y, ...poi } = formValues;
    poi.class_ = 'point_poi';
    poi.categoria = 'Glocally Pois';
    poi.id_categoria = 20;
    poi.key_categoria = 'glocally_pois';
    poi.sub_categoria = this.categories$.value.find(
      (c) => c.key_sub_categoria === poi.key_sub_categoria
    )?.sub_categoria;
    return {
      type: 'Feature',
      properties: poi,
      geometry: {
        type: 'Point',
        coordinates: [Number(x), Number(y)]
      }
    };
  }

  addPoi(poi: PoiProperty) {
    this.pois$.next(undefined);
    const newPoi: PoiDb = this.buildPoi(poi);
    return this.http
      .post<PoiDb>(`${this.poisUrl}`, newPoi)
      .subscribe((d: any) => {
        this.router.navigate(['/pois']);
      });
  }

  updatePoi(id: string, poi: PoiProperty) {
    const newPoi: PoiDb = this.buildPoi(poi);
    newPoi.properties.id = id;
    return this.http
      .put<PoiDb>(`${this.poisUrl}`, newPoi)
      .subscribe((d: any) => {
        this.router.navigate(['/pois']);
      });
  }

  deletePoi(id: string) {
    this.pois$.next(undefined);
    return this.http
      .delete<PoiDb>(`${this.poisUrl}${id}`)
      .subscribe((d: any) => {
        this.getAll();
      });
  }

  clearPoi() {
    this.poi$.next(undefined);
  }

  public upload(formData, fileName) {
    return this.http
      .post<any>(this.fileUploadUrl, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              this.getAll();
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }

  getPOIsUrl(type: string, study: any) {
    let query = `?query=`;
    switch (type) {
      case 'global': {
        query += `{"properties.key_categoria":{"$nin":["transporte","glocally_pois"]}}`;
        break;
      }
      case 'local': {
        query += `{"properties.key_categoria":"glocally_pois"}`;
        break;
      }
      case 'transport': {
        query += `{"properties.key_categoria":"transporte"}`;
        break;
      }
    }
    //console.log(`${this.poisUrl}${query}&${this.geoCoordsService.getFrom(study)}`)
    return `${this.poisUrl}${query}&${this.geoCoordsService.getFrom(study)}`;
  }

  getAreaGlobal(study: any, force: boolean = false) {
    if (!this.categoriesList$.value || force) {
      // important to keep track when stage changes
      this.studyPois$.next(undefined);
      return this.http
        .get(this.getPOIsUrl('global', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          const nested_data = this.sidenavPoisApiService.nestCategories(
            studyPois,
            'key_categoria'
          );

          //console.log(nested_data)
          const mapped_data = this.sidenavPoisApiService
            .setFourLevelsProps(nested_data, studyPois, study.properties?.saved_global_pois ?? []);
          this.categoriesList$.next(mapped_data);
        });
    }
  }

  getAreaLocal(study: any) {
    if (!this.localCategoriesList$.value) {
      // important to keep track when stage changes
      this.studyPois$.next(undefined);
      this.categoriesList$.next(undefined);
      if (study.properties.saved_local_pois) {
        return this.localCategoriesList$.next(
          study.properties.saved_local_pois
        );
      }
      return this.http
        .get(this.getPOIsUrl('local', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          let nested_data = this.sidenavPoisApiService.nestTwoLevelsPois(
            studyPois,
            'key_sub_categoria'
          );
          let mapped_data = this.sidenavPoisApiService.setTwoLevelsProps(
            nested_data,
            studyPois
          );
          this.localCategoriesList$.next(mapped_data);
        });
    }
  }

  getAreaTransport(study: any) {
    if (!this.transportList$.value) {
      // important to keep track when stage changes
      if (study.properties.saved_transport) {
        return this.transportList$.next(study.properties.saved_transport);
      }
      return this.http
        .get(this.getPOIsUrl('transport', study))
        .subscribe((studyPois: PoiDb[]) => {
          this.studyPois$.next(studyPois);
          const nested_data = this.sidenavPoisApiService.nestTwoLevelsPois(
            studyPois,
            'key_sub_categoria'
          );
          const mapped_data = this.sidenavPoisApiService.setTwoLevelsProps(
            nested_data,
            studyPois
          );
          this.transportList$.next(mapped_data);
        });
    }
  }
}
