<section>
  <div class="container">
    <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
  </div>
</section>
<section>
  <div class="container">
    <compass-select-layer-loader></compass-select-layer-loader>
  </div>
</section>

<section>
  <div class="container">
    <compass-pois-searcher></compass-pois-searcher>

    <compass-pois-accordion [setup]="accordionSetup">
      <ng-template #loading_transport>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>

      <compass-pois-table
        *ngIf="transportList$ | async as transportList; else loading_transport"
        [poisTableList]="transportList"
        style="width: 100%"
      >
      </compass-pois-table>
    </compass-pois-accordion>
  </div>
</section>
