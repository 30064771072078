import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as crossfilter from 'crossfilter2/crossfilter';

@Injectable({
  providedIn: 'root'
})
export class CrossfilterService {
  data$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  crossfilter$: BehaviorSubject<crossfilter> = new BehaviorSubject<crossfilter>(undefined);

  private _crossfilter: crossfilter;

  dimensions = {};
  groups = {};

  constructor() {
    this.data$.next([]);
    this.crossfilter$.next(undefined);

    // update crossfilter when data is setted
    this.data$.subscribe(data => {
      this._setCrossfilter(data);
    });

    // when crossfilter is updated, generates the mapper
    this.crossfilter$.subscribe((crossfilter: crossfilter) => {
      this._crossfilter = crossfilter;
    });
  }

  crossfilter() {
    return this._crossfilter;
  }

  all(ignoreDimensions: any[] = []) {
    ignoreDimensions = ignoreDimensions?.map((dimName) => {
      return this._crossfilter.dimension(dimName);
    });

    return this._crossfilter.allFiltered(ignoreDimensions);
  }

  /**
   * Creates and return a new dimension
   */
  createDimension(dimensionFunction) {
    return this._crossfilter.dimension(dimensionFunction);
  }

  /**
   * Creates or retireves a dimension.
   *
   * @param dimName
   * @param dimFunc
   */
  dim(dimName = null, dimFunc = null) {
    // if dim func setted, creates/overrides the dimension
    if (dimFunc) {
      // check if dimension exists and reset it removing the filter and group
      if (this.dimensions[dimName]) {
        this.dimensions[dimName].filter(null);
        this.groups[dimName].remove();
      }

      const createdDim = this._crossfilter.dimension(dimFunc);

      this.dimensions[dimName] = createdDim;
      this.groups[dimName] = createdDim.group();
    }

    return {
      dimension: this.dimensions[dimName],
      group: this.groups[dimName]
    };
  }

  /**
   * Set fullset of data. This action must be done with all available data. Once is setted it shouldn't change.
   * @param data
   */
  setData(fullSetData) {
    setTimeout(() => {
      this.data$.next(fullSetData);
    })
  }

  /**
   * Returns dimension and domain of actual crossfilter.
   *
   * TODO: implemented dimension callback or something like that, to make dynamic service, !!!!!  avoid "properties.rating_morosidad"
   *
   *
   * @param dimension
   */
  getDimDomain(dimension, dimensionFuncCallback) {
    let min = 0, max = 0;

    min = dimension.bottom(1).length ? dimensionFuncCallback(dimension.bottom(1)[0]) : 0;
    max = dimension.top(1).length ? dimensionFuncCallback(dimension.top(1)[0]) : 0;

    // add/rest to show full range on brush select
    return [min - 0.9, max + 0.9];
  }


  /**
   * Creates crossfilter element.
   *
   * @param data
   * @private
   */
  private _setCrossfilter(data = null) {
    const crossFilter = crossfilter(data);
    this.crossfilter$.next(crossFilter);
  }
}
