import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherComponent } from './weather/weather.component';
import { SevenDaysWeatherComponent } from './seven-days-weather/seven-days-weather.component';
import { MaterialModule } from '@compass/material';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [WeatherComponent, SevenDaysWeatherComponent],
  exports: [WeatherComponent, SevenDaysWeatherComponent],
})
export class WeatherModule {}
