import { Injectable } from "@angular/core";
import { TooltipStyleService } from "../tooltipStyle.service";
import { ColorsService } from "@compass/utils/misc";

@Injectable()
export class HeatMatrixLayoutService {

    colors: any = this.colorsService.colors;
    tooltipValues: any = this.tooltipStyleService.tooltipValues;
    layoutSelected: any;

    constructor(
        private colorsService: ColorsService,
        private tooltipStyleService: TooltipStyleService
    ) { }

    public layouts: any[] = [
        {
            // vertical bar chart
            key: "aimc_matrix",
            class: "aimc-matrix",
            section: "aimc",
            design: {
                innerRadius: 2,
                height: 720,
                width: null,
                margin: { top: 32, right: 8, bottom: 120, left: 160 },
                stroke: {
                    width: 0.2,
                    stroke: this.colors.black,
                },
                style: {
                    colors: [

                    ],
                    paddingInner: 0.01,
                    padding: 0.01,
                    align: 0.1,
                    legend: {
                        fontSize: "10px",
                    },
                    averages: {},
                },
            },
            legend: {
                visible: true,
                width: 200,
                height: 16,
                steps: 4,
                margin: { top: 16, right: 8, bottom: 8, left: 8 },


            },
            properties: {
                key: '',
                value: ''
            },
            dictionary: [
                {
                    name: null,
                    key: "screen_name",
                },
            ],
            tooltip: {
                id: "heat-matrix_tooltip",
                style: this.tooltipValues,
                header: null//   key [string, "personas_hogar"], value [number], category[string, "Resto"] or index[string, "tienda_3_Resto"]


            },
        }
    ];

    public getLayout(key: string): any {
        let layout = this.layouts.filter((l) => {
            return l.key === key;
        })[0];
        return layout;
    }
}
