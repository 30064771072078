import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  CompassMapDrawerService,
  CompassMapWrapperService,
  Map,
  MapSetUp
} from '@compass/utils/leaflet';
import { MapService } from '../map/map.service';
import { BusinessReshapeService } from '../business.reshape.service';
import { BusinessViewerService } from '../business-viewer.service';
import { PoiBusiness } from '../business-poi.type';


@Component({
  selector: 'business-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  @ViewChild('map_elementRef', { static: false }) map_elementRef: ElementRef;
  @Input() poiList: PoiBusiness[];
  map: Map;
  murciaCathedral = [37.98517948783534, -1.12879400642717];
  mapSetUp: MapSetUp;


  // map service
  setMapCenter: (arr: Array<any>) => void;
  drawMap: (el: ElementRef) => void;
  removeMap: (el: ElementRef) => void;
  drawZoomCtrl: () => void;
  drawTopoCtrl: () => void;
  getTileLayerOptions: () => any;
  drawAttributionLayer: () => void;
  fitGeojsonBounds: () => void;
  removeGeojsonLayer: (layer: any) => void;
  getZoomStudy: () => void;
  drawCtrl: (draw: boolean, study: any) => void;
  // pois
  addMarkersToMap: (map: Map, markers: any, layer: string) => Map;
  getCategoryColor: (poi, options?) => string;
  buildCircleMarkers: (pois: any, options?) => any[];
  clickCircleMarker: (marker) => boolean;
  mouseoutCircleMarker: (marker) => boolean;
  mouseoverCircleMarker: (marker) => boolean;
  popUpText: (poi) => string;
  drawPois: (map: Map, pois: any, layer: string, type: string, options?) => Map;


  map$ = this.compassMapWrapperService.map$;
  mapIsOn$ = this.businessViewerService.mapIsOn$;


  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private businessReshapeService: BusinessReshapeService,
    private mapService: MapService,
    private businessViewerService: BusinessViewerService
  ) {
    this.mapSetUp = {
      id: 'map_elementRef',
      theme: 'light',
      center: this.murciaCathedral,
      zoom: 14,
      iconUrl: '../../../assets/img/markers/compass-marker-blue.svg'
    };

    this.businessReshapeService.getPoisList().subscribe(geo => {
      this.poiList = geo;
      if (this.poiList && this.mapIsOn$.value && this.map) {

        this.mapService.removeLayer(this.map, 'businessLayer');
        this.mapService.drawPois(this.map, this.poiList, 'businessLayer', 'featureGroup');
      }

    });


  }

  ngOnInit(): void {
    this.initServices();
  }

  ngAfterViewInit(): void {

    this.initMap();
    this.compassMapWrapperService.setMap(this.map);
    this.mapService.removeLayer(this.map, 'businessLayer');
    this.mapService.drawPois(this.map, this.poiList, 'businessLayer', 'featureGroup');


  }

  initServices() {
    this.setMapCenter = this.compassMapWrapperService.setMapCenter;
    this.removeMap = this.compassMapDrawerService.removeMap;
    this.drawMap = this.compassMapDrawerService.drawMap;
    this.drawZoomCtrl = this.compassMapDrawerService.drawZoomCtrl;
    this.drawTopoCtrl = this.compassMapDrawerService.drawTopoCtrl;
    this.drawAttributionLayer = this.compassMapDrawerService.drawAttributionLayer;
    this.getTileLayerOptions = this.compassMapDrawerService.getTileLayerOptions;
    this.removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
    this.addMarkersToMap = this.mapService.addMarkersToMap;
    this.getCategoryColor = this.mapService.getCategoryColor;
    this.buildCircleMarkers = this.mapService.buildCircleMarkers;
    this.clickCircleMarker = this.mapService.clickCircleMarker;
    this.mouseoutCircleMarker = this.mapService.mouseoutCircleMarker;
    this.mouseoverCircleMarker = this.mapService.mouseoverCircleMarker;
    this.popUpText = this.mapService.popUpText;
    this.drawPois = this.mapService.drawPois;
  }


  initMap(): void {
    this.map = this.compassMapWrapperService.initMap(this.mapSetUp);
    this.setMapCenter([
      this.map.properties.center[0],
      this.map.properties.center[1]
    ]);

    this.drawMap(this.map_elementRef);
    this.drawZoomCtrl();
    //this.drawTopoCtrl();
    this.drawAttributionLayer();

  }


}
