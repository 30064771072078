import { Component, OnInit } from '@angular/core';
import { SideNavStatusService } from '@compass/utils/navigation';
import { StartLeftSidenavService } from '@compass/page-navigation/start-left-sidenav';

import { CompassMapMarkerService } from '@compass/utils/leaflet';
import { CreateStudyService } from '@compass/feature-create-study';
import { BricksApiService } from '@compass/brick-api';
import { Router } from '@angular/router';

@Component({
  selector: 'glo-cancel-study-button',
  templateUrl: './cancel-study-button.component.html',
  styleUrls: ['./cancel-study-button.component.css'],
})
export class CancelStudyButtonComponent implements OnInit {
  constructor(
    private startLeftSidenavService: StartLeftSidenavService,
    private sideNavStatusService: SideNavStatusService,
    private compassMapMarkerService: CompassMapMarkerService,
    public createStudyService: CreateStudyService,
  ) { }

  onClickCancelButton(): Boolean {
    this.startLeftSidenavService.setSidenavIsOpen(true);
    this.startLeftSidenavService.setCompassStatus(true);
    this.sideNavStatusService.setStudyFormStatus(false);
    this.sideNavStatusService.setStagesNavStatus(true);
    this.sideNavStatusService.setStageViewerStatus(false);
    this.sideNavStatusService.setSubcategoriesNavStatus(false);
    this.sideNavStatusService.setCompaniesNavStatus(false);
    this.sideNavStatusService.setPlacesNavStatus(false);
    this.createStudyService.drawShape(false);
    this.compassMapMarkerService.removeMarker();

    return false;
  }

  ngOnInit(): void { }
}
