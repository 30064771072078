import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

export interface Stage {
  key: string;
  value: string;
  icon: string;
  active: boolean;
  open: boolean;
  routerLink: string;
  production: boolean;
  branch: string;
  children?: Array<Stage>;
}

@Injectable({ providedIn: 'root' })
export class SideNavStagesService {
  stagesURL: string = 'assets/data/navigation/sidenav-stages.json';
  public stagesList$ = new BehaviorSubject<Stage[]>(undefined);
  public activeStage$ = new BehaviorSubject<Stage>(undefined);

  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  getStagesApi() {
    return this.http
      .get(`${this.stagesURL}`)
      .pipe(
        catchError(this.handleError),
        map((stages: Stage[]) => stages)
      )
      .subscribe((d: Stage[]) => {
        this.stagesList$.next(d);
      });
  }

  setStages(stages: Stage[]): void {
    this.stagesList$.next(stages);
  }

  getStages() {
    return this.stagesList$.asObservable();
  }

  toggleOpenStage(toToggle) {
    const temp: Stage[] = this.stagesList$.value.map((stage) => {
      // reset states
      if (stage.key === toToggle) {
        stage.open ? (stage.open = false) : (stage.open = true);
      }

      return stage;
    });
    this.setStages(temp);
  }

  setActiveStage(toSetActive: string): void {
    const temp: Stage[] = this.stagesList$.value.map((stage) => {
      // reset states
      stage['active'] = false;

      // set states on singles
      if (stage.key === toSetActive) {
        stage['active'] = true;
        this.activeStage$.next(stage);
      }
      // set states on children
      if (stage.children) {
        stage.children.forEach((child) => {
          child['active'] = false;
          if (child.key === toSetActive) {
            stage['active'] = true;
            child['active'] = true;
            this.activeStage$.next(child);
          }
        });
      }
      return stage;
    });
    this.setStages(temp);
  }

  getActiveStage() {
    return this.activeStage$.asObservable();
  }
}
