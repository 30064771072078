import { Component, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { CreateStudyService } from '@compass/feature-create-study';
import { CompassMapMarkerService, CompassMapWrapperService } from '@compass/utils/leaflet';
import { SideNavStatusService } from '@compass/utils/navigation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-form-cp',
  templateUrl: './form-cp.component.html',
  styleUrls: ['./form-cp.component.css']
})
export class FormCpComponent implements OnInit, OnDestroy {
  study = {
    cps: [
      /*{ name: '28001', checked: true, id: 28001 },
      { name: '28002', checked: true, id: 28002 },
      { name: '28003', checked: true, id: 28003 },
      { name: '28004', checked: true, id: 28004 },
      { name: '28005', checked: true, id: 28005 },
      { name: '28006', checked: true, id: 28006 },
      { name: '28007', checked: true, id: 28007 },
      { name: '28008', checked: true, id: 28008 },
      { name: '28009', checked: true, id: 28009 },
      { name: '28010', checked: true, id: 28010 },
      { name: '28011', checked: true, id: 28011 },
      */
    ]
  };

  form;
  allChecked: boolean = false;
  noneChecked: boolean = true;

  cpss = [];
  studyListObjectCps: any;

  setZoomMap: (value: number) => void;

  zoomSubscription: Subscription;
  sidenavSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private compassMapWrapperService: CompassMapWrapperService,
    private createStudyService: CreateStudyService,
    private sideNavStatusService: SideNavStatusService,
    private compassMapMarkerService: CompassMapMarkerService
  ) {
    this.form = this.formBuilder.group({
      cps: []
    });

    this.sidenavSubscription = this.sideNavStatusService.getStudyButtonCreateStatus().subscribe((value) => {
      this.createStudyService.cpStudyOption = this.createStudyService.getCPSelected();
    });

    this.loadCPS();

    const _this = this;
    this.zoomSubscription = this.createStudyService.zoomendMapCpsChanged$.subscribe((params) => {
      if (params) {
        this.form = this.formBuilder.group({
          cps: []
        });

        _this.loadCPS();
      }
    });

    this.setZoomMap = this.compassMapWrapperService.setZoomMap;
  }

  ngOnDestroy() {
    this.zoomSubscription?.unsubscribe();
    this.sidenavSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    const bounds = this.createStudyService.getBoundsStudy();
    this.createStudyService.setDrawShape(true);

    if (bounds) {
      this.createStudyService
        .getListCPStudy(bounds[0], bounds[1], bounds[2], bounds[3])
        .subscribe((result_cps) => {
          this.createStudyService.setFeatureStudyInitCps(result_cps);
          this.loadCPS();
        });
    }
  }

  buildCps() {
    const arr = this.study.cps.map((cp) => {
      return this.formBuilder.control(cp.checked);
    });

    return this.formBuilder.array(arr);
  }

  get cps(): FormArray {
    return this.form.get('cps') as FormArray;
  }

  submit(value) {
    const formValue = Object.assign({}, value, {
      cps: value.cps.map((checked, i) => {
        return {
          id: this.study.cps[i].id,
          name: this.study.cps[i].name,
          checked
        };
      })
    });

    var filteredArray = formValue.cps.filter((data) => {
      return data.checked === true && data.id != 1;
    });

    this.cpss = [];
    for (let index = 0; index < filteredArray.length; index++) {
      const element = filteredArray[index];
      this.cpss.push(element.name);
    }

    let cpsList = this.createStudyService.getFeatureStudyInitCps();
    var filtered = [];

    for (var arr in cpsList) {
      for (var filter in filteredArray) {
        if (cpsList[arr].properties.COD_POSTAL == filteredArray[filter].name) {
          filtered.push(cpsList[arr]);
        }
      }
    }

    this.compassMapMarkerService.drawCpsLayer(true, filtered);
    this.createStudyService.setCPSelected(JSON.stringify(this.cpss));
  }

  setAllChecked(value: boolean) {
    this.allChecked = value;
    if (this.cps.controls == null) {
      return;
    }
    this.cps.controls.forEach((ctrl: FormControl) => {
      ctrl.setValue(value);
    });
  }

  onClickAllChecked(value: boolean) {
    this.setAllChecked(value);

    const formValue = Object.assign({}, this.study.cps, {
      cps: this.form.value.cps.map((checked, i) => {
        return {
          id: this.study.cps[i].id,
          checked
        };
      })
    });
  }

  someChecked(): boolean {
    if (this.cps.controls == null) {
      return false;
    }

    const filteredPostalCodes = this.cps.controls.filter((t) => t.value);
    if (filteredPostalCodes.length && !this.allChecked) {
      this.submit(this.form.value);
    }

    return filteredPostalCodes.length && !this.allChecked;
  }

  checkNoneChecked(): void {
    this.noneChecked = !this.cps.controls?.filter((t, i) => t.value).length;
  }

  updateAllChecked() {
    this.allChecked =
      this.cps.controls != null && this.cps.controls.every((t) => t.value);
    let x = this.allChecked;
    let y = this.someChecked();
    if (y) {
      this.submit(this.form.value);
    }

    this.checkNoneChecked();
  }

  loadCPS(): void {
    let result_cps = this.createStudyService.getFeatureStudyInitCps();
    this.createStudyService.setFeatureStudyInitCps(result_cps);
    let cpss = !result_cps ? [] : result_cps.map((cp) => {
      return {
        name: cp.properties.COD_POSTAL,
        checked: false,
        id: Number(cp.properties.id)
      };
    });

    if (this.createStudyService.getCPSelected()) {
      let filterList = JSON.parse(this.createStudyService.getCPSelected());
      for (var arr in cpss) {
        for (var filter in filterList) {
          if (cpss[arr].name == filterList[filter]) {
            cpss[arr].checked = true;
          }
        }
      }

      //check if cps study saved are on the list
      for (var cpSelList in filterList) {
        let found = cpss.some((e) => e.name == filterList[cpSelList]);
        if (!found) {
          let obj = {
            id: Number(filterList[cpSelList]),
            name: filterList[cpSelList],
            checked: true
          };
          cpss.push(obj);
        }
      }
    } else {
      if (cpss.length > 1) {
        this.setAllChecked(true);
      }
    }

    this.study.cps = cpss;
    this.form = this.formBuilder.group({
      cps: this.buildCps()
    });

    this.compassMapWrapperService.createMakerMap(false);
    this.submit(this.form.value);
  }

}
