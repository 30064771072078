import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BusinessReshapeService } from '../business.reshape.service';
import { BusinessFormOptionsService } from './business-form-options.service';
import { Map } from '../map/map.type';
import { MapService } from '../map/map.service';
import { BusinessViewerService } from '../business-viewer.service';
import { BusinessApiService } from '../business-api.service';
import { RangeSliderLayoutService } from '../../../../utils/d3/src/lib/layout-wrappers/range-slider-layout.service';
import { RangeSliderService, RangeSliderValue } from '../../../../utils/d3/src/lib/range-slider/range-slider.service';
//import { RangeSliderLayoutService, RangeSliderService, RangeSliderValue } from '@compass/utils/d3';
import { formatCurrency } from '@angular/common';
import { CompassMapPoisService } from '@compass/utils/leaflet';


@Component({
  selector: 'compass-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.scss']
})
export class BusinessFormComponent implements OnInit {

  @Input() setup: any[];
  @ViewChild('cnaeInput') cnaeInput: ElementRef;

  formGroup$ = this.businessApiService.formGroup$;
  mapIsOn$ = this.businessViewerService.mapIsOn$;

  murciaCathedral = [37.98517948783534, -1.12879400642717];
  // form
  businessForm: FormGroup;

  descripcion_cnae_options: Array<string>;
  filtered_cnae_options: Array<string>;
  cnae_all: string; // "Todas";
  selected_cnae_options: Array<string>;
  // final pois for map
  poisList: Array<any> = [];
  // map and layers
  map: Map;
  box: any;
  rangeSliderMorosidad = {
    data: null,
    accessorFunction: d => d.rating_morosidad,
    aggregatorFunction: undefined,
    paramsObject: {}
  };

  rangeSliderValue: RangeSliderValue;

  constructor(
    private businessReshapeService: BusinessReshapeService,
    private businessFormOptionsService: BusinessFormOptionsService,
    private mapService: MapService,
    // private compassMapWrapperService: CompassMapWrapperService,
    private businessViewerService: BusinessViewerService,
    private businessApiService: BusinessApiService,
    private rangeSliderLayoutService: RangeSliderLayoutService,
    private rangeSliderService: RangeSliderService,
    private poiService: CompassMapPoisService
  ) {

    this.businessForm = new FormGroup({
      descripcion_cnae: new FormControl(this.businessFormOptionsService.cnae_all), // input to search options
      selected_cnae: new FormControl(), // selected options after searching
      rating_morosidad: new FormControl(), // between 1 and 9, rangeSlider subscription on constructor sets the value
      cliente: new FormControl(null)
    });

    this.rangeSliderService.getRangeSlider().subscribe((range: RangeSliderValue) => {
      if (range) {
        this.rangeSliderValue = range;
        this.businessFormOptionsService.setControlValue(this.businessForm, 'rating_morosidad', this.rangeSliderValue.range);
      }
    });
  }


  // on click add button
  public addSelectedCnaeOption() {
    const option = this.businessForm.get('descripcion_cnae').value;
    if (!option || option === '') {
      return false;
    }

    if (this.selected_cnae_options.indexOf(option) === -1) {
      this.selected_cnae_options = this.selected_cnae_options.filter(d => d !== this.businessFormOptionsService.cnae_all);
      this.selected_cnae_options.push(option);
    }

    if (option === this.businessFormOptionsService.cnae_all) {
      this.selected_cnae_options = [this.businessFormOptionsService.cnae_all];
    }

    this.businessFormOptionsService.setControlValue(this.businessForm, 'descripcion_cnae', '');
    this.businessFormOptionsService.setControlValue(this.businessForm, 'selected_cnae', this.selected_cnae_options);

    this.cnaeInput.nativeElement.blur();

    return false;
  }

  // on checkbox-> change cnea options
  changeCnaeCheckBox(event) {
    if (!event.checked) {
      this.selected_cnae_options = this.selected_cnae_options.filter(d => d !== event.source.value);
      if (this.selected_cnae_options.length <= 0) {
        this.selected_cnae_options = [this.businessFormOptionsService.cnae_all];
      }

      this.businessFormOptionsService.setControlValue(this.businessForm, 'selected_cnae', this.selected_cnae_options);
    }

    return false;
  }


  // helpers
  // build cnae options for select
  private getOptions(type) {
    this.descripcion_cnae_options = this.businessFormOptionsService.getDescripcionCnae(this.setup);
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.descripcion_cnae_options.filter(cnae_option => this._normalizeValue(cnae_option).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  initBusinessForm(val) {
    this.businessForm = new FormGroup({
      descripcion_cnae: new FormControl(this.businessFormOptionsService.cnae_all), // input to search options
      selected_cnae: new FormControl(val.selected_cnae), // selected options after searching
      rating_morosidad: new FormControl(val.rating_morosidad), // between 1 and 9, rangeSlider subscription on constructor sets the value
      cliente: new FormControl(null)
    });

    // listen for descripcion_cnae change
    this.businessForm.get('descripcion_cnae').valueChanges.subscribe(value => {
      this.filtered_cnae_options = this._filter(value);
    });
  }

  morosidadChanged(minMax) {
    this.businessFormOptionsService.setControlValue(this.businessForm, 'rating_morosidad', minMax);
  }


  reshapeTableList(businessForm, businessPois) {
    this.poisList = this.businessReshapeService.filterPois(businessForm, businessPois);

    // add popup callback
    this.poisList.map(marker => {
      marker.popUpText = this.popUpContent;
    });

    this.poiService.drawMarkers(this.poisList);
  }

  // reset to be done
  resetForm(e) {
    this.businessFormOptionsService.setControlValue(this.businessForm, 'descripcion_cnae', this.businessFormOptionsService.cnae_all);
    this.businessFormOptionsService.setControlValue(this.businessForm, 'selected_cnae', [this.businessFormOptionsService.cnae_all]);
    this.businessFormOptionsService.setControlValue(this.businessForm, 'rating_morosidad', [1, 9]);

    this.businessApiService.setFormGroup(this.businessApiService.formGroup);
    this.reshapeTableList(this.businessApiService.formGroup, this.setup);
    this.selected_cnae_options = this.businessApiService.formGroup.selected_cnae;

    return false;
  }

  onSubmit() {
    this.businessApiService.setFormGroup(this.businessForm.value);
    this.reshapeTableList(this.businessForm.value, this.setup);

    return false;
  }


  ngOnInit(): void {
    const formGroupValue = this.formGroup$.value;
    this.selected_cnae_options = formGroupValue.selected_cnae;
    this.getOptions('');
    this.initBusinessForm(formGroupValue);

    // new
    this.rangeSliderMorosidad.data = this.setup.map(d => d.properties);
    this.rangeSliderMorosidad.paramsObject = this.rangeSliderLayoutService.getLayout('rangeSlider_morosidad')[0];

    // when form changes execute submit
    this.businessForm.valueChanges.subscribe(data => {
      this.onSubmit();
    });
  }

  popUpContent(marker) {
    const data = marker.properties;

    return `
      <div>
        <h4>
          <strong>${data.denominacion} <small>(${data.sigla})</small></strong> <br>
          Rating Morosidad: ${data.rating_morosidad}
        </h4>
        <p>
          <small>${data.tipo_via} ${data.nombre_via} ${data.numero_via}, ${data.resto_via}, ${data.codigo_postal} ${data.municipio}, ${data.provincia}</small>
          <br>
          ${data.descripcion_cnae}
          <br>
          ${data.descripcion_cnae2}
        </p>
        <hr>

        <p>
          Ventas: ${formatCurrency(data.ventas, 'es_ES', '€')}<br>
          Fondos Propios: ${formatCurrency(data.fondos_propios, 'es_ES', '€')}<br>
          Resultado Ejercicio: ${formatCurrency(data.resultado_ejercicio, 'es_ES', '€')}<br>
          Total Activo: ${formatCurrency(data.total_activo, 'es_ES', '€')}
        </p>

        <hr>

        <p>
          <small>Estado: ${data.estado_confirmado}</small> <br>
        </p>
      </div>
    `;
  }

}
