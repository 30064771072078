import { Component, OnInit } from '@angular/core';
import { SharedMapFeatureMapModule } from '@compass/shared/map/feature-map';
import { SelectLayerGroupService } from '../select-layer-group/select-layer-group.service';

@Component({
  selector: 'compass-select-layer-loader',
  templateUrl: './select-layer-loader.component.html',
  styleUrls: ['./select-layer-loader.component.css'],
})
export class SelectLayerLoaderComponent implements OnInit {
  slideSetup: any;
  selectSetup: any = {
    options: [],
    parentLabel: '',
  };

  constructor(private selectLayerGroupService: SelectLayerGroupService) {}
  ngOnInit(): void {
    this.selectSetup['options'] = this.selectLayerGroupService.getOptions(
      'layerGroup'
    );
    this.selectSetup['parentLabel'] = 'Capa de clusters';
    this.selectSetup[
      'selected'
    ] = this.selectLayerGroupService.layerGroup$.value;
  }
}
