<div class="ul__accordion">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            *ngFor="let l of list; let index = index" class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{l.nombre_medio}}
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngIf='l.tematica'>{{l.tematica}}</p>
            <p *ngIf='l.municipio'>{{l.municipio}}</p>
            <p *ngIf='l.provincia'>{{l.provincia}}</p>
            <p *ngIf='l.notes'>{{l.notes}}</p>
            <p *ngIf='l.url && l.nombre_medio'><a target='_blank'
                    [attr.href]="'https://' + l.url">{{l.nombre_medio}}</a>

        </mat-expansion-panel>

    </mat-accordion>
</div>