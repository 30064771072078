import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HeritageLoaderComponent } from './heritage-loader/heritage-loader.component';
import { HeritageFormComponent } from './heritage-form/heritage-form.component';
import { HeritageFormOptionsService } from './heritage-form/heritage-form-options.service';
import { HeritageFormReshapeService } from './heritage-form/heritage-form-reshape.service';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [HeritageLoaderComponent, HeritageFormComponent],
  exports: [HeritageLoaderComponent, HeritageFormComponent],
  providers: [HeritageFormOptionsService, HeritageFormReshapeService],

})
export class HeritageModule { }
