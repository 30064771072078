<section>
  <div class="container">
    <mat-button-toggle-group
      [(value)]="selectedForm"
      name="fontStyle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        value="cp"
        (change)="emitActiveForm($event.value)"
        [checked]="true"
        >CP</mat-button-toggle
      >
      <mat-button-toggle value="point" (change)="emitActiveForm($event.value)"
        >Punto</mat-button-toggle
      >
      <mat-button-toggle value="manual" (change)="emitActiveForm($event.value)"
        >Manual</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
</section>
