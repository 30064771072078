<button
  mat-flat-button
  color="primary"
  class="button--xl"
  type="submit"
  [disabled]="disabled || loading"
  (click)="onClickCreateButton($event)"
>
  <mat-icon>check_circle</mat-icon> {{ textButton }}
</button>
