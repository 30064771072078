import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Map } from './compass-map-wrapper.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import { CreateStudyService } from '@compass/feature-create-study';
import { CompassTopoLayersService } from './compass-topo-layers.service';
import { HasEvents } from '../../../../map/src/lib/classes/has-events';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompassMapDrawerService extends HasEvents{
  map: Map;
  public map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(null);


  editableLayers = new L.FeatureGroup();
  drawControl = new L.Control.Draw();
  drawControlEditOnly = new L.Control.Draw();
  tileLayer: any;

  constructor(
    private createStudyService: CreateStudyService,
    private compassTopoLayersService: CompassTopoLayersService) {
    super();
  }


  getTileLayerOptions() {
    return {
      attribution: this.map.attribution.attribution,
      maxZoom: this.map.properties.maxZoom,
      maxNativeZoom: this.map.properties.maxBounds
    };
  }

  // drawers
  public removeMap(el: ElementRef): void {
    // never removing because this.geo_elementRef does not exist up to afterViewInInit
    if (el) {
      el.nativeElement.innerHTML = '';
    }
  }

  public getZoomStudy(): void {
    this.map.box.getZoom();
    let bounds = this.map.box.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;
    this.createStudyService.setBoundsStudy(lngmin, latmin, lngmax, latmax);
    this.createStudyService.zoomendCpStudy(true);
  }

  public drawMap(el: ElementRef): void {
    if (this.map.box) {
      let container: any;
      container = L.DomUtil.get(this.map.id);
      if (container != null) {
        container._leaflet_id = null;
      }
    }
    this.map.box = new L.Map(el.nativeElement, this.map.properties);

    const _this = this;
    this.map.box.on('zoomend', function(e) {
      if (_this.getZoomStudy) {
        _this.getZoomStudy();
      }
    });
  }

  public drawZoomCtrl(): void {
    this.map['zoomCtrl'] = L.control.zoom(this.map.zoom).addTo(this.map.box);
  }

  public drawTopoCtrl(): void {
    this.map['topoLayers'] = this.compassTopoLayersService.get('topoLayers');

    let baseLayers = {
      'OpenStreetMap': this.map['topoLayers'].openStreetMapLayer,
      'IGN': this.map['topoLayers'].ignBaseLayer,
      'Ortofoto': this.map['topoLayers'].ignOrthoimageCoverageLayer,
      'Topográfico': this.map['topoLayers'].ignMapaRasterLayer,
      'Militar': this.map['topoLayers'].defensacegetM682Layer,
      'LiDar': this.map['topoLayers'].ignLidarLayer,
      'Transporte': this.map['topoLayers'].thunderforestTransportLayer,
      'MapBox Dark': this.map['topoLayers'].mapBoxDarkLayer,
      'MapBox Light': this.map['topoLayers'].mapBoxLightLayer
    };
    this.map['topoCtrl'] = L.control.layers(baseLayers).addTo(this.map.box);
  };

  public drawAttributionLayer() {
    this.map['tileLayer'] = L.tileLayer(
      this.map.access_token,
      this.getTileLayerOptions()
    ).addTo(this.map.box);
    return this.map;
  }

  public fitGeojsonBounds(): void {
    this.map['bounds'] = this.map.geojsonLayer.getBounds();
    if (this.map['bounds']['_northEast']) {
      this.map.box.fitBounds(this.map.bounds);
    }
  }

  public removeGeojsonLayer(layer): void {
    if (this.map && this.map[layer]) {
      this.map[layer].remove();
    }
  }

  public setView(map: Map): void {
    this.map = map;
    this.map.box.setView(this.map.properties.center, this.map.properties.zoom);
  }

  public drawCtrl(draw: boolean, study: any): void {
    const _this = this;
    this.createStudyService.studyGeomPainted(false);
    if (this.drawControl) {
      this.map.box.removeControl(this.drawControl);
    }

    if (this.drawControlEditOnly) {
      this.map.box.removeControl(this.drawControlEditOnly);
    }

    if (this.editableLayers) {
      this.map.box.removeLayer(this.editableLayers);
    }

    if (draw) {
      this.editableLayers = new L.FeatureGroup();
      if (study) {
        var coordenadas = study.properties.areas.manual.geometry.coordinates[0];
        var latlngsGoo = [];
        for (var z = 0; z < coordenadas.length; z++) {
          latlngsGoo.push([coordenadas[z][1], coordenadas[z][0]]);
        }
        var polygon = L.polygon(latlngsGoo, {
          /*color: '#ff5b00'*/
        }).addTo(this.editableLayers);
        this.createStudyService.setAreaPainted(
          JSON.stringify(study.properties.areas.manual.geometry.coordinates[0])
        );
        this.createStudyService.studyGeomPainted(true);
      }

      this.map?.box.addLayer(this.editableLayers);

      this.drawControl = new L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          circlemarker: false,
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            showArea: false, // set false because error caused when drawing poligon (3rd point)
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong>¡esa forma no es válida!' // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          marker: false
        },
        edit: {
          featureGroup: this.editableLayers
        }
      });

      this.drawControlEditOnly = new L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers
        },
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          circlemarker: false,
          polygon: false,
          marker: false
        }
      });

      this.map.box.addControl(this.drawControl);

      if (study) {
        if (this.drawControl) {
          this.map.box.removeControl(this.drawControl);
        }
        if (this.drawControlEditOnly) {
          this.map.box.removeControl(this.drawControlEditOnly);
        }
        this.map.box.addControl(this.drawControlEditOnly);
      }

      this.map.box.on('draw:created', function(e) {
        var layers = e.layer;
        var type = e.layerType,
          layer = e.layer;
        _this.map.box.removeControl(_this.drawControl);
        _this.map.box.removeControl(_this.drawControlEditOnly);
        _this.map.box.addControl(_this.drawControlEditOnly);
        _this.editableLayers.addLayer(layer);
        _this.createStudyService.setAreaPainted(
          JSON.stringify(layer.toGeoJSON().geometry.coordinates[0])
        );
        _this.createStudyService.studyGeomPainted(true);
      });

      this.map.box.on('draw:edited', function(e) {
        var layers = e.layers;
        layers.eachLayer(function(layer) {
          _this.map.box.removeControl(_this.drawControl);
          _this.map.box.removeControl(_this.drawControlEditOnly);
          _this.map.box.addControl(_this.drawControlEditOnly);
          _this.editableLayers.addLayer(layer);
          _this.createStudyService.setAreaPainted(
            JSON.stringify(layer.toGeoJSON().geometry.coordinates[0])
          );
          _this.createStudyService.studyGeomPainted(true);
        });
      });

      this.map.box.on('draw:deleted', function(e) {
        _this.createStudyService.setAreaPainted(null);
        _this.createStudyService.studyGeomPainted(false);
        _this.map.box.removeControl(_this.drawControlEditOnly);
        _this.drawControl.addTo(_this.map.box);
      });
    } else {
      if (this.editableLayers) {
        this.map.box.removeLayer(this.editableLayers);
      }
      if (_this.drawControl) {
        _this.map.box.removeControl(_this.drawControl);
      }
      if (_this.drawControlEditOnly) {
        _this.map.box.removeControl(_this.drawControlEditOnly);
      }
    }
  }

  public setMap(map: Map): BehaviorSubject<Map> {
    this.map$.next(map);

    return this.map$;
  }
}
