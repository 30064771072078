import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SimpleChange } from '@angular/core';


@Component({
  selector: 'app-presence-total',
  templateUrl: './presence-total.component.html',
  styleUrls: ['./presence-total.component.scss']
})
export class PresenceTotalComponent implements OnChanges {
  @Input() data: any;
  presence: any = undefined;

  constructor() { }

  getPresence(pois: any) {
    return pois.map((poi: any, index: number) => {
      return {
        value: poi.summary.presence.total,
        id: 'block_' + index,
        title: poi.name,
        color: poi.color,
        isImportant: false,
        height: '16px',
        width: poi.summary.presence.percent,
        border: '1px solid white'
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.data) {
      this.presence = this.getPresence(this.data);
    }
  };

}
