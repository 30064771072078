import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { PoiBusiness } from './business-poi.type';

@Injectable({ providedIn: 'root' })
export class BusinessReshapeService {

  public poisList$: BehaviorSubject<PoiBusiness[]> = new BehaviorSubject<PoiBusiness[]>(undefined);

  constructor() {
  }

  public setPoisList(business: PoiBusiness[]) {
    this.poisList$.next(business);
  }

  public getPoisList(): Observable<PoiBusiness[]> {
    return this.poisList$.asObservable();
  }

  public filterPois(businessForm, businessPois) {
    console.log(businessForm, businessPois)
    const keys = Object.keys(businessForm);
    let result = businessPois;

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'selected_cnae') {
        if (!(businessForm.selected_cnae.indexOf('Todas') >= 0)) {
          result = result.filter(
            (poi) => businessForm.selected_cnae.indexOf(poi.properties.descripcion_cnae) >= 0
          );
        }
      }

      if (keys[i] === 'rating_morosidad') {
        result = result.filter(poi => poi.properties.rating_morosidad >= businessForm.rating_morosidad[0] && poi.properties.rating_morosidad <= businessForm.rating_morosidad[1]);
      }

      if (keys[i] === 'cliente') {
        result = result.filter(poi => {
          // if undefined, pass
          if (typeof businessForm.cliente == 'undefined' || businessForm.cliente === null) {
            return true;
          }

          return poi.properties.cliente == businessForm.cliente;
        });
      }
    }

    this.setPoisList(result);

    return result;
  }

}
