import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'compass-form-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent implements OnInit {
  @Input() objectName = 'opción';

  @Input('options')
  set options(options: any) {
    this._options = options;
    this.filteredOptions = this._filter(); // execute filter when options setted
  }

  get options(): any {
    return this._options;
  }

  @Output() onOptionSelected = new EventEmitter<any>();

  @ViewChild('searcher') activitySearcher: ElementRef;

  private _options: any = [];
  public filteredOptions: Observable<any>;
  public selectedOptions: any = [];

  private _searchTimeout: any;

  public form: FormGroup = new FormGroup({
    search: new FormControl()
  });

  constructor() {
    // when businessForm.activity changes, filter options
    this.form.get('search').valueChanges.subscribe(searchString => {
      this.search(searchString);
    });
  }

  ngOnInit(): void {
    this.filteredOptions = this.options;
  }

  public search(searchString?: string) {
    searchString = searchString ?? this.form.get('search').value;

    clearTimeout(this._searchTimeout);
    this._searchTimeout = setTimeout(() => {
      this.filteredOptions = this._filter(searchString);
    }, 690);
  }

  selectOption(option) {
    this.selectedOptions.push(option);
    this.onOptionSelected.emit(this.selectedOptions);
  }

  /**
   * Add form.search value to selected options. If form.search is null, it will remove all.
   */
  addOption() {
    if (!this.form.controls.search.value) {
      this.removeAllOptions();
    } else {
      this.selectOption(this.form.controls.search.value);
    }

    this.form.controls.search.setValue(null);
    this.activitySearcher.nativeElement.blur();
  }

  /**
   * Remove option from selected by index.
   *
   * @param index
   */
  removeOption(index) {
    this.selectedOptions.splice(index, 1);
    this.form.controls.search.setValue(null);

    this.onOptionSelected.emit(this.selectedOptions);
  }

  /**
   * Remove all selected options.
   */
  removeAllOptions() {
    this.selectedOptions = [];
    this.form.controls.search.setValue(null);

    this.onOptionSelected.emit(this.selectedOptions);
  }

  /**
   * Filter all the options.
   *
   * @param value
   * @private
   */
  private _filter(value: any = null): any {
    let filtered = this._options.filter(data => !this.selectedOptions.includes(data));
    // if value is null, dont filter
    if (value) {
      filtered = filtered.filter((data: any) => {
        let hasCoincidence = true;
        const words = value.split(' '); // extract all words

        words.forEach(word => {
          // check if
          if (!this._normalizeValue(data).includes(this._normalizeValue(word))) {
            hasCoincidence = false;
            return;
          }
        });

        return hasCoincidence;
      });
    }

    return filtered;
  }

  /**
   * Remove spaces and lower case of string, for correct search.
   *
   * @param value
   * @private
   */
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  clearSearcher($event?: any) {
    $event?.stopPropagation();
    this.form.get('search').setValue('');
    this.activitySearcher.nativeElement.focus();
  }

}
