import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { ManagementTitleComponent } from './management-title.component';
import { SharedCrudElementsModule } from '@compass/shared/crud-elements';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedCrudElementsModule],
  declarations: [ManagementTitleComponent],
  exports: [ManagementTitleComponent],
})
export class SharedFeatureManagementTitleModule {}
