<section *ngIf="data">
  <p class="title">
    Referencia: <a target="_blank" href="{{ url }}">{{ id }}</a>
  </p>
  <p>{{ type }} | {{ category }}</p>
  <hr />

  <p class="title"><strong>Dirección:</strong></p>
  <p>{{ address }}</p>

  <p class="title"><strong>Inmueble:</strong></p>
  <p>{{ title }}</p>
  <hr />
  <h1>{{ price | number: '1.0-0':'es-ES' }} eur.</h1>
  <hr />

  <table class="brick-table">
    <tr>
      <th></th>
      <th></th>
    </tr>
    <tr>
      <td>Precio (€/m2):</td>
      <td>{{ price_sqm | number: '1.0-0':'es-ES' }}</td>
    </tr>
    <tr>
      <td>Superficie (m2):</td>
      <td>{{ size | number: '1.0-0':'es-ES' }}</td>
    </tr>
    <tr>
      <td>Habitaciones:</td>
      <td>{{ rooms | number: '1.0-0':'es-ES' }}</td>
    </tr>
    <tr>
      <td>Tasación (€):</td>
      <td>{{ price | number: '1.0-0':'es-ES' }}</td>
    </tr>
  </table>

  <div *ngIf="deposit !== ''">
    <p class="title"><strong>Depósito:</strong></p>
    <p>{{ deposit }}</p>
  </div>
  <hr />
  <p class="title"><strong>Resumen:</strong></p>
  <p *ngFor="let res of basic_resume">{{ res }}</p>
</section>
