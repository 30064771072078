import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import { InmueblesService } from '@compass/inmuebles/data-access';

@Component({
  selector: 'compass-inmuebles',
  templateUrl: './inmuebles.component.html',
  styleUrls: ['./inmuebles.component.scss'],
})
export class InmueblesComponent implements OnInit {
  idealistaPois$ = this.inmueblesService.idealistaPois$;

  constructor(
    private bricksApiService: BricksApiService,
    private inmueblesService: InmueblesService
  ) {
    if (!this.idealistaPois$.value) {
      this.inmueblesService.getByArea(this.bricksApiService.fullStages$.value);
    };

  }

  ngOnInit(): void { }
}


