import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as c3 from 'c3';
import { DecimalPipe } from '@angular/common';

export interface ChartDataInterface {
  value: string;
  color: string;
}

@Component({
  selector: 'compass-analytics-chart',
  templateUrl: './analytics-chart.component.html',
  styleUrls: ['./analytics-chart.component.scss']
})
export class AnalyticsChartComponent implements AfterViewInit, OnChanges {
  @Input() data: ChartDataInterface[] = [];
  chartId: string = 'chart-' + new Date().getTime();
  chart: any;

  parsedData = [''];
  parsedColors = [''];

  constructor(private decimalPipe: DecimalPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.parseData();
    }
  }

  ngAfterViewInit() {
    // timeout to let the component render before handling the chart
    setTimeout(() => {
      this.initChart();
      this.parseData();
    }, 10);
  }

  parseData() {
    if (this.chart && this.data?.length) {
      const uniqueData: any = [];

      this.data.forEach(filterData => {
        // if value not in uniqueData
        if (true || uniqueData.findIndex(el => el.value == filterData.value) === -1) {
          uniqueData.push(filterData);
        }
      });

      this.parsedColors = uniqueData.map(d => d.color);
      this.parsedData = [''].concat(uniqueData.map(d => d.value));

      setTimeout(() => {
        this.chart.load({ columns: [this.parsedData] });
      }, 200);
    }
  }

  initChart() {
    this.chart = c3.generate({
      bindto: `#${this.chartId}`,
      size: { height: 140 },
      data: {
        type: 'bar',
        columns: [this.parsedData],
        color: (color, d) => {
          return this.parsedColors[d.index] ?? 'lightgrey';
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      axis: {
        y: {
          tick: {
            count: 4,
            format: (yData) => {
              let res = yData;
              if (yData > 1000) {
                res = this.decimalPipe.transform(yData, '1.0-0', 'es-ES') ;
              } else if (yData < 10) {
                res = yData.toFixed(2);
              } else {
                res = parseInt(yData);
              }

              return res;
            }
          }
        },
        x: {
          tick: {
            format: (x, e) => {
              return '';
            }
          }
        }
      }
    });

  }

}
