import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


export interface EgmDb {
  key: string;
  class_: string;
  geo_level: string;
  id: string;
  estudio: string;
  tipologia: string;
  medio: string;
  geo_level_id: string;
  geo: string;
  target: string;
  valor: number;
  source: string;
}

@Injectable()
export class EgmAimcDataService {
  private egmUrl = '/abacusgis/core/glocally/collection/data_egm/';
  public egmCountry$ = new BehaviorSubject(undefined);
  public egmProvince$ = new BehaviorSubject(undefined);
  public egmMunicipality$ = new BehaviorSubject(undefined);
  // egmLocal$ : egmProvince$ or egmMunicipality$
  // because we have only municipality data for Madrid city and Barcelona city
  // if study is from Madrid city or Barcelona, local data is municipality$ if not
  // local data is province$
  public egmLocal$ = new BehaviorSubject(undefined);
  private prevLocal: string;

  constructor(private http: HttpClient) {
  }

  getEgmCountry() {
    return this.http
      .get(`${this.egmUrl}?query={"key":"data_egm_area_country_ES"}`)
      .pipe(map((egm: EgmDb[]) => egm))
      .subscribe((egm: EgmDb[]) => {
        this.egmCountry$.next(egm);
      });
  }

  getEgmProvince(province: string) {
    if (this.prevLocal !== province) {
      this.egmProvince$.next(undefined);
      return this.http
        .get(
          `${this.egmUrl}?query={"key":"data_egm_area_province_${province}"}`
        )
        .pipe(map((egm: EgmDb[]) => egm))
        .subscribe((egm: EgmDb[]) => {
          this.egmProvince$.next(egm);
          this.egmLocal$.next(egm);
          this.prevLocal = province;
        });
    }
  }

  getEgmMunicipality(municipalityId: string) {
    if (this.prevLocal !== municipalityId) {
      this.egmMunicipality$.next(undefined);
      return this.http
        .get(
          `${this.egmUrl}?query={"key":"data_egm_area_municipality_${municipalityId}"}`
        )
        .pipe(map((egm: EgmDb[]) => egm))
        .subscribe((egm: EgmDb[]) => {
          this.egmMunicipality$.next(egm);
          this.egmLocal$.next(egm);
          this.prevLocal = municipalityId;
        });
    }

  }

  getEgmLocal(provinceId: string, municipalityId: string) {
    // Madrid or Barcelona
    if (municipalityId && (municipalityId === '28079' || municipalityId === '08019')) {
      this.getEgmMunicipality(municipalityId);
    } else {
      this.getEgmProvince(provinceId);
    }
  }
}
