import { Component, OnInit, OnChanges } from '@angular/core';
import { CompassMapWrapperService } from '@compass/utils/leaflet';
import { CreateStudyService } from '@compass/feature-create-study';
import { SideNavStatusService } from '@compass/utils/navigation';

@Component({
  selector: 'compass-form-area',
  templateUrl: './form-area.component.html',
  styleUrls: ['./form-area.component.css']
})
export class FormAreaComponent implements OnInit {
  disabled: boolean = false;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private createStudyService: CreateStudyService,
    private sideNavStatusService: SideNavStatusService
  ) {
    this.createStudyService.studyGeomPaintedChanged$.subscribe((params) => {
      /*if (this.createStudyService.getCPSelected()) {
        if (JSON.parse(this.createStudyService.getCPSelected()).length == 0) {
          if (this.createStudyService.getAreaPainted()) {
            this.disabled = !params;
            this.createStudyService.areaPainted = this.createStudyService.getAreaPainted();
          } else {
            this.disabled = params;
          }
        } else {
          this.disabled = true;
        }
      } else {
        */

      setTimeout(() => {
        if (this.createStudyService.getAreaPainted()) {
          this.disabled = !params;

          this.createStudyService.areaPainted = this.createStudyService.getAreaPainted();
        } else {
          this.disabled = true;
        }
      });
      //}
    });
  }

  ngOnInit(): void {
    this.compassMapWrapperService.createMakerMap(false);
    /*
    this.sideNavStatusService
      .getStudyButtonCreateStatus()
      .subscribe((value) => {
        if(){

        }
        if (this.createStudyService.getAreaPainted()) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
        //this.createStudyService.cpStudyOption = null;
        this.createStudyService.areaPainted = this.createStudyService.getAreaPainted();
      });
      */
  }

  ngOnChanges() {
  }
}
