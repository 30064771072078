import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class GroupedHorizontalBarchartReshapeService {

    constructor(
    ) { }

    public reshape = (table: any, selector: string): Array<any> => {

        let result = table.map(t => ({ category: t.category, [selector]: t[selector] }));
        return result;

    };
}