import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InfluencersFormOptionsService, Options } from './influencers-form-options.service';
import { InfluencersReshapeService } from '../influencers-reshape.service';
import { InfluencersApiService, Influencer } from '../influencers-loader/influencers-api.service';

@Component({
  selector: 'compass-influencers-form',
  templateUrl: './influencers-form.component.html',
  styleUrls: ['./influencers-form.component.scss']
})
export class InfluencersFormComponent implements OnInit {
  @Input() setup: Influencer[];
  mediaData: Influencer[];
  form: FormGroup;
  options: Array<Options>

  constructor(
    private influencersFormOptionsService: InfluencersFormOptionsService,
    private influencersReshapeService: InfluencersReshapeService
  ) { }

  onSubmit() {
    return false;
  }

  initForm() {
    this.form = new FormGroup({
      tematica: new FormControl(this.options[0].value)
    });
    this.form.valueChanges.subscribe(target => {
      this.influencersReshapeService.reshape(this.setup, target['tematica']);
    })
  }


  initServices() {
    return false;
  }

  initOptions() {
    this.options = this.influencersFormOptionsService.get('options');
  }

  ngOnInit(): void {
    this.initServices();
    this.initOptions();
    this.initForm();
    this.influencersReshapeService.reshape(this.setup, 'activewear');
  }
}





