<article class="max--width management-table--container">
  <section class="full-width clearfix">
    <div class="section-container">
      <div class="container">
        <div fxLayoutAlign="start center">
          <div class="margin__right--s" data-cy="table-filter">
            <mat-form-field>
              <input
                matInput
                #usersFilter
                name="usersFilter"
                (keyup)="applyFilter($event.target.value)"
                placeholder="Filtrar"
              />
            </mat-form-field>
          </div>
          <div>
            <button
              mat-flat-button
              color="warn"
              class="button--xs"
              (click)="resetFilters()"
            >
              Borrar filtro
            </button>
          </div>
        </div>
        <table
          mat-table
          matSort
          [matSortActive]="defaultSorting"
          matSortDirection="desc"
          [dataSource]="dataSource"
          class="mat-elevation-z0 carrefour-users-table"
        >
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row"></td>
          </ng-container>

          <ng-container
            [matColumnDef]="col.key"
            *ngFor="let col of displayedColumns"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ col.display }}
            </th>
            <td mat-cell *matCellDef="let element">
              <p>{{ element[col.key] }}</p>
              <p *ngIf="col.role">
                <i [className]="'material-icons sub-icons small ' + element.role">
                  assignment_ind
                </i>
              </p>
              <p *ngIf="col.crud">
                <span class="button" [routerLink]="['/' + url + '/detalle/', element.id]" class="button">
                  <i class="material-icons sub-icons small"> create </i>Editar
                </span>
                |
                <span (click)="openDialog('Eliminar', element)" class="button">
                  <i class="material-icons sub-icons small"> delete </i>Eliminar
                </span>
              </p>

              <p *ngIf="col.study">
                <span [routerLink]="['/estudio/', element.id]" class="button">
                  <i class="material-icons sub-icons small"> create </i>Ver
                </span>
                |
                <span (click)="openDialog('Eliminar', element)" class="button">
                  <i class="material-icons sub-icons small"> delete </i>Eliminar
                </span>
              </p>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="stringColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: stringColumns"></tr>
        </table>

        <div class="text__align--right"></div>

        <mat-paginator
          [length]="data.length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </section>
</article>
