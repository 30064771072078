import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

export interface Crud {
  active: boolean;
  icon: string;
  key: string;
  value: string;
  role: string;
}
export interface Cruds extends Array<Crud> {}

@Injectable({ providedIn: 'root' })
export class CrudMenuService {
  crudMenuURL: string = 'assets/data/navigation/crud-menu-list.json';
  public crudMenuList$: BehaviorSubject<Cruds> = new BehaviorSubject<Cruds>([]);
  constructor(private http: HttpClient) {}

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  getCrudApi() {
    return this.http
      .get(`${this.crudMenuURL}`)
      .subscribe((d: Cruds) => this.crudMenuList$.next(d));
  }

  setCrudMenu(cruds: Cruds): void {
    this.crudMenuList$.next(cruds);
  }

  setActiveCrud(event: Event, toSetActive: string): void {
    const temp: Cruds = this.crudMenuList$.value.map((crud) => {
      crud['active'] = false;
      if (crud.key === toSetActive) {
        crud['active'] = true;
      }
      return crud;
    });
    this.setCrudMenu(temp);
  }

  getActiveCrud(): Crud {
    let activeCrud: Crud = this.crudMenuList$.value.filter(
      (crud) => crud.active === true
    )[0];
    return activeCrud;
  }
}
