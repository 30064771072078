import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
// @ts-ignore
import { PoisComparatorService } from '@compass/pois/comparator';

@Injectable({
  providedIn: 'root'
})
export class BottomContainerService implements OnDestroy {
  public visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public relativePos$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status$: BehaviorSubject<any> = new BehaviorSubject<any>('hide')
  private comparatorSub: Subscription;

  constructor() {
    // this.poisComparatorService.selected$.subscribe((selected: any) => {
    //   if (selected.length) {
    //     this.show();
    //   } else {
    //     this.hide();
    //   }
    // });

    // this.poisComparatorService.compareMode$.subscribe((active: any) => this.relativePos$.next(active));
  }

  ngOnDestroy() {
    this.comparatorSub?.unsubscribe();
  }

  public status(status?:any){
    if (status){
      this.status$.next(status);
    }
    return this.status$.value;
  }

  public show() {
    this.visible$.next(true);
  }

  public hide() {
    this.visible$.next(false);
  }

  public toggle() {
    this.visible$.next(!this.visible$.value);
  }

  public toggleRelativePos(){
    this.relativePos$.next(!this.relativePos$.value)
  }
}
