<mat-calendar
  #calendar
  *ngIf="showCalendar"
  [dateClass]="dateClass()"
  [selected]="selectedDate"
  (selectedChange)="onSelect($event)"
  (monthSelected)="monthChange()"
></mat-calendar>

<div class="knowledge-list-container">
  <mat-list>
    <div mat-subheader>Festividades</div>
    <mat-list-item *ngIf="!this.information.length">
      <div mat-line class="aligncenter">No hay festividades</div>
    </mat-list-item>
    <ng-container *ngIf="this.information">
      <mat-list-item
        *ngFor="let info of information"
          (mouseover)="toggleActive(info, true)"
          (mouseout)="toggleActive(info, false)"
      >
        <button mat-icon-button (click)="showDetails(info)" *ngIf="info.categoria === 'predicthq'">
          <mat-icon mat-list-icon>visibility</mat-icon>
        </button>
        <div mat-line [title]="info.descripcion || info.title">
          <strong>{{ info.descripcion || info.title }}</strong>
        </div>
        <div mat-line [title]="info.fecha | date">
          {{ info.fecha | date }} <br>
          <small *ngIf="info.phq_attendance" title="Predicción de asistentes" [style.color]="info.color || null">
            {{ info.phq_attendance }} asistentes
          </small>
        </div>
      </mat-list-item>
    </ng-container>
    <mat-list-item *ngIf="dayInformationEnabled">
      <div mat-line class="aligncenter">
        <button mat-button (click)="switchView(false)"> Ver todo</button>
      </div>
    </mat-list-item>
  </mat-list>
</div>




