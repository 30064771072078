import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { PoiService } from '@compass/pois/data-access-poi';
import { PoiProperty } from 'libs/pois/data-access-poi/src/lib/poi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-pois',
  templateUrl: './pois.component.html',
  styleUrls: ['./pois.component.scss'],
})
export class PoisComponent implements OnDestroy{
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('uploadComment', { static: false }) uploadComment: ElementRef;
  files = [];

  pois$ = this.poiService.pois$;

  displayedColumns = [
    {
      key: 'nombre',
      display: 'Nombre',
      crud: true,
    },
    {
      key: 'sub_categoria',
      display: 'Categoría',
    },
    {
      key: 'direccion',
      display: 'Dirección',
    },
  ];
  errors: boolean;

  allSubscription: Subscription;

  constructor(private poiService: PoiService, private _snackBar: MatSnackBar) {
    this.allSubscription = this.poiService.getAll().subscribe();
  }

  ngOnDestroy() {
    this.allSubscription?.unsubscribe();
  }

  changeFromDialog(poiProperty: PoiProperty): void {
    this.poiService.deletePoi(poiProperty.id);
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    const uploadComment = this.uploadComment.nativeElement.value;
    formData.append('remark', uploadComment);
    this.poiService.upload(formData, file.data.name).subscribe((event: any) => {
      if (typeof event === 'object') {
        let msg = 'Fichero subido correctamente';
        let duration = 3000;
        let panelClass = '';
        if (event.error) {
          msg = `Ha ocurrido un error con el fichero: ${event.error.DESCRIPTION}`;
          duration = 6000;
          panelClass = 'danger';
        }
        this._snackBar.open(msg, 'OK', {
          duration: duration,
          panelClass: panelClass
        });

        this.files = undefined;
      }
    });
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onClick() {
    const uploadComment = this.uploadComment.nativeElement.value;
    if (uploadComment.length) {
      this.errors = false;
      this.files = [];
      const fileUpload = this.fileUpload.nativeElement;
      fileUpload.onchange = () => {
        for (let index = 0; index < fileUpload.files.length; index++) {
          const file = fileUpload.files[index];
          this.files.push({ data: file });
        }
        this.uploadFiles();
      };
      fileUpload.click();
    } else {
      this.errors = true;
    }
  }
}
