<section>
  <div class="container">
    <section>
      <div class="container">
        <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
      </div>
    </section>

    <section>
      <div class="container">
        <compass-pois-searcher></compass-pois-searcher>
        <glo-places-loader></glo-places-loader>
      </div>
    </section>
  </div>
</section>
