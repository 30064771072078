import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { Map } from '@compass/utils/leaflet';
import { MapService } from './map.service';
import { LocationService } from '@compass/utils/misc';
import { Subject } from 'rxjs';

@Injectable()
export class MarkerService {
  studyFormNavOn: boolean;
  stagesNavOn: boolean;
  map: Map = this.mapService.map;
  public marker$ = new Subject();

  removeGeojsonLayer: (layer: any) => void;
  drawCtrl: (draw: boolean, study: any) => void;

  coords: Array<number>;
  areaPainted: string;
  study: any;
  cpStudyOption: any;

  constructor(
    private mapService: MapService,
    private locationService: LocationService
  ) {
  }

  removeMarker(): void {
    if (this.map.marker.options.name) {
      this.mapService.removeGeojsonLayer(this.map.marker.options.name);
    }
  }

  createPane(): void {
    this.map[this.map.marker.options.name] = this.map.box.createPane(
      this.map.marker.options.name
    );
  }

  stylePane(): void {
    this.map.box.getPane(this.map.marker.options.name)['style']['zIndex'] =
      this.map.marker.options.zIndex;
    this.map.box.getPane(this.map.marker.options.name)['style']['width'] =
      this.map.marker.options.width + 'px';
  }

  addMarker(): void {
    this.map['marker']['l'] = L.marker(this.map.properties.center, {
      icon: L.icon(this.map.marker.icon),
      pane: this.map.marker.options.name,
      title: '',
      alt: this.map.marker.options.name,
      draggable: !this.stagesNavOn
    }).addTo(this.map.box);

    this.map['marker']['l'].on('dragend', (e) => {
      var latlng = e.target.getLatLng();
      this.map.properties.center[0] = latlng.lat;
      this.map.properties.center[1] = latlng.lng;

      this.mapService.setMapCenter([
        this.map.properties.center[0],
        this.map.properties.center[1]
      ]);

      this.mapService.setView(this.map);
      this.locationService.geocodificarCoordenadas({
        lat: latlng.lat,
        lon: latlng.lng
      }).subscribe((respuesta: any) => {
        const geoData = this.locationService.buscarDireccion(respuesta);
        this.marker$.next(geoData);
      });
    });
  }

  public drawMarker(): void {
    this.removeMarker();
    this.createPane();
    this.stylePane();
    this.addMarker();
  }
}
