import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'compass-seven-days-weather',
  templateUrl: './seven-days-weather.component.html',
  styleUrls: ['./seven-days-weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SevenDaysWeatherComponent implements OnChanges {
  @Input() sevenDaysWeather: any;

  //img
  imgUrl = '../../../../assets/img/weather/SVG/';

  // vars
  days: Array<any>;
  elaborado: Date;
  provincia: string;
  municipio: string;
  copyright: string;
  enlace: string;
  notaLegal: string;
  productor: string;
  web: string;

  // expansion panel
  panelOpenState = true;
  currentlyOpenedItemIndex = -1;
  items = [];

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  getWeatherImg(arr, key, period) {
    let temp = '';
    if (!arr || arr.length === 0) {
      temp = '999';
    }
    if (arr.length > 1) {
      temp = arr.filter((d) => d.periodo === period)[0][key];
      if (!temp || temp === '') {
        temp = arr.filter((d) => d[key] !== '')[0][key];
        if (!temp || temp === '') {
          temp = '999';
        }
      }
    }
    if (arr.length === 1) {
      temp = arr[0][key];
      if (!temp || temp === '') {
        temp = '999';
      }
    }
    return this.imgUrl + temp + '.svg';
  }

  // @arr: array of days
  // @time: key to filter (periodo, hora)-string or number
  // @property: key to return (descripcion, value)
  // @range: period of time ('00-24', '6')
  getWeatherText(
    arr: any[],
    time: string,
    property: string,
    range: string | number
  ): string {
    let temp = '';
    if (!arr || arr.length === 0) {
      temp = 's.d.';
    }
    if (arr.length > 1) {
      temp = arr.filter((d) => d[time] === range)[0][property].toString();
      if (!temp || temp === '') {
        temp = arr.filter((d) => d[property] !== '')[0]
          ? arr.filter((d) => d[property] !== '')[0][property]
          : 's.d.';
      }
    }
    if (arr.length === 1) {
      temp = arr[0][property];
      if (!temp || temp === '') {
        temp = 's.d.';
      }
    }
    return temp;
  }
  // helpers
  getWeatherNumber(val, symbol) {
    if (val && typeof +val === 'number') {
      return val + symbol;
    }
    return 's.d.';
  }

  getVelocidadText(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      return temp + ' km/h.';
    }
    return temp;
  }

  getCotaNieveText(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      return temp + ' metros';
    }
    return temp;
  }

  probPrecipitacion(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      return temp + '%';
    }
    return temp;
  }
  getHumedadRelativa(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      return temp + '%';
    }
    return temp;
  }
  getRachaMax(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      return temp + 'km/hora';
    }
    return temp;
  }
  getSensTermica(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    let result = this.getWeatherNumber(temp, '°');
    return result;
  }

  getVientoText(arr, time, property, range) {
    let temp = this.getWeatherText(arr, time, property, range);
    if (temp !== 's.d.') {
      if (temp === 'C') {
        return 'Viento en calma';
      }
      return 'Viento del ' + temp;
    }
    return temp;
  }

  getSensTermicaImg(val) {
    let temp = 'sensTermicaMax';
    if (val && typeof +val === 'number') {
      if (val <= 0) {
        temp = 'sensTermicaIce';
      }
      if (val > 0 && val < 15) {
        temp = 'sensTermicaMin';
      }
      if (val >= 15 && val < 30) {
        temp = 'sensTermicaMax';
      }
      if (val >= 30) {
        temp = 'sensTermicaHot';
      }
    }
    return this.imgUrl + temp + '.svg';
  }

  constructor() { }

  ngOnChanges(): void {
    this.days = this.sevenDaysWeather.prediccion.dia;
    this.elaborado = new Date(this.sevenDaysWeather.elaborado);
    this.provincia = this.sevenDaysWeather.provincia;
    this.municipio = this.sevenDaysWeather.nombre;
    this.copyright = this.sevenDaysWeather.origen.copyright;
    this.enlace = this.sevenDaysWeather.origen.enlace;
    this.notaLegal = this.sevenDaysWeather.origen.notaLegal;
    this.productor = this.sevenDaysWeather.origen.productor;
    this.web = this.sevenDaysWeather.origen.web;
  }
}
