import {Component, OnDestroy, OnInit} from '@angular/core';
import {PoiBasketService} from '../poi-basket.service';
import {Subscription} from 'rxjs';
import {StudyService} from '@compass/studies/data-access-study';
import {EndRightSidenavService} from '@compass/page-navigation/end-right-sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'compass-basket-list',
  templateUrl: './basket-list.component.html',
  styleUrls: ['./basket-list.component.scss']
})
export class BasketListComponent implements OnInit, OnDestroy {
  public basket;
  public hours = {};
  public basketEmpty: boolean;
  public showPlanOnly: boolean;

  public loading: boolean;
  public active: boolean;
  private study: any;

  private showPlanElementsSub: Subscription;
  private basketSub: Subscription;
  private hoursSub: Subscription;
  private studySub: Subscription;


  constructor(
    public poiBasketService: PoiBasketService,
    private studyService: StudyService,
    private endRightSidenavService: EndRightSidenavService,
    private snackBar: MatSnackBar
  ) {

    this.basketEmpty = false;
    this.showPlanOnly = false;
    this.loading = true;
    this.active = false;
    this.basket = this.poiBasketService.getBasket();
    this.showPlanOnly = this.poiBasketService.showPlanElementsOnly$.value;
    this.poiBasketService.active$.subscribe(active => {
      this.active = active;
      const study = this.studyService.study$.value;
      if (active && study?.properties?.id) {
        this.endRightSidenavService.setTitle(`
          Plan del Estudio
          <span class="studyIdBadge" title="Haz click para copiar el id">
            ${study.properties.id}
          </span>
        `);
      }
    });

    this.showPlanElementsSub = this.poiBasketService.showPlanElementsOnly$.subscribe(show => {
      this.showPlanOnly = show;
      const leafletContainer = document.getElementsByClassName('leaflet-container');
      if (leafletContainer.length) {
        if (!this.showPlanOnly) {
          leafletContainer[0].classList.remove('show-basket');
        } else {
          leafletContainer[0].classList.add('show-basket');
        }
      }
    });

    this.hoursSub = this.poiBasketService.hours$.subscribe(hours => {
      this.hours = hours;
      this.checkIfBasketEmpty();
    });

    this.basketSub = this.poiBasketService.basket$.subscribe(b => {
      this.basket = b;
      this.checkIfBasketEmpty();
      this.loading = false;
    });


    // when study loaded
    this.studySub = this.studyService.study$.subscribe(study => {
      if (study && study.properties) {
        this.study = study;
        this.poiBasketService.hours$.next(study.properties?.basket?.hours);
      }

      this.checkIfBasketEmpty();
    });

    this.checkIfBasketEmpty();
  }

  ngOnDestroy(): void {
    this.endRightSidenavService.setTitle('');
    this.hoursSub?.unsubscribe();
    this.showPlanElementsSub?.unsubscribe();
    this.basketSub?.unsubscribe();
    this.studySub?.unsubscribe();
  }

  ngOnInit(): void {
    document.addEventListener('click', (e: MouseEvent) => {
      // listen when clicked on badge
      if (e.target && (e.target as Element).classList.contains('studyIdBadge')) {
        navigator.clipboard.writeText(this.study.properties.id + '').then(() => {
          this.snackBar.open('Copiado al portapapeles', undefined, {
            duration: 1300
          });
        });
      }
    });

  }

  removeFromBasket(poi) {
    this.poiBasketService.remove(poi, true);
    this.checkIfBasketEmpty();
  }

  checkIfBasketEmpty() {
    this.basketEmpty = this.poiBasketService.empty$.value;

    // if basket empty reset shoPlanElementsOnly
    if (this.basketEmpty) {
      this.poiBasketService.showPlanElementsOnly$.next(false);
    }
  }

  saveBasket() {
    this.loading = true;

    const study = this.studyService.study$.value;
    const basket = this.poiBasketService.getFormattedBasket();
    basket['hours'] = this.hours;

    this.studyService.updateStudy(study.properties.id, 'properties.basket', basket)
      .subscribe(res => {
        this.loading = false;
        this.snackBar.dismiss();
        this.snackBar.open('Plan de estudio guardado correctamente', undefined, {
          panelClass: 'success',
          duration: 2400
        });
      });
  }

  downloadCsv() {
    const plainBasket = this.poiBasketService.getPlainBaket();
    const study = this.studyService.study$.value;

    if (study && study.properties?.id) {
      const headers = ['id', 'categoria', 'sub_categoria', 'nombre', 'direccion', 'comentarios', 'x', 'y'];
      const rows = plainBasket.map((poi: any) => {
        const geoData = poi.properties.centroid?.coordinates || poi.geometry?.coordinates || ['', ''];

        return [
          poi.properties.id,
          poi.properties.categoria ? `"${poi.properties.categoria}"` : 'brick',
          poi.properties.key_ensenya_desc ? `"${poi.properties.key_ensenya_desc}"` : '',
          poi.properties.nombre ? `"${poi.properties.nombre}"` : '',
          poi.properties.direccion ? `"${poi.properties.direccion}"` : '',
          poi.properties.sub_categoria ? `"${poi.properties.sub_categoria}"` : '',
          geoData[0],
          geoData[1]
        ];
      });

      const csvContent = headers.join(',') + '\r\n' + rows.join('\r\n');

      const a = document.createElement('a');
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = `Plan_estudio_${study.properties.id}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }
}
