<div class="accordion">
    <mat-accordion flat>
        <mat-expansion-panel (opened)="setup.opened" (closed)="setup.closed" [expanded]="setup.expanded"
            class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{setup.title}}
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-content></ng-content>
        </mat-expansion-panel>
    </mat-accordion>
</div>