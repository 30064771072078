import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PoiFormComponent } from './poi-form/poi-form.component';
import { MaterialModule } from '@compass/material';
import { ReactiveFormsModule } from '@angular/forms';
import { PoiService } from '@compass/pois/data-access-poi';
import { CategoryService } from '@compass/categories/data-access-category';
import { MarkerService } from '@compass/shared/map/feature-map';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  declarations: [PoiFormComponent],
  exports: [PoiFormComponent],
  providers: [PoiService, CategoryService, MarkerService],
})
export class PoisFeaturePoiFormModule {}
