<div class="container padding__basic">

    <section>
        <div>
            <form [formGroup]="formGroup" target="_blank" class="form" id="traffic_form" name="traffic_form"
                (ngSubmit)="onSubmit()">

                <div class="container">
                    <div fxFlex="100" fxLayout="row">
                        <div class="margin__top--xs container">
                            <mat-checkbox class="example-margin" formControlName="visibility" [checked]="true"
                                color="primary">
                                {{ checkboxText }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>



                <div class="margin__top--xs">
                    <p class="margin__bottom-xxs"><strong><small>Tipo de velocidad:</small></strong></p>

                    <mat-form-field appearance="fill">
                        <mat-label>Variables</mat-label>
                        <mat-select formControlName="map">
                            <mat-option *ngFor="let style of styles" [value]="style">
                                {{style.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


            </form>
        </div>
    </section>
    <section class="margin__top--xs">
        <div>
            <p><strong>Clave {{style.name}}</strong></p>
            <p *ngFor="let child of style.children"><span [style.background]="child.line_main_colors"
                    [style.width.px]="16" [style.height.px]="16"
                    style="display:inline-block; margin-right: 8px;"></span><span
                    style="display:inline-block">{{child.intervals_es}}</span>
            </p>
        </div>
        <div class="margin__top--l">
            <p><strong>Descripción</strong></p>
            <p>{{style.description}}</p>
        </div>
        <div class="margin__top--l">
            <p>
                <small><strong>Fuente</strong>: TomTom Developer Portal.</small>
            </p>

        </div>

    </section>
    <!-- columns -->
</div>