import { Component, OnInit } from '@angular/core';
import { CreateStudyService } from '@compass/feature-create-study';


@Component({
  selector: 'glo-stage-traffic-flow',
  templateUrl: './stage-traffic-flow.component.html',
  styleUrls: ['./stage-traffic-flow.component.scss']
})
export class StageTrafficFlowComponent implements OnInit {
  coordsCenter: Array<number>;

  constructor(private createStudyService: CreateStudyService) { }

  ngOnInit(): void {
    this.coordsCenter = this.createStudyService.getCoordsMarker();

  }

}