import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatDialogNotFoundComponent } from '@compass/shared/mat-dialog-not-found';
import { pairwise, debounceTime, startWith } from 'rxjs/operators';
import {
  IdealistaFormOptionsService,
  Options,
} from './idealista-form-options.service';
import { IdealistaReshapePoisService } from './idealista-reshape-pois.service';
import { CompassMapIdealistaService } from '../../../../utils/leaflet/src/lib/compass-map-idealista.service';
import { CompassMapWrapperService } from '../../../../utils/leaflet/src/lib/compass-map-wrapper.service';
import { EndRightSidenavService } from '../../../../page-navigation/end-right-sidenav/src/lib/end-right-sidenav.service';
import { IdealistaSidenavService } from '../idealista-sidenav/idealista-sidenav.service';
import { OnclickStageShapeService } from '@compass/utils/navigation'

import * as L from 'leaflet';

@Component({
  selector: 'compass-idealista-form',
  templateUrl: './idealista-form.component.html',
  styleUrls: ['./idealista-form.component.scss'],
})
export class IdealistaFormComponent implements OnInit {
  @Input() poisTableList: any;
  poisList: any;

  // form
  idealistaForm: FormGroup;
  idealistaOptions: Array<Options>;
  typeOptions: Array<Options>;
  agencyOptions: Array<Options>;
  categoryOptions: Array<Options>;
  priceMinOptions: Array<Options>;
  priceMaxOptions: Array<Options>;
  sqmMinOptions: Array<Options>;
  sqmMaxOptions: Array<Options>;
  roomsOptions: Array<Options>;
  top_value: number;
  public checkboxText: string = 'Ocultar inmuebles';

  // form functions
  setMaxOptions;
  setControlValue;
  minIsHigher;
  // resetForm;
  onSubmit;

  // data
  reshape: any;
  // map and layers
  map: any;
  box: any;

  // drawPois
  drawPois: any;
  buildCircleMarkers: any;
  popUpText: any;
  addMarkersToMap: any;
  removeIdealistaLayer: any;
  fitBoundsCategoryGroup: any;
  getCategoryColor: any;
  clickCircleMarker: any;
  mouseoverCircleMarker: any;
  mouseoutCircleMarker: any;
  // L.control
  drawIdealistaControl: any;
  removeIdealistaControl: any;
  reshapeIdealistaControl: any;
  getControlInnerHTML: any;
  innerControlHelpers: any;

  // sidenav
  setSidenavStatus: any;

  constructor(
    private idealistaFormOptionsService: IdealistaFormOptionsService,
    private compassMapIdealistaService: CompassMapIdealistaService,
    private compassMapWrapperService: CompassMapWrapperService,
    private idealistaReshapePoisService: IdealistaReshapePoisService,
    private endRightSidenavService: EndRightSidenavService,
    private idealistaSidenavService: IdealistaSidenavService,
    private onclickStageShapeService: OnclickStageShapeService,
    public dialog: MatDialog
  ) {

  }

  // *******************************
  // **** FORM *******
  // *******************************

  getOptions(type) {
    this.typeOptions = this.idealistaOptions.filter((d) => d.value === type);
    this.categoryOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'type'
    );
    this.agencyOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'agency'
    );
    this.priceMinOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'price_min'
    );
    this.priceMaxOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'price_max'
    );
    this.sqmMinOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'size_min'
    );
    this.sqmMaxOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'size_max'
    );
    this.roomsOptions = this.typeOptions[0]['children'].filter(
      (d) => d.value === 'rooms'
    );
  }

  initIdealistaForm() {
    this.idealistaForm = new FormGroup({
      visibility: new FormControl(true),
      type: new FormControl(this.typeOptions[0].value),
      agency: new FormControl(3),
      category: new FormControl('all'),
      price_min: new FormControl(this.priceMinOptions[0].children[0].value),
      price_max: new FormControl(this.top_value),
      size_min: new FormControl(this.sqmMinOptions[0].children[0].value),
      size_max: new FormControl(this.top_value),
      rooms: new FormControl('all'),
    });

    this.idealistaForm.get('visibility').valueChanges.subscribe((value) => {
      let x = value
      if (!value) {
        this.removeIdealistaLayer('idealistaLayer');
        this.checkboxText = 'Ver inmuebles';
      } else {
        this.checkboxText = 'Ocultar inmuebles'
      }

    });

    this.idealistaForm.get('type').valueChanges.subscribe((value) => {
      this.getOptions(value);
      this.setControlValue(
        this.idealistaForm,
        'price_min',
        this.priceMinOptions[0].children[0].value
      );
      this.setControlValue(this.idealistaForm, 'price_max', this.top_value);
      // do not rehape here, wait...
    });

    this.idealistaForm.get('price_min').valueChanges.subscribe((value) => {
      this.priceMaxOptions = this.setMaxOptions(
        [this.priceMinOptions, this.priceMaxOptions],
        value
      );
      if (this.minIsHigher(this.idealistaForm, ['price_min', 'price_max'])) {
        this.setControlValue(this.idealistaForm, 'price_max', this.top_value);
        // don´t reshape, wait to price_max change
      }
    });

    this.idealistaForm.get('size_min').valueChanges.subscribe((value) => {
      this.sqmMaxOptions = this.setMaxOptions(
        [this.sqmMinOptions, this.sqmMaxOptions],
        value
      );
      if (this.minIsHigher(this.idealistaForm, ['size_min', 'size_max'])) {
        this.setControlValue(this.idealistaForm, 'size_max', this.top_value);
        // don´t reshape, wait to size_max change
      }
    });

    // rxjs/operators
    this.idealistaForm.valueChanges
      .pipe(startWith(this.idealistaForm.value), debounceTime(500), pairwise())
      .subscribe(([old, value]) => {
        this.reshapeTableList(value, this.poisTableList);
        if (value.visibility) {
          this.checkboxText = 'Ocultar inmuebles';
          this.removeIdealistaLayer('idealistaLayer');
          this.drawPois(this.poisList, 'idealistaLayer', 'featureGroup');
          this.reshapeIdealistaControl(this.poisList);

        }


        return;

        //console.log(old, value)
      });
  }

  resetForm() {
    this.getOptions('Comprar');
    this.setControlValue(this.idealistaForm, 'visibility', true);
    this.setControlValue(this.idealistaForm, 'type', this.typeOptions[0].value);
    this.setControlValue(this.idealistaForm, 'agency', 3);
    this.setControlValue(this.idealistaForm, 'category', 'all');
    this.setControlValue(
      this.idealistaForm,
      'price_min',
      this.priceMinOptions[0].children[0].value
    );
    this.setControlValue(this.idealistaForm, 'price_max', this.top_value);
    this.setControlValue(
      this.idealistaForm,
      'size_min',
      this.sqmMinOptions[0].children[0].value
    );
    this.setControlValue(this.idealistaForm, 'size_max', this.top_value);
    this.setControlValue(this.idealistaForm, 'rooms', 'all');
  }
  initServices() {
    // form
    this.setMaxOptions = this.idealistaFormOptionsService.setMaxOptions;
    this.setControlValue = this.idealistaFormOptionsService.setControlValue;
    this.minIsHigher = this.idealistaFormOptionsService.minIsHigher;
    //this.resetForm = this.idealistaFormOptionsService.resetForm;
    this.onSubmit = this.idealistaFormOptionsService.onSubmit;
    this.idealistaOptions = this.idealistaFormOptionsService.getAll();
    this.top_value = this.idealistaFormOptionsService.top_value;
    // data
    this.reshape = this.idealistaReshapePoisService.reshape;
    // map and layers
    this.map = this.compassMapWrapperService.map$.value;
    this.box = this.map.box;
    this.drawPois = this.compassMapIdealistaService.drawPois;
    // from service
    this.buildCircleMarkers = this.compassMapIdealistaService.buildCircleMarkers;

    this.fitBoundsCategoryGroup = this.compassMapIdealistaService.fitBoundsCategoryGroup;
    this.removeIdealistaLayer = this.compassMapIdealistaService.removeIdealistaLayer;
    this.getCategoryColor = this.compassMapIdealistaService.getCategoryColor;
    this.clickCircleMarker = this.compassMapIdealistaService.clickCircleMarker;
    this.mouseoverCircleMarker = this.compassMapIdealistaService.mouseoverCircleMarker;
    this.mouseoutCircleMarker = this.compassMapIdealistaService.mouseoutCircleMarker;
    this.setSidenavStatus = this.endRightSidenavService.setSidenavStatus;
    this.drawIdealistaControl = this.compassMapIdealistaService.drawIdealistaControl;
    this.removeIdealistaControl = this.compassMapIdealistaService.removeIdealistaControl;
    this.reshapeIdealistaControl = this.compassMapIdealistaService.reshapeIdealistaControl;
    this.getControlInnerHTML = this.compassMapIdealistaService.getControlInnerHTML;
    this.innerControlHelpers = this.compassMapIdealistaService.innerControlHelpers;
    this.popUpText = this.compassMapIdealistaService.popUpText;
    this.addMarkersToMap = this.compassMapIdealistaService.addMarkersToMap;




  }


  reshapeTableList(formValues, tableList) {
    this.poisList = this.reshape(formValues, tableList);
    if (this.poisList.length <= 0) {
      this.openDialog();
    }
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    let dialogRef = this.dialog.open(MatDialogNotFoundComponent, dialogConfig);
  };

  ngOnInit(): void {

    this.initServices();
    this.getOptions('Comprar');
    this.initIdealistaForm();
    this.reshapeTableList(this.idealistaForm.value, this.poisTableList);
    this.checkboxText = 'Ocultar inmuebles';
    this.removeIdealistaLayer('idealistaLayer');
    this.drawPois(this.poisList, 'idealistaLayer', 'featureGroup');

    this.fitBoundsCategoryGroup('idealistaLayer');
    this.drawIdealistaControl(this.poisList);
  }

  ngOnDestroy() {
    this.removeIdealistaControl();
  }
}

