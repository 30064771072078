import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoisComparatorComponent } from './pois-comparator/pois-comparator.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { _MatMenuDirectivesModule, MatMenuModule } from '@angular/material/menu';
import { PopularTimesModule } from '@compass/popular-times';
import {
  BusiestHoursComparatorComponent
} from './components/busiest-hours-comparator/busiest-hours-comparator.component';
import { PoiTitleComponent } from './components/busiest-hours-comparator/poi-title/poi-title.component';
import { PoiSelectComponent } from './components/busiest-hours-comparator/poi-select/poi-select.component';
import { PopularTimeMetricsComponent } from './components/popular-time-metrics/popular-time-metrics.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PresenceComponent } from './components/presence/presence.component';
import { PresenceTotalComponent } from './components/presence-total/presence-total.component';
import { MutedStackedbarComponent } from './components/muted-stackedbar/muted-stackedbar.component';
import { PresenceKeysComponent } from './components/presence-keys/presence-keys.component';
import { PresenceIncomeComponent } from './components/presence-income/presence-income.component';
import {
  InlineCirclesChartLayoutService
} from './components/d3/inline-circles-chart/inline-circles-chart-layout.service';
import {
  GroupedVerticalBarchartComponent
} from './components/d3/grouped-vertical-barchart/grouped-vertical-barchart.component';
import { InlineCirclesChartComponent } from './components/d3/inline-circles-chart/inline-circles-chart.component';
import { PresenceGenderComponent } from './components/presence-gender/presence-gender.component';

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    PopularTimesModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  declarations: [
    PoisComparatorComponent,
    BusiestHoursComparatorComponent,
    PoiTitleComponent,
    PoiSelectComponent,
    PopularTimeMetricsComponent,
    PresenceComponent,
    PresenceTotalComponent,
    MutedStackedbarComponent,
    PresenceKeysComponent,
    PresenceIncomeComponent,
    GroupedVerticalBarchartComponent,
    InlineCirclesChartComponent,
    PresenceGenderComponent

  ],
  exports: [
    PoisComparatorComponent
  ],
  providers: [
    InlineCirclesChartLayoutService
  ]
})
export class PoisComparatorModule {
}
