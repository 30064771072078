<div class='container padding__basic'>

  <form [formGroup]='form' target='_blank' class='local-form' id='local_knowledge_form' name='local_knowledge_form'>
    <div class='container'>

      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='100'>
          <p class="margin__bottom-xxs"><strong><small>Línea del tiempo:</small></strong></p>
          <mat-form-field>
            <mat-label>Rango</mat-label>
            <mat-select formControlName="timeline">
              <mat-option value="0">Por horas</mat-option>
              <mat-option value="1">Por días</mat-option>

            </mat-select>

          </mat-form-field>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='100'>
          <p class='margin__bottom-xxs'><strong><small>Día de la semana:</small></strong></p>
          <mat-form-field *ngIf="form.controls.timeline.value === '0'; else elseTimeline">
            <mat-label>Día de la semana</mat-label>
            <mat-select formControlName='day'>
              <mat-option value='1'>Lunes</mat-option>
              <mat-option value='2'>Martes</mat-option>
              <mat-option value='3'>Miércoles</mat-option>
              <mat-option value='4'>Jueves</mat-option>
              <mat-option value='5'>Viernes</mat-option>
              <mat-option value='6'>Sábado</mat-option>
              <mat-option value='0'>Domingo</mat-option>
              <mat-option value='7'>Toda la semana</mat-option>
            </mat-select>

          </mat-form-field>

          <ng-template #elseTimeline>
            <mat-form-field>
              <mat-label>Día de la semana</mat-label>
              <mat-select formControlName="days" multiple="multiple">
                <mat-option value="1">Lunes</mat-option>
                <mat-option value="2">Martes</mat-option>
                <mat-option value="3">Miércoles</mat-option>
                <mat-option value="4">Jueves</mat-option>
                <mat-option value="5">Viernes</mat-option>
                <mat-option value="6">Sábado</mat-option>
                <mat-option value="0">Domingo</mat-option>

              </mat-select>
            </mat-form-field>



          </ng-template>

          <mat-checkbox formControlName='hidePois'>Ocultar pois</mat-checkbox>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='100'>
          <p class='margin__bottom-xxs'><strong><small>Horario:</small></strong></p>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='50'>
          <div class='margin__top--xs container'>
            <mat-form-field appearance='fill'>
              <mat-label>Desde</mat-label>
              <mat-select formControlName='startHour'>
                <mat-option value='0' [disabled]='this.form.controls.endHour.value <= 0'>6 AM</mat-option>
                <mat-option value='1' [disabled]='this.form.controls.endHour.value <= 1'>7 AM</mat-option>
                <mat-option value='2' [disabled]='this.form.controls.endHour.value <= 2'>8 AM</mat-option>
                <mat-option value='3' [disabled]='this.form.controls.endHour.value <= 3'>9 AM</mat-option>
                <mat-option value='4' [disabled]='this.form.controls.endHour.value <= 4'>10 AM</mat-option>
                <mat-option value='5' [disabled]='this.form.controls.endHour.value <= 5'>11 AM</mat-option>
                <mat-option value='6' [disabled]='this.form.controls.endHour.value <= 6'>12 AM</mat-option>
                <mat-option value='7' [disabled]='this.form.controls.endHour.value <= 7'>1 PM</mat-option>
                <mat-option value='8' [disabled]='this.form.controls.endHour.value <= 8'>2 PM</mat-option>
                <mat-option value='9' [disabled]='this.form.controls.endHour.value <= 9'>3 PM</mat-option>
                <mat-option value='10' [disabled]='this.form.controls.endHour.value <= 10'>4 PM</mat-option>
                <mat-option value='11' [disabled]='this.form.controls.endHour.value <= 11'>5 PM</mat-option>
                <mat-option value='12' [disabled]='this.form.controls.endHour.value <= 12'>6 PM</mat-option>
                <mat-option value='13' [disabled]='this.form.controls.endHour.value <= 13'>7 PM</mat-option>
                <mat-option value='14' [disabled]='this.form.controls.endHour.value <= 14'>8 PM</mat-option>
                <mat-option value='15' [disabled]='this.form.controls.endHour.value <= 15'>9 PM</mat-option>
                <mat-option value='16' [disabled]='this.form.controls.endHour.value <= 16'>10 PM</mat-option>
                <mat-option value='17' [disabled]='this.form.controls.endHour.value <= 17'>11 PM</mat-option>
                <mat-option value='18' [disabled]='this.form.controls.endHour.value <= 18'>12 PM</mat-option>
                <mat-option value='19' [disabled]='this.form.controls.endHour.value <= 19'>1 AM</mat-option>
                <mat-option value='20' [disabled]='this.form.controls.endHour.value <= 20'>2 AM</mat-option>
                <mat-option value='21' [disabled]='this.form.controls.endHour.value <= 21'>3 AM</mat-option>
                <mat-option value='22' [disabled]='this.form.controls.endHour.value <= 22'>4 AM</mat-option>
                <mat-option value='23' [disabled]='this.form.controls.endHour.value <= 23'>5 AMS</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex='50'>
          <div class='margin__top--xs container'>
            <mat-form-field appearance='fill'>
              <mat-label>Hasta</mat-label>
              <mat-select formControlName='endHour'>
                <mat-option value='0' [disabled]='this.form.controls.startHour.value >= 0'>6 AM</mat-option>
                <mat-option value='1' [disabled]='this.form.controls.startHour.value >= 1'>7 AM</mat-option>
                <mat-option value='2' [disabled]='this.form.controls.startHour.value >= 2'>8 AM</mat-option>
                <mat-option value='3' [disabled]='this.form.controls.startHour.value >= 3'>9 AM</mat-option>
                <mat-option value='4' [disabled]='this.form.controls.startHour.value >= 4'>10 AM</mat-option>
                <mat-option value='5' [disabled]='this.form.controls.startHour.value >= 5'>11 AM</mat-option>
                <mat-option value='6' [disabled]='this.form.controls.startHour.value >= 6'>12 AM</mat-option>
                <mat-option value='7' [disabled]='this.form.controls.startHour.value >= 7'>1 PM</mat-option>
                <mat-option value='8' [disabled]='this.form.controls.startHour.value >= 8'>2 PM</mat-option>
                <mat-option value='9' [disabled]='this.form.controls.startHour.value >= 9'>3 PM</mat-option>
                <mat-option value='10' [disabled]='this.form.controls.startHour.value >= 10'>4 PM</mat-option>
                <mat-option value='11' [disabled]='this.form.controls.startHour.value >= 11'>5 PM</mat-option>
                <mat-option value='12' [disabled]='this.form.controls.startHour.value >= 12'>6 PM</mat-option>
                <mat-option value='13' [disabled]='this.form.controls.startHour.value >= 13'>7 PM</mat-option>
                <mat-option value='14' [disabled]='this.form.controls.startHour.value >= 14'>8 PM</mat-option>
                <mat-option value='15' [disabled]='this.form.controls.startHour.value >= 15'>9 PM</mat-option>
                <mat-option value='16' [disabled]='this.form.controls.startHour.value >= 16'>10 PM</mat-option>
                <mat-option value='17' [disabled]='this.form.controls.startHour.value >= 17'>11 PM</mat-option>
                <mat-option value='18' [disabled]='this.form.controls.startHour.value >= 18'>12 PM</mat-option>
                <mat-option value='19' [disabled]='this.form.controls.startHour.value >= 19'>1 AM</mat-option>
                <mat-option value='20' [disabled]='this.form.controls.startHour.value >= 20'>2 AM</mat-option>
                <mat-option value='21' [disabled]='this.form.controls.startHour.value >= 21'>3 AM</mat-option>
                <mat-option value='22' [disabled]='this.form.controls.startHour.value >= 22'>4 AM</mat-option>
                <mat-option value='23' [disabled]='this.form.controls.startHour.value >= 23'>5 AM</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='100'>
          <p class='margin__bottom-xxs'><strong><small>Rating:</small></strong></p>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>

        <div fxFlex='50'>
          <div class='margin__top--xs container'>

            <mat-form-field appearance='fill'>
              <mat-label>Desde</mat-label>
              <mat-select formControlName='minRating'>
                <mat-option [disabled]='this.form.controls.maxRating.value < 0' value='0'>0</mat-option>
                <mat-option [disabled]='this.form.controls.maxRating.value < 1' value='1'>1</mat-option>
                <mat-option [disabled]='this.form.controls.maxRating.value < 2' value='2'>2</mat-option>
                <mat-option [disabled]='this.form.controls.maxRating.value < 3' value='3'>3</mat-option>
                <mat-option [disabled]='this.form.controls.maxRating.value < 4' value='4'>4</mat-option>
                <mat-option [disabled]='this.form.controls.maxRating.value < 5' value='5'>5</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex='50'>
          <div class='margin__top--xs container'>
            <mat-form-field appearance='fill'>
              <mat-label>Hasta</mat-label>
              <mat-select formControlName='maxRating'>
                <mat-option [disabled]='this.form.controls.minRating.value > 0' value='0'>0</mat-option>
                <mat-option [disabled]='this.form.controls.minRating.value > 1' value='1'>1</mat-option>
                <mat-option [disabled]='this.form.controls.minRating.value > 2' value='2'>2</mat-option>
                <mat-option [disabled]='this.form.controls.minRating.value > 3' value='3'>3</mat-option>
                <mat-option [disabled]='this.form.controls.minRating.value > 4' value='4'>4</mat-option>
                <mat-option [disabled]='this.form.controls.minRating.value > 5' value='5'>5</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
      </div>
      <div fxLayout='row' fxLayoutGap='4%'>
        <div fxFlex='50'>
          <div class='margin__top--xs container'>
            <p class='margin__bottom-xxs'><strong><small>Animación:</small></strong></p>
            <mat-form-field appearance='fill'>
              <mat-label>Velocidad</mat-label>
              <mat-select formControlName='velocity'>
                <mat-option value='5000'>1</mat-option>
                <mat-option value='2000'>2</mat-option>
                <mat-option value='1000'>3</mat-option>
                <mat-option value='500'>4</mat-option>
                <mat-option value='300'>5</mat-option>
                <mat-option value='100'>6</mat-option>
                <mat-option value='20'>7</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
