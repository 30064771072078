import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BestTimesService } from '@compass/best-times';

@Component({
  selector: 'compass-comparator-poi-title',
  templateUrl: './poi-title.component.html',
  styleUrls: ['./poi-title.component.scss']
})
export class PoiTitleComponent implements OnChanges {
  @Input() poi;
  @Input() compared: any = [];
  @Input() canUpdate: boolean = true;

  comparedTitle = '';

  constructor(public bestTimes: BestTimesService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.compared && this.compared.length) {
      this.comparedTitle = `${this.compared[0] ?? '--'} vs ${this.compared[1] ?? '--'}`;
    }
  }

  showBestimes(e: Event) {
    e.stopPropagation();
    this.bestTimes.showOptionsFor(this.poi);
  }

}
