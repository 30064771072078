import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import {
  SideNavIndicatorsService,
  Indicator,
  IndicatorsGroups,
} from '@compass/utils/navigation';

@Component({
  selector: 'glo-stage-indicators',
  templateUrl: './stage-indicators.component.html',
  styleUrls: ['./stage-indicators.component.css'],
})
export class StageIndicatorsComponent implements OnInit {
  public bricks;
  indicatorsGroups$ = this.sideNavIndicatorsService.indicatorsGroups$;
  bricks$ = this.bricksApiService.bricks$;

  constructor(
    private bricksApiService: BricksApiService,
    private sideNavIndicatorsService: SideNavIndicatorsService
  ) {
    this.bricksApiService.getFullBricks().subscribe((d) => {
      this.bricks = d;
    });

    this.sideNavIndicatorsService.getGeoIndicatorsApi();
  }

  ngOnInit(): void { }
}
