import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AnalyticsFormComponent } from './analytics-form/analytics-form.component';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsChartComponent } from './analytics-chart/analytics-chart.component';
import { AnalyticsIndexComponent } from './analytics-index/analytics-index.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AnalyticsFormComponent,
    AnalyticsChartComponent,
    AnalyticsIndexComponent
  ],
  exports: [
    AnalyticsFormComponent,
    AnalyticsChartComponent,
    AnalyticsIndexComponent
  ],
  providers: [
    DecimalPipe
  ]
})
export class AnalyticsModule {
}
