import { Injectable } from '@angular/core';
import { CategoryService, Category } from '@compass/categories/data-access-category';

@Injectable({ providedIn: 'root' })
export class MarkerOptionsApiService {
  icon_patrimonio: boolean;
  icon_transport: boolean;
  icons_local: boolean;
  categories$ = this.categoryService.categories$;

  constructor(private categoryService: CategoryService) {
  }

  getMarkerUrl(d) {
    let url: string = '/icons/';

    //let url: string = '../../../assets/img/pois/icons/';

    if (this.icon_transport || this.icons_local) {
      let term = d.properties && d.properties.key_sub_categoria ? d.properties.key_sub_categoria : undefined;
      if (!term || term === '') {
        term = 'default';
        url += term;
      } else {
        if (this.icons_local && this.categories$.value) {
          const cat: Category = this.categories$.value.find(
            (c: Category) => c.key_sub_categoria === term
          );

          if (!cat || cat.icono === 'ftp') {
            url += term;
          } else {
            url += cat.icono;
          }
        } else {
          url += term;
        }
      }
    } else {
      let term =
        d.properties.icono &&
        d.properties.icono.replace(
          /\.?(gif|jpe?g|tiff?|png|webp|bmp|svg|pdf)$/i,
          ''
        );
      if (!term) {
        term = 'default';
      }
      url += term;
    }
    return url;
  }

  getIconSize(d) {
    if (this.icon_transport) {
      return [20, 30];
    }
    return [24, 24];
  }

  getPopupAnchor(d) {
    if (this.icon_transport) {
      return [-4, -36];
    }
    return [-4, -24];
  }

  public setIconFamily(d) {
    this.icon_patrimonio = d?.properties?.class_ === 'point_patrimonio';
    if (this.icon_patrimonio) {
      return false;
    }

    this.icon_transport = ['parada_bus', 'estacion_de_tren'].includes(d?.category) || d?.properties?.key_categoria === 'transporte';
    if (this.icon_transport) {
      return false;
    }

    this.icons_local = d?.properties?.key_categoria === 'glocally_pois';
    if (this.icons_local) {
      return false;
    }
  }

  public getMarkerOptions(d) {
    this.setIconFamily(d);
    return {
      iconUrl: this.getMarkerUrl(d),
      iconSize: this.getIconSize(d),
      iconAnchor: this.getIconSize(d),
      popupAnchor: this.getPopupAnchor(d)
    };
  }
}
