<compass-stage-breadcrumbs></compass-stage-breadcrumbs>

<ng-container *ngIf="indicatorsList$ | async as indicators">
  <mat-slide-toggle
    [(ngModel)]="isChecked"
    [disabled]="!indicators.length"
    [matTooltip]="!indicators.length ? 'Debes seleccionar indicadores' : ''"
    matTooltipPosition="right"
  >
    <span *ngIf="!isChecked">Analizar valores</span>
    <span *ngIf="isChecked">Cambiar indicadores</span>
  </mat-slide-toggle>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<!-- ANALIZE INDICATORS SECTION -->
<mat-tab-group *ngIf="isChecked" [(selectedIndex)]="selectedTab">
  <mat-tab label="Indicadores">
    <compass-analytics-form *ngIf="selectedTab == 0"></compass-analytics-form>
  </mat-tab>
  <mat-tab label="índices">
    <compass-analytics-index *ngIf="selectedTab == 1"></compass-analytics-index>
  </mat-tab>
</mat-tab-group>
<!--/ ANALIZE INDICATORS SECTION -->

<div class="margin__top--s" [class.display-none]="isChecked">
  <!-- SELECTED INDICATORS CHIPS -->
  <ng-container *ngIf="indicatorsList$.value as indicators">
    <div class="padding__basic margin__bottom--xs" fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{ indicators.length }} indicadores seleccionados</p>
      <mat-icon
        (click)="removeAllIndicators()"
        matTooltip="Eliminar todos los indicadores"
        matTooltipPosition="right"
        *ngIf="indicators.length > 1"
      >backspace
      </mat-icon>
    </div>
    <mat-chip-list>
      <mat-chip
        [removable]="true"
        (removed)="remove(indicator)"
        *ngFor="let indicator of indicators"
        [matTooltip]="indicator.screen_name"
      >
        {{ indicator.screen_name?.length > 24 ? (indicator.screen_name | slice:0:24) + '..' : indicator.screen_name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <!--/ SELECTED INDICATORS CHIPS -->

  <!-- INDICATORS LIST -->
  <ng-container *ngIf="bricks && indicatorsGroups$ | async; else loading">
    <h4 class="noData" *ngIf="!bricks.length; else bricksSelect">
      <mat-icon>warning</mat-icon>
      No hay bricks en el área
    </h4>
    <ng-template #bricksSelect>
      <compass-select-indicators
        parentLabel="Categorías"
        childrenLabel="Indicadores"
      ></compass-select-indicators>
      <compass-list-indicators
        *ngIf="bricks.length"
        [bricks]="bricks"
        [removePreviousData]="false"
      ></compass-list-indicators>
    </ng-template>
  </ng-container>
  <!--/ INDICATORS LIST -->

</div>
