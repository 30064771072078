import { Component, OnInit } from '@angular/core';
import { LocalMediaReshapeService } from '../local-media-reshape.service';

@Component({
  selector: 'compass-local-media-accordion',
  templateUrl: './local-media-accordion.component.html',
  styleUrls: ['./local-media-accordion.component.scss']
})
export class LocalMediaAccordionComponent implements OnInit {
  list: Array<any>;

  constructor(private localMediaReshapeService: LocalMediaReshapeService) {

    this.localMediaReshapeService.getMediaData().subscribe((d) => {
      this.list = d;
    });
  }

  ngOnInit(): void {
  }

}
