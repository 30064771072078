import { Component } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';

import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { CreateStudyService } from '@compass/feature-create-study';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-form-name',
  templateUrl: './form-name.component.html',
  styleUrls: ['./form-name.component.css']
})
export class FormNameComponent implements OnInit, OnDestroy {
  study: any = null;
  creating: boolean = false;
  formGroup: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  formSubscription: Subscription;
  creatingSubscription: Subscription;

  constructor(private createStudyService: CreateStudyService) {
    this.formGroup.get('name').setValue(this.createStudyService.getStudyName());

    this.createStudyService.getDataFormStudy().subscribe((study) => {
      this.study = study;
      this.createStudyService.setStudyName(study?.properties?.study?.name);
      const studyNameControl = this.formGroup.get('name');
      studyNameControl.setValue(this.createStudyService.getStudyName());
      if (this.study) {
        studyNameControl.disable();
      }
    });

    this.creatingSubscription = this.createStudyService.creating$.subscribe((creating: boolean) => {
      if (creating || this.study) {
        this.formGroup.get('name').disable();
      } else {
        this.formGroup.get('name').enable();
      }
    });
  }

  ngOnInit(): void {
    this.formSubscription = this.formGroup.valueChanges.subscribe((nameStudyChanged) => {
      this.createStudyService.setStudyName(nameStudyChanged.name);
    });
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.creatingSubscription?.unsubscribe();
  }
}
