import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';
import { Map } from './compass-map-wrapper.service';
import { CompassMapWrapperService } from '../../../../utils/leaflet/src/lib/compass-map-wrapper.service';
import { PoiBasketService } from '../../../../shared/poi-basket/src/lib/poi-basket.service';


@Injectable({ providedIn: 'root' })
export class CompassMapHeritageService {
  map: Map;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private poiBasketService: PoiBasketService
  ) {
  }

  public removeHeritageLayer(layer): void {
    if (this.map[layer]) {
      this.map.box.removeLayer(this.map[layer]);
    }
  }

  public fitBoundsCategoryGroup(layer): void {
    if (this.map['pois'][layer]) {
      let bounds = this.map['pois'][layer].getBounds();
      if (bounds && bounds._northEast && bounds._northEast.lng && bounds._southWest && bounds._southWest.lat) {
        this.map.box.fitBounds(bounds);
      }

    }
  }

  public buildIconMarkers(pois: any) {
    const markers = pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];
      const markerOptions = poi.markerOptions;

      poi.inBasket = this.poiBasketService.inBasket(poi);

      const icon = L.divIcon({
        iconSize: markerOptions.iconSize,
        iconAnchor: markerOptions.iconAnchor,
        popupAnchor: markerOptions.popupAnchor,
        html: `<img src="${markerOptions.iconUrl}.svg" onerror="this.onerror=null;this.src='${markerOptions.iconUrl}.png'">`,
        className: `abacus-poi-marker ${poi.properties.class_}${poi.properties.id} `  + (poi.inBasket ? 'inBasket' : '')
      });

      const marker = {
        actualMarker: L.marker([lat, long], {
          draggable: false,
          icon: icon,
          keyboard: true,
          title: poi.properties.nombre,
          alt: poi.properties.nombre,
          zIndexOffset: 0,
          opacity: 1,
          riseOnHover: false,
          riseOffset: 250,
          pane: 'markerPane',
          shadowPane: 'shadowPane',
          bubblingMouseEvents: false
        }),
        popUp: this.popUpText(poi),
        popupopenCallback: (event, marker) => {
          // update popup content
          marker.actualMarker.setPopupContent(this.popUpText(poi));

          this.poiBasketService.onPopupOpen(poi, (inBasket) => {
            // close and open popup to rebind click events
            marker.actualMarker.closePopup(); //.openPopup();

            // update icon class
            if (inBasket) {
              marker.actualMarker._icon.classList.add('inBasket');
            } else {
              marker.actualMarker._icon.classList.remove('inBasket');
            }
          });
        },
        class: 'inBasket'
      };

      marker.actualMarker['data'] = poi;
      marker.actualMarker.bindPopup(marker.popUp); // bind the popUp
      /* marker.actualMarker.on('mouseover', (e) => {
         this.mouseoverMarker(e);
         e.sourceTarget.openPopup();
       });

       marker.actualMarker.on('mouseout', (e) => {
         this.mouseoutMarker(e);
         if (e.target.clicked) {
           e.target.clicked = false;
         } else {
           e.sourceTarget.closePopup();
         }
       });*/

      marker.actualMarker.on('click', (e) => {
        this.clickMarker(e);
        e.target.clicked = true;
        e.sourceTarget.openPopup();
      });

      // bind when popupopen
      marker.actualMarker.on('popupopen', (event) => {
        marker.actualMarker.closePopup()
        if (marker.popupopenCallback && typeof marker.popupopenCallback === 'function') {
          setTimeout(() => {
            marker.popupopenCallback(event, marker);
          }, 100);
        }
      });

      return marker;
    });
    return markers;
  }

  public clickMarker(e) {
    return false;
  }

  public mouseoverMarker(marker) {
    return false;
  }

  public mouseoutMarker(marker) {
    return false;
  }

  public addMarkersToMap(markers: any, layer: string) {
    this.map[layer] = L.featureGroup([]);
    markers.forEach((marker) => {
      marker.actualMarker.addTo(this.map[layer]).bindPopup(marker.popUp);
    });
    this.map[layer].addTo(this.map.box);
  }


  public drawPois(pois: any, layer: string, type: string) {

    switch (type) {
      case 'featureGroup': {
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
      }
        break;

      case 'markerClusterGroup': {
        this.map.pois[layer] = L.markerClusterGroup();
        const markers = this.buildIconMarkers(pois);
        this.addMarkersToMap(markers, layer);
        this.map.box.addLayer(this.map[layer]);
      }
        break;


      default: {
        return null;
      }
    }
  }

  public popUpText = (poi) => {
    let text = '';
    if (poi && poi.properties) {
      if (poi.properties.nombre) {
        text += `<p data-id="popUpText"><strong>${poi.properties.nombre}</strong></p>`;
      }

      if (poi.properties.key_sub_categoria) {
        text += `<p>${poi.properties.key_sub_categoria}</p>`;
      }

      if (poi.properties.medio) {
        text += `<p>${poi.properties.medio}</p>`;
      }

      if (poi.properties.soporte) {
        text += `<p>${poi.properties.soporte}</p>`;
      }

      if (poi.properties.proveedor) {
        text += `<p>${poi.properties.proveedor}</p>`;
      }

      if (poi.properties.valoracion) {
        text += `<p>${poi.properties.valoracion}</p>`;
      }

      if (poi.properties.status) {
        text += `<p>${poi.properties.status}</p>`;
      }

      if (poi.properties.descripcion) {
        text += `<p>${poi.properties.descripcion}</p>`;
      }

      text += this.poiBasketService.getButton(poi);
    }

    return text;
  };
}
