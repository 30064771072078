<h2 mat-dialog-title>¡Atención!</h2>

<mat-dialog-content>
    <p> ¿Está seguro de que quiere eliminar el estudio?</p>
    <p> Esta acción no tienen vuelta atrás.</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" class='button--xl' [disabled]=false (click)="delete($event)">
        <mat-icon>delete_forever</mat-icon>Eliminar
    </button>
    <button mat-flat-button class='button--xl' type="cancel" [disabled]=false (click)="close($event)">
        <mat-icon>cancel</mat-icon>Cancelar
    </button>
</mat-dialog-actions>