<compass-start-left-sidenav-base [setup]='setup'>
    <!--     MENU WORDING -->
    <!--     STUDY -study-sidenav.component: the main study formularies -->
    <!--     STAGES -stages-sidenav.component: App Menu stages (indicators, reports, analytics, pois...) -->
    <!--     STAGE-VIEWER -stage-viewer-sidenav.component: One stage content (POIS) -->
    <!--     POIS- It´s a stage-viewer state -stage-pois.component: Points of interest ( restaurants, banks, schools...)-->
    <!--     PLACES -places-sidenav.component: Places of interest ( Vips, Burger King, 100 Montaditos ...)-->

    <glo-study-sidenav *ngIf="studyFormNavOn"></glo-study-sidenav>
    <mat-progress-bar *ngIf="stagesNavOnLoading" mode="indeterminate"></mat-progress-bar>
    <glo-stages-sidenav *ngIf="stagesNavOn"></glo-stages-sidenav>
    <glo-stage-viewer *ngIf="stageViewerOn"></glo-stage-viewer>
    <glo-subpois-sidenav *ngIf="subcategoriesNavOn"></glo-subpois-sidenav>
    <glo-companies-sidenav *ngIf="companiesNavOn"></glo-companies-sidenav>
    <glo-places-sidenav *ngIf="placesNavOn"></glo-places-sidenav>

</compass-start-left-sidenav-base>
