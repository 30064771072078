import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'glo-subpois-sidenav',
  templateUrl: './subpois-sidenav.component.html',
  styleUrls: ['./subpois-sidenav.component.css']
})
export class SubpoisSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
