<section class="p-1 pb-3 full-width clearfix">
  <!--More info-->
  <mat-card>
    <img mat-card-image
         alt="Imagen de {{ marker.actualMarker?.info?.properties?.nombre || 'negocio'}}"
         *ngIf="marker.actualMarker?.info?.properties?.goo?.image"
         [src]="marker.actualMarker.info.properties.goo?.image"
         onerror="this.src='/assets/img/brand/logo-white.svg'"
    >

    <mat-card-title-group>
      <mat-card-title *ngIf="marker.actualMarker.info.properties.nombre">
        {{marker.actualMarker.info.properties.nombre}}
      </mat-card-title>
      <mat-card-subtitle *ngIf="marker.actualMarker.info.properties.categoria">
        <div>{{marker.actualMarker.info.properties.categoria}}</div>
        <small class="direction">{{ marker.actualMarker.info.properties.direccion }}</small>
        <div class="text__align--center" *ngIf="marker.actualMarker.info.properties.goo?.rating">
          {{ this.getRatingStars(this.marker.actualMarker.info.properties.goo?.rating) }}
        </div>
        <div class="text__align--center" *ngIf="marker.actualMarker.info.properties.goo?.new_review">
          ({{ this.roundNumber(+this.marker.actualMarker.info.properties.goo?.new_review) }})
        </div>
      </mat-card-subtitle>
    </mat-card-title-group>

    <mat-card-content>
      <!-- ADD TO BASKET -->
      <button
        mat-flat-button (click)="toggleBasket()" color="primary"
        style="width: 100%; margin-bottom: 1.5rem" matTooltipPosition="before"
        [matTooltip]="this.inBasket ? 'Quitar del plan' : 'Añadir al plan'"
      >
        <mat-icon>{{ this.inBasket ? 'clear' : 'add' }}</mat-icon>
        {{ this.inBasket ? 'Quitar del plan' : 'Añadir al plan' }}
      </button>

      <!-- COMPARATOR -->
      <button
        mat-flat-button (click)="toggleComparator()"
        style="width: 100%; margin-bottom: 1rem" matTooltipPosition="before"
        [matTooltip]="this.inComparator ? 'Quitar de comparación' : 'Comparar POI'"
      >
        <mat-icon>{{ this.inComparator ? 'clear' : 'difference' }}</mat-icon>
        {{ this.inComparator ? 'Quitar comparación' : 'Comparar' }}
      </button>

      <div *ngIf="marker.actualMarker.info.properties.goo?.description">
        {{ marker.actualMarker.info.properties.goo?.description}}
      </div>

      <compass-popular-time *ngIf="this.marker.actualMarker.info.properties.goo?.popular_time"
      ></compass-popular-time>

      <div *ngIf="!this.marker.actualMarker.info.properties.goo?.popular_time" class="text__align--center">
        <h4 class="m-0 mb-3 mt-2">No hay datos de las horas puntas</h4>
        <button
          mat-raised-button class="update-button" color="warn"
          matTooltip="Asignar ubicación al POI" matTooltipPosition="before"
          (click)="bestTimeService.showOptionsFor(marker.actualMarker.info)"
        >
          <mat-icon>my_location</mat-icon>
          Asignar
        </button>
      </div>


      <mat-accordion>
        <mat-expansion-panel (opened)="true" *ngIf="openingDays">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Horario de apertura
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list>
            <mat-list-item *ngFor="let item of openingDays">
              <mat-icon mat-list-icon>schedule</mat-icon>
              <div mat-line>{{item[0]}}</div>
              <div mat-line>
                <dd>{{item[1]}}<br>
                  {{item[2]}}</dd>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="marker.actualMarker.info.properties.categoria">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Características
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list>
            <mat-list-item *ngIf="marker.actualMarker.info.properties.sup_m2">
              <mat-icon mat-list-icon>terrain</mat-icon>
              <div mat-line>{{marker.actualMarker.info.properties.sup_m2}} m2</div>
            </mat-list-item>
            <mat-list-item *ngIf="marker.actualMarker.info.properties.categoria">
              <mat-icon mat-list-icon>category</mat-icon>
              <div mat-line>{{marker.actualMarker.info.properties.categoria}}</div>
            </mat-list-item>
            <mat-list-item *ngIf="marker.actualMarker.info.properties.goo?.rating">
              <mat-icon mat-list-icon>star</mat-icon>
              <div mat-line>{{ this.getRatingStars(this.marker.actualMarker.info.properties.goo?.rating) }}</div>
            </mat-list-item>
            <mat-list-item *ngIf="marker.actualMarker.info.properties.goo?.new_review">
              <mat-icon mat-list-icon>reviews</mat-icon>
              <div mat-line>{{ this.roundNumber(+marker.actualMarker.info.properties.goo?.new_review) }}</div>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>

  </mat-card>
  <!--/More info-->
</section>
