import { Component, OnInit } from '@angular/core';
import { LocalKnowledgeReshapeService } from '../local-knowledge-reshape.service';

@Component({
  selector: 'compass-local-knowledge-table',
  templateUrl: './local-knowledge-table.component.html',
  styleUrls: ['./local-knowledge-table.component.scss']
})
export class LocalKnowledgeTableComponent implements OnInit {
  dataSource: any[];
  displayedColumns: string[] = ['descripcion', 'fecha'];
  constructor(
    private localKnowledgeReshapeService: LocalKnowledgeReshapeService
  ) {

    this.localKnowledgeReshapeService.get().subscribe((source) => {
      this.dataSource = source
    });
  }

  ngOnInit(): void {
  }

}
