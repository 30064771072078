import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'compass-pois-accordion',
  templateUrl: './pois-accordion.component.html',
  styleUrls: ['./pois-accordion.component.css']
})
export class PoisAccordionComponent implements OnInit {
  @Input() setup: any;
  constructor() { }
  ngOnInit(): void { }
}