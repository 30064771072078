import { Injectable } from '@angular/core';
import { LocationService } from '@compass/utils/misc';
import { Subject, Observable, BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@compass/shared/feature-auth';
import {LocalKnowledgeApiService} from "@compass/local-knowledge";

@Injectable({
  providedIn: 'root'
})
export class CreateStudyService {
  coordsMap: Array<number>;
  address: string;
  locality: string;
  province: string;
  studyName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  areaPainted: string;
  cpStudyOption: any;
  studyForm: any;

  featureStudy: any;
  studyIdSelected: any;
  modeStudy: string;
  distanceStudy: string;

  coordsBoundsMap: any;
  studyTabInit: string;
  featureStudyCps: any;
  drawStudyCps: boolean = false;
  selectedForm: string = 'point';

  creating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  private studyParamsForm$ = new Subject<any>();
  studyParamsFormChanged$ = this.studyParamsForm$.asObservable();

  private studyCanceled$ = new Subject<any>();
  studyCanceledChanged$ = this.studyCanceled$.asObservable();

  private studyDrawShape$ = new Subject<any>();
  studyDrawShapeChanged$ = this.studyDrawShape$.asObservable();

  private studyGeomPainted$ = new Subject<any>();
  studyGeomPaintedChanged$ = this.studyGeomPainted$.asObservable();

  public studyDataForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private studyGeomStudy$ = new Subject<any>();

  private studyIdSlct$ = new Subject<any>();

  private studyCpsShape$ = new Subject<any>();
  studyCpsShapeChanged$ = this.studyCpsShape$.asObservable();

  private zoomendMap$ = new Subject<any>();
  zoomendMapChanged$ = this.zoomendMap$.asObservable();

  private zoomendMapCps$ = new Subject<any>();
  zoomendMapCpsChanged$ = this.zoomendMapCps$.asObservable();

  private studyUrl = '/abacusgis/study/glocally';
  private studyCoreUrl = '/abacusgis/core/glocally';

  constructor(
    private locationService: LocationService,
    private http: HttpClient,
    private authService: AuthService,
    private localKnowledgeApiService: LocalKnowledgeApiService,
  ) {
    // this.studyDataForm.next(JSON.parse(localStorage.getItem('studyDataForm')));
  }

  toggleCreating(val: boolean = true) {
    this.creating$.next(val);
  }

  setCoordsMarker(latLongMarker: Array<number>): void {
    this.coordsMap = latLongMarker;
  }

  getCoordsMarker(): Array<number> {
    return this.coordsMap;
  }

  setIdStudySelected(studyId: any): void {
    this.studyIdSelected = studyId;
    this.studyIdSlct$.next(this.studyIdSelected);
    this.localKnowledgeApiService.localKnowledge$.next([]);
    this.localKnowledgeApiService.activeDate$.next(new Date());
  }

  getIdStudySelected() {
    return this.studyIdSelected;
  }

  setAddressMarker(streetMarker: string): void {
    this.address = streetMarker;
  }

  getAddressMarker(): string {
    return this.address;
  }

  setLocalityMarker(localityMarker: string): void {
    this.locality = localityMarker;
  }

  getLocalityMarker(): string {
    return this.locality;
  }

  setProvinceMarker(provinceMarker: string): void {
    this.province = provinceMarker;
  }

  getProvinceMarker(): string {
    return this.province;
  }

  setStudyName(studyName: string): void {
    this.studyName$.next(studyName);
  }

  getStudyName(): string {
    return this.studyName$.value;
  }

  setFormStudy(study: any): void {
    this.studyForm = study;
  }

  getFormStudy(): any {
    return this.studyForm;
  }

  setModeStudy(mode: string): void {
    this.modeStudy = mode;
  }

  getModeStudy(): string {
    return this.modeStudy;
  }

  setDistanceStudy(distance: string): void {
    this.distanceStudy = distance;
  }

  getDistanceStudy(): string {
    return this.distanceStudy;
  }

  reloadDataForm(object: any) {
    this.studyParamsForm$.next(object);
  }

  reloadData(data: any): void {
    this.locationService.geocodificarCoordenadas({
      lat: data[0],
      lon: data[1]
    }).subscribe((respuesta: any) => {
      let resultApi = this.locationService.buscarDireccion(respuesta);

      this.setAddressMarker(resultApi.address);
      this.setLocalityMarker(resultApi.locality);
      this.setProvinceMarker(resultApi.province);

      this.reloadDataForm({
        x: resultApi.x,
        y: resultApi.y,
        address: resultApi.address,
        locality: resultApi.locality,
        province: resultApi.province
      });
    });
  }

  cancelStudy(object: any): void {
    this.studyCanceled$.next(object);
  }

  setStudyTabInit(tab: any) {
    this.studyTabInit = tab;
  }

  getStudyTabInit() {
    return this.studyTabInit;
  }

  setStudyTabSelected(tab: any) {
    this.selectedForm = tab;
  }

  getStudyTabSelected() {
    return this.selectedForm;
  }

  drawShape(object: any): void {
    this.studyDrawShape$.next(object);
  }

  setDrawShape(drawCps: any) {
    return (this.drawStudyCps = drawCps);
  }

  getDrawShape() {
    return this.drawStudyCps;
  }

  getStudyGeomStudy(object: any): void {
    this.studyGeomStudy$.next(object);
  }

  setFeatureStudy(feature: any) {
    this.featureStudy = feature;
  }

  getFeatureStudy() {
    return this.featureStudy;
  }

  studyGeomPainted(object: any): void {
    this.studyGeomPainted$.next(object);
  }

  setAreaPainted(geomPainted: any) {
    this.areaPainted = geomPainted;
  }

  getAreaPainted() {
    return this.areaPainted;
  }

  setCPSelected(cps: any) {
    this.cpStudyOption = cps;
  }

  getCPSelected() {
    return this.cpStudyOption;
  }

  drawShapeCps(object: any): void {
    this.studyCpsShape$.next(object);
  }

  zoomendCpStudy(object: any): void {
    this.zoomendMap$.next(object);
  }

  zoomendMapCpStudy(object: any): void {
    this.zoomendMapCps$.next(object);
  }

  setFeatureStudyInitCps(feature: any) {
    this.featureStudyCps = feature;
  }

  getFeatureStudyInitCps() {
    return this.featureStudyCps;
  }

  setBoundsStudy(lngmin: any, latmin: any, lngmax: any, latmax: any) {
    this.coordsBoundsMap = [lngmin, latmin, lngmax, latmax];
  }

  getBoundsStudy() {
    return this.coordsBoundsMap;
  }

  getCreateBasicPointStudy(lng: any, lat: any, name: any, desc: any, distance: string, mode: string) {
    const currentUser = this.authService.currentUser$.value;
    const body = {
      params: {
        point: [Number(lng), Number(lat)],
        username: currentUser.username,
        studyname: name,
        keyareas: [`minutes_${mode}_${distance}`]
      }
    };

    return this.http.post(`${this.studyUrl}/create/`, body).pipe(
      map((study: any) => {
        return study;
      })
    );
  }

  getCreateBasicAreaStudy(geom: any, name: any, desc: any, lng: any, lat: any): Observable<any> {
    const currentUser = this.authService.currentUser$.value;
    const body = {
      params: {
        area: JSON.parse(geom),
        username: currentUser.username,
        studyname: name,
        studydescription: desc,
        point: [Number(lng), Number(lat)]
      }
    };

    return this.http.post(`${this.studyUrl}/create/`, body).pipe(
      map((study: any) => {
        return study;
      })
    );
  }

  getListCPStudy(lngmin: any, latmin: any, lngmax: any, latmax: any): Observable<any> {
    const zoom = `${lngmin},${latmin},${lngmax},${latmax}`;
    return this.http.get(`${this.studyCoreUrl}/collection/area_zip_code/?zoom=${zoom}`, {
      responseType: 'json'
    });
  }

  getCreateCPStudy(cps: any, name: any, lng: any, lat: any): Observable<any> {
    const currentUser = this.authService.currentUser$.value;

    const body = {
      params: {
        area_collection: { area_zip_code: JSON.parse(cps) },
        username: currentUser.username,
        studyname: name,
        point: [Number(lng), Number(lat)]
      }
    };

    return this.http.post(`${this.studyUrl}/create/`, body).pipe(
      map((study: any) => {
        return study;
      })
    );
  }

  getCreateStudyAnew(type: any, study: any, params: any): Observable<any> {
    const currentUser = this.authService.currentUser$.value;
    let body: any = {};

    if (type == 'point') {
      let lng = params.lng;
      let lat = params.lat;
      let mode = params.mode;
      let distance = params.distance;
      body = {
        params: {
          point: [Number(lng), Number(lat)],
          username: currentUser.username,
          studyname: study.properties.study.name,
          keyareas: [`minutes_${mode}_${distance}`]
        }
      };
    }

    if (type == 'manual') {
      body = {
        params: {
          area: JSON.parse(params),
          username: currentUser.username,
          studyname: study.properties.study.name
        }
      };
    }

    if (type == 'cp') {
      body = {
        params: {
          area_collection: { area_zip_code: JSON.parse(params) },
          username: currentUser.username
        }
      };
    }

    body.params.name = study.properties.study.name;

    return this.http.put(`${this.studyUrl}/create/` + study.properties.id + `/`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(catchError(this.handleError));
  }

  setDataFormStudy(object) {
    // localStorage.setItem('studyDataForm', JSON.stringify(object));
    this.studyDataForm.next(object);
  }

  getDataFormStudy(): Observable<any> {
    return this.studyDataForm.asObservable();
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Code returned: ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
