<compass-stage-breadcrumbs></compass-stage-breadcrumbs>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate" style="margin: auto"></mat-progress-bar>
</ng-template>

<ng-container *ngIf="indicatorsGroups$ | async; else loader">
  <h4 class="noData" *ngIf="!bricks.length; else bricksSelect">
    <mat-icon>warning</mat-icon>
    No hay bricks en el área
  </h4>

  <ng-template #bricksSelect>
    <compass-select-indicators parentLabel="Categorías" childrenLabel="Indicadores"></compass-select-indicators>
    <ng-container *ngIf="bricks$ | async as bricks">
      <compass-list-indicators [bricks]="bricks"></compass-list-indicators>
    </ng-container>
  </ng-template>
</ng-container>
