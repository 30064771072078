<div mat-dialog-title>
  Asignar datos al POI <br />
  <small>{{ poi.properties.nombre }} - {{ poi.properties.direccion }}</small>
</div>

<mat-dialog-content>
  <small class="mb-3">
    Seleccione una ubicación para asignar los datos al POI.
  </small>

  <div class="searchbox" [class.visible]="manualSearch">
    <input #addresstext [ngModel]="addressSearchText">
  </div>

  <div class="loader" *ngIf="bestTimeService.loading">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
  </div>

  <h3 class="no-data-text" *ngIf="!bestTimeService.loading && !options.length">
    - No se han encontrado posibles ubicaciones para asignar -
  </h3>

  <mat-selection-list
    class="mb-3 list" [class.disabled]="manualSearch"
    *ngIf="options.length"
    (selectionChange)="!manualSearch ? selected($event) : ''"
    [multiple]="false"
  >
    <mat-list-option
      *ngFor="let option of options" [value]="option"
      [class.selected]="option.place_id === selectedOption?.place_id"
      [class.mat-elevation-z2]="option.place_id === selectedOption?.place_id"
      [title]="option.structured_formatting?.main_text || option.description"
    >
      <mat-icon mat-list-icon>location_on</mat-icon>
      <div mat-line [title]="option.structured_formatting?.main_text || option.description">
        {{ option.structured_formatting?.main_text || option.description }}
      </div>
      <div mat-line [title]="option.structured_formatting?.secondary_text || ''">
        {{ option.structured_formatting?.secondary_text || '' }}
      </div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-button mat-dialog-close>Cancelar</button>

  <button
    mat-raised-button color="primary"
    [disabled]="bestTimeService.loading"
    *ngIf="searchCount <= 1"
    (click)="getPlacePrediction()"
    matTooltip="Ejecutar la búsqueda mejorada"
  >
    <mat-icon>manage_search</mat-icon>
    Búsqueda mejorada
  </button>
  <button
    mat-raised-button color="warn"
    [disabled]="bestTimeService.loading"
    *ngIf="searchCount == 2"
    (click)="toggleManualSearch()"
    matTooltip="Realiar búsqueda manual"
  >
    <mat-icon>person_search</mat-icon>
    Búsqueda manual
  </button>


  <button
    mat-flat-button color="primary"
    (click)="assign()" [disabled]="!selectedOption || bestTimeService.loading"
  >
    Asignar
  </button>
</mat-dialog-actions>
