<div class="container" [attr.data-title]="toolbarSetUp.title">
  <mat-toolbar fxLayoutAlign="space-between center" [ngClass]="toolbarSetUp.class" [ngStyle]="{
      'min-height': toolbarSetUp.style.height,
      height: toolbarSetUp.style.height
    }">
    <a [routerLink]="['/']">
      <img [ngClass]="toolbarSetUp.img.class" [src]="toolbarSetUp.img.src" [alt]="toolbarSetUp.img.alt"
           [width]="toolbarSetUp.img.width" />
    </a>

    <div class="right-side">
      <ng-content select="[right]"></ng-content>
    </div>
  </mat-toolbar>
</div>
