<main>
  <div class="container">
    <section>
      <compass-popular-times-form></compass-popular-times-form>
    </section>
    <section>
      <compass-popular-times-timeline *ngIf="heatMapData$ | async as heatMapData;"
        [heatMapData]="heatMapData" [form]="eightDaysForm"></compass-popular-times-timeline>
    </section>


    <!--  <section>

       <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="2rem">
         <div fxFlex="100%">

           <h1>Popular Times Map</h1>
           <h4>Seleccione un punto de interés</h4>

           <compass-popular-times-form></compass-popular-times-form>

         </div>
       </div>
     </section>

     <section>

       <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="2rem">
         <div fxFlex="100%">

           <ng-template #loadingHeatMapData>
             <mat-progress-bar mode="indeterminate"></mat-progress-bar>
           </ng-template>

           <app-popular-times-timeline *ngIf="heatMapData$ | async as heatMapData; else loadingHeatMapData"
                                       [heatMapData]="heatMapData" [form]="eightDaysForm"></app-popular-times-timeline>

           <div class="heatmap-wrapper">
             <app-popular-times-map></app-popular-times-map>
           </div>
         </div>

      v>

     </section>



           <section>
         <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="2rem">
             <div fxFlex="50%">
                 <p>New</p>

                 50%
             </div>
             <div fxFlex="50%">
                 <p>heatmap-page works!</p>

                 50%
             </div>
         </div>
     </section> -->
  </div>
</main>
