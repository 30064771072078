<!--
<compass-analytics-chart></compass-analytics-chart>
-->

<ul class="nav-ul">
  <li *ngFor="let stage of setup.stagesList">
    <div *ngIf="!setup.env.production || (setup.env.production && stage.production === setup.env.production)">
      <div *ngIf="!stage.children">
        <a
          href="#"
          (click)="navigateToNextComponent($event, stage)"
          [ngClass]="stage.active ? 'active' : 'inactive'"
          [attr.data-cy]="stage.key"
        >
          <span class="material-icons">
            {{ stage.icon }}
          </span>
          <span>{{ stage.value }}</span>
        </a>
      </div>

      <div *ngIf="stage.children" class="stage-children">
        <a
          href="#"
          (click)="openChildrenStages($event, stage)"
          [ngClass]="stage.active ? 'active' : 'inactive'"
          [attr.data-cy]="stage.key"
        >
          <span class="material-icons">
            {{ stage.icon }}
          </span>
          <span>{{ stage.value }}</span>
          <span *ngIf="stage.open" class="material-icons toggle-open">
            keyboard_arrow_down
          </span>
          <span *ngIf="!stage.open" class="material-icons toggle-open">
            keyboard_arrow_up
          </span>
        </a>
        <ul
          class="nav-ul"
          [ngClass]="stage.open ? 'visible' : 'hidden'"
          data-cy="children_list"
        >
          <li *ngFor="let child of stage.children">
            <a
              href=""
              (click)="navigateToNextComponent($event, child)"
              [ngClass]="child.active ? 'active' : 'inactive'"
              [attr.data-cy]="child.key"
            >
              <span class="material-icons">
                {{ child.icon }}
              </span>
              <span>{{ child.value }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
