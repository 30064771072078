<div *ngIf='range' class="range">
    Rango seleccionado : [{{ range[0]| number: '1.0-0' }}, {{ range[1]| number: '1.0-0' }}]
</div>
<div class="data" id="data">

</div>

<section>
    <div [attr.id]="chartId" [ngClass]="class" width="100%" [style.minHeight]="height+ 'px'">
    </div>
</section>