import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
// import { CrossfilterService } from '@compass/crossfilter';
import { FormControl, FormGroup } from '@angular/forms';
import { formatCurrency } from '@angular/common';
//import { SidenavService } from '@compass/page-navigation/sidenav';
import { CompassMapWrapperService} from '@compass/utils/leaflet';
import { MapService } from '../../../../map/src/lib/map.service';
import { CrossfilterService } from '../../../../crossfilter/src/lib/crossfilter.service';

@Component({
  selector: 'compass-business-filter',
  templateUrl: './business-filter.component.html',
  styleUrls: ['./business-filter.component.scss']
})
export class BusinessFilterComponent implements OnChanges,OnDestroy {
  @Input() data: any[];
  @Input() loading: boolean = true;
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>(this.loading);

  @ViewChild('activitySearcher') activitySearcher: ElementRef;

  form: FormGroup = new FormGroup({
    // client filter selected - null is all
    client: new FormControl(null),
    rating: new FormControl() // between 1 and 9, rangeSlider subscription on constructor sets the value
  });

  activities = [];

  minMax: any = {
    ratio_morosidad: { min: 0, max: 0 },
    total_empleados: { min: 0, max: 0 },
    ventas: { min: 0, max: 0 },
    ano_inicio_actividad: { min: 0, max: 0 }
  };
  dimensions = {};
  groups = {};
  private _crossfilter: any;

  constructor(
    private crossfilterService: CrossfilterService,
    //public sidenavService: SidenavService,
    private mapService: MapService,
    private compassMapWrapperService:CompassMapWrapperService
    //public poiService: CompassMapPoisService,

  ) {
  }

  ngOnDestroy(): void {
    this.mapService.poiService.removeLayer('companyLayer')
    //this.compassMapWrapperService.removeLayer('companyLayer')

    }

  ngOnChanges(changes: SimpleChanges): void {
    // if data is setted, initialize crossfilter
    if (changes.data && this.data) {
      this.loading = true;
      this.crossfilterService.setData(this.data);
      // get descripcion_cnae only and extract unique values

      // timeout to wait crossfilterService to change observable value
      setTimeout(() => {
        this.activities = [...new Set(this.data.map((company: any) => company.properties.descripcion_cnae))];

        this.drawMarkersOnMap();

        // listen when crossfilter changes
        this.crossfilterService.crossfilter$.value.onChange(() => {
          this.drawMarkersOnMap();
        });
      });
    }
  }

  ngAfterViewInit(): void {
    // client filter changed
    this.form.controls.client.valueChanges.subscribe(showClients => {
      const dimGroup = this.crossfilterService.dim('client', (d) => d.properties.cliente);
      dimGroup.dimension.filter(showClients);
    });
  }


  crossfilterRatioMorisidad(d) {
    return d.properties.rating_morosidad;
  }

  crossfilterTotalEmpleados(d) {
    return Math.round(d.properties.total_empleados);
  }

  crossfilterAnoInicioActividad(d) {
    const actualYear = new Date().getFullYear();
    return actualYear - parseInt(d.properties.ano_inicio_actividad);
  }

  crossfilterVentas(d) {
    return d.properties.ventas;
  }


  activitySelected(activities) {
    // crossfilter activity
    const dimGroup = this.crossfilterService.dim('selectedActivites', (d) => d.properties.descripcion_cnae);
    if (activities.length) {
      dimGroup.dimension.filter(d => {
        return activities.includes(d);
      });
    } else {
      dimGroup.dimension.filter();
    }
  }

  drawMarkersOnMap() {
    setTimeout(() => {
      this.compassMapWrapperService.removeLayer('companyLayer')
      //this.mapService.poiService.removeLayer('companyLayer')
      const markers = this.crossfilterService.all();
      // add popup callback
      markers.map(marker => {
        marker.popUpText = this.popUpContent;
      });

      this.mapService.poiService.drawMarkers(markers, 'companyLayer');


      this.loading = false;
    });

  }

  popUpContent(marker) {
    const data = marker.properties;

    let content = `
      <div>
        <h3 style='line-height: 1'>
          <strong>${data.denominacion} <small>(${data.sigla})</small></strong> <br><br>
          Rating Morosidad: ${data.rating_morosidad}
        </h3>
          <p class='mb-1' style='line-height: 1'><small>${data.tipo_via ?? ''} ${data.nombre_via ?? ''} ${data.numero_via ?? ''}, ${data.resto_via ?? ''}, ${data.codigo_postal} ${data.municipio}, ${data.provincia}</small></p>
          <p class='m-0' style='line-height: 1'><strong>${data.descripcion_cnae}</strong></p>`;

    content += data.descripcion_cnae2 ? `<small class='m-0' style='line-height: 1'><strong>${data.descripcion_cnae2}</strong></small>` : '';

    content += `
        <hr>

        <p>
          Ventas: ${formatCurrency(data.ventas, 'es_ES', '€')}<br>
          Fondos Propios: ${formatCurrency(data.fondos_propios, 'es_ES', '€')}<br>
          Resultado Ejercicio: ${formatCurrency(data.resultado_ejercicio, 'es_ES', '€')}<br>
          Total Activo: ${formatCurrency(data.total_activo, 'es_ES', '€')}
        </p>

        <hr>

        <p>
          <small>Estado: ${data.estado_confirmado}</small> <br>
        </p>
      </div>
    `;

    return content;
  }

  /**
   * Creates or retireves a dimension.
   *
   * @param dimName
   * @param dimFunc
   */
  dim(dimName = null, dimFunc = null) {
    // if dim func setted, creates/overrides the dimension
    if (dimFunc) {
      // check if dimension exists and reset it removing the filter and group
      if (this.dimensions[dimName]) {
        this.dimensions[dimName].filter(null);
        this.groups[dimName].remove();
      }

      const createdDim = this._crossfilter.dimension(dimFunc);

      this.dimensions[dimName] = createdDim;
      this.groups[dimName] = createdDim.group();
    }

    return {
      dimension: this.dimensions[dimName],
      group: this.groups[dimName]
    };
  }

}
