import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoisPage } from './pois.page';
import { SharedModule } from '../shared/shared.module';
import { PoisFeaturePoiManagementModule } from '@compass/pois/feature-poi-management';
import { PoisRoutingModule } from './pois-routing.module';

@NgModule({
  declarations: [PoisPage],
  imports: [
    CommonModule,
    SharedModule,
    PoisFeaturePoiManagementModule,
    PoisRoutingModule,
  ],
})
export class PoisModule {}
