import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PresenceService } from '../../presence.service';
import { GroupedVerticalBarLayoutService } from '@compass/utils/d3';
import { InlineCirclesChartLayoutService } from '../d3/inline-circles-chart/inline-circles-chart-layout.service';


@Component({
  selector: 'app-presence-income',
  templateUrl: './presence-income.component.html',
  styleUrls: ['./presence-income.component.scss']
})
export class PresenceIncomeComponent implements OnChanges {
  @Input() data: any;

  public hour: Date | undefined = undefined;
  chartData: any;
  chartLayout: any = {};

  circlesData: any;
  circlesLayout: any = {};
  dictionary = {};

  constructor(
    private groupedVerticalBarLayoutService: GroupedVerticalBarLayoutService,
    private InlineCirclesChartLayoutService: InlineCirclesChartLayoutService,
    private presenceService: PresenceService
  ) {
    this.presenceService.dictionary$.subscribe(d => {
      this.dictionary = d;
      this.drawChart();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.drawChart();
    }
  }

  drawChart() {
    if (this.data) {
      this.setTheBarChart();
      this.setTheCirclesChart();
      this.hour = new Date();
    }
  }

  getIncomeGroups(pois: any[]): any[] {
    let children = [];

    if(pois?.length) {
      let keys: Array<string> = Object.keys(pois[0].summary?.income?.groups).filter(key => key && key !== 'none' && key !== 'None');
      children = keys.map((key: string) => {
        let category = {
          'category': this.dictionary && this.dictionary[key] ? this.dictionary[key] : key
        };
        pois.forEach((poi: any) => {
          category[poi.title] = poi.summary.income.groups[key];
        });
        return category;
      });
    }

    return children;
  }

  getBarChartData(pois: any[]): {} {
    let result = {
      key: 'income',
      format: 'Volumen',
      categories: 'Ingresos',
      children: this.getIncomeGroups(pois)
    };

    return result;
  }

  getTheColors(pois: any[]): string[] {
    return pois.map(poi => poi.color);
  }

  /* CIRCLES*/

  getCirclesSum(group, keys) {
    let sum = 0;
    keys.forEach((key: string) => {
      sum += group[key];
    });
    return {
      sum: sum,
      percent: null
    };
  }

  getSumGroups(pois: any[]): any[] {
    let incomeGroups = this.getIncomeGroups(pois);
    let sum = [];

    if(incomeGroups?.length) {
      let xKeys = Object.keys(incomeGroups[0]);
      xKeys.shift();
      sum = incomeGroups.map((group: any) => {
        let datum = {
          category: group.category,
          value: this.getCirclesSum(group, xKeys)
        };
        return datum;
      });
    }

    return sum;
  }

  getIncomePercent(groups: any[]): any[] {
    let total = 0;
    let result = [];

    groups.forEach((group: string) => {
      total += group['value'].sum;
    });

    result = groups.map((group: any) => {
      group.value.percent = group.value.sum * 100 / total;
      return group;
    });

    return result;

  }

  getIncomeCircles(pois: any[]): any[] {
    let sumGroups = this.getSumGroups(pois);
    let percent = this.getIncomePercent(sumGroups);
    return percent;
  }

  getCirclesChartData(pois: any[]) {
    let result = {
      key: 'income',
      format: 'Percent',
      categories: 'Ingresos',
      children: this.getIncomeCircles(pois)
    };
    return result;
  }

  setTheBarChart() {
    //  grouped Bar chart
    this.chartData = this.getBarChartData(this.data);
    this.chartLayout = this.groupedVerticalBarLayoutService.getLayout('presence_income')[0];
    this.chartLayout.design.style.colors = this.getTheColors(this.data);
    this.chartLayout['data'] = this.chartData;
  }

  setTheCirclesChart() {
    //  circles chart
    this.circlesData = this.getCirclesChartData(this.data);
    this.circlesLayout = this.InlineCirclesChartLayoutService.getLayout('income_circles')[0];
    this.circlesLayout['data'] = this.circlesData;
  }

}
