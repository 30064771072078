import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopularTimeDataService {
  private daysList = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  private choices_days = [[0, 'Sunday'], [1, 'Monday'], [2, 'Tuesday'], [3, 'Wednesday'], [4, 'Thursday'], [5, 'Friday'], [6, 'Saturday']];
  private choices_hours = [
    [0, '6AM', 6],
    [1, '7AM', 7],
    [2, '8AM', 8],
    [3, '9AM', 9],
    [4, '10AM', 10],
    [5, '11AM', 11],
    [6, '12PM', 12],
    [7, '1PM', 13],
    [8, '2PM', 14],
    [9, '3PM', 15],
    [10, '4PM', 16],
    [11, '5PM', 17],
    [12, '6PM', 18],
    [13, '7PM', 19],
    [14, '8PM', 20],
    [15, '9PM', 21],
    [16, '10PM', 22],
    [17, '11PM', 23],
    [18, '12AM', 0]
  ];

  constructor() {
  }

  public getPoiPopularTimes(poi: any, selected: any = []) {
    const result: any[] = [];

    this.choices_days.map((day: any[]) => {
      this.choices_hours.forEach((hour: any[]) => {
        let popularTimes = {
          y: hour[0],
          x: day[0] == 0 ? 6 : day[0] - 1,
          value: this.getHourValue(poi, day, hour),
          selected: false
        };

        popularTimes.selected = selected.includes(this.parsePopularTimeValue(popularTimes));

        result.push(popularTimes);
      });
    });

    return result;
  }

  private getHourValue(poi: any, day: any[], hour: any[]) {
    // isPrimitive(d.days[day_index]) ? 0 : d.days[day_index][hour[2]] ? d.days[day_index][hour[2]] : 0
    const route: any = poi.properties.goo?.popular_time?.days;
    if (!route || typeof route[day[0]] !== 'object' || !route[day[0]] || !route[day[0]][hour[2]]) {
      return 0;
    }

    return parseInt(route[day[0]][hour[2]].toFixed(0));
  }

  public parsePopularTimeValue(popularTime: { x: any, y: any, value: any }) {
    const hour = this.choices_hours.find((d: any[]) => d?.[0] === popularTime.y)?.[1];
    const day = this.daysList[popularTime.x];

    return `${day} - ${hour}`;
  }
}
