import { IHasEvents } from '../interfaces/ihas-events';

/**
 * This class provides an event handler system.
 * It will control the callbacks for each event and call them when the event is fired through code.
 *
 */
export class HasEvents implements IHasEvents {
  _events = []; // declared events.
  private _eventsBag = {}; // bag of callbacks

  /**
   * Reset all events
   */
  public resetEvents() {
    this._events.forEach((event) => {
      this._validateEvent(event);
      this._eventsBag[event] = [];
    });
  }

  /**
   * Remove callbacks from event.
   *
   * @param event
   */
  public removeListener(events: any) {
    if(typeof events === 'string') {
      events = [events];
    }

    events.forEach((event) => {
      this._validateEvent(event);
      this._eventsBag[event] = [];
    });
  }

  /**
   * Add event listener.
   *
   * @param event
   * @param callback
   */
  public on(event: any, callback: any): this {
    this._validateEvent(event);

    // avoid duplicate callbacks
    if (!this._eventsBag[event].map(registered => registered.toString()).includes(callback.toString())) {
      this._eventsBag[event].push(callback);
    }

    return this;
  }

  /**
   * Fire event by event name.
   *
   * @param event
   * @param args
   * @private
   */
  protected fireEvent(event: any, ...args: any): void {
    this._validateEvent(event);
    this._eventsBag[event].forEach((callback) => {
      if (typeof callback === 'function') {
        callback(...args);
      }
    });
  }


  /**
   * Validates if the event is declared in _events.
   *
   * @param event
   * @private
   */
  private _validateEvent(event: any) {
    // check if event is declared on available _events
    if (!this._events.includes(event)) {
      throw `The event "${event}" is not declared in "${this.constructor.name}"`;
    }

    // check if eventBag is registered
    if (!Array.isArray(this._eventsBag[event])) {
      this._eventsBag[event] = [];
    }
  }
}
