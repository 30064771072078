<div
  class="pois-comparator-wrapper"
  *ngIf="selectedPois.length"
  [class.sticky]="isSticky"
>
  <div #header class="header-container"
       fxLayout="row" fxLayoutAlign="center stretch"
       [class.mat-elevation-z5]="isSticky"
  >
    <ng-container *ngFor="let poi of selectedPois; let first = first">
      <div class="poi-container" fxLayout="column" fxLayoutAlign="">

        <small [title]="poi.properties.category">{{ poi.properties.category }}</small>
        <div class="title-container mb-2" fxLayout="row" fxLayoutAlign="space-between center">
          <h1 class="title m-0">
            <span [title]="poi.properties.nombre">{{ poi.properties.nombre }}</span>
          </h1>
          <mat-icon class="options-btn" [matMenuTriggerFor]="belowMenu">more_vert</mat-icon>
        </div>

        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="poisComparatorService.remove(poi)">
            <mat-icon>close</mat-icon>
            Eliminar
          </button>
        </mat-menu>

        <div class="street" [title]="poi.properties.direccion">
          <mat-icon [style.color]="poi.properties.color">place</mat-icon>
          {{ poi.properties.direccion }}
        </div>
      </div>

      <div *ngIf="first && selectedPois.length > 1" fxLayout="row" fxLayoutAlign=" center">
        <h2 class="m-0 p-3">Vs</h2>
      </div>
    </ng-container>

    <div class="actions-container" fxLayout="row" fxLayoutAlign="space-between center">
      <!-- START COMPARATION -->
      <button
        mat-icon-button
        class="start-button"
        title="Iniciar comparación"
        *ngIf="!compareMode"
        [disabled]="selectedPois.length <= 1"
        (click)="poisComparatorService.initComparation()"
      >
        <mat-icon>done</mat-icon>
      </button>
      <!--/ START COMPARATION -->

      <button mat-icon-button *ngIf="compareMode" [matMenuTriggerFor]="actionsMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #actionsMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="poisComparatorService.stopComparation()">
          <mat-icon>add</mat-icon>
          Añadir más POIS
        </button>

        <button mat-menu-item color="warn" (click)="removeComparation()">
          <mat-icon>delete</mat-icon>
          Eliminar Comparación
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="report-container" [class.active]="compareMode">
    <!-- MÁS AFLUENCIA -->
    <compass-busiest-hours-comparator [pois]="selectedPois"></compass-busiest-hours-comparator>

    <h3 class="mb-0 mt-2">Métricas Popular Times</h3>
    <compass-popular-time-metrics [pois]="selectedPois"></compass-popular-time-metrics>

    <h3 class="my-3">Resumen Presencia</h3>
    <h5 class="secondary" *ngIf="!cards.length">No hay datos disponibles</h5>
    <compass-presence [cards]="cards"></compass-presence>

    <h3 class="my-3">Presencia (%)</h3>
    <h5 class="secondary" *ngIf="!cards.length">No hay datos disponibles</h5>
    <app-presence-total [data]="cards"></app-presence-total>

    <h3 class="my-3">Ingresos (registros)</h3>
    <h5 class="secondary" *ngIf="!cards.length">No hay datos disponibles</h5>
    <app-presence-keys [cards]="cards"></app-presence-keys>
    <app-presence-income [data]="cards"></app-presence-income>

    <h3 class="my-3">Género (%)</h3>
    <h5 class="secondary" *ngIf="!cards.length">No hay datos disponibles</h5>
    <app-presence-gender [data]="cards"></app-presence-gender>

  </div>
</div>
