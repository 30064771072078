import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Mathematics } from './mathematics.service';
import { ThematicKeySymbol } from './thematic-key-symbol.service';
import { ScrollIntoViewService } from './scrollIntoView.service';
import { ColorsService } from './colors.service';
import { MapBoxService } from './map-box.service';
import { MatTableService } from './mat-table.service';
import { LocationService } from './location.service';
import { ZIndexService } from './z-index.service';
@NgModule({
  imports: [CommonModule],
  providers: [Mathematics, ThematicKeySymbol, ScrollIntoViewService, ColorsService, MapBoxService, MatTableService, LocationService, ZIndexService]
})
export class UtilsMiscModule { }
