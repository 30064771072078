import {Component, OnInit} from '@angular/core';
import {StartLeftSidenavService} from "@compass/page-navigation/start-left-sidenav";

@Component({
  selector: 'glo-stage-local-knowledge',
  templateUrl: './stage-local-knowledge.component.html',
  styleUrls: ['./stage-local-knowledge.component.scss']
})
export class StageLocalKnowledgeComponent implements OnInit {

  constructor(
    public startLeftSidenavService:StartLeftSidenavService
  ) {

  }

  ngOnInit(): void {
  }

}
