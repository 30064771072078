import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { SideNavStatusService } from '@compass/utils/navigation';
import { CompassMapMarkerService } from '@compass/utils/leaflet';
import { CreateStudyService } from '@compass/feature-create-study';

@Component({
  selector: 'glo-create-study-button',
  templateUrl: './create-study-button.component.html',
  styleUrls: ['./create-study-button.component.css']
})
export class CreateStudyButtonComponent {
  @Input() disabled: boolean;
  textButton: string = 'Crear';

  coords: Array<number>;
  areaPainted: string;
  study: any;

  loading: boolean = false;

  constructor(
    private sideNavStatusService: SideNavStatusService,
    private compassMapMarkerService: CompassMapMarkerService,
    private createStudyService: CreateStudyService
  ) {
    // check for creating status to disable button and show loader
    this.createStudyService.creating$.subscribe((creating) => this.loading = creating);

    this.createStudyService.getDataFormStudy().subscribe((params) => {
      if (params) {
        this.study = params;
      }
    });


    this.sideNavStatusService.getStudyButtonCreateStatus().subscribe((value) => {
      this.textButton = this.createStudyService.getIdStudySelected() ? 'Recalcular' : this.textButton;

      const studyType = this.createStudyService.getStudyTabSelected();
      switch (studyType) {
        case 'cp':
          if (this.createStudyService.getCPSelected()) {
            if (JSON.parse(this.createStudyService.getCPSelected()).length == 0) {
              if (this.createStudyService.areaPainted) {
                this.createStudyService.studyGeomPainted(true);
              } else {
                this.createStudyService.studyGeomPainted(false);
              }
            } else {
              this.createStudyService.setAreaPainted(null);
              this.createStudyService.studyGeomPainted(false);
              //go to form cp
            }
          } else {
            if (this.createStudyService.areaPainted) {
              this.createStudyService.studyGeomPainted(true);
            } else {
              this.createStudyService.studyGeomPainted(false);
            }
          }
          break;

        case 'manual':
          if (this.createStudyService.areaPainted) {
            this.createStudyService.studyGeomPainted(true);
          } else {
            this.createStudyService.studyGeomPainted(false);
          }
          break;

        case 'point':
          this.createStudyService.setAreaPainted(null);
          this.createStudyService.studyGeomPainted(false);
          break;
      }
    });
  }

  onClickCreateButton(e): void {
    e.preventDefault();
    this.compassMapMarkerService.onCreateButtonClick();
  }

}
