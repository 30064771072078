<compass-footer>
  <div class="footer">
    <div fxLayout="row" fxLayout.lt-sm="column">
      <div fxFlex="33%" class="text__align--left brand-container">
        <div class="image-wrapper">
          <a [href]="env.footerUrl || '#'" target="_blank" title="{{ env.title }}">
            <img src="/assets/img/brand/logo-white.svg" class="image" alt="{{ env.title }}">
          </a>
        </div>
        <p>
          <small>{{ env.copyrightText }}</small>
        </p>
      </div>

      <div fxFlex="33%" class="text__align--center brand-container">
        <div class="image-wrapper"></div>

        <p><small></small></p>
      </div>
      <div fxFlex="33%" class="text__align--right brand-container">
        <div class="image-wrapper">
          <a href="http://abacus-consulting.net/" target="_blank" title="Abacus Consulting">
            <img style="height: 30px !important;" src="/assets/img/brand/svg/logo-abacus-h-white.svg" class="image"
                 alt="Abacus Consulting" />
          </a>
        </div>

        <p><small>Powered by Abacus Consulting</small></p>
      </div>
    </div>
  </div>
</compass-footer>
