import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


// NEST NEW CATEGORIES


@Injectable({ providedIn: 'root' })
export class LocalKnowledgeReshapeService {
  public knowledgeSource$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor() {
  }

  // data
  public reshape(setup, target) {
    let result;
    if (target.category.key === 'Todos') {
      if (target.subcategory.key === 'Todos') {
        result = setup;
      } else {
        result = setup.filter(set => set.subcategoria === target.subcategory.key);
      }

    }

    if (target.category.key !== 'Todos') {
      if (target.subcategory.key === 'Todos') {
        result = setup.filter(set => set.categoria === target.category.key);
      } else {
        result = setup.filter(set => set.categoria === target.category.key && set.subcategoria === target.subcategory.key);
      }
    }

    this.set(result);
    return result;

  };

  public get() {
    return this.knowledgeSource$.asObservable();
  }

  public set(data) {
    this.knowledgeSource$.next(data);
  }


}
