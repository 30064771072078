import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class HeritageFormReshapeService {

  indexOfProperty(arr, stringToSearch) {
    return arr.indexOf(stringToSearch) >= 0;
  }

  setResult(key, form, result) {
    let temp = [];

    if (this.indexOfProperty(form[key], "Todos")) {
      temp = result;
    } else {
      //  ["JC DECAUX ESPAÑA, S.L.U", "IMPROVED BUSINESS MEDIA, S.L"]
      if (form[key].length > 0) {
        temp = result.filter(
          (poi) => {
            return form[key].indexOf(poi.properties[key]) >= 0;
          }
        );
      } else {
        temp = [];
      }
    }
    return temp;
  }
  public reshape(formControlValues, poisTableList) {
    let keys = Object.keys(formControlValues);
    let result = poisTableList;
    let i = 0;

    for (i; i < keys.length; i++) {

      if (keys[i] === 'proveedor') {
        result = this.setResult(keys[i], formControlValues, result);
      }
      if (keys[i] === 'medio') {
        result = this.setResult(keys[i], formControlValues, result);
      }
      if (keys[i] === 'soporte') {
        result = this.setResult(keys[i], formControlValues, result);
      }
      if (keys[i] === 'sub_categoria') {
        result = this.setResult(keys[i], formControlValues, result);
      }
      if (keys[i] === 'cliente') {
        result = this.setResult(keys[i], formControlValues, result);
      }
      if (keys[i] === 'status') {
        result = this.setResult(keys[i], formControlValues, result);
      }
    }
    return result;
  }
}
