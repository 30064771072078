<div fxLayout="row wrap" fxLayoutGap="1%">
  <div class="card" *ngFor="let card of cards" fxFlex="100%" fxFlex.gt-xs="49" fxFlex.gt-sm="32" fxFlex.gt-lg="24">
    <div class="card-head">
      <div class="inline card-icon">
        <svg height="17.99999" viewBox="0 0 13.31494 17.99999" width="13.31494" xmlns="http://www.w3.org/2000/svg">
          <g [attr.fill]="card.color" stroke="#49565d" stroke-linecap="round" stroke-width=".75">
            <path d="m2.6573 17.625h8" />
            <path
              d="m6.67.375h-.025a6.27042 6.27042 0 0 0 -6.27 6.27 6.18415 6.18415 0 0 0 1.62207 4.198c1.07393 1.262 4.65993 4.407 4.65993 4.407s3.58606-3.145 4.66-4.407a6.18035 6.18035 0 0 0 1.62292-4.198 6.27031 6.27031 0 0 0 -6.26992-6.27zm-.013 8.57751a2.392 2.392 0 1 1 2.39185-2.392 2.39195 2.39195 0 0 1 -2.39185 2.392z" />
          </g>
        </svg>
      </div>

      <div class="inline card-title">
        <p class="title" style="text-overflow: ellipsis;">{{card.title}}</p>
        <p class="address" style="text-overflow: ellipsis;">{{card.address}}</p>
        <p class="number">{{card.summary.presence.total | number: "1.0-0":"es-ES"}}</p>
      </div>

      <div class="inline card-circle">
        <div class="circle">
          <svg viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle [attr.fill]="card.color" cx="80" cy="80" [attr.r]="card.summary.presence.radius * 80" />
          </svg>
        </div>
      </div>
    </div>

    <div class="card-body">
      <table>
        <tr>
          <td>Ingresos</td>
          <td>{{card.income}}</td>
        </tr>
        <tr>
          <td>Género</td>
          <td>{{card.gender}}</td>
        </tr>
        <tr>
          <td>Grupo de edad</td>
          <td>{{card.age}}</td>
        </tr>
        <tr>
          <td>Nacionalidad</td>
          <td>{{card.nationalty}}</td>
        </tr>
        <tr>
          <td>Periodo del día</td>
          <td>{{card.period}}</td>
        </tr>
        <tr>
          <td>Actividad</td>
          <td>{{card.activity}}</td>
        </tr>
        <tr>
          <td>Tránsito</td>
          <td>{{card.transit | number: "1.0-0":"es-ES"}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
