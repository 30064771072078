<section>
  <div class="container">
    <p class="margin__top--s">
      <mat-icon color="primary">maps_ugc</mat-icon>
      Pulse sobre el mapa para escoger el punto.
    </p>
  </div>
</section>

<section>
  <div class="container">
    <div class="margin__top--s">
      <p>
        <strong *ngIf="selectedDirection">{{ selectedDirection }}</strong>
      </p>
      <p>{{ selectedLocality }}</p>
      <p>
        <small><span>{{ selectedLatitude }}</span>, <span>{{ selectedLongitude }}</span></small>
      </p>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="margin__top--xl">
      <p><small>MODO</small></p>
      <mat-button-toggle-group
        name="fontStyle"
        aria-label="Font Style"
        [value]="selectedMode"
      >
        <mat-button-toggle
          value="foot"
          (change)="emitActiveMode($event.value)"
          [checked]="true"
        >
          <span class="material-icons">
            directions_walk
          </span>
        </mat-button-toggle>
        <mat-button-toggle value="car" (change)="emitActiveMode($event.value)">
          <span class="material-icons">
            directions_car
          </span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="margin__top--s">
      <p><small>DISTANCIA (minutos)</small></p>
      <mat-button-toggle-group
        name="fontStyle"
        aria-label="Font Style"
        [value]="selectedDistance"
      >
        <mat-button-toggle
          value="00_05"
          (change)="emitActiveDistance($event.value)"
          [checked]="true"
        >5
        </mat-button-toggle>
        <mat-button-toggle
          value="00_10"
          (change)="emitActiveDistance($event.value)"
        >10
        </mat-button-toggle>
        <mat-button-toggle
          value="00_15"
          (change)="emitActiveDistance($event.value)"
        >15
        </mat-button-toggle>
        <!--<mat-button-toggle value="d20" (change)="emitActiveDistance($event.value)">20</mat-button-toggle>-->
      </mat-button-toggle-group>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="margin__top--xl">
      <form
        hidden="true"
        novalidate
        [formGroup]="form"
        (submit)="submit(form.value)"
      >
        <div class="container">
          <input type="text" formControlName="direction" [value]="selectedDirection" />
          <input type="text" formControlName="latitude" [value]="selectedLatitude" />
          <input type="text" formControlName="longitude" [value]="selectedLongitude" />
          <input type="text" formControlName="mode" [value]="selectedMode" />
          <input type="text" formControlName="distance" [value]="selectedDistance" />
        </div>
      </form>

      <div class="container mt-2">
        <div fxFlex="50">
          <glo-cancel-study-button></glo-cancel-study-button>
        </div>
        <div fxFlex="50">
          <glo-create-study-button [disabled]="disabled" fxFlex="50"></glo-create-study-button>
        </div>
      </div>
    </div>
  </div>
</section>
