<form
  [formGroup]="newPoiForm"
  class="full-width clearfix newUserForm"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field *ngIf="categories" class="margin__top--l">
    <mat-label>Categoría</mat-label>
    <mat-select formControlName="key_sub_categoria" required>
      <mat-option *ngFor="let category of categories" [value]="category.key_sub_categoria">
        {{ category.sub_categoria }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="margin__top--l" data-cy="poi-name">
    <input
      matInput
      type="text"
      placeholder="Nombre"
      formControlName="nombre"
      required
    />
  </mat-form-field>

  <mat-form-field class="margin__top--l">
    <input
      matInput
      type="text"
      placeholder="Dirección"
      formControlName="direccion"
      required
    />
  </mat-form-field>

  <mat-form-field class="margin__top--l" data-cy="poi-comments">
    <input
      matInput
      type="text"
      placeholder="Comentarios"
      formControlName="comentarios"
    />
  </mat-form-field>

  <mat-form-field class="margin__top--l">
    <input matInput type="text" placeholder="X" formControlName="x" required />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" placeholder="Y" formControlName="y" required />
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="center center" class="margin__top--xl">
    <div class="margin__right--s">
      <button
        mat-flat-button
        class="button--xl lightgray"
        type="reset"
        (click)="resetForm($event)"
      >
        <i class="material-icons sub-icons small"> refresh </i>
        Reiniciar
      </button>
    </div>
    <div>
      <button mat-flat-button color="primary" class="button--xl" type="submit" [disabled]="submitted">
        <i class="material-icons sub-icons small"> beenhere </i>
        Guardar
      </button>
    </div>
  </div>
</form>
