import { Component, OnInit, Input } from '@angular/core';
export interface Style {
  height: string;
}
export interface Img {
  class: string;
  src: string;
  alt: string;
  width: string;
}
export interface Toolbar {
  title: string;
  style: Style;
  class: string;
  img: Img;
}

@Component({
  selector: 'compass-top-toolbar-wrapper',
  templateUrl: './top-toolbar-wrapper.component.html',
  styleUrls: ['./top-toolbar-wrapper.component.scss'],
})
export class TopToolbarWrapperComponent implements OnInit {
  @Input() toolbarSetUp: Toolbar;

  constructor() { }

  ngOnInit(): void { }
}
