import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../material/src/lib/material.module';
import { CrudMenuNavComponent } from './crud-menu-nav/crud-menu-nav.component';
import { LogoutAppService } from '@compass/utils/navigation';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [CrudMenuNavComponent],
  declarations: [CrudMenuNavComponent],
  providers: [LogoutAppService],
})
export class SharedCrudMenuModule {}
