<section>
  <div class="container">
    <compass-stage-breadcrumbs></compass-stage-breadcrumbs>
  </div>
</section>
<section>
  <div class="container">
    <compass-select-layer-loader></compass-select-layer-loader>
  </div>
</section>

<section>
  <div class="container">
    <compass-pois-searcher></compass-pois-searcher>

    <compass-pois-accordion [setup]="accordionSetup">
      <ng-template #loading_local>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
      <!-- category list is needed its icons -->
      <ng-container *ngIf="categories$ | async as categories">
        <compass-pois-table
          *ngIf="
            localCategoriesList$ | async as localCategoriesList;
            else loading_local
          "
          [poisTableList]="localCategoriesList"
          style="width: 100%"
        >
        </compass-pois-table>
      </ng-container>
    </compass-pois-accordion>
  </div>
</section>
