import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable, of, throwError, BehaviorSubject, Subject } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LocationService {
  constructor(private http: HttpClient) {}

  getApiAddress(address: any): Observable<any> {
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&components=country:ES&sensor=false&key=AIzaSyCL7i66Wm5oSFomXenZThk9yK-0Q9Sxlks"
    );
  }

  getApiCoords(coords: any): Observable<any> {
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        coords.lat +
        "," +
        coords.lon +
        "&sensor=false&key=AIzaSyCL7i66Wm5oSFomXenZThk9yK-0Q9Sxlks"
    );
  }

  buscarDireccion(respuesta: any): any {
    if (respuesta.status == "INVALID_REQUEST") {
      alert(
        "Error al realizar la geolocalización\n\nEs posible que la dirección sea erronea"
      );
      return;
    }
    if (respuesta.status == "OVER_QUERY_LIMIT") {
      alert(
        "Se ha sobrepasado el límite de peticiones\n\nNo se han obtenido coordenadas para ese punto"
      );
      return;
    }

    var direccion = null;
    var numero = null;
    var municipio = null;
    var provincia = null;
    for (var i = 0; i < respuesta.results[0].address_components.length; i++) {
      var tipos = respuesta.results[0].address_components[i].types;
      for (var j = 0; j < tipos.length; j++) {
        switch (tipos[j]) {
          case "street_number":
            numero = respuesta.results[0].address_components[i].long_name;
            break;
          case "route":
            direccion = respuesta.results[0].address_components[i].long_name;
            break;
          case "locality":
            municipio = respuesta.results[0].address_components[i].long_name;
            break;
          case "administrative_area_level_2":
            provincia = respuesta.results[0].address_components[i].long_name;
            break;
          default:
            break;
        }
      }
    }

    var x = respuesta.results[0].geometry.location.lng;
    var y = respuesta.results[0].geometry.location.lat;
    var result = null;
    if (respuesta.status == "OK") {
      result = {
        estado: "ok",
        address: direccion + ", " + numero,
        locality: municipio,
        province: provincia,
        x: x,
        y: y
      };
    } else {
      result = {
        estado: "ko",
        direccion: direccion + ", " + numero + ", " + municipio,
        x: x,
        y: y
      };
    }

    return result;
  }
  geocodificarDireccion(address: any): any {
    return this.getApiAddress(address + " ");
  }

  geocodificarCoordenadas(coords: any): any {
    return this.getApiCoords(coords);
  }
}
