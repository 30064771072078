import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PoisSearcherService {
  toggledPoi$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  status$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  togglePoi(poi) {
    this.toggledPoi$.next(poi);
    this.toggledPoi$.next(undefined); // reset the status for new subscribers
  }

  toggleStatus(status) {
    this.status$.next(status);
  }

  getRatingStars(n: any) {
    let response = n;

    switch (n) {
      case 0:
        response = '';
        break;
      case 1:
        response = '⭐';
        break;
      case 2:
        response = '⭐⭐';
        break;
      case 3:
        response = '⭐⭐⭐';
        break;
      case 4:
        response = '⭐⭐⭐⭐';
        break;
      case 5:
        response = '⭐⭐⭐⭐⭐';
        break;
      case 'none':
        response = 'Sin Puntuación';
        break;
    }

    return response;
  }
}
