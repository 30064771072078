import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessComponent } from './business.component';
import { MapModule,  } from '@compass/map';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedWidgetsModule } from '@compass/shared/widgets';
import { BusinessModule as LibBusinessModule } from '@compass/business';
import { IndicatorsModule } from '@compass/indicators-table';

@NgModule({
  declarations: [BusinessComponent],
  imports: [
    CommonModule,
    MapModule,
    MatProgressBarModule,
    SharedWidgetsModule,
    LibBusinessModule,
    IndicatorsModule,


  ],
  exports: [
    BusinessComponent
  ],
  providers: []
})
export class BusinessModule {
}

