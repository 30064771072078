import { Component, OnInit } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-companies-loader',
  templateUrl: './companies-loader.component.html',
  styleUrls: ['./companies-loader.component.css'],
})
export class CompaniesLoaderComponent implements OnInit {
  companiesList: any;
  activeTableRoot: string;

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((d: string) => {
        this.activeTableRoot = d;
      });

    this.sidenavPoisLevelsService.getCompanyList().subscribe((d: any) => {
      this.companiesList = d;
    });
  }
  ngOnInit(): void {}
}
