<p>{{provincia}} | Previsión para el municipio de <strong>{{municipio}}</strong> | {{ elaborado |
    date:'full':'+0100':'es'}}</p>
<h1>Pronóstico para los próximos días</h1>

<div class="margin__top--xl weather-mat-accordion">

    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let day of days ; let i = index" [expanded]="i===0 ? 'true' : false"
            (opened)="setOpened(i)" (closed)="setClosed(i)" class="mat-elevation-z1">

            <mat-expansion-panel-header>
                <!-- panel title CLOSED -->
                <div [ngClass]="{'hidden': currentlyOpenedItemIndex === i}" class="title-container closed">
                    <mat-panel-title>
                        <div class="inline first-col col">
                            <span class="inline fecha demo">{{day.fecha | date:'EEEE,
                                d':'+0100':'es'}}</span>
                        </div>
                        <div class="inline second-col col center">
                            <span class="inline max">{{getWeatherNumber(day.temperatura.maxima, '°')}}</span>
                            <span class="inline">/</span>
                            <span class="inline min">{{getWeatherNumber(day.temperatura.minima, '°')}}</span>
                        </div>
                        <div class="inline third-col col">
                            <div class="estadoCielo">
                                <img class='img-l' [src]="getWeatherImg(day.estadoCielo,'value','00-24')" />

                            </div>
                        </div>
                        <div class="inline fourth-col col noMobile">
                            <div class="probPrecipitacion text__align--rigth">
                                <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                <span>{{probPrecipitacion(day.probPrecipitacion, 'periodo','value','00-24')}}</span>
                            </div>
                        </div>
                        <div class="inline five-col col noMobile">
                            <div class="rachaMax text__align--rigth">
                                <img class='img-l' [src]="imgUrl + 'rachaMax.svg'" />
                                <span>{{getRachaMax(day.rachaMax, 'periodo', 'value','00-24')}}</span>

                            </div>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        {{getWeatherText(day.estadoCielo, 'periodo','descripcion', '00-24')}}
                    </mat-panel-description>

                </div>

                <!-- panel title OPEN -->
                <div [ngClass]="{'hidden': currentlyOpenedItemIndex !== i}">
                    <mat-panel-title>
                        <div class="inline">
                            <span class="inline fecha demo">{{day.fecha | date:'EEEE,
                                d':'+0100':'es'}}</span>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div class="container mat-panel-description">

                            <div>
                                <img class='img-s' [src]="getWeatherImg(day.estadoCielo, 'value','00-24')" />
                                <span class="inline max">{{getWeatherNumber(day.temperatura.maxima, '°')}}</span>
                                <span class="inline">/</span>
                                <span class="inline min">{{getWeatherNumber(day.temperatura.minima, '°')}}</span>
                                <span class="inline"> &nbsp;&nbsp;| &nbsp;&nbsp;</span>
                                <strong>{{getWeatherText(day.estadoCielo, 'periodo','descripcion', '00-24')}}</strong>

                            </div>


                        </div>

                    </mat-panel-description>
                </div>
            </mat-expansion-panel-header>




            <!-- The content: For all days-->
            <!-- The content: 0-24 hours -->
            <section>
                <div class="container">

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">
                        <div fxFlex="50%">
                            <div>
                                <img class='img-s' [src]="imgUrl + 'humedadRelativa.svg'" />
                                <span class="inline">{{getWeatherNumber(day.humedadRelativa.maxima, '%')}}</span>
                                <span class="inline">/</span>
                                <span class="inline">{{getWeatherNumber(day.humedadRelativa.minima, '%')}}</span>

                                <span class="inline"><small>&nbsp;(Humedad relativa)</small></span>

                            </div>
                            <div>
                                <img class='img-s' [src]="imgUrl + 'uvMax.svg'" />
                                <span class="inline min">{{getWeatherNumber(day.uvMax, '')}}</span>
                                <span><small>&nbsp;(Índice ultravioleta máximo)</small></span>
                            </div>
                            <div>
                                <img class='img-s' [src]="imgUrl + 'sensTermicaMax.svg'" />
                                <span class="inline">{{getWeatherNumber(day.sensTermica.maxima, '°')}}</span>
                                <span class="inline">/</span>
                                <span class="inline">{{getWeatherNumber(day.sensTermica.minima, '°')}}</span>
                                <span class="inline"><small>&nbsp;(Sensación térmica)</small></span>
                            </div>
                            <div>
                                <img class='img-s' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                <span>{{probPrecipitacion(day.probPrecipitacion, 'periodo','value','00-24')}}</span>
                            </div>

                        </div>

                        <div fxFlex="50%">


                            <div>
                                <img class='img-s' [src]="getWeatherImg(day.viento, 'direccion','00-24')" />

                                <span class="inline">{{getVientoText(day.viento, 'periodo','direccion',
                                    '00-24')

                                    }}</span>
                                <span class="inline">,&nbsp;</span>
                                <span class="inline">{{getVelocidadText(day.viento, 'periodo','velocidad',
                                    '00-24')}}</span>
                            </div>
                            <div>
                                <img class='img-s' [src]="imgUrl + 'rachaMax.svg'" />
                                <span>{{getRachaMax(day.rachaMax, 'periodo','value','00-24')}}</span>
                                <span class="inline"><small>&nbsp;(Racha máxima)</small></span>
                            </div>
                            <div>
                                <img class='img-s' [src]="imgUrl + 'cotaNieveProv.svg'" />
                                <span>{{getCotaNieveText(day.cotaNieveProv, 'periodo','value', '00-24')}}</span>
                                <span class="inline"><small>&nbsp;(Cota de nieve)</small></span>
                            </div>


                        </div>
                    </div>
                </div>
            </section>


            <!-- The content: For days 1, 2, 3, 4 -->
            <!-- The content: 0-12 hours -->
            <section *ngIf="i<4">

                <div class="container">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">

                        <div fxFlex="50%">
                            <p class="title"><strong>De 00.00h. a 12.00h.</strong></p>
                            <div class="container">
                                <div class="inline col half center">
                                    <div>
                                        <img class='img-l' [src]="getWeatherImg(day.estadoCielo, 'value','00-12')" />
                                        <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                            '00-12')}}</span>

                                    </div>
                                </div>

                                <div class="inline col half">
                                    <div>
                                        <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                        <span>{{probPrecipitacion(day.probPrecipitacion,'periodo',
                                            'value','00-12')}}</span>
                                    </div>

                                </div>
                            </div>

                            <div class="container">
                                <div class="inline col half center">
                                    <div>
                                        <img class='img-l' [src]="getWeatherImg(day.viento, 'direccion','00-12')" />
                                        <span class="inline">{{getVientoText(day.viento,'periodo', 'direccion',
                                            '00-12')}}</span>
                                        <span class="inline">,&nbsp;</span>
                                        <span class="inline">{{getVelocidadText(day.viento,'periodo', 'velocidad',
                                            '00-12')}}</span>
                                    </div>
                                </div>

                                <div class="inline col half">
                                    <div class="inline">
                                        <div>
                                            <img class='img-l' [src]="imgUrl + 'rachaMax.svg'" />
                                            <span>{{getRachaMax(day.rachaMax, 'periodo','value','00-12')}}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>









                            <!-- The content: Only For days 1, 2-->
                            <!-- The content: 0-12 hours -->
                            <section *ngIf="i<2">
                                <div class="container">
                                    <!-- The content: 0-6 hours -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">
                                        <div fxFlex="50%">
                                            <p class="title text__aling--center">06.00h.</p>
                                            <div>


                                                <img class='img-l' [src]="getSensTermicaImg(getWeatherText(day.sensTermica.dato,
                                                'hora','value', 6))" />
                                                <span class="inline bigNumber">{{getSensTermica(day.sensTermica.dato,
                                                    'hora','value', 6)}}</span>
                                            </div>

                                            <div>
                                                <img class='img-l'
                                                    [src]="getWeatherImg(day.estadoCielo,  'value','00-06')" />
                                                <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                                    '00-06')}}</span>
                                            </div>

                                            <div>
                                                <img class='img-s' [src]="imgUrl + 'humedadRelativa.svg'" />
                                                <span class="inline">{{getHumedadRelativa(day.humedadRelativa.dato,
                                                    'hora','value', 6)}}</span>
                                            </div>
                                            <div>
                                                <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                                <span>{{probPrecipitacion(day.probPrecipitacion,
                                                    'periodo','value','00-06')}}</span>
                                            </div>
                                        </div>

                                        <!-- The content: 6-12 hours -->
                                        <div fxFlex="50%">
                                            <p class="title text__aling--center">12.00h.</p>
                                            <div>
                                                <img class='img-l'
                                                    [src]="getSensTermicaImg(getWeatherText(day.sensTermica.dato, 'hora','value', 12))" />
                                                <span class="inline bigNumber">{{getSensTermica(day.sensTermica.dato,
                                                    'hora','value', 12)}}</span>
                                            </div>

                                            <div>
                                                <img class='img-l'
                                                    [src]="getWeatherImg(day.estadoCielo,  'value','06-12')" />
                                                <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                                    "06-12")}}</span>
                                            </div>

                                            <div>
                                                <img class='img-s' [src]="imgUrl + 'humedadRelativa.svg'" />
                                                <span class="inline">{{getHumedadRelativa(day.humedadRelativa.dato,
                                                    'hora','value', 12)}}</span>
                                            </div>
                                            <div>
                                                <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                                <span>{{probPrecipitacion(day.probPrecipitacion,
                                                    'periodo','value',"06-12")}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>




                        </div>

                        <div fxFlex="50%">
                            <p class="title"><strong>De 12.00h. a 24.00h.</strong></p>
                            <div class="container">
                                <div class="inline col half center">
                                    <div>
                                        <img class='img-l' [src]="getWeatherImg(day.estadoCielo, 'value','12-24')" />
                                        <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                            '12-24')}}</span>

                                    </div>
                                </div>

                                <div class="inline col half">
                                    <div>
                                        <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                        <span>{{probPrecipitacion(day.probPrecipitacion,
                                            'periodo','value','12-24')}}</span>
                                    </div>

                                </div>
                            </div>

                            <div class="container">
                                <div class="inline col half center">
                                    <div>
                                        <img class='img-l' [src]="getWeatherImg(day.viento, 'direccion','12-24')" />
                                        <span class="inline">{{getVientoText(day.viento, 'periodo','direccion',
                                            '12-24')}}</span>
                                        <span class="inline">,&nbsp;</span>
                                        <span class="inline">{{getVelocidadText(day.viento, 'periodo','velocidad',
                                            '12-24')}}</span>
                                    </div>
                                </div>

                                <div class="inline col half">
                                    <div class="inline">
                                        <div>
                                            <img class='img-l' [src]="imgUrl + 'rachaMax.svg'" />
                                            <span>{{getRachaMax(day.rachaMax, 'periodo','value','12-24')}}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>







                            <!-- The content: Only For days 1, 2-->
                            <!-- The content: 12-24 hours. -->
                            <section *ngIf="i<2">
                                <div class="container">
                                    <!-- The content: 12-18 hours -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8%">
                                        <div fxFlex="50%">
                                            <p class="title text__aling--center">18.00h.</p>
                                            <div>
                                                <img class='img-l'
                                                    [src]="getSensTermicaImg(getWeatherText(day.sensTermica.dato, 'hora','value', 18))" />
                                                <span class="inline bigNumber">{{getSensTermica(day.sensTermica.dato,
                                                    'hora','value', 18)}}</span>
                                            </div>

                                            <div>
                                                <img class='img-l'
                                                    [src]="getWeatherImg(day.estadoCielo,  'value','12-18')" />
                                                <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                                    "12-18")}}</span>
                                            </div>

                                            <div>
                                                <img class='img-s' [src]="imgUrl + 'humedadRelativa.svg'" />
                                                <span class="inline">{{getHumedadRelativa(day.humedadRelativa.dato,
                                                    'hora','value', 18)}}</span>
                                            </div>
                                            <div>
                                                <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                                <span>{{probPrecipitacion(day.probPrecipitacion,
                                                    'periodo','value',"12-18")}}</span>
                                            </div>
                                        </div>

                                        <!-- The content: 18-24 hours -->
                                        <div fxFlex="50%">
                                            <p class="title text__aling--center">24.00h.</p>
                                            <div>
                                                <img class='img-l'
                                                    [src]="getSensTermicaImg(getWeatherText(day.sensTermica.dato, 'hora','value', 24))" />
                                                <span class="inline bigNumber">{{getSensTermica(day.sensTermica.dato,
                                                    'hora','value', 24)}}</span>
                                            </div>

                                            <div>
                                                <img class='img-l'
                                                    [src]="getWeatherImg(day.estadoCielo,  'value','18-24')" />
                                                <span> {{getWeatherText(day.estadoCielo, 'periodo','descripcion',
                                                    "18-24")}}</span>
                                            </div>

                                            <div>
                                                <img class='img-s' [src]="imgUrl + 'humedadRelativa.svg'" />
                                                <span class="inline">{{getHumedadRelativa(day.humedadRelativa.dato,
                                                    'hora','value', 24)}}</span>
                                            </div>
                                            <div>
                                                <img class='img-l' [src]="imgUrl + 'probPrecipitacion.svg'" />
                                                <span>{{probPrecipitacion(day.probPrecipitacion,
                                                    'periodo','value',"18-24")}}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                </div>

            </section>



        </mat-expansion-panel>
    </mat-accordion>


</div>

<div class="margin__top--xxl">
    <p><small>{{productor }}</small></p>
    <p><small>©<a href="{{ web}}" target='_blank' title={{copyright}}>AEMET</a> | <a href="{{ notaLegal }}"
                target='_blank' title={{copyright}}>Nota legal</a></small></p>
</div>