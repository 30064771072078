import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface Card {
  activity: string;
  address: string;
  age: string;
  color: string;
  gender: string;
  income: string;
  nationalty: string;
  period: string;
  summary: {
    presence: {
      total: number;
      radius: number;
      percent: number;
    }
  };
  title: string;
  transit: number;
}


@Injectable({ providedIn: 'root' })
export class PresenceService {
  private url = '/compass-api/api/popular_time/presence/250';
  public dictionary$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(public http: HttpClient, private router: Router) {
  }

  public getPresence(poi: any) {
    return this.http.post(`${this.url}`, {
      type: poi.type,
      geometry: poi.geometry
    });
  }

  getCards(pois: any): Card[] {
    let result = [];
    let maxPresence = this.getTheMaxPresence(pois);
    let sumPresence = this.getTheSumPresence(pois);

    pois.forEach((poi: any, i: number) => {
      let presence = poi.properties.presence;

      if (presence) {
        const data: any = {
          summary: {
            presence: {
              total: null,
              radius: null,
              percent: null
            },
            gender: {
              groups: null,
              percents: null
            },
            income: {
              groups: null,
              percents: null
            }
          }
        };

        if (poi.properties?.dictionary) {
          this.dictionary$.next({ ...this.dictionary$.value, ...poi.properties.dictionary });
        }

        data.color = poi.properties.color;
        data.title = poi.properties.nombre;
        data.address = poi.properties.direccion;
        data.income = this.getWinner(presence.income, poi.properties.dictionary);
        data.gender = this.getWinner(presence.sex, poi.properties.dictionary);
        data.age = this.getWinner(presence.age, poi.properties.dictionary);
        data.nationalty = this.getWinner(presence.nationalty, poi.properties.dictionary);
        data.period = this.getWinner(presence.period, poi.properties.dictionary);
        data.activity = this.getWinner(presence.activity, poi.properties.dictionary);
        data.transit = presence.activity.t;
        data.summary.presence.total = presence.total;
        data.summary.presence.radius = presence.total / maxPresence;
        data.summary.presence.percent = presence.total * 100 / sumPresence;
        data.summary.gender.groups = {
          male: presence.sex.male,
          female: presence.sex.female
        };

        data.summary.gender.percents = {
          male: this.getGenderPercent('male', presence.sex),
          female: this.getGenderPercent('female', presence.sex)
        };

        data.summary.income.groups = presence.income;

        result.push(data);
      }
    });


    return this.sortCards(result);
  }

  sortCards(cards: Card[]): Card[] {
    return cards.sort((a, b) => b.summary.presence.total - a.summary.presence.total);
  }

  getGenderPercent(sex, d) {
    let sum = d.female + d.male;
    return d[sex] * 100 / sum;
  }

  getTheSumPresence(pois): number {
    const total = pois.map((poi: any) => poi.properties?.presence?.total ?? 0);
    return total.length ? total.reduce((total, num) => total + num) : 0;
  }

  getWinner(data, dictionary) {
    // añadimos españa y others
    dictionary.spain = 'España';
    dictionary.others = 'Otro';
    dictionary.none = 'Ninguna';
    dictionary.female = 'Mujer';
    dictionary.male = 'Hombre';

    let entries = Object.entries(data);
    let sorted = entries.sort((a: any, b: any) => b[1] - a[1]);
    let winner = sorted[0] && sorted[1] && (sorted[0][0] === 't') ? sorted[1] : sorted[0];
    return dictionary[winner[0]] ?? dictionary[winner[0]?.toUpperCase()] ?? '--';
  }

  getTheMaxPresence(pois): number {
    let presences = [];
    let max: number = 0;

    pois.forEach((poi: any) => {
      presences.push(poi.properties?.presence?.total ?? 0);
    });

    max = Math.max(...presences);
    return max;
  }
}
