<article class="med--width">
  <section class="container section">
    <div class="margin__top--xl">
      <div
        class="container"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="0.5%"
        fxLayoutAlign="left"
      >
        <div fxFlex="100">
          <div class="text__align--right">
            <compass-cancel-button
              [router_link]="router_link"
            ></compass-cancel-button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container section">
    <div class="margin__top--s">
      <div
        class="container"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="0.5%"
        fxLayoutAlign="left"
      >
        <div fxFlex="100">
          <div class="container text__align--center">
            <compass-crud-icons [icon_class]="icon_class"></compass-crud-icons>
          </div>
          <ng-template #addpoi>
            <div class="container text__align--center">
              <h1 class="h1-page--title">
                <span class="icon usuarios-add animate-blue"></span>
                Añadir | Nuevo POI
              </h1>
              <p>Crea un nuevo punto de interés y añádelo a la aplicación.</p>
            </div>
          </ng-template>

          <div *ngIf="poi$ | async as poi; else addpoi">
            <div class="container text__align--center">
              <h1 class="h1-page--title">
                <span class="icon usuarios-edit animate-blue"></span> Editar |
                {{ poi.properties.nombre }}
              </h1>
              <p>Edita un punto de interés ya existente en la aplicación.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    class="section"
    fxLayoutGap="15px"
  >
    <div
      fxLayout="column"
      fxFlex="60"
      class="margin__top--l"
      style="position: relative; overflow: hidden;"
    >
      <compass-map-searchbox [setup]="searchboxSetup"> </compass-map-searchbox>
      <plain-map [poi]="poi$ | async"></plain-map>
    </div>
    <poi-form [poi]="poi$ | async" fxLayout="column" fxFlex="40"></poi-form>
  </div>
</article>
