import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@compass/shared/feature-auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authService.currentUser$.value;
    const isLoggedIn = currentUser && currentUser.access_token;
    const isApiUrl = request.url.startsWith('/api');
    const isGoogle = request.url.includes('google');
    const isFileUpload = request.url.includes('upload');
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`
        },
      });
    } else if(request.url.includes('predicthq')) {
      // do nothing... genius control time...
    }else if (isLoggedIn && !isGoogle) {
      // remove this when upload security is ready
      // file upload security
      if (!isFileUpload) {
        const token = btoa('glocally:ssolab270$');
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${token}`
          },
        });
      }
    }
    //reset the error observable
    this.authService.userError$.next(undefined);
    return next.handle(request);
  }
}
