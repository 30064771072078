<section>
  <div class="container">
    <p class="mt-1">
      Seleccione los códigos postales que desee reflejar en su estudio.
    </p>
    <p class="mt-1">
      Haz click sobre un área para saber el nombre del código postal.
    </p>

    <form [formGroup]="form" (submit)="submit(form.value)">
      <div data-cy="cp-checkbox" *ngFor="let cp of cps?.controls; let i = index">
        <mat-checkbox
          class="mx-2" color="primary"
          (ngModelChange)="updateAllChecked()"
          [formControl]="cp"
        >
          {{ study.cps[i].name }}
        </mat-checkbox>
      </div>

      <div class="container mt-2">
        <div fxFlex="50">
          <glo-cancel-study-button></glo-cancel-study-button>
        </div>
        <div fxFlex="50">
          <glo-create-study-button [disabled]="noneChecked"></glo-create-study-button>
        </div>
      </div>

      <div class="mt-0" *ngIf="noneChecked">
        <div fxFlex="100">Debe seleccionar al menos un código postal.</div>
      </div>
    </form>
  </div>
</section>
