import { Inject } from '@angular/core';

export class ApiService {
  public NAMESPACE = ''; // namespace defined
  private isProd = true;

  protected devUrl;
  protected url = '';

  constructor(
    @Inject('environment') environment: any,
  ) {
    // set NAMESPACE to the class
    this.NAMESPACE = environment.apiNamespace;
    this.isProd = environment.production;

    this.setUrl();
  }

  /**
   * Sets devUrl as base url if exists.
   *
   * @protected
   */
  protected setUrl() {
    if (!this.isProd && this.devUrl) {
      this.url = this.devUrl;
    }
  }


}
