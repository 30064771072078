import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompassMapWrapperService, CompassCatastroLayersService, FeatureInfoWMSService } from '@compass/utils/leaflet';
import { CatastroStatusService } from '@compass/utils/navigation'

import * as L from 'leaflet';
interface iMap {
  value: string;
  name: string;
}
interface iOvc {
  value: string;
  name: string;
}

@Component({
  selector: 'compass-catastro-menu',
  templateUrl: './catastro-menu.component.html',
  styleUrls: ['./catastro-menu.component.scss']
})
export class CatastroMenuComponent implements OnInit, OnChanges {
  @Input() coordsCenter: Array<number>;

  // environment
  // environment = 'DEV';
  environment = 'PRO';

  // map and layers
  map$ = this.compassMapWrapperService.map$;
  box = this.map$.value.box;
  catastroLayers = {};
  // getFeatureInfo Layer
  featureInfoLayer;
  layerOn = this.catastroStatusService.layerOn$;
  // layer status
  ovcOn: boolean = true

  // form
  castastroForm: FormGroup;
  ovc: iOvc[] = [
    { value: 'ovcCatastroSolidLayer', name: 'Relleno' },
    { value: 'ovcCatastroTransparentLayer', name: 'Línea' },
    { value: 'hidden', name: 'Ocultar' }
  ];
  noOvc: iOvc[] = [
    { value: 'navarraCatastroLayer', name: 'Navarra' },
    { value: 'arabaCadastralParcelLayer', name: 'Álava' },
    { value: 'gipuzkoaKatastroLayer', name: 'Catastro Gipuzkoa' },
    { value: 'bizkaiaKatrastroLayer', name: 'Catastro Bizkaia' }
  ];
  maps: iMap[] = [
    { value: 'openStreetMapLayer', name: 'Open Street Map' },
    { value: 'ignOrthoimageCoverageLayer', name: 'Ortofoto' },
    { value: 'ignBaseLayer', name: 'IGN' },
    { value: 'ignMapaRasterLayer', name: 'Topográfico' },
    { value: 'defensacegetM682Layer', name: 'Militar' },
    { value: 'ignLidarLayer', name: 'LiDar' }
  ];

  ovcLayers: iOvc[] = [
    { value: "sedeCatastroParcelaLayer", name: "Parcela catastral" },
    { value: "sedeCatastroConstruLayer", name: "Subparcelas de construcción" },
    { value: "sedeCatastroSubparceLayer", name: "Subparcelas de cultivo" },
    { value: "sedeCatastroMasaLayer", name: "Vías de comunicación" },
    { value: "sedeCatastroEjesLayer", name: "Parcela catastral" },
    { value: "sedeCatastroLimitesLayer", name: "Límites administrativos, costa y suelo urbano" },
    { value: "sedeCatastroTextosLayer", name: "Toponimia" },
    { value: "sedeCatastroElemlinLayer", name: "Caminos barrancos, masas de agua" },
    { value: "ovcPonenciasLayer", name: "Ponencias" }
  ]
  ovcCheckBoxesOff: boolean = false;





  // opacity
  overlayLayers: any;
  fullOpacity: number;

  // coords (setView function)
  // used for testing and development
  // on Init, on cadastre radio buttons change - onClickNoOvcRadios -
  // In the case Study coords are disabled
  // On production, use study coords, coordsCenter @input
  dummyCoords: any;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private compassCatastroLayersService: CompassCatastroLayersService,
    private featureInfoWMSService: FeatureInfoWMSService,
    private catastroStatusService: CatastroStatusService
  ) { }


  // MAP HELPERS
  drawTileLayerWms(layer, box) {
    if (!box.hasLayer(layer)) {
      layer.addTo(box);
    }
  }
  //remove  single layer
  removeTileLayerWms(layer, box) {
    if (box.hasLayer(layer)) {
      layer.remove();
    }
  }

  removePartialTileLayersWms(layer) {
    layer.forEach((layer) => {
      this.removeTileLayerWms(this.catastroLayers[layer['value']], this.box);
    })
  }
  removeAllTileLayersWms() {
    this.removeTileLayerWms(this.catastroLayers['ignAdministrativeUnitLayer'], this.box);
    this.removePartialTileLayersWms(this.ovc);
    this.removePartialTileLayersWms(this.ovcLayers);
    this.removePartialTileLayersWms(this.noOvc);
  }



  // set views to Navarra, ...
  setView(coords, map) {
    map.setView(new L.LatLng(coords[0], coords[1]), map.getZoom());
  }

  // layer status
  setLayerStatus() {
    let key = this.layerOn.value;
    if (key === undefined || key === 'ovcCatastroSolidLayer' || key === 'ovcCatastroTransparentLayer') {
      this.ovcOn = true;
    } else {
      this.ovcOn = false;
    }
  }

  // OPACITY
  // layer opacity
  setControlOpacity() {
    this.castastroForm.controls['opacity'].setValue(this.fullOpacity);
  }
  setControlOvc(value) {
    this.castastroForm.controls['ovc'].setValue(value);
  }



  setLayerOpacity(value) {
    this.overlayLayers.forEach((layer) => {
      layer.style.opacity = + Number(value) / 100;
      layer.style.filter = 'alpha(opacity=' + Number(value) / 100 + ')';
    })
  }
  setVisibleLayers(classSelector) {
    this.overlayLayers = document.querySelectorAll(classSelector);
  }

  // Opacity init
  initRangeOpacity() {
    let opacity = this.castastroForm.get('opacity').value;
    this.setVisibleLayers('.leaflet-layer.catastroLayer');
    this.setLayerOpacity(opacity);
  }


  // **** FORM *******
  // it Never submits or resets
  // Angular FormGroup to get values, not to set values
  // Radio buttons and checkboxes are working via click listeners, not when values change.
  onSubmit() { return false; }

  resetForm(event) {
    event.preventDefault();
    this.removeAllTileLayersWms();
    this.castastroForm.reset();
    this.setControlOpacity();
    this.setControlOvc(this.ovc[2].value);
  }

  setOvcControlValue() {
    let finder = this.layerOn.value;
    if (!finder) {
      return this.ovc[2].value;
    }
    return this.layerOn.value;

  }
  initCatastroForm() {
    let ovcControlValue = this.setOvcControlValue();
    this.castastroForm = new FormGroup({
      // map: new FormControl(this.maps[0].value),
      opacity: new FormControl(this.fullOpacity),
      ovc: new FormControl(ovcControlValue),
      sedeCatastroParcelaLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroConstruLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroSubparceLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroMasaLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroEjesLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroLimitesLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroTextosLayer: new FormControl({ value: false, disabled: false }),
      sedeCatastroElemlinLayer: new FormControl({ value: false, disabled: false }),
      ovcPonenciasLayer: new FormControl({ value: false, disabled: false }),
      noOvc: new FormControl(ovcControlValue),
    });
  }


  /* OVC LAYERS */
  // toggle ovc layers check boxes (caminos, roads, parcels...)
  toggleOvcCheckBoxesValue(bool) {
    this.ovcLayers.forEach((layer) => {
      if (this.castastroForm.get(layer.value).value !== bool) {
        this.castastroForm.get(layer.value).setValue(bool);
      }
    })
  }
  disableOvcCheckBoxes() {
    this.ovcLayers.forEach((layer) => {
      this.castastroForm.controls[layer.value].disable();
    })
  }
  enableOvcCheckBoxes() {
    this.ovcLayers.forEach((layer) => {
      this.castastroForm.controls[layer.value].enable();
    })
  }

  // featureInfo layer
  setFeatureInfoLayer(layer) {
    this.featureInfoLayer = layer;
  }


  setActiveRadioButton(radio: string) {
    if (radio === 'hidden') {
      this.catastroStatusService.setLayerOn(undefined);
    } else {
      this.catastroStatusService.setLayerOn(radio);
    }
  }
  // **** CLICKS *******
  // SEDE CATASTRO
  // Sede Catastro OVC MAIN
  // Radio buttons and checkboxes are working via click listeners, not when values change.
  // Here, we differentiate form elements via IDs.
  onClickOvcRadios(e) {
    let val = e.currentTarget.id;
    this.ovcOn = true;
    this.setFeatureInfoLayer(this.catastroLayers['ovcCatastroSolidLayer']);
    this.setActiveRadioButton(val);
    this.enableOvcCheckBoxes();
    this.castastroForm.get("noOvc").setValue(false);
    this.removePartialTileLayersWms(this.noOvc);


    if (this.environment === 'DEV') {
      this.setView(this.dummyCoords[val], this.box);
    }

    // first click fires, then value change, we use ID
    if (val === 'ovcCatastroSolidLayer') { // solid
      this.removeTileLayerWms(this.catastroLayers['ovcCatastroTransparentLayer'], this.box);
      this.drawTileLayerWms(this.catastroLayers['ovcCatastroSolidLayer'], this.box);
    }
    if (val === 'ovcCatastroTransparentLayer') { // transparent
      this.removeTileLayerWms(this.catastroLayers['ovcCatastroSolidLayer'], this.box);
      this.drawTileLayerWms(this.catastroLayers['ovcCatastroTransparentLayer'], this.box);
    }
    if (val === 'hidden') { // hidden
      this.removeTileLayerWms(this.catastroLayers['ovcCatastroSolidLayer'], this.box);
      this.removeTileLayerWms(this.catastroLayers['ovcCatastroTransparentLayer'], this.box);
    }
    // finally
    this.setVisibleLayers('.leaflet-layer.catastroLayer')
    this.setControlOpacity();

  }
  // 

  // Sede Catastro OVC LAYERS
  onClickOvcCheckBoxes(e) {

    if (this.ovcOn) {
      let val = e.currentTarget.id;
      let checked = !this.castastroForm.get(val).value;

      //unSetNonUvcAreas();
      if (checked) {
        this.drawTileLayerWms(this.catastroLayers[val], this.box);
      } else {
        this.removeTileLayerWms(this.catastroLayers[val], this.box);
      }
      this.setVisibleLayers('.leaflet-layer.catastroLayer')
      this.setControlOpacity();

    } else {
      return false;
    }

  }
  // CATASTRO PROVINCIAS
  // Navarra, Araba, Guipuzcoa, Vizcaya
  onClickNoOvcRadios(e) {
    let val = e.currentTarget.id;
    this.setFeatureInfoLayer(this.catastroLayers[val]);
    this.setActiveRadioButton(val);
    this.toggleOvcCheckBoxesValue(false);
    this.disableOvcCheckBoxes();
    this.castastroForm.get("ovc").setValue('hidden');
    this.ovcOn = false;
    this.removeAllTileLayersWms();

    this.drawTileLayerWms(this.catastroLayers[val], this.box);
    if (this.environment === 'DEV') {
      this.setView(this.dummyCoords[val], this.box);
    }

    this.setVisibleLayers('.leaflet-layer.catastroLayer')
    this.setControlOpacity();
  }

  f: any = (e) => {
    this.featureInfoWMSService.get(e, this.featureInfoLayer)
  }
  initMap() {
    // if the case, draw a map
    if (this.environment === 'DEV') {
      this.setView(this.dummyCoords['ovcCatastroSolidLayer'], this.box);
    } else {
      this.setView(this.coordsCenter, this.box);
    }

    this.box.addEventListener("click", this.f, true);
    return false;
  };

  initLayers() {
    // if the case, draw a map
    //this.drawTileLayerWms(this.catastroLayers['ovcCatastroSolidLayer'], this.box);
    //this.drawTileLayerWms(this.catastroLayers['ignAdministrativeUnitLayer'], this.box);
    this.setLayerStatus();
    return false;
  };
  initServices() {
    this.fullOpacity = this.compassCatastroLayersService.opacity * 100; // 0.8 *100 =  80
    this.dummyCoords = this.compassCatastroLayersService.coords;
    this.catastroLayers = this.compassCatastroLayersService.get('catastroLayers');
    this.featureInfoLayer = this.catastroLayers['ovcCatastroSolidLayer'];

  }

  ngOnInit(): void {
    this.initServices();
    this.initCatastroForm();
    this.initMap();
    this.initLayers();
    this.initRangeOpacity();
  }

  ngOnChanges() { }

  ngOnDestroy() {
    this.box.removeEventListener("click", this.f, true);
    //this.removeAllTileLayersWms();
  }

}