import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PopularTimesService {
  private apiUrl = '/compass-api/api/popular_time/area';

  constructor(
    private http: HttpClient
  ) {
  }

  get(municipalityId: string, id_ensenya: string) {
    return this.http.post(`${this.apiUrl}/${municipalityId}`, {
      "properties.id_ensenya": id_ensenya
    });
  }
}
