<ng-container *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="this.crudList">
    <button mat-icon-button class="navbar-btn" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let crud of crudList">
        <div *ngIf="!crud.role || crud.role == currentUser.role">
          <button mat-menu-item [ngClass]="crud.active ? 'active' : ''" (click)="gotoCrud(crud.path)">
            <span class="material-icons primary"> {{ crud.icon }} </span>{{ crud.name }}
          </button>
        </div>
      </ng-container>
      <button mat-menu-item (click)="logOut()">
        <span class="material-icons primary"> exit_to_app </span>Salir
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
