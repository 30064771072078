import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, filter } from 'rxjs/operators';
import * as d3 from 'd3';

export interface DictionaryEntryInterface {
  active: boolean;
  key: string;
  categoria: string;
  categoria_key: string;
  subcategoria: string;
  subcategoria_key: string;
  grupo: string;
  indicador: string;
  screen_name: string;
  formato: string;
}

export interface dictionaryIndicators extends Array<DictionaryEntryInterface> {
}

export interface Indicator {
  screen_name: string;
  key: string;
  values: Array<Indicator>;
  formato: string;
  grupo: string;
  series?: [];
  minValue?: any;
  maxValue?: any;
}

export interface IndicatorsGroups extends Array<Indicator> {
}

export interface IndicatorGroupSelected {
  category: IndicatorsGroups;
  indicator: IndicatorsGroups;
}

@Injectable({ providedIn: 'root' })
export class SideNavIndicatorsService {
  geoIndicatorsURL: string = 'assets/data/navigation/estructura_geoindicadores_v5.json';

  public indicatorsDictionary$: BehaviorSubject<DictionaryEntryInterface[]> = new BehaviorSubject<dictionaryIndicators>([]);

  public indicatorGroupSelected$: BehaviorSubject<IndicatorGroupSelected> = new BehaviorSubject<IndicatorGroupSelected>(
    undefined
  );

  public indicatorsGroups$: BehaviorSubject<IndicatorsGroups> = new BehaviorSubject<IndicatorsGroups>(
    undefined
  );

  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  // NEW PROCESS

  // NEST NEW CATEGORIES
  nestGeoIndicators(data) {
    return d3
      .nest()
      .key((d) => {
        return d['categoria_key'];
      })
      .sortKeys(d3.ascending)
      .key((d) => {
        return d['subcategoria_key'];
      })
      .sortKeys(d3.ascending)
      .entries(data);
  }

  mapGeoIndicadores(data) {
    return (
      data &&
      data.map((d) => {
        d['screen_name'] = d.values[0].values[0]['categoria'];
        d['values'] &&
        d['values'].map((v) => {
          v['screen_name'] = v.values[0]['subcategoria'];
          return v;
        });
        return d;
      })
    );
  }

  getGeoIndicatorsDictionaryApi() {
    return this.http
      .get(`${this.geoIndicatorsURL}`)
      .pipe(
        catchError(this.handleError),
        map((data: any) => {
          let filterIndicators = data.filter((d) => d.active === 'true');
          return filterIndicators;
        })
      )
      .subscribe((d) => {
        this.indicatorsDictionary$.next(d);
      });
  }

  setIndicatorsDictionary(dic) {
    this.indicatorsDictionary$.next(dic);
  }

  getGeoIndicatorsApi() {
    if (!this.indicatorsGroups$.value) {
      // important to keep alive indicators and analityc stages
      return this.http
        .get(`${this.geoIndicatorsURL}`)
        .pipe(
          map((data: any) => {
            return this.parseGeoindicators(data);
          }),
          catchError(this.handleError)
        )
        .subscribe((indicators: any) => {
          // this.indicatorsGroups$.next(indicators);
        });
    }
  }

  parseGeoindicators(data) {
    let filterIndicators = data.filter((d) => d.active === 'true' || d.active === true);
    let nested_data = this.nestGeoIndicators(filterIndicators);
    let mapped_data = this.mapGeoIndicadores(nested_data);
    this.indicatorsGroups$.next(mapped_data);
    this.indicatorsDictionary$.next(filterIndicators);
    this.indicatorGroupSelected$.next({
      category: mapped_data[0],
      indicator: mapped_data[0].values[0]
    });

    return mapped_data;
  }


  setindicatorsGroups(indicatorsGroups: IndicatorsGroups): void {
    this.indicatorsGroups$.next(indicatorsGroups);
  }

  setIndicatorGroupSelected(indicatorGroupSelected: IndicatorGroupSelected): void {
    this.indicatorGroupSelected$.next(indicatorGroupSelected);
  }

  getIndicatorGroupSelected() {
    return this.indicatorGroupSelected$.asObservable();
  }

}
