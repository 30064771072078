import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BestTimesListComponent } from './best-times-list/best-times-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatListModule, MatIconModule, FlexModule, FormsModule, MatProgressSpinnerModule, MatTooltipModule],
  declarations: [
    BestTimesListComponent
  ]
})
export class BestTimesModule {
}
