import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'compass-full-screen-video',
  templateUrl: './full-screen-video.component.html',
  styleUrls: ['./full-screen-video.component.scss']
})
export class FullScreenVideoComponent implements OnInit {
  @Input() setup: any;
  src: any;
  poster: string;
  constructor() { }

  ngOnInit(): void {
    this.src = {
      _mp4: this.setup.route + this.setup.id + '.mp4',
      _3gp: this.setup.route + this.setup.id + '.3gp',
      _ogv: this.setup.route + this.setup.id + '.ogg',
      _webm: this.setup.route + this.setup.id + '.webm'
    }
    this.poster = this.setup.route + this.setup.id + '_poster.jpg'
  }

}
