import { Component, OnInit } from '@angular/core';
import { IdealistaSidenavService } from './idealista-sidenav.service';
import { IdealistaFormOptionsService } from '../idealista-form/idealista-form-options.service';
import { WordingService } from '../../../../utils/dictionaries/src/lib/wording.service';

@Component({
  selector: 'compass-idealista-sidenav',
  templateUrl: './idealista-sidenav.component.html',
  styleUrls: ['./idealista-sidenav.component.scss']
})
export class IdealistaSidenavComponent implements OnInit {
  category_options = this.idealistaFormOptionsService.category_options;
  data: any;
  id: number;
  url: string;
  type: string;
  category: string;
  address: string;
  title: string;


  basic_resume: Array<string>;
  deposit: string;
  price_sqm: number;
  size: number;
  rooms: number;
  price: number;


  getType(type: string): string {
    if (type) {
      return type === 'Comprar' ? 'En venta' : 'En alquiler';
    }
    return '';
  }

  normalizeCategory(cat) {
    // pendiente de normalizar el campo categoría de la base de datos
    if (cat === 'Locales o naves') { return 'locales' };
    if (cat === 'Habitación') { return 'habitacion' };
    if (cat === 'Obra nueva') { return 'obranueva' };
    if (cat === 'Viviends') { return 'viviendas' };
    return cat.toLowerCase() ? cat.toLowerCase() : 'Inmueble';
  }
  getCategory(category: string) {
    if (category) {
      category = this.normalizeCategory(category);
      return this.category_options.filter(option => option.value === category)[0].name;
    }
    return '';
  }
  getBasicResume(resume: string): Array<string> {
    if (resume) {
      let temp = this.wordingService.replaceTabsAndWhites(resume);
      return temp.split('|');
    }
    return [''];
  }
  getString(str: string) {
    if (str) {
      return str;
    }
    return '';
  }
  getNumber(num: number) {
    if (num) {
      return num;
    }
    return 0;
  }
  getPriceSqm(price_sqm: number, size: number, price: number) {
    if (price_sqm && price_sqm !== 0) {
      return price_sqm;
    }
    if (size && size !== 0 && price && price !== 0) {
      return price / size;
    }
    return 0;
  }




  constructor(
    private idealistaSidenavService: IdealistaSidenavService,
    private idealistaFormOptionsService: IdealistaFormOptionsService,
    private wordingService: WordingService
  ) {
    this.idealistaSidenavService.getHousingInfo().subscribe((info) => {
      if (info) {
        this.data = info;
        this.type = this.getType(this.data.properties.type);
        this.category = this.getCategory(this.data.properties.category);
        this.id = this.data.properties.id;
        this.url = this.data.properties.image_url;
        this.address = this.data.properties.address;
        this.title = this.wordingService.formatTitleVal(this.data.properties.title);
        this.basic_resume = this.getBasicResume(this.data.properties.basic_resume);
        this.deposit = this.getString(this.data.properties.deposit);
        this.size = this.getNumber(this.data.properties.size);
        this.rooms = this.getNumber(this.data.properties.rooms);
        this.price = this.getNumber(this.data.properties.price);
        this.price_sqm = this.getPriceSqm(+this.data.properties.price_sqm, this.size, this.price);
      }
    });
  }

  ngOnInit(): void { }

}
