import {
  Component,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy, OnChanges, SimpleChanges
} from '@angular/core';
import { Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

export interface DisplayColumn {
  key: string;
  display: string;
  study?: boolean;
  role?: boolean;
  crud?: boolean;
}

@Component({
  selector: 'compass-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit, OnChanges {
  @Input() data: any;
  @Input() displayedColumns: DisplayColumn[];
  @Input() toErase: string;
  @Input() url: string;
  @Input() defaultSorting: string;
  @Output() dialogChange = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('usersFilter') usersFilter: ElementRef;

  // table vars
  dataSource: MatTableDataSource<any>;

  // MatPaginator Inputs
  pageSize: number = 25;
  pageSizeOptions: number[] = [10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  // reset button
  renderTable: boolean = false;
  stringColumns: string[];

  constructor(public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource(this.data);

  }


  ngOnInit() {
    this.stringColumns = this.displayedColumns.map((d) => d.key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data) {
      this.dataSource.data = this.data;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = String(event);
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFilters(): void {
    this.usersFilter.nativeElement.value = '';
    this.dataSource.filter = '';
  }


  // Delete dialog Modal window
  openDialog(action: string, user: any): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '42%',
      data: {
        dialogTitle: '¡Cuidado!',
        dialogIcon: 'warning',
        dialogMessage: `Esta acción no podrá deshacerse. ¿Está seguro que quiere eliminar ${this.toErase}?`,
        dialogBody: user,
      },
    });

    dialogRef.afterClosed().subscribe((option) => {
      if (option) {
        this.dialogChange.emit(user);
      }
    });
  }


}
