import { Component } from '@angular/core';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { IndicatorService } from '@compass/utils/navigation';
import { ChartDataInterface } from '../analytics-chart/analytics-chart.component';
import { PoiBasketService } from '../../../../shared/poi-basket/src/lib/poi-basket.service';
import { Subscription } from 'rxjs';
import { StudyService } from '@compass/studies/data-access-study';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'compass-analytics-form',
  templateUrl: './analytics-form.component.html',
  styleUrls: ['./analytics-form.component.scss']
})
export class AnalyticsFormComponent {
  indicators: any[] = [];
  chartData: ChartDataInterface[] = [];
  bricks: any[] = [];

  basketSubscription: Subscription = null;
  storeInStudyTimeout: any;
  storeStudySubscription: Subscription;
  firstDraw: boolean = true;

  constructor(
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private indicatorService: IndicatorService,
    private basketService: PoiBasketService,
    private studyService: StudyService,
    private snackBar: MatSnackBar
  ) {
    this.indicators = this.indicatorService.getIndicators();

    this.indicatorService.activeIndicator$.subscribe((activeIndicator: any) => {
      if (activeIndicator) {
        this.indicators = this.indicators.map((indicator) => {
          indicator.active = activeIndicator.key === indicator.key;
          return indicator;
        });
      }
    });


    this.basketSubscription = this.basketService.basket$.subscribe(() => {
      this.showAnalytics();
    });
  }

  ngOnDestroy() {
    this.basketSubscription.unsubscribe();
  }

  toggleIndicator(indicator) {
    this.indicatorService.activeIndicator$.next(indicator);
    this.drawOnMap();
  }

  drawOnMap() {
    this.compassMapIndicatorsService.filterGeojsonLayer(
      this.indicatorService.activeIndicator$.value,
      this.indicators,
      this.bricks.filter((brick) => brick.properties.excluded).map((brick) => brick.properties.id)
    );

    this.saveIndicatorsToStorage();
  }

  showAnalytics(indicator = null): void {
    // add exclude status to brick if not in range of indicator
    this.bricks = this.indicatorService.getBricks((indicator, indicatorBrickValue, brick) => {
      brick.properties.excluded = brick.properties.excluded || !(indicatorBrickValue >= indicator.minValue && indicatorBrickValue <= indicator.maxValue);
      brick.properties.indicators[indicator.key] = indicatorBrickValue;
      return brick;
    }, (bricks) => {
      return bricks.map((brick) => {
        // set default values for new properties
        brick.properties.excluded = false;
        brick.properties.indicators = brick.properties.indicators ?? {};
        return brick;
      });
    });

    this.drawOnMap();
    this.generateChartData();
  }

  /**
   * Store to localstorage studies data for PDF REPORT
   */
  saveIndicatorsToStorage() {
    const analyticsData = {
      activeIndicator: this.indicatorService.activeIndicator$.value,
      indicatorsList: this.indicators
    };

    // on first draw do nothing
    if (this.firstDraw) {
      this.firstDraw = false;
    } else {
      // clear previous storeTimeout and request
      clearTimeout(this.storeInStudyTimeout);
      if(this.storeStudySubscription) {
        this.storeStudySubscription.unsubscribe();
      }

      // store study in some seconds
      this.storeInStudyTimeout = setTimeout(() => {
        this.storeStudySubscription = this.studyService.updateStudy(this.studyService.study$.value.properties.id, 'properties.analytics', analyticsData)
          .subscribe((study) => {
            this.snackBar.dismiss();
            this.snackBar.open('Analítica guardada correctamente', undefined, {
              duration: 1600
            });
          });
      }, 1600);
    }
  }

  generateChartData() {
    this.indicators = this.indicators.map((indicator) => {
      // the bricks with value
      indicator.chartData = this.bricks.filter((brick) => brick.properties.indicators?.[indicator.key])
        .map((brick) => {
          let color = null;
          const indicatorValue = brick.properties.indicators[indicator.key];

          if (!brick.properties.excluded) {
            // check recieved colors or indicartors color
            if (indicatorValue >= indicator.minValue && indicatorValue <= indicator.maxValue) {
              color = this.compassMapIndicatorsService.getColor(indicatorValue, indicator);
            }
          }

          return {
            value: indicatorValue,
            color: color
          };
        });

      // order chartData by value
      indicator.chartData = indicator.chartData.sort((a, b) => {
        return (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0);
      });

      return indicator;
    });

    this.indicatorService.indicatorsList$.next(this.indicators);
  }

  getChartData(indicator: any, excluded: boolean = false) {
    let response = [];

    if (indicator?.series) {
      response = indicator.series.map((number: any) => {
        let color = null;

        if (!excluded) {
          // check recieved colors or indicartors color
          if (number >= indicator.minValue && number <= indicator.maxValue) {
            color = this.compassMapIndicatorsService.getColor(number, indicator);
          }
        }

        return {
          value: number,
          color: color
        };
      });
    }

    return response;
  }


}
