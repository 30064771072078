import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {bbox, centroid, distance} from '@turf/turf';
import {scale} from 'chroma-js';
import {LayerGroup} from "leaflet";

@Injectable({providedIn: 'root'})
export class LocalKnowledgeApiService {
  _accesToken = '35g3xV9tWwr2QNXPw2BuOi3dIYrZ8SgJK58hSzb0';
  //private url = 'assets/data/local-knowledge/local-knowledge-demo.json';
  private url = `/master/v2/core/data_hollidays/?query={}&limit=1000&sort=&skip=0`;
  public localKnowledge$: BehaviorSubject<any> = new BehaviorSubject([]);
  predictHqCategories = [
    'academic',
    'sports',
    'conferences',
    'expos',
    'concerts',
    'festivals',
    'performing-arts',
    'community'
  ];

  categories = [
    {
      title: 'Autonómico',
      key: 'Autonomico',
      color: '#ff0000'
    },
    {
      title: 'Nacional',
      key: 'Nacional',
      color: '#1939ff'
    },
    {
      title: 'Local',
      key: 'Local',
      color: '#10ff00'
    },
    {
      title: 'Academico',
      key: 'academic',
      color: '#ff9797'
    },
    {
      title: 'Deportes',
      key: 'sports',
      color: '#97ffef'
    },
    {
      title: 'Conferencias',
      key: 'conferences',
      color: '#a300ff'
    },
    {
      title: 'Expos',
      key: 'expos',
      color: '#ff97d5'
    },
    {
      title: 'Conciertos',
      key: 'concerts',
      color: '#81ff93'
    },
    {
      title: 'Festivales',
      key: 'festivals',
      color: '#665bff'
    },
    {
      title: 'Artes Escénicas',
      key: 'performing-arts',
      color: '#6b6b68'
    },
    {
      title: 'Comunidad',
      key: 'community',
      color: '#ff9400'
    }
  ];

  private scale: any;
  private maxScaleDomain = 100;

  public activeDate$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public eventsLayer$: BehaviorSubject<any> = new BehaviorSubject<LayerGroup>(new LayerGroup<any>());

  public study: any;
  private municipalityId: any;
  public selectedCategories$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(public http: HttpClient) {

  }


  async getLocalKnowledgeData(municipalityID?: any, categories?: any, study?: any) {
    this.municipalityId = municipalityID ?? this.municipalityId;
    this.study = study ?? this.study;
    this.selectedCategories$.next(categories ?? this.selectedCategories$.value);

    this.getHolidays(municipalityID, categories)
      .subscribe((data: any[]) => {

          this.getMunicipallities(municipalityID)
            .subscribe(async (munic: any) => {
            if (categories.length) {
              // extract center and radius from municipality or study
              const center = centroid(munic?.geometry ?? study?.geometry);
              const studyAreas: any = Object.values(study?.properties?.areas);
              const square = bbox(munic?.geometry ?? studyAreas?.[0]?.geometry);
              const radius = distance([square[0], square[1]], [square[2], square[3]]);

              const events = await this.events(null, categories, center, radius);

              this.localKnowledge$.next([...data, ...events]);
            } else {
              this.localKnowledge$.next(data);
            }

          },error => {console.error('No se cargaton los ')});

      }, (error) => {
        console.log(error);
      });
  }

  public getHolidays(municipalityID:any, categories: any[]){
    this.selectedCategories$.next(categories);
    if(categories?.length) {

      const url = `/core/glocally/collection/data_hollidays/?query={"$and":[{"geo_level_id": "${municipalityID}"},{"tipo":{"$in":${JSON.stringify(this.selectedCategories$.value)}} }]}&limit=1000&sort=&skip=0&fields=categoria,fecha,descripción,tipo`;
      return this.http
        .get(url ?? this.url)
        .pipe(
          map((data: any[]) => {
            // filter the date or map data here if needed
            data = data.map((item) => {
              item.start = new Date(item.fecha)
              item.descripcion = item['descripción'] ?? item.descripcion;
              item.title = item['descripción'] ?? item.descripcion;

              return item;
            });

            return data.sort((a, b) => {
              var dateA = new Date(a.date).getTime();
              var dateB = new Date(b.date).getTime();
              return dateA > dateB ? 1 : -1;
            });
          })
        )
    }
  }

  getMunicipallities(municipalityID: any) {
    this.municipalityId = municipalityID ?? this.municipalityId;
    const municipalityUrl = `/core/glocally/collection/area_municipality/?query={"properties.CUMUN": "${municipalityID}"}`;

    return this.http.get(municipalityUrl).pipe(
      map((data: any) => {
        return data?.[0];
      })
    )

  }


  async events(placeId: any, categories: any, centerFeature?: any, radiusKm?: any, nextPageUrl?: string) {
    let url = `https://api.predicthq.com/v1/events`;
    const params: any = {
      limit: 200,
      sort: 'rank,start'
    };

    if (placeId) {
      params.place = {scope: placeId};
    }

    if (centerFeature && radiusKm) {
      params.within = `${radiusKm}km@${centerFeature.geometry.coordinates[1]},${centerFeature.geometry.coordinates[0]}`;
    }

    if (categories) {
      params.category = categories.filter(cat => this.predictHqCategories.includes(cat));
    }

    if (params.category?.length) {
      params.category = params.category.toString();
      params['start.gte'] = moment(this.activeDate$.value).startOf('month').format('YYYY-MM-DD');
      params['end.lte'] = moment(this.activeDate$.value).endOf('month').format('YYYY-MM-DD');

      const headers = new HttpHeaders({
        'Authorization': `Bearer ${this._accesToken}`
      });

      return this.http.get(nextPageUrl ?? url, {headers, params})
        .pipe(map((d: any) => {
          const mapped = this.mapEvents(d.results);

          // if was next page call, add to
          if (nextPageUrl) {
            this.localKnowledge$.next(this.localKnowledge$.value.concat(mapped));
          }

          // if response has next page, load it
          if (d.next) {
            this.events(null, null, null, null, d.next);
          }

          return mapped;
        })).toPromise();
    } else {
      return [];
    }
  }

  places(place: any, mapCallback?: any) {
    const url = `https://api.predicthq.com/v1/places?q=${place}, Spain`;
    let headers = new HttpHeaders().set('Authorization', `Bearer ${this._accesToken}`);
    return this.http.get(url, {headers}).pipe(map((d: any) => {
      return typeof mapCallback === 'function' ? mapCallback(d) : d?.results;
    }));
  }

  private recalculateColorRange(events?: any) {
    this.maxScaleDomain = 0;
    this.localKnowledge$.value.concat(events ?? []).forEach(d => {
      if (d.rank > this.maxScaleDomain) {
        this.maxScaleDomain = d.rank;
      }
    });

    this.scale = scale(['#5d5d5d', '#ff8a19', '#ff2364']).domain([0, this.maxScaleDomain]);
  }


  private mapEvents(events: any) {
    this.recalculateColorRange(events);

    return events.map((item) => {
      const title = item['descripción'] ?? item.title;
      const categoryColor = {};
      this.categories.forEach((cat: any) => {
        categoryColor[cat.key] = cat.color;
      });

      return {
        ...{
          tipo: item.category,
          descripcion: title,
          fecha: item.start,
          categoria: 'predicthq',
          geo: item.geo,
          scope: item.scope,
          attendance: item.phq_attendance,
          rank: item.rank,
          colorRange: this.scale(item.rank).hex()
        }, ...item, ...{
          // angular-calendar required data
          start: new Date(item.start),
          end: new Date(item.end),
          title: title,
          color: {
            primary: categoryColor[item.category] ?? 'grey'
          }
        }
      };
    });
  }

}

