import { Component } from '@angular/core';
import { LineTransitService } from '@compass/line-transit/data-access';
import { BricksApiService } from '@compass/brick-api';

@Component({
  selector: 'glo-stage-transit',
  templateUrl: './stage-transit.component.html',
  styleUrls: ['./stage-transit.component.css'],
})
export class StageTransitComponent {
  transitLines$ = this.lineTransitService.transitLines$;
  constructor(
    private bricksApiService: BricksApiService,
    private lineTransitService: LineTransitService
  ) {
    this.lineTransitService.getAreaTransit(
      this.bricksApiService.fullStages$.value
    );
  }
}
