<mat-sidenav-container autosize class="estudio-sidenav right-sidenav-container z__index-top">
  <mat-sidenav #endRightSidenav
               mode="over"
               position="end"
               fixedInViewport="true"
               [opened]="endRightSidenavOn"
               style="width: 24%;">
    <div class="sidenav-title">
      <div class="content" [innerHTML]="this.sidenavTitle"></div>
      <compass-end-right-button-close></compass-end-right-button-close>
    </div>
    <div class="sidebar" style="overflow: hidden;">
      <div class="sidebar--inner">
        <div class="sidebar--direction">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content></mat-sidenav-content>
</mat-sidenav-container>

<div class="overlay-right-label">
  <div class="overlay fade-in" (click)="closeSideNav()"></div>
</div>
