import { Component, OnDestroy, OnInit } from '@angular/core';
import { IndicatorService } from '@compass/utils/navigation';
import { BricksApiService } from '@compass/brick-api';
import { CompassMapIndicatorsService } from '@compass/utils/leaflet';
import { PoiBasketService } from '../../../../shared/poi-basket/src/lib/poi-basket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'compass-analytics-index',
  templateUrl: './analytics-index.component.html',
  styleUrls: ['./analytics-index.component.scss']
})
export class AnalyticsIndexComponent implements OnDestroy {
  indicators: any[] = [];
  globalIndex: number = 0;
  bricks: any[] = [];
  removeExcludedBricks: boolean = false;
  totalPercent: number = 100;

  basketSubscription: Subscription = null;

  constructor(
    private indicatorService: IndicatorService,
    private bricksApiService: BricksApiService,
    private compassMapIndicatorsService: CompassMapIndicatorsService,
    private basketService: PoiBasketService
  ) {
    this.indicators = this.indicatorService.getIndicators((indicator, allIndicators) => {
      // calculate the weight (% of weight) for each indicator if not setted
      indicator.weight = indicator.weight ?? 100 / allIndicators.length;
      indicator.influence = indicator.influence ?? 0;

      return indicator;
    });

    this.calculateBricksIndex();

    this.basketSubscription = this.basketService.basket$.subscribe(() => {
      this.calculateBricksIndex();
    });
  }

  ngOnDestroy() {
    this.basketSubscription.unsubscribe();
  }

  sliderLabel(value: number) {
    return `${value}%`;
  }

  /**
   * Calculates for each indicators score and total score for brick.
   */
  calculateBricksIndex() {
    setTimeout(() => {
      this.totalPercent = 0;

      // add each indicators scores
      this.bricks = this.bricksApiService.bricks$.value.map(brick => {
        let brickScore = 0;
        brick.properties.indicators = brick.properties.indicators || {};

        this.indicators.forEach((indicator) => {
          const indicatorBrickValue = brick.properties['data_portals2019']?.[indicator.grupo]?.[indicator.key];

          // if theres a value, the brick is correct
          if (indicatorBrickValue) {
            // get the sum of values in range
            const indicatorSum = indicator.series.reduce((sum, actual) => {
              // check if this value in range to sum
              return (indicator.minValue >= actual && indicator.maxValue <= actual) ? sum + actual : sum;
            }, 0);

            // if this brick is in range
            if (indicatorBrickValue >= indicator.minValue && indicatorBrickValue <= indicator.maxValue) {
              const maxValue = indicator.maxValue;
              let indicatorScore = (indicatorBrickValue * 100 / maxValue);

              if (indicator.influence) {
                indicatorScore = indicator.influence - (indicatorBrickValue * 100 / maxValue);
              }

              // set the indicator value in brick
              brick.properties.indicators[indicator.key] = {
                total: indicatorSum,
                maxValue: maxValue,
                score: indicatorScore
              };

              brickScore += (indicatorScore * indicator.weight / 100);
            }
          }
        });

        brick.properties.score = brickScore;
        brick.properties.active = brickScore >= this.globalIndex; // && brickScore <= 100;
        return brick;
      }).filter(brick => {
        // remove excluded bricks and without score
        return brick.properties.score && !brick.properties.excluded;
      });

      // calculate actual total perecent
      this.totalPercent = this.indicators.reduce((total, prev) => {
        return total + (prev.weight ?? 0);
      }, 0);

      this.compassMapIndicatorsService.drawIndexLayer(this.bricks);
    }, 200);
  }

  addBricksToBasket() {
    this.bricks.forEach((brick) => {
      if (brick.properties.active) {
        this.basketService.add(brick);
      } else {
        if (this.removeExcludedBricks) {
          this.basketService.remove(brick);
        }
      }
    });
  }

}
