import { Component, OnInit } from '@angular/core';
import { BottomContainerService } from './bottom-container.service';
import { PoisComparatorService } from '@compass/pois/comparator';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'glo-bottom-container',
  templateUrl: './bottom-container.component.html',
  styleUrls: ['./bottom-container.component.scss'],
  animations: [
    trigger('bottomContainerWraper', [
      state('visible', style({
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        'max-height': '100%'
      })),
      state('relative', style({
        position: 'relative',
        bottom: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        'max-height': 'unset'
      })),
      transition('* => *', [
        animate('1s')
      ])
    ])
  ]

})
export class BottomContainerComponent implements OnInit {

  constructor(
    public bottomContainerService: BottomContainerService,
    public poisComparatorService: PoisComparatorService
  ) {
  }

  ngOnInit(): void {

  }

}
