import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopularTimesFormComponent} from './popular-times-form/popular-times-form.component';


import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@compass/material';
import {PopularTimesTimelineComponent} from './popular-times-timeline/popular-times-timeline.component';
import {PopularTimeWeekMatrixComponent} from './popular-time-week-matrix/popular-time-week-matrix.component';
import {PopularTimesComponent} from './popular-times/popular-times.component';

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    ReactiveFormsModule,
    MaterialModule],
  declarations: [
    PopularTimesFormComponent,
    PopularTimesTimelineComponent,
    PopularTimeWeekMatrixComponent,
    PopularTimesComponent],
  exports: [
    PopularTimesFormComponent,
    PopularTimesTimelineComponent,
    PopularTimeWeekMatrixComponent,
    PopularTimesComponent],
})
export class PopularTimesModule {
}
