import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalMediaReshapeService {

    public mediaData$: BehaviorSubject<any> = new BehaviorSubject(undefined);

    constructor() { }

    // data
    public reshape(setup, target) {
        let result = setup.filter((d) => {
            let temp = d.medio.toLowerCase().replace(/[òó]/g, "o").replace(/[^a-z0-9]/gi, '');
            return temp === target;
        });
        this.setMediaData(result);
    };

    public getMediaData() {
        return this.mediaData$.asObservable();
    }
    public setMediaData(data) {
        this.mediaData$.next(data);
    }
}
