import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SideNavStatusService, SideNavStagesService } from '@compass/utils/navigation';
import { BricksApiService } from '@compass/brick-api';
import { CompassMapMarkerService } from '@compass/utils/leaflet';
import { CompassMapWrapperService } from '@compass/utils/leaflet';
import { MatDialogDeleteComponent } from '@compass/shared/mat-dialog-delete';
import { CreateStudyService } from '@compass/feature-create-study';
import { EgmAimcStatusService } from '@compass/egm-aimc/data-access';
import { WeatherStatusService } from '@compass/weather';
import { StudyService } from '@compass/studies/data-access-study';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { PatrimonioService } from '@compass/patrimonio/data-access';
import { LogoutAppService } from '@compass/utils/navigation';

@Component({
  selector: 'compass-studies-crud-menu',
  templateUrl: './studies-crud-menu.component.html',
  styleUrls: ['./studies-crud-menu.component.scss']
})
export class StudiesCrudMenuComponent implements OnInit {
  study_name: string = 'Leroy Merlin Badalona';
  poisToSaveInDDBB: Array<any> = [];
  heritageToSaveInDDBB: Array<any> = [];
  getAreaName = this.bricksApiService.getAreaName;
  areasName: string;
  bricksAreasName: string;
  studyId: number;
  map = this.compassMapWrapperService.map$.value;
  localCategoriesList$ = this.sidenavPoisLevelsService.localCategoriesList$;
  categoriesList$ = this.sidenavPoisLevelsService.categoriesList$;
  heritagePois$ = this.patrimonioService.heritagePois$;
  transportList$ = this.sidenavPoisLevelsService.transportList$;

  constructor(
    private sideNavStatusService: SideNavStatusService,
    private sideNavStagesService: SideNavStagesService,
    private bricksApiService: BricksApiService,
    private compassMapMarkerService: CompassMapMarkerService,
    private compassMapWrapperService: CompassMapWrapperService,
    private matDialog: MatDialog,
    private createStudyService: CreateStudyService,
    private studyService: StudyService,
    private egmAimcStatusService: EgmAimcStatusService,
    private weatherStatusService: WeatherStatusService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private patrimonioService: PatrimonioService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private logoutAppService: LogoutAppService
  ) {
    this.study_name = this.createStudyService.getStudyName();
  }

  editStudy = () => {
    this.sideNavStatusService.setStudyFormStatus(true);
    this.sideNavStatusService.setStagesNavStatus(false);
    this.sideNavStatusService.setStagesNavOnLoading(false);
    //this.compassMapMarkerService.drawMarker();
  };

  deleteStudy = () => {
    const dialogConfig = new MatDialogConfig();
    let dialogRef = this.matDialog.open(MatDialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmDeleted) => {
      if (confirmDeleted) {
        this.studyService
          .deleteStudyBy(this.createStudyService.featureStudy.properties.id)
          .subscribe((response) => {
              this.studyService.getListStudies().subscribe((result_studies) => {
                this.compassMapMarkerService.drawMarkerList(result_studies);
              });
              this.createStudyService.setFeatureStudy(null);
              this.createStudyService.setIdStudySelected(null);
              this.createStudyService.setFeatureStudyInitCps(null);
              this.compassMapMarkerService.removeLayerStudy();

              this.router.navigateByUrl('/estudio');

              this._snackBar.open('Se ha eliminado el estudio correctamente', undefined, {
                duration: 2600,
                panelClass: 'success'
              });
            }, (error) => {
              console.error('delete study, an error occurred, ', error);
              this._snackBar.open('Ha ocurrido un error al eliminar el estudio', undefined, {
                duration: 6400,
                panelClass: 'danger'
              });
            }
          );
      }
    });
  };

  updatePois(studyId: number, propertyName: string, pois: any, poisType: string) {
    if (pois) {
      this.sideNavStatusService.setStagesNavOnLoading(true);
      this.studyService.updateStudy(studyId, propertyName, pois)
        .subscribe((response) => {
            this.sideNavStatusService.setStagesNavOnLoading(false);
            this._snackBar.open(`${poisType} guardados`, '', {
              panelClass: 'success',
              duration: 3000
            });
          }, (error) => {
            this.sideNavStatusService.setStagesNavOnLoading(false);
            this._snackBar.open(`error al guardar ${poisType}`, '', {
              panelClass: 'danger',
              duration: 5000
            });
          }
        );
    }
  }

  savePois() {
    const studyId = this.createStudyService.featureStudy.properties.id;
    const activeGlobalPois = this.sidenavPoisLevelsService.getSelectedPlainNodes()
      .map(node => node.key);

    this.updatePois(studyId, 'properties.saved_global_pois', activeGlobalPois, 'Global Pois');
    this.updatePois(studyId, 'properties.saved_local_pois', this.localCategoriesList$.value, 'Local Pois');
    this.updatePois(studyId, 'properties.saved_patrimonio', this.heritagePois$.value, 'Patrimonios');
    this.updatePois(studyId, 'properties.saved_transport', this.transportList$.value, 'Rutas/Transporte');
  }

  closeStudy = () => {
    this.logoutAppService.closeNavigation();
    this.compassMapMarkerService.removeMarker();
    this.compassMapMarkerService.removeLayerStudy();
    this.createStudyService.cancelStudy(true);
    this.createStudyService.drawShape(false);
    this.createStudyService.setDrawShape(false);
    this.createStudyService.setStudyName('');
    this.createStudyService.setFeatureStudy(null);
    this.createStudyService.setIdStudySelected(null);
    this.createStudyService.setAreaPainted(null);
    this.createStudyService.setModeStudy('foot');
    this.createStudyService.setDistanceStudy('00_05');
    this.createStudyService.drawShapeCps(false);
    this.createStudyService.setFeatureStudyInitCps(null);
    this.createStudyService.setCPSelected(null);
    this.router.navigate(['/estudio']);
    return false;
  };

  printStudy() {
    const id: number = this.createStudyService.getIdStudySelected();
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree(['/pdf_report/' + id])
    );
    window.open(url, '_blank');
    // this.router.navigate([stage.routerLink]);
  }

  studyCrudAction(e, action: string): boolean {
    if (action === 'edit') {
      this.editStudy();
      return false;
    }

    if (action === 'print') {
      this.printStudy();
      return false;
    }

    if (action === 'delete') {
      this.deleteStudy();
      return false;
    }

    if (action === 'save') {
      // this.saveStudy();
      this.savePois();
      return false;
    }

    if (action === 'close') {
      this.closeStudy();
      return false;
    }
  }

  ngOnInit(): void {
  }
}
