import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InmueblesComponent } from './inmuebles/inmuebles.component';
import { IdealistaModule } from '@compass/idealista';
import { MaterialModule } from '@compass/material';
import { InmueblesService } from '@compass/inmuebles/data-access';

@NgModule({
  imports: [CommonModule, IdealistaModule, MaterialModule],
  declarations: [InmueblesComponent],
  exports: [InmueblesComponent],
  providers: [InmueblesService],
})
export class InmueblesFeatureDrawerModule {}
