import { Component, Input } from '@angular/core';

@Component({
  selector: 'management-title',
  templateUrl: './management-title.component.html',
  styleUrls: ['./management-title.component.scss'],
})
export class ManagementTitleComponent {
  @Input() icon_class: string;
  @Input() title: string;
  @Input() layout_direction: string;
  constructor() {}
}
