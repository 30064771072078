import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { pairwise, debounceTime, startWith } from 'rxjs/operators';
import { LocalKnowledgeOptionsService } from './local-knowledge-options.service';
import { LocalKnowledgeReshapeService } from '../local-knowledge-reshape.service';

@Component({
  selector: 'compass-local-knowledge-form',
  templateUrl: './local-knowledge-form.component.html',
  styleUrls: ['./local-knowledge-form.component.scss']
})
export class LocalKnowledgeFormComponent implements OnInit {
  @Input() setup: Array<any>;
  form: FormGroup;
  options: Array<any>;
  children: Array<any>;

  constructor(
    private localKnowledgeReshapeService: LocalKnowledgeReshapeService,
    private localKnowledgeOptionsService: LocalKnowledgeOptionsService
  ) { }

  onSubmit() {
    return false;
  }

  initForm() {
    this.form = new FormGroup({
      category: new FormControl(this.options.filter(o => o.key === 'Todos')[0]),
      subcategory: new FormControl(this.children.filter(o => o.key === 'Todos')[0])


    });

    this.form.get('category').valueChanges.subscribe((value) => {
      this.children = value.values;
      this.form.controls['subcategory'].setValue(this.children[0]);
    });

    // rxjs/operators
    this.form.valueChanges
      .pipe(startWith(this.form.value), debounceTime(500), pairwise())
      .subscribe(([old, value]) => {
        this.localKnowledgeReshapeService.reshape(this.setup, value);
        return false;
      });
  }


  initServices() {
    return false;
  }

  initOptions() {
    this.options = this.localKnowledgeOptionsService.getOptions(this.setup);
    this.children = this.options.filter(o => o.key === 'Todos')[0]['values'];
  }

  ngOnInit(): void {
    this.initServices();
    this.initOptions();
    this.initForm();
    this.localKnowledgeReshapeService.reshape(this.setup, this.form.value);
  }


}
