import { Component } from '@angular/core';
import { OnInit, OnChanges, SimpleChange, Input } from '@angular/core';
import { SideNavStagesService } from '@compass/utils/navigation';

@Component({
  selector: 'glo-stages-sidenav',
  templateUrl: './stages-sidenav.component.html',
  styleUrls: ['./stages-sidenav.component.css'],
})
export class StagesSidenavComponent implements OnInit, OnChanges {
  stagesList$ = this.sideNavStagesService.stagesList$;

  constructor(private sideNavStagesService: SideNavStagesService) {
    if (!this.sideNavStagesService.stagesList$.value) {
      this.sideNavStagesService.getStagesApi();
    }
  }
  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let changeObj: string[] = Object.keys(changes);
  }
}
