import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Influencer, InfluencersApiService } from './influencers-loader/influencers-api.service';

@Injectable({ providedIn: 'root' })
export class InfluencersReshapeService {

    public influencersData$: BehaviorSubject<Influencer[]> = new BehaviorSubject(undefined);

    constructor() { }

    // data
    public reshape(setup: Influencer[], target: string) {
        let result: Influencer[] = setup.filter((influencer: Influencer) => {
            return influencer.key === target;
        });
        this.set(result);
    };

    public get() {
        return this.influencersData$.asObservable();
    }
    public set(influencers: Influencer[]) {
        this.influencersData$.next(influencers);
    }
}
