import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserNameComponent } from './current-user-name/current-user-name.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CurrentUserNameComponent],
  exports: [CurrentUserNameComponent],
})
export class SharedCurrentUserNameModule { }
