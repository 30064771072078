import { Injectable } from "@angular/core";


export interface Options {
    value: string | number,
    name: string,
    children?: Array<Options>,
    color?: string
}

@Injectable({ providedIn: "root" })
export class LocalMediaFormOptionsService {



    /// google 10c colors
    media_options: Array<Options> = [
        { value: 'internet', name: 'Internet', color: '#3366cc' },
        { value: 'prensa', name: 'Prensa', color: '#dc3912' },
        { value: 'radio', name: 'Radio', color: '#ff9900' },
        { value: 'television', name: 'Televisión', color: '#dd4477' },
        { value: 'revistas', name: 'Revistas', color: '#109618' }
    ];


    public get(options) {
        return this[options];
    }
}