import { Component } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import { CategoryService } from '@compass/categories/data-access-category';
import {
  PoiService,
  SidenavPoisLevelsService
} from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-stage-local-pois',
  templateUrl: './stage-local-pois.component.html',
  styleUrls: ['./stage-local-pois.component.scss'],
})
export class StageLocalPoisComponent {
  accordionSetup = {
    title: 'Local Pois',
    opened: true,
    closed: false,
    expanded: true,
  };
  localCategoriesList$ = this.sidenavPoisLevelsService.localCategoriesList$;
  categories$ = this.categoryService.categories$;

  constructor(
    private bricksApiService: BricksApiService,
    private poiService: PoiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private categoryService: CategoryService
  ) {
    this.categoryService.getAll().subscribe();
    this.poiService.getAreaLocal(this.bricksApiService.fullStages$.value);
  }
}
