import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glo-stage-housing',
  templateUrl: './stage-housing.component.html',
  styleUrls: ['./stage-housing.component.scss']
})
export class StageHousingComponent implements OnInit {
  accordionHousing: {};

  constructor() { }

  ngOnInit(): void {

    this.accordionHousing = {
      title: "Mercado inmobiliario",
      opened: true,
      closed: false,
      expanded: true,
    };
  }
}
