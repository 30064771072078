import { Component, OnInit } from '@angular/core';
import { StudyService } from '@compass/studies/data-access-study';
import { CompassMapMarkerService } from '../../../../../utils/leaflet/src/lib/compass-map-marker.service';

@Component({
  selector: 'compass-studies-toggle',
  templateUrl: './studies-toggle.component.html',
  styleUrls: ['./studies-toggle.component.scss']
})
export class StudiesToggleComponent implements OnInit {
  public checkboxText: string = 'Ocultar estudios';
  isChecked = false;

  constructor(
    private compassMapMarkerService: CompassMapMarkerService,
    private studyService: StudyService
  ) {
    this.isChecked = JSON.parse(localStorage.getItem('studiesToggle'));
  }

  ngOnInit(): void {
    this.onChangeState(this.isChecked);
  }

  onClickCheckbox(event: Event) {
    event.stopPropagation();
  }

  onChangeCheckbox(event: Event, state: boolean) {
    if (event) {
      if (state) {
        this.checkboxText = 'Ocultar estudios';
        // show studies inside map
        this.compassMapMarkerService.removeMarkerList();
      } else {
        if (!state) {
          this.checkboxText = 'Ver estudios';
          // remove studies from map
          this.studyService.getListStudies().subscribe((result_studies) => {
            this.compassMapMarkerService.drawMarkerList(result_studies);
          });
        }
      }

      localStorage.setItem('studiesToggle', JSON.stringify(this.isChecked));
    }
  }

  onChangeState(state: boolean) {
    if (state) {
      //this.checkboxText = 'Ocultar estudios';
      // show studies inside map
      this.compassMapMarkerService.removeMarkerList();
    } else {
      if (!state) {
        //this.checkboxText = 'Ver estudios';
        // remove studies from map
        this.studyService.getListStudies().subscribe((result_studies) => {
          this.compassMapMarkerService.drawMarkerList(result_studies);
        });
      }
    }

    localStorage.setItem('studiesToggle', JSON.stringify(this.isChecked));
  }
}
