<div class="bar-chart-wrapper {{ this.barChartId }}">
  <div class="title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayoutAlign=" center">
      <p class="title mb-0" *ngIf="title?.length">{{ title }}</p>
      <div class="filter" *ngIf="this.min || this.max">
        de {{ this.min }}{{ this.valueSuffix }} a {{ this.max }}{{ this.valueSuffix }}
      </div>
    </div>
    <mat-icon
      class="clear-filter"
      title="Limpiar filtro"
      (click)="clearFilter()"
      *ngIf="min || max"
    >backspace
    </mat-icon>
  </div>
  <div [id]="this.barChartId" class="bar-chart-component"></div>
</div>
