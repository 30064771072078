import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { SideNavStatusService } from '@compass/utils/navigation';
import { CompassMapMarkerService } from '@compass/utils/leaflet'
import { StudyService } from '@compass/studies/data-access-study';


@Component({
  selector: 'compass-mat-dialog-delete',
  templateUrl: './mat-dialog-delete.component.html',
  styleUrls: ['./mat-dialog-delete.component.scss']
})
export class MatDialogDeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MatDialogDeleteComponent>,
    private sideNavStatusService: SideNavStatusService,
    private compassMapMarkerService: CompassMapMarkerService,
  ) { }

  close(e) {
    this.dialogRef.close(false);
  }
  delete(e) {
    this.sideNavStatusService.resetAllSidenavs();
    //this.compassMapMarkerService.removeMarker();
    this.compassMapMarkerService.removeMarkerList();
    this.dialogRef.close(true);


  }

  ngOnInit(): void {
  }

}
