import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatDialogNotFoundComponent } from '@compass/shared/mat-dialog-not-found';
import { pairwise, debounceTime, startWith, pluck } from 'rxjs/operators';
import { CompassMapHeritageService } from '../../../../../utils/leaflet/src/lib/compass-map-heritage.service';
import { CompassMapWrapperService } from '../../../../../utils/leaflet/src/lib/compass-map-wrapper.service';
import { HeritageFormOptionsService } from './heritage-form-options.service';
import { HeritageFormReshapeService } from './heritage-form-reshape.service';
import { PoiBasketService } from '../../../../../shared/poi-basket/src/lib/poi-basket.service';
import { get } from 'lodash';

@Component({
  selector: 'compass-heritage-form',
  templateUrl: './heritage-form.component.html',
  styleUrls: ['./heritage-form.component.scss']
})
export class HeritageFormComponent implements OnInit {
  @Input() heritagePois: any[];
  filteredPoisList: any[];
  heritageExists: boolean = false;
  checkboxText: string = 'Ocultar patrimonio';

  // map and layers
  map: any;
  box: any;
  // form
  heritageForm: FormGroup;
  // options
  options: any;

  // services
  setControlValue: (f: FormGroup, c: string, val) => void;
  reshape: (f: any[], c: any[]) => any[];
  setResult: any;
  indexOfProperty: any;
  removeHeritageLayer: (layer: string) => void;
  buildIconMarkers: (pois: any) => void;
  drawPois: (pois: any, layer: string, type: string) => void;
  addMarkersToMap: any;
  popUpText: any;
  fitBoundsCategoryGroup: any;
  clickMarker: (any) => boolean;
  mouseoverMarker: (any) => boolean;
  mouseoutMarker: (any) => boolean;

  constructor(
    private heritageFormOptionsService: HeritageFormOptionsService,
    private heritageFormReshapeService: HeritageFormReshapeService,
    public compassMapHeritageService: CompassMapHeritageService,
    private compassMapWrapperService: CompassMapWrapperService,
    public dialog: MatDialog,
    private poiBasketService: PoiBasketService // shit required for use of compassMapHeritageServcice.buildIconMarkers
  ) {
    // when basket flag to update is fired, redraw markers
    this.poiBasketService.basketUpdated$.subscribe(() => {
      this.removeHeritageLayer('heritageLayer');
      this.drawPois(this.filteredPoisList, 'heritageLayer', 'featureGroup');
    });
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    let dialogRef = this.dialog.open(MatDialogNotFoundComponent, dialogConfig);
  };


  reshapeTableList(formValues, tableList) {
    this.filteredPoisList = this.reshape(formValues, tableList);
    if (this.filteredPoisList.length <= 0) {
      this.openDialog();
    }
  }

  onClickMatOption(formControl, matOption) {
    if (matOption.value === 'Todos') {
      this.toggleAllSelection(formControl, matOption);
    } else {
      this.toggleSingleSelection(formControl, matOption);
    }
  }

  toggleAllSelection(formControl, matOption) {

    if (matOption.selected) {
      this.heritageForm.controls[formControl]
        .patchValue([...this.options[formControl]]);
    } else {
      this.heritageForm.controls[formControl].patchValue([]);
    }

  }

  toggleSingleSelection(formControl, matOption) {
    let formControlValue = this.heritageForm.get(formControl).value;
    let optionsValue = this.options[formControl];
    let selection = [];

    if ((formControlValue.length) === (optionsValue.length)) {
      selection = optionsValue;
    } else if ((formControlValue.length) === (optionsValue.length - 1)) {
      if (matOption.selected) {
        selection = optionsValue;
      } else {
        selection = formControlValue.filter(f => f !== 'Todos');
      }
    } else {
      selection = formControlValue.filter(f => f !== 'Todos');
    }
    this.heritageForm.controls[formControl]
      .patchValue([...selection]);
  }


  initServices() {
    this.heritageExists = true;
    this.map = this.compassMapWrapperService.map$.value;
    this.box = this.map.box;
    this.setControlValue = this.heritageFormOptionsService.setControlValue;
    this.reshape = this.heritageFormReshapeService.reshape;
    this.setResult = this.heritageFormReshapeService.setResult;
    this.indexOfProperty = this.heritageFormReshapeService.indexOfProperty;
    this.removeHeritageLayer = this.compassMapHeritageService.removeHeritageLayer;
    this.drawPois = this.compassMapHeritageService.drawPois;
    this.addMarkersToMap = this.compassMapHeritageService.addMarkersToMap;
    this.popUpText = this.compassMapHeritageService.popUpText;
    this.buildIconMarkers = this.compassMapHeritageService.buildIconMarkers;
    this.fitBoundsCategoryGroup = this.compassMapHeritageService.fitBoundsCategoryGroup;
    this.clickMarker = this.compassMapHeritageService.clickMarker;
    this.mouseoverMarker = this.compassMapHeritageService.mouseoverMarker;
    this.mouseoutMarker = this.compassMapHeritageService.mouseoutMarker;

  }

  initOptions(list) {
    this.options = this.heritageFormOptionsService.getOptions(list);
  }

  resetForm() {
    this.initOptions(this.heritagePois);
    this.setControlValue(this.heritageForm, 'visibility', true);
    this.setControlValue(this.heritageForm, 'proveedor', this.options.proveedor);
    this.setControlValue(this.heritageForm, 'medio', this.options.medio);
    this.setControlValue(this.heritageForm, 'soporte', this.options.soporte);
    this.setControlValue(this.heritageForm, 'sub_categoria', this.options.sub_categoria);
    this.setControlValue(this.heritageForm, 'cliente', this.options.cliente);
    this.setControlValue(this.heritageForm, 'status', this.options.status);
    return false;
  }

  initForm() {
    this.heritageForm = new FormGroup({
      visibility: new FormControl(this.options.visibility),
      proveedor: new FormControl(this.options.proveedor),
      medio: new FormControl(this.options.medio),
      soporte: new FormControl(this.options.soporte),
      sub_categoria: new FormControl(this.options.sub_categoria),
      cliente: new FormControl(this.options.cliente),
      status: new FormControl(this.options.status)
    });

    this.heritageForm.get('visibility').valueChanges.subscribe((value) => {
      if (!value) {
        this.removeHeritageLayer('heritageLayer');
        this.checkboxText = 'Ver patrimonio';
      } else {
        this.checkboxText = 'Ocultar patrimonio';
      }
    });

    // rxjs/operators
    this.heritageForm.valueChanges
      .pipe(startWith(this.heritageForm.value), debounceTime(500), pairwise())
      .subscribe(([old, value]) => {
        this.reshapeTableList(value, this.heritagePois);
        //this.initOptions(this.filteredPoisList);
        if (value.visibility) {
          this.checkboxText = 'Ocultar inmuebles';
          this.removeHeritageLayer('heritageLayer');
          this.drawPois(this.filteredPoisList, 'heritageLayer', 'featureGroup');
        }
        return;
      });
  }

  downloadFiltered() {
    if (!this.filteredPoisList?.length) {
      alert('Debe aplicar algún filtro para poder descargar los datos');
      return;
    }

    const fields: string[] = [
      'id',
      'nombre',
      'direccion',
      'sub_categoria',
      'medio',
      'proveedor',
      'soporte',
      'cliente',
      'cccaa',
      'nccaa',
      'class_',
      'categoria',
      'key_categoria',
      'key_sub_categoria'
    ];

    // extract data using fields
    const data: any[] = this.filteredPoisList.map(poi => {
      let row: string[] = [];

      // add lng and lat
      row.push(poi?.geometry?.coordinates?.[0] ?? null); // lng
      row.push(poi?.geometry?.coordinates?.[1] ?? null); // lat

      fields.forEach(field => {
        let value: any = get(poi, `properties.${field}`) ?? null;
        // escape string values
        if(typeof value === 'string') {
          value = JSON.stringify(value);
        }

        row.push(value);
      });

      return row.join(',');
    });

    // download the file
    const csv: string = 'lat,lng,' + fields.join(',') + '\n' + data.join('\n');

    // Create a Blob object from the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });

    // Generate a unique filename for the download
    const timestamp = new Date().getTime();
    const fileName = `patrimonios_${timestamp}.csv`;

    // Create a temporary anchor element
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = fileName;

    // Programmatically trigger the download
    document.body.appendChild(element);
    element.click();

    // Clean up
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
  }

  ngOnInit(): void {
    if (this.heritagePois && this.heritagePois.length > 0) {
      this.initServices();
      this.initOptions(this.heritagePois);
      this.initForm();
      this.checkboxText = 'Ocultar inmuebles';
      this.removeHeritageLayer('heritageLayer');
      this.drawPois(this.heritagePois, 'heritageLayer', 'featureGroup');
      this.fitBoundsCategoryGroup('heritageLayer');
    }
  }
}


