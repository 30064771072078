import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ANGULAR UTILS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// MATERIAL AND flex
import { MaterialModule } from '@compass/material';

// page wrapper
import { PageWrapperModule } from '@compass/page-structure/page-wrapper';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';

// Top toolbar
import { TopToolbarWrapperModule } from '@compass/page-structure/top-toolbar-wrapper';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
// mainSideNav base
import { StartLeftSidenavModule } from '@compass/page-navigation/start-left-sidenav';
import { EndRightSidenavModule } from '@compass/page-navigation/end-right-sidenav';

// study crud-menu
import { SharedCrudMenuModule } from '@compass/shared/crud-menu';


// sidenav loader
import { EstudioSidenavLoaderComponent } from './estudio-sidenav-loader/estudio-sidenav-loader.component';

// footer
import { PageStructureFooterModule } from '@compass/page-structure/footer';
import { PageFooterComponent } from './page-footer/page-footer.component';

// mainSideNav content
import { StudySidenavComponent } from './estudio-sidenav/study-sidenav/study-sidenav.component';

// study sidenav content
import { FormNameComponent } from './estudio-sidenav/study-sidenav/form-name/form-name.component';
import { FormCpComponent } from './estudio-sidenav/study-sidenav/form-cp/form-cp.component';
import { FormPointComponent } from './estudio-sidenav/study-sidenav/form-point/form-point.component';
import { FormAreaComponent } from './estudio-sidenav/study-sidenav/form-area/form-area.component';
import { FormButtonsComponent } from './estudio-sidenav/study-sidenav/form-buttons/form-buttons.component';
import {
  CreateStudyButtonComponent
} from './estudio-sidenav/study-sidenav/create-study-button/create-study-button.component';
import {
  CancelStudyButtonComponent
} from './estudio-sidenav/study-sidenav/cancel-study-button/cancel-study-button.component';

// stages sidenav content
import { StagesSidenavComponent } from './estudio-sidenav/stages-sidenav/stages-sidenav.component';
import { StagesCrudComponent } from './estudio-sidenav/stages-sidenav/stages-crud/stages-crud.component';

import { StagesMenuComponent } from './estudio-sidenav/stages-sidenav/stages-menu/stages-menu.component';
import { StagePoisComponent } from './estudio-sidenav/stages-sidenav/stage-pois/stage-pois.component';
import { StageLocalPoisComponent } from './estudio-sidenav/stages-sidenav/stage-local-pois/stage-local-pois.component';
import {
  StageLocalMediaComponent
} from './estudio-sidenav/stages-sidenav/stage-local-media/stage-local-media.component';
import { StageIndicatorsComponent } from './estudio-sidenav/stages-sidenav/stage-indicators/stage-indicators.component';
import { StageTransitComponent } from './estudio-sidenav/stages-sidenav/stage-transit/stage-transit.component';
import { TrafficModule } from '@compass/traffic';
import {
  StageTrafficFlowComponent
} from './estudio-sidenav/stages-sidenav/stage-traffic-flow/stage-traffic-flow.component';

import { LineTransitFeatureDrawerModule } from '@compass/line-transit/feature-drawer';
import { StageEgmComponent } from './estudio-sidenav/stages-sidenav/stage-egm/stage-egm.component';
import { StageTransportComponent } from './estudio-sidenav/stages-sidenav/stage-transport/stage-transport.component';
import { StageHeritageComponent } from './estudio-sidenav/stages-sidenav/stage-heritage/stage-heritage.component';
import { StageAnalyticsComponent } from './estudio-sidenav/stages-sidenav/stage-analytics/stage-analytics.component';
import { StageHousingComponent } from './estudio-sidenav/stages-sidenav/stage-housing/stage-housing.component';
import { StageCadastreComponent } from './estudio-sidenav/stages-sidenav/stage-cadastre/stage-cadastre.component';

import { StageViewerComponent } from './estudio-sidenav/stages-sidenav/stage-viewer/stage-viewer.component';

// delete Study Mat-dialog
import { MatDialogDeleteComponent } from '@compass/shared/mat-dialog-delete';


// Pois sidenav content
// pois-table
import { PoisPoisTableModule } from '@compass/pois/pois-table';
// subpois
import { SubpoisSidenavComponent } from './estudio-sidenav/subpois-sidenav/subpois-sidenav.component';
import { SubpoisLoaderComponent } from './estudio-sidenav/subpois-sidenav/subpois-loader/subpois-loader.component';

// companies sidenav
import { CompaniesSidenavComponent } from './estudio-sidenav/companies-sidenav/companies-sidenav.component';
import { CompaniesLoaderComponent } from './estudio-sidenav/companies-loader/companies-loader.component';

// Places sidenav content
import { PlacesSidenavComponent } from './estudio-sidenav/places-sidenav/places-sidenav.component';
import { PlacesLoaderComponent } from './estudio-sidenav/places-sidenav/places-loader/places-loader.component';

// indicators sidenav content
import { IndicatorsSelectModule } from '@compass/indicators-select';
// weather
import { StageWeatherComponent } from './estudio-sidenav/stages-sidenav/stage-weather/stage-weather.component';


// analytics
import { AnalyticsModule } from '@compass/analytics';
// catastro
import { CatastroModule } from '@compass/catastro';
// idealista
import { IdealistaModule } from '@compass/idealista';
import { InmueblesFeatureDrawerModule } from '@compass/inmuebles/feature-drawer';

// heritage
import { HeritageModule } from '@compass/patrimonio/heritage';

// local media (medios locales internet, prensa)
import { LocalMediaModule } from '@compass/local-media';

// local knowledge (festivos, calendiarios)
import { LocalKnowledgeModule } from '@compass/local-knowledge';

// influencers (instagram,...)
import { InfluencersModule } from '@compass/influencers';

import {
  StageLocalKnowledgeComponent
} from './estudio-sidenav/stages-sidenav/stage-local-knowledge/stage-local-knowledge.component';
import {
  StagesMenuTabComponent
} from './estudio-sidenav/stages-sidenav/stages-menu/stages-menu-tab/stages-menu-tab.component';

//Users
import { SharedCurrentUserNameModule } from '@compass/shared/current-user-name';

// d3
import { UtilsD3Module } from '@compass/utils/d3';
import { MatDialogNotFoundComponent } from '@compass/shared/mat-dialog-not-found';
// slide toggle mastercluster widget
import { SharedWidgetsModule } from '@compass/shared/widgets';
import {
  StageInfluencersComponent
} from './estudio-sidenav/stages-sidenav/stage-influencers/stage-influencers.component';
import { StageMobilityComponent } from './estudio-sidenav/stages-sidenav/stage-mobility/stage-mobility.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  StagePopularTimesComponent
} from './estudio-sidenav/stages-sidenav/stage-popular-times/stage-popular-times.component';
import { PopularTimesModule } from '@compass/popular-times';
import { BottomContainerComponent } from './bottom-container/bottom-container.component';
import { PoisComparatorModule } from '@compass/pois/comparator';
import { BusinessModule } from '../business/business.module';

@NgModule({
  declarations: [
    PageWrapperComponent,
    TopToolbarComponent,
    EstudioSidenavLoaderComponent,
    StudySidenavComponent,
    PageFooterComponent,
    FormNameComponent,
    FormCpComponent,
    FormPointComponent,
    FormAreaComponent,
    FormButtonsComponent,
    CreateStudyButtonComponent,
    StagesSidenavComponent,
    StagesCrudComponent,
    StagesMenuComponent,
    StagePoisComponent,
    StageIndicatorsComponent,
    StageTransitComponent,
    StageEgmComponent,
    StageTransportComponent,
    StageHeritageComponent,
    StageAnalyticsComponent,
    StageViewerComponent,
    PlacesSidenavComponent,
    CancelStudyButtonComponent,
    SubpoisSidenavComponent,
    SubpoisLoaderComponent,
    PlacesLoaderComponent,
    CompaniesSidenavComponent,
    CompaniesLoaderComponent,
    StageLocalPoisComponent,
    StageHousingComponent,
    StageCadastreComponent,
    StageLocalMediaComponent,
    StageLocalKnowledgeComponent,
    StagesMenuTabComponent,
    StageWeatherComponent,
    StageTrafficFlowComponent,
    StageInfluencersComponent,
    StageMobilityComponent,
    StagePopularTimesComponent,
    BottomContainerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    PageWrapperModule,
    PageStructureFooterModule,
    StartLeftSidenavModule,
    EndRightSidenavModule,
    TopToolbarWrapperModule,
    SharedCurrentUserNameModule,
    SharedCrudMenuModule,
    UtilsD3Module,
    LineTransitFeatureDrawerModule,
    PoisPoisTableModule,
    SharedWidgetsModule,
    CatastroModule,
    IdealistaModule,
    LocalMediaModule,
    InfluencersModule,
    InmueblesFeatureDrawerModule,
    LocalKnowledgeModule,
    TrafficModule,
    AnalyticsModule,
    SharedCurrentUserNameModule,
    IndicatorsSelectModule,
    HeritageModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    PopularTimesModule,
    PoisComparatorModule,
    BusinessModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PageWrapperModule,
    PageStructureFooterModule,
    PageWrapperComponent,
    StartLeftSidenavModule,
    EndRightSidenavModule,
    TopToolbarWrapperModule,
    SharedCurrentUserNameModule,
    UtilsD3Module,
    PoisPoisTableModule,
    SharedWidgetsModule,
    CatastroModule,
    IdealistaModule,
    LocalMediaModule,
    InfluencersModule,
    LocalKnowledgeModule,
    TrafficModule,
    AnalyticsModule,
    IndicatorsSelectModule,
    HeritageModule,
    StagePopularTimesComponent,
    PoisComparatorModule,
    BottomContainerComponent,
  ],
  providers: [],
  entryComponents: [MatDialogDeleteComponent, MatDialogNotFoundComponent]
})
export class SharedModule {
}
