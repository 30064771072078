import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SideNavStagesService, SideNavStatusService, Stage } from '@compass/utils/navigation';
import { EgmAimcStatusService } from '@compass/egm-aimc/data-access';
import { WeatherStatusService } from '@compass/weather';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-stages-menu-tab',
  templateUrl: './stages-menu-tab.component.html',
  styleUrls: ['./stages-menu-tab.component.scss']
})
export class StagesMenuTabComponent implements OnInit, OnChanges {
  @Input() setup: any;
  activeStage: Stage;
  stageOn: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private sideNavStagesService: SideNavStagesService,
    private sideNavStatusService: SideNavStatusService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private egmAimcStatusService: EgmAimcStatusService,
    private weatherStatusService: WeatherStatusService
  ) {
  }

  openChildrenStages(event, stage: Stage) {
    event.stopPropagation();
    event.preventDefault();
    this.sideNavStagesService.setActiveStage(stage.key);
    this.sideNavStagesService.toggleOpenStage(stage.key);
    return false;
  }

  setStoresStage() {
    window.open('http://34.255.188.29/ecuacion', '_blank');
  }

  isPoisStage(toSetActive: string) {
    if (
      toSetActive === 'global_pois' ||
      toSetActive === 'local_pois' ||
      toSetActive === 'transport' ||
      toSetActive === 'heritage'
    ) {
      return true;
    }
    return false;
  }

  setPoisStage(key) {
    this.sidenavPoisLevelsService.setActiveTableRoot(key);
    this.sidenavPoisLevelsService.resetParentCategories();
  }

  setEgmStage() {
    this.egmAimcStatusService.setEgmOn(true);
    this.egmAimcStatusService.setAimcOn(false);
    this.egmAimcStatusService.setSurveyType(undefined);
  }

  unSetEgmStage() {
    this.egmAimcStatusService.setEgmOn(false);
    this.egmAimcStatusService.setAimcOn(false);
  }

  setWeatherStage(key) {
    this.weatherStatusService.setWeatherOn(true);
  }

  unSetWeatherStage(key) {
    this.weatherStatusService.setWeatherOn(false);
  }

  navigateToNextComponent(event, stage: Stage) {
    event.stopPropagation();
    event.preventDefault();

    if (stage.key === 'stores') {
      this.setStoresStage();
      return false;
    }

    if (this.isPoisStage(stage.key)) {
      this.setPoisStage(stage.key);
    }

    if (this.isPoisStage(stage.key)) {
      this.setPoisStage(stage.key);
    }

    if (stage.key === 'egm') {
      this.setEgmStage();
    } else {
      this.unSetEgmStage();
    }

    if (stage.key === 'weather') {
      this.setWeatherStage(stage.key);
    } else {
      this.unSetWeatherStage(stage.key);
    }
    this.sideNavStagesService.setActiveStage(stage.key);
    this.sideNavStatusService.setStagesNavStatus(false);
    this.sideNavStatusService.setStageViewerStatus(true);

    return false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let changeObj: string[] = Object.keys(changes);
    let x = changes;
  }
}
