import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SlideToggleService } from './slide-toogle.service';


@Component({
  selector: 'compass-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {
  @Input()
  setup: any;

  constructor(private slideToggleService: SlideToggleService) { }

  ngOnInit(): void {
  }

  emitToggle(event: MatSlideToggleChange): void {
    this.slideToggleService.setSlideToggle(event.checked)
  }
}

