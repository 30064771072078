import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';

import { Map } from './map.type';


@Injectable({ providedIn: 'root' })
export class MapService {

  constructor() {
  }

  public removeLayer(map, layer): Map {
    if (map[layer]) {
      map.box.removeLayer(map[layer]);
    }
    return map;
  }

  public fitBoundsCategoryGroup(map, layer): Map {
    if (map['pois'][layer]) {
      const bounds = map['pois'][layer].getBounds();
      if (bounds && bounds._northEast && bounds._northEast.lng && bounds._southWest && bounds._southWest.lat) {
        map.box.fitBounds(bounds);
      }
    }

    return map;
  }

  public setView(map: Map, center: number[], zoom?: number): Map {
    map.box.setView(center, zoom || 8);

    return map;
  }


  public setViewToMapCenter(map): Map {
    map.box.setView(
      new L.LatLng(
        map.properties.center[0],
        map.properties.center[1]
      ),
      map.properties.zoom
    );

    return map;
  }

  // @options: Array of options from selector
  // to select a color for each category
  // if not, defaults to blue '#3366cc'
  //[{value: "edificios", name: "Edificios", color: "#3366cc"}]
  getCategoryColor(poi, options?): string {
    let color = '#3366cc'; // default color
    if (options) {
      options.forEach((option) => {
        if (option.value === poi.properties.category) color = option.color;
      });
    }
    return color;
  }


  public buildCircleMarkers(pois: any, options?): any[] {
    return pois.map((poi) => {
      const lat: number = poi.geometry.coordinates[1];
      const long: number = poi.geometry.coordinates[0];

      const circle = {
        circleMarker: L.circleMarker([lat, long], {
          color: '#333333', // border color
          weight: 1, // border weight in px
          fillColor: this.getCategoryColor(poi, options || null), // circle color
          fillOpacity: 0.8,
          radius: 8, // Radius of the circle marker, in pixels
          className: 'poi-circle'
        }),
        popUp: this.popUpText(poi)
      };

      circle.circleMarker['data'] = poi; // bind the data for right info window
      circle.circleMarker.bindPopup(circle.popUp); // bind the popUp
      circle.circleMarker.on('mouseover', (e) => {
        this.mouseoverCircleMarker(e);
        e.sourceTarget.openPopup();
      });
      circle.circleMarker.on('mouseout', (e) => {
        this.mouseoutCircleMarker(e);
        e.sourceTarget.closePopup();
      });
      circle.circleMarker.on('click', (e) => {
        this.clickCircleMarker(e);
      });
      return circle;
    });
  }


  public clickCircleMarker(marker): boolean {
    // open right window, pass info, get more data from API
    // opens right end sidenav

    // we are here
    return false;
  }

  public mouseoverCircleMarker(marker): boolean {
    return false;
  }

  public mouseoutCircleMarker(marker): boolean {
    return false;
  }

  public addMarkersToMap(map: Map, markers: any, layer: string): Map {
    map[layer] = L.featureGroup([]);
    markers.forEach((marker) => {
      marker.circleMarker.addTo(map[layer]);
    });
    map[layer].addTo(map.box);
    map[layer].bringToFront();

    return map;
  }

  // @map: the map
  // @pois: array of pois to draw
  // @layer: map layer to draw the array of pois
  // @type: type of layer --> 'featureGroup', 'markerClusterGroup' (mapa de clusters), 'heatMapGroup' (mapa de calor)
  // @options: Select options, to choose circle color
  public drawPois(map: Map, pois: any, layer: string, type: string, options?: any[]): Map {
    switch (type) {
      case 'featureGroup': {
        const markers = this.buildCircleMarkers(pois, options);
        this.addMarkersToMap(map, markers, layer);

      }
        break;

      default: {
        return null;
      }
    }
    return map;
  }

  public popUpText = (poi): string => {
    let text =
      '<p data-id="popUpText"><strong>' +
      poi.properties.denominacion +
      '</strong></p>';
    if (poi.properties && poi.properties.descripcion_cnae) {
      text += '<p>' +
        (poi.properties.descripcion_cnae) +
        '</p>';
    }
    if (poi && poi.properties && poi.properties.sigla) {
      text += '<p>';
      text += poi.properties.sigla;
      text += '</p>';
    }
    if (poi && poi.properties && poi.properties.rating_morosidad) {
      text += '<p>';
      text += 'Morosidad: <strong>' + poi.properties.rating_morosidad + '</strong>';
      text += '</p>';
    }
    return text;
  };
}
