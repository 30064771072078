import { Component, OnInit } from '@angular/core';
import { CreateStudyService } from '@compass/feature-create-study';


@Component({
  selector: 'glo-stage-cadastre',
  templateUrl: './stage-cadastre.component.html',
  styleUrls: ['./stage-cadastre.component.scss']
})
export class StageCadastreComponent implements OnInit {
  coordsCenter: Array<number>;
  accordionCatastro: {};
  constructor(private createStudyService: CreateStudyService) { }

  ngOnInit(): void {
    this.coordsCenter = this.createStudyService.getCoordsMarker();

    this.accordionCatastro = {
      title: "Visor del catastro",
      opened: true,
      closed: false,
      expanded: true,
    };
  }
}

