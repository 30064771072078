<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <compass-comparator-poi-title [poi]="element" [canUpdate]="false"></compass-comparator-poi-title>
    </td>
  </ng-container>

  <ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef>Rating</th>
    <td mat-cell *matCellDef="let element" class="rating">
      <div class="stars">{{ getStars(element) }}</div>
      <h1 *ngIf="element.properties?.goo?.rating">
        {{ element.properties?.goo?.rating | number:'1.0-2' }}
      </h1>

      <h1 *ngIf="!element.properties?.goo?.rating">--</h1>
    </td>

  </ng-container>

  <ng-container matColumnDef="new_reviews">
    <th mat-header-cell *matHeaderCellDef>Reviews</th>
    <td mat-cell *matCellDef="let element">
      <h1>{{ (element.properties?.goo?.new_review || 0) | number:'1.0-0' }}</h1>
    </td>
  </ng-container>

  <ng-container matColumnDef="sector">
    <th mat-header-cell *matHeaderCellDef>Sector</th>
    <td mat-cell *matCellDef="let element">{{element.properties.categoria}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


