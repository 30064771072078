import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glo-stage-weather',
  templateUrl: './stage-weather.component.html',
  styleUrls: ['./stage-weather.component.scss']
})
export class StageWeatherComponent implements OnInit {

  activeTableLevel: number;
  parentIndex: number = 0;
  childIndex: number = 0;

  weather = [
    {
      key: 'weather-forecast',
      value: 'Predicción',
      icon: 'calendar_today',
      children: [
        {
          key: 'seven-days-weather',
          value: 'Próximos 7 días',
          tipologia: 'prediction',
        }

      ]
    }
  ];

  onClickWeather(item, index, event) {
    event.preventDefault();
    // do nothing for now, ready for future extensions
    this.parentIndex = index;
    return false;
  }
  onClickChild(type, index, event) {
    event.preventDefault();
    // do nothing for now, ready for future extensions
    this.childIndex = index;
    return false;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
