import { Component, OnInit } from '@angular/core';
import {
  SidenavPoisApiService,
  SidenavPoisLevelsService,
} from '@compass/pois/data-access-poi';
import {
  SideNavStatusService,
  SideNavStagesService,
  Stage,
} from '@compass/utils/navigation';

@Component({
  selector: 'compass-stage-breadcrumbs',
  templateUrl: './stage-breadcrumbs.component.html',
  styleUrls: ['./stage-breadcrumbs.component.scss'],
})
export class StageBreadcrumbsComponent implements OnInit {
  activeTableLevel: number;
  activeTableRoot: string;
  activeStage: Stage;
  parentCategory = null;
  parentSubcategory = null;
  parentCompany = null;

  clicked: boolean = false; // prevent two clicks on li

  constructor(
    private sideNavStatusService: SideNavStatusService,
    private sideNavStagesService: SideNavStagesService,
    private sidenavPoisApiService: SidenavPoisApiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService
  ) {
    // menu list stage (pois, transport, indicators...)
    this.sideNavStagesService.getActiveStage().subscribe((value) => {
      this.activeStage = value;
    });
    // parentCategory for breadcrumbs
    this.sidenavPoisLevelsService.getParentCategory().subscribe((value) => {
      this.parentCategory = value;
    });
    this.sidenavPoisLevelsService.getParentSubcategory().subscribe((value) => {
      this.parentSubcategory = value;
    });
    this.sidenavPoisLevelsService.getParentCompany().subscribe((value) => {
      this.parentCompany = value;
    });

    this.sidenavPoisLevelsService
      .getActiveTableLevel()
      .subscribe((value: number) => {
        this.activeTableLevel = value;
      });
    this.sidenavPoisLevelsService.getActiveTableRoot().subscribe((value) => {
      this.activeTableRoot = value;
    });
  }

  returnToStagesViewer(event) {
    event.stopPropagation();
    if (!this.clicked) {
      this.clicked = true;

      if (this.activeTableLevel === 1) {
        this.sideNavStatusService.setStagesNavStatus(true);
        this.sideNavStatusService.setStageViewerStatus(false);
      }
      if (this.activeTableLevel === 2) {
        this.sideNavStatusService.setSubcategoriesNavStatus(false);
        this.sideNavStatusService.setStageViewerStatus(true);
        this.sidenavPoisLevelsService.setActiveTableLevel(
          this.activeTableLevel - 1
        );
      }
      if (this.activeTableLevel === 3) {
        this.sideNavStatusService.setStageViewerStatus(false);
        this.sideNavStatusService.setCompaniesNavStatus(false);
        this.sideNavStatusService.setSubcategoriesNavStatus(true);
        this.sidenavPoisLevelsService.setActiveTableLevel(
          this.activeTableLevel - 1
        );
      }

      if (this.activeTableLevel === 4) {
        this.sideNavStatusService.setStageViewerStatus(false);
        this.sideNavStatusService.setSubcategoriesNavStatus(false);
        this.sideNavStatusService.setPlacesNavStatus(false);
        this.sideNavStatusService.setCompaniesNavStatus(true);
        this.sidenavPoisLevelsService.setActiveTableLevel(
          this.activeTableLevel - 1
        );
      }

      this.clicked = false;
    }
    return false;
  }
  ngOnInit(): void {}
}
