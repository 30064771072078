import { Component, OnInit } from '@angular/core';
import { BricksApiService } from '../../../../../brick-api/src/lib/bricks-api.service';
import { PatrimonioService } from '../../../../data-access/src/lib/patrimonio.service';

@Component({
  selector: 'compass-heritage-loader',
  templateUrl: './heritage-loader.component.html',
  styleUrls: ['./heritage-loader.component.scss']
})
export class HeritageLoaderComponent implements OnInit {
  heritagePois$ = this.patrimonioService.heritagePois$;

  constructor(
    private patrimonioService: PatrimonioService,
    private bricksApiService: BricksApiService
  ) {
    this.patrimonioService.getByAreaFrom(this.bricksApiService.fullStages$.value);
  }

  ngOnInit(): void {
  }

}
