import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { LocalKnowledgeLoaderComponent } from './local-knowledge-loader/local-knowledge-loader.component';
import { LocalKnowledgeApiService } from './local-knowledge-loader/local-knowledge-api.service';
import { LocalKnowledgeFormComponent } from './local-knowledge-form/local-knowledge-form.component';
import { LocalKnowledgeOptionsService } from './local-knowledge-form/local-knowledge-options.service';
import { LocalKnowledgeTableComponent } from './local-knowledge-table/local-knowledge-table.component';
import { LocalKnowledgeReshapeService } from './local-knowledge-reshape.service';
import { LocalKnowledgeCalendarComponent } from './local-knowledge-calendar/local-knowledge-calendar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CalendarModule } from 'angular-calendar';
import { MatGridListModule } from '@angular/material/grid-list';
import { PoisComparatorModule } from '@compass/pois/comparator';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    CalendarModule,
    MatGridListModule,
    PoisComparatorModule
  ],
  declarations: [
    LocalKnowledgeLoaderComponent,
    LocalKnowledgeFormComponent,
    LocalKnowledgeTableComponent,
    LocalKnowledgeCalendarComponent,
    EventDetailComponent
  ],
  exports: [
    LocalKnowledgeLoaderComponent,
    LocalKnowledgeFormComponent,
    LocalKnowledgeTableComponent,
    LocalKnowledgeCalendarComponent,
    EventDetailComponent
  ],
  providers: [
    LocalKnowledgeApiService,
    LocalKnowledgeReshapeService,
    LocalKnowledgeOptionsService
  ]
})
export class LocalKnowledgeModule {
}
