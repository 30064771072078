import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoisComponent } from './pois.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@compass/material';
import { PoiService } from '@compass/pois/data-access-poi';
import { PoiDetailComponent } from './poi-detail/poi-detail.component';
import { PoisFeaturePoiFormModule } from '@compass/pois/feature-poi-form';
import { SharedCrudElementsModule } from '@compass/shared/crud-elements';
import { SharedMapFeatureMapModule } from '@compass/shared/map/feature-map';
import { FeatureSearchboxModule } from '@compass/feature-searchbox';
import { SharedFeatureManagementTitleModule } from '@compass/shared/feature-management-title';
import { UiManagementTableModule } from '@compass/ui-management-table';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild([
      { path: 'detalle/:poiID', component: PoiDetailComponent },
      { path: 'agregar', component: PoiDetailComponent },
    ]),
    PoisFeaturePoiFormModule,
    SharedCrudElementsModule,
    SharedMapFeatureMapModule,
    FeatureSearchboxModule,
    SharedFeatureManagementTitleModule,
    UiManagementTableModule,
  ],
  declarations: [PoisComponent, PoiDetailComponent],
  exports: [PoisComponent],
  providers: [PoiService],
})
export class PoisFeaturePoiManagementModule {}
