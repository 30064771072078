import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material'
import { MatDialogNotFoundComponent } from './mat-dialog-not-found/mat-dialog-not-found.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [MatDialogNotFoundComponent],
  exports: [MatDialogNotFoundComponent],
})
export class SharedMatDialogNotFoundModule { }
