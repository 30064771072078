import { Component, OnInit } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';

@Component({
  selector: 'glo-places-loader',
  templateUrl: './places-loader.component.html',
  styleUrls: ['./places-loader.component.css'],
})
export class PlacesLoaderComponent implements OnInit {
  placesList: any;
  activeTableRoot: string;

  constructor(private sidenavPoisLevelsService: SidenavPoisLevelsService) {
    this.sidenavPoisLevelsService
      .getActiveTableRoot()
      .subscribe((d: string) => {
        this.activeTableRoot = d;
      });

    this.sidenavPoisLevelsService.getPlaceList().subscribe((d: any) => {
      this.placesList = d;
    });
  }

  ngOnInit(): void {}
}
