import { Component, OnInit } from '@angular/core';
import { AuthService } from '@compass/shared/feature-auth';
@Component({
  selector: 'compass-current-user-name',
  templateUrl: './current-user-name.component.html',
  styleUrls: ['./current-user-name.component.scss']
})
export class CurrentUserNameComponent implements OnInit {
  currentUser$ = this.authService.currentUser$;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

}



