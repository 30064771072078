<div class="container padding__basic">

    <form [formGroup]="form" target="_blank" class="local-media-form" id="local_media_form" name="local_media_form"
        (ngSubmit)="onSubmit()">
        <div class="container">
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxFlex="50">
                    <div class="margin__top--xs container">
                        <p class="margin__bottom-xxs"><strong><small>Medio:</small></strong></p>
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="media">
                                <mat-option *ngFor="let option of media_options" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="50">
                    <div class="margin__top--xs container">

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>