import { Component } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { CategoryService } from '@compass/categories/data-access-category';
import { PoiService } from '@compass/pois/data-access-poi';
import { PoiDb } from 'libs/pois/data-access-poi/src/lib/poi.service';
import { MarkerService } from '@compass/shared/map/feature-map';

@Component({
  selector: 'poi-form',
  templateUrl: './poi-form.component.html',
  styleUrls: ['./poi-form.component.scss']
})
export class PoiFormComponent implements OnChanges {
  @Input() poi: PoiDb;
  categories: any = [];
  newPoiForm: FormGroup;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private poiService: PoiService,
    private categoryService: CategoryService,
    private markerService: MarkerService
  ) {
    this.categoryService.getAll('Glocally Pois',  true).subscribe(cat => {
      this.categories = cat;
      console.log('CATEGORIAS', cat)
    });

    this.markerService.marker$.subscribe((params: any) => {
      this.newPoiForm.patchValue({
        x: params.x,
        y: params.y,
        direccion: params.address
      });
    });
  }

  async onSubmit() {
    if(!this.submitted) {
      this.submitted = true;

      if (this.poi) {
        this.poiService.updatePoi(this.poi.properties.id, this.newPoiForm.value)
          .add(() => {
            this.submitted = false;
          });
      } else {
        await this.poiService.addPoi(this.newPoiForm.value)
          .add(() => {
            this.submitted = false;
          });
      }
    }
  }

  ngOnChanges() {
    this.newPoiForm = this.formBuilder.group({
      key_sub_categoria: [
        this.poi?.properties.key_sub_categoria,
        Validators.required
      ],
      nombre: [this.poi?.properties.nombre, Validators.required],
      direccion: [this.poi?.properties.direccion, Validators.required],
      x: [this.poi?.geometry?.coordinates[0], Validators.required],
      y: [this.poi?.geometry?.coordinates[1], Validators.required],
      comentarios: [this.poi?.properties.comentarios]
    });
  }

  resetForm(event) {
    event.preventDefault();
    this.newPoiForm.reset();
  }
}
