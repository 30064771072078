import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatrimonioService } from './patrimonio.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

@NgModule({
  providers: [PatrimonioService, GeoCoordsService],
  imports: [CommonModule],
})
export class PatrimonioDataAccessModule {}
