import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Map, MapSetUp } from '@compass/utils/leaflet';
import { MarkerService } from './marker.service';
import { SearchboxService } from '@compass/feature-searchbox';
import { LocationService, MapBoxService } from '@compass/utils/misc';
import { PoiDb } from '@compass/pois/data-access-poi';
import * as L from 'leaflet';
import { MapService } from './map.service';

@Component({
  selector: 'plain-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges {
  private map: any;

  @ViewChild('map')
  private mapContainer: ElementRef<HTMLElement>;

  @Input() poi: PoiDb;

  constructor(
    private mapService: MapService,
    private markerService: MarkerService,
    private searchboxService: SearchboxService,
    private locationService: LocationService
  ) {
    this.searchboxService.searchboxDescriptiveChanged$.subscribe((address) => {
      if (address == '') {
        this.mapService.removeGeojsonLayer(this.map.marker.options.name);
      } else {
        this.locationService
          .geocodificarDireccion(address)
          .subscribe((respuesta: any) => {
            let dataResult = this.locationService.buscarDireccion(respuesta);
            this.map.properties.center[0] = dataResult.y;
            this.map.properties.center[1] = dataResult.x;
            this.map.properties.address = dataResult.address;
            this.markerService.drawMarker();
            this.mapService.setView(this.map);
            this.markerService.marker$.next(dataResult);
          });
      }
    });
  }

  initMap(): void {
    this.map = this.mapService.initMap();
    this.mapService.setMap(this.map);
    this.mapService.setMapCenter([
      this.map.properties.center[0],
      this.map.properties.center[1],
    ]);
    // this.map.box = L.map(this.map.id, this.map.properties);
    this.map.box = new L.Map(
      this.mapContainer.nativeElement,
      this.map.properties
    );
    this.mapService.drawZoomCtrl();
    this.mapService.drawAttributionLayer();
    this.mapService.drawCtrl(false, null);
    this.mapService.setMap(this.map);
  }

  ngOnChanges() {
    if (this.poi) {
      this.map.properties.center = [
        this.poi.geometry.coordinates[1],
        this.poi.geometry.coordinates[0],
      ];
      this.markerService.drawMarker();
      this.mapService.setView(this.map);
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.mapService.setMap(this.map);
  }
}
