import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SideNavMatTabChangeService {

  public selectedIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }

  setSelectedIndex(index: number): void {
    this.selectedIndex$.next(index);
  }

  getSelectedIndex() {
    return this.selectedIndex$.asObservable();
  }
}
