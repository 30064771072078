import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Geometry } from '@compass/types';
import { Map } from '@compass/utils/leaflet';
import { ColorsService, MapBoxService } from '@compass/utils/misc';
import * as L from 'leaflet';

export interface LineTransit {
  type: string;
  properties: LineTransitProperty;
  geometry: LineTransitGeometry;
}

export interface LineTransitProperty {
  id: number;
  transit: Transit;
  coordinateA: Geometry;
  coordinateB: Geometry;
  class_: string;
}

export interface Transit {
  cumun: string;
  link_id: number;
  percentile: number;
  shape_leng: number;
  st_name: string;
}

export interface LineTransitGeometry {
  type: string;
  coordinates: Coordinates[];
}

export interface Coordinates {
  coordinates: number[];
}

@Injectable()
export class LineTransitService {
  private lineTransitUrl = '/abacusgis/core/glocally/collection/line_transit/';
  public transitLines$ = new BehaviorSubject<LineTransit[]>(undefined);
  map: Map;
  colorScale = this.colorsService.divergingGnYlRd;

  constructor(
    private http: HttpClient,
    private colorsService: ColorsService,
    private mapBoxService: MapBoxService
  ) { }

  getAreaTransit(currentStudy: any) {
    this.transitLines$.next(undefined);
    const areaValue = Object.entries(
      currentStudy.properties.areas
    )[0][1] as LineTransit;
    const geometry = JSON.stringify(areaValue.geometry);
    this.transitLines$.next(undefined);
    return this.http.get(`${this.lineTransitUrl}?study_id=${currentStudy.properties.id}`)
      .subscribe((transitLines: LineTransit[]) => {
        this.transitLines$.next(transitLines);
      });
  }

  getColor(percentile) {
    return percentile > 90
      ? this.colorScale[0]
      : percentile > 80
        ? this.colorScale[1]
        : percentile > 70
          ? this.colorScale[2]
          : percentile > 60
            ? this.colorScale[3]
            : percentile > 50
              ? this.colorScale[4]
              : percentile > 40
                ? this.colorScale[5]
                : percentile > 30
                  ? this.colorScale[6]
                  : percentile > 20
                    ? this.colorScale[7]
                    : percentile > 10
                      ? this.colorScale[8]
                      : this.colorScale[9];
  }
  public percentileScale = [
    { color: this.getColor(0), value: 10 + '(-)' },
    { color: this.getColor(11), value: 20 },
    { color: this.getColor(21), value: 30 },
    { color: this.getColor(31), value: 40 },
    { color: this.getColor(41), value: 50 },
    { color: this.getColor(51), value: 60 },
    { color: this.getColor(61), value: 70 },
    { color: this.getColor(71), value: 80 },
    { color: this.getColor(81), value: 90 },
    { color: this.getColor(99), value: 100 + '(+)' },
  ];

  styling = (feature: any): any => {
    return {
      fillColor: this.getColor(feature.properties.transit.percentile),
      weight: 3,
      opacity: 0.8,
      color: this.getColor(feature.properties.transit.percentile),
      dashArray: '0',
      fillOpacity: 0.8,
      className: 'transit-lines',
    };
  };

  removeLayers() {
    if (this.map) {
      if (this.map.tileLayer) {
        this.map.tileLayer.remove();
      }
      if (this.map.transitLayer) {
        this.map.transitLayer.remove();
      }
    }
  }

  private drawAttribution() {
    const access_token = this.mapBoxService.getMapBoxUrl('light')[0].value;
    this.map.tileLayer = L.tileLayer(access_token, this.map.attribution).addTo(
      this.map.box
    );
    return this.map;
  }

  private fitTransitBounds(): void {
    this.map.bounds = this.map.transitLayer.getBounds();
    this.map.box.fitBounds(this.map.bounds);
  }

  private drawTransit(nodes) {
    this.removeLayers();
    this.map.transitLayer = L.geoJSON(nodes, {
      style: (feature): any => {
        return this.styling(feature);
      },
    }).addTo(this.map.box);
    this.fitTransitBounds();
  }

  setMap(map: any) {
    this.map = map;
  }

  drawLayers(map: any, areaTransit: any) {
    this.setMap(map);
    this.drawAttribution();
    this.drawTransit(areaTransit);
  }
}
