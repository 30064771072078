import { Component, OnInit, Input } from '@angular/core';
import { SimpleChange } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DimensionsService } from '../dimensiones.service';
import { DefaultVarsService } from '../default-vars.service';
import { LocaleEsService } from '../locale-es.service';
import { AxisTitleService } from '../axis-title.service';
import { MeasureService } from '../measure.service';
import { ColorsService } from '@compass/utils/misc';
import { WordingService } from '@compass/utils/dictionaries';
import { GroupedHorizontalBarchartReshapeService } from './grouped-horizontal-barchart-reshape.service';
import * as d3 from 'd3';
@Component({
  selector: 'compass-grouped-horizontal-barchart',
  templateUrl: './grouped-horizontal-barchart.component.html',
  styleUrls: ['./grouped-horizontal-barchart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/********************************* */
// THIS IS THE NEW CHART
/********************************* */
export class GroupedHorizontalBarchartComponent implements OnInit {
  @Input() barChart: any;

  // data
  dataBase: any;
  dataChart: any;
  dataLength: number; // form chart height
  measure: string;
  xSegment: any;
  ySegment: any;

  // load control
  afterViewInInit: boolean = false;
  chartId: string;
  class: string;
  // Axis
  xVal: any;
  yVal: any;
  xAxis: any;
  yAxis: any;

  //keys
  yKeys: Array<string>;

  // y, x, scales
  yScale: any;
  yScaleBars: any;
  xScale: any;
  min: number;
  max: number;

  // colors scale
  colorScale: any;

  // legend
  viewChartLegend: boolean;
  legendKeys: Array<any>;
  legendScaleDomain: Array<string>;
  legendScale: any;
  // legend state
  singleLegendOn: boolean = false;

  // domains
  xDomain: Array<number>;
  yDomain: Array<string>;

  // containers
  win: any = window; // in use
  vBody: any;
  container: any;
  svg: any;
  chartLegend: any;
  chartWrapper: any;
  chartInner: any;

  // services
  getDictionaryName: any;
  formatRound_1f: any;
  formatRound_2f: any;
  formatThousands: any;
  default_time: number;
  timeout: any = false;
  resize_delay: number;
  getDimensions: any;
  switchTitle: any;
  reshapeService: any;
  capitalize: any;

  // params
  style: any;
  margin: any;
  width: number;
  height: number;
  heightFactor: number;
  dictionary: Array<any>;

  // tooltip
  tooltip: any;
  tooltipStyle: any;
  tooltipText: any;
  tooltipID: string;
  tooltipHeader: string;

  /********************************* */
  // THIS IS THE NEW CHART
  /********************************* */
  constructor(
    private dimensionsService: DimensionsService,
    private defaultVarsService: DefaultVarsService,
    private colorsService: ColorsService,
    private axisTitleService: AxisTitleService,
    private measureService: MeasureService,
    private localeEsService: LocaleEsService,
    private wordingService: WordingService,
    private groupedHorizontalBarchartReshapeService: GroupedHorizontalBarchartReshapeService
  ) { }
  private sortData(data: any, keys: Array<string>): any {
    return data.sort(function (x, y) {
      return d3.descending(x[keys[0]], y[keys[0]]);
    });
  }

  /********************************* */
  // THIS IS THE NEW CHART EVERYBODY IS TALKING ABOUT
  /********************************* */
  private runAll = (): void => {
    ////////// initialize scales //////////
    this.colorScale = d3.scaleOrdinal().range(this.style.colors);
    this.xScale = d3.scaleLinear();
    this.yScale = d3.scaleBand().paddingInner(this.style.paddingInner);
    this.yScaleBars = d3.scaleBand().padding(this.style.padding);
    this.legendScale = d3.scaleBand().paddingInner(this.style.paddingInner);

    ////////// Initialize axis //////////
    this.xAxis = d3.axisBottom(this.xScale).tickFormat(this.formatRound_1f);
    this.yAxis = d3.axisLeft(this.yScale);
    // ELEMENTS
    this.container = d3.select('#' + this.chartId); // placeholder div for svg

    this.svg = this.container
      .selectAll('svg')
      .data([{}])
      .enter()
      .append('svg:svg');

    this.chartLegend = this.svg
      .selectAll('g.chartLegend')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartLegend')
      .attr('transform', 'translate(' + this.margin.left + ',' + 0 + ')');

    this.chartWrapper = this.svg
      .selectAll('g.chartWrapper')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartWrapper')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );

    this.chartInner = this.chartWrapper
      .selectAll('g.chartInner') // chart without axis to clipPath
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'chartInner');

    // Axis groups
    this.chartWrapper
      .selectAll('.x.axis')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'x axis');
    this.chartWrapper
      .selectAll('.y.axis')
      .data([{}])
      .enter()
      .append('svg:g')
      .attr('class', 'y axis');
    this.win.addEventListener('resize', () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.render();
      }, this.resize_delay);
    });

    this.reshapedata();
  };
  /********************************* */
  // THIS IS THE NEW CHART
  /********************************* */
  private reshapedata(fromLegend: string = ''): void {
    // reshape data here with new services if needed
    if (fromLegend !== '' && !this.singleLegendOn) {
      this.dataBase = this.reshapeService(this.dataBase, fromLegend);
    } else {
      this.dataBase = this.barChart.data;
    }

    this.yKeys = Object.keys(this.dataBase[0]);
    this.yKeys.shift();
    this.dataChart = this.sortData(this.dataBase, this.yKeys);
    this.dataLength = this.dataChart.length;

    this.min = d3.min(this.dataChart, (d) => {
      return d3.min(this.yKeys, (key) => {
        return +d[key];
      });
    });
    this.max = d3.max(this.dataChart, (d) => {
      return d3.max(this.yKeys, (key) => {
        return +d[key];
      });
    });

    this.xDomain = [this.min >= 0 ? 0 : this.min, this.max];
    this.yDomain = this.dataChart.map((d) => {
      return d['category'];
    });

    this.legendKeys = this.getLegendFromKeys(this.yKeys);
    this.legendScaleDomain = this.legendKeys.map((d) => {
      return d['value'];
    });
    // render everything!
    this.render();
  }
  /********************************* */
  // THIS IS THE NEW CHART
  /********************************* */
  private render(): void {
    let dimensions: any = this.getDimensions(
      this.svg,
      '#' + this.chartId,
      this.margin
    );

    this.width = dimensions.width;
    this.height = this.heightFactor
      ? this.dataLength * this.heightFactor
      : dimensions.height;

    this.xScale.rangeRound([0, this.width]).domain(this.xDomain).nice();
    this.yScale.domain(this.yDomain).range([0, this.height]);
    this.yScaleBars.domain(this.yKeys).rangeRound([0, this.yScale.bandwidth()]);

    this.legendScale.domain(this.legendScaleDomain).range([0, this.width]);

    // Apply to svg
    this.svg
      .attr('width', this.width + this.margin.right + this.margin.left)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .attr(
        'viewBox',
        '0 0 ' +
        (this.width + this.margin.left + this.margin.right) +
        ' ' +
        (this.height + this.margin.top + this.margin.bottom)
      )
      .attr('preserveAspectRatio', 'xMaxYMax meet');

    //this.destroyTooltip();
    this.drawTooltip();
    this.drawAxis();
    this.drawAxisTitles();
    this.drawBarGroups();
    this.drawBars();
    this.drawZeroLine();
    if (this.viewChartLegend) {
      this.drawLegendGroups();
      this.drawChartLegend();
    }
    let y;
  }

  /********************************* */
  // THIS IS THE NEW CHART EVERYBODY IS TALKING ABOUT
  /********************************* */

  private drawBarGroups(): void {
    let yScale = this.yScale;
    const default_time: number = this.default_time;

    // Create a group to store the 'nodes'

    let chartGroups = this.chartInner
      .selectAll('g.chartGroup')
      .data(this.dataChart);
    // Exit the nodes
    chartGroups
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(default_time / 2)
      .remove();

    chartGroups
      .enter()
      .append('svg:g')
      .attr('class', 'chartGroup')
      .attr('transform', (d) => {
        return 'translate(0,' + -yScale(d.category) + ')';
      });

    chartGroups = this.chartInner.selectAll('g.chartGroup');
    chartGroups
      .transition()
      .duration(default_time)
      .attr('transform', (d) => {
        return 'translate(0,' + yScale(d.category) + ')';
      });
  }
  private drawBars(): void {
    let yKeys = this.yKeys;
    let yScaleBars = this.yScaleBars;
    let xScale = this.xScale;
    let colorScale = this.colorScale;
    let height = this.height;
    let width = this.width;
    const default_time: number = this.default_time;

    let bars = this.chartInner
      .selectAll('g.chartGroup')
      .selectAll('rect.bar')
      .data((d, i) => {
        return yKeys.map(function (key) {
          return {
            key: key,
            value: !!d[key] ? d[key] : 0,
            category: d['category'],
            index: d['category'],
          };
        });
      });

    bars
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(default_time / 2)
      .remove();

    bars
      .enter()
      .append('svg:rect')
      .attr('class', (d) => {
        return 'bar ' + d.key + ' ' + this.class;
      })
      .attr('x', (d) => {
        return yScaleBars(d.key);
      })
      //If the value is negative, put the top left corner of the rect bar on the zero line
      .attr('y', (d) => {
        //Grouped bar chart, with only POSITIVE values
        // Grouped bar chart, with negative values
        return d.value < 0 ? xScale(0) : xScale(d.value);
      })
      .attr('height', yScaleBars.bandwidth())
      .attr('data-index', (d) => {
        return d.index;
      })
      .attr('width', (d) => {
        return Math.abs(xScale(d.value) - xScale(0));
      })
      .attr('fill', (d) => {
        return colorScale(d.key);
      });

    bars = this.chartInner.selectAll('g.chartGroup').selectAll('rect.bar');

    bars
      .transition()
      .duration(default_time)
      .on('start', (d, i, arr) => {
        d3.select(arr[i])
          .on('mouseover', null)
          .on('mousemove', null)
          .on('mouseout', null)
          .on('click', null);
      })
      .attr('y', (d) => {
        return yScaleBars(d.key);
      })
      .attr('x', (d) => {
        return d.value < 0 ? xScale(d.value) : xScale(0);
      })
      .attr('height', yScaleBars.bandwidth())
      .attr('data-index', (d) => {
        return d.index;
      })
      .attr('width', (d) => {
        return Math.abs(xScale(0) - xScale(d.value));
      })
      .attr('fill', (d) => {
        return colorScale(d.key);
      })
      .on('end', (d, i, arr) => {
        d3.select(arr[i])
          .on('mouseover', () => {
            this.mouseover(d, i, arr);
          })
          .on('mousemove', () => {
            this.mousemove(d, i, arr);
          })
          .on('mouseout', () => {
            this.mouseout(d, i, arr);
          })
          .on('click', null);
      });
  }

  private mouseover(d, i, arr): void {
    d3.select(arr[i]).style('opacity', 0.8);

    this.tooltip
      .style('cursor', 'pointer')
      .style('width', 'auto')
      .style('height', 'auto')
      .style('display', null)
      .style('opacity', 0.9);
  }
  private mousemove(d, i, arr): void {
    // d: {key: "tienda", value: 9999923, category: "Total", index: "tienda_4_Total"}
    // i: 0
    // arr[i]: <rect class="bar" </rect>
    // d3.event.target : <rect class="bar" </rect>
    // d3.event.target.nodeName: rect
    // d3.event: MouseEvent {isTrusted: true, screenX: -405, screenY: 574, clientX: 952, clientY: 440, …}
    // this: GroupedVerticalBarChartComponent

    let text: string = '';
    if (this.tooltipHeader) {
      if (this.tooltipHeader === 'index') {
        text +=
          '<strong>' +
          this.getDictionaryName(
            this.dictionary,
            this.dictionary[0].key,
            d.index
          ) +
          '</strong>' +
          '<br />';
      } else {
        text +=
          '<strong>' +
          this.getDictionaryName(
            this.dictionary,
            this.dictionary[0].key,
            d.key
          ) +
          '</strong>' +
          '<br />';
      }
    }

    if (this.measure && this.measure === 'val') {
      text += this.formatThousands(d.value);
    }
    if (this.measure && this.measure === 'perf') {
      text += this.formatRound_2f(d.value);
      text += '%';
    }
    this.tooltip
      .html(text)
      .style('left', d3.event.pageX - 30 + 'px')
      .style('top', d3.event.pageY - 60 + 'px');
  }
  private mouseout(d, i, arr): void {
    //console.log('outter');
    d3.select(arr[i]).style('opacity', 1);
    this.tooltip.style('opacity', 0).style('display', 'none');
  }
  private drawAxis(): void {
    this.chartWrapper
      .selectAll('.x.axis')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(this.default_time)
      .call(this.xAxis);
    this.chartWrapper
      .selectAll('.y.axis')
      .attr('transform', 'translate(0, 0)')
      .transition()
      .duration(this.default_time)
      .call(this.yAxis);
  }
  private drawAxisTitles(): void {
    // Append axis titles
    this.chartWrapper
      .selectAll('.x.axis')
      .selectAll('.axis_title')
      .data([{}])
      .enter()
      .append('svg:text')
      .attr('class', 'axis_title')
      .attr('text-anchor', 'middle')
      .style('fill', 'rgb(110, 110, 110)')
      .merge(this.chartWrapper.selectAll('.x.axis').selectAll('.axis_title'))
      .text(this.switchTitle(this.xSegment))
      .attr(
        'transform',
        `translate(${this.width / 2}, ${this.margin.bottom / 1.4})`
      );

    this.chartWrapper
      .selectAll('.y.axis')
      .selectAll('.axis_title')
      .data([{}])
      .enter()
      .append('svg:text')
      .attr('class', 'axis_title')
      .attr('text-anchor', 'middle')
      .style('fill', '#rgb(110, 110, 110)')
      .merge(this.chartWrapper.selectAll('.y.axis').selectAll('.axis_title'))
      .text(this.switchTitle(this.ySegment.key))
      .attr(
        'transform',
        `translate(${-this.margin.left / 1.3}, ${this.height / 2}) rotate(-90)`
      );
  }

  private drawZeroLine(): void {
    let zeroLine = this.chartInner.selectAll('line.zeroLine').data([{}]);
    zeroLine
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    zeroLine
      .enter()
      .append('svg:line')
      .attr('class', 'zeroLine')
      .attr('stroke', this.barChart.design.stroke.color);

    zeroLine = this.chartInner.selectAll('.zeroLine');

    zeroLine
      .transition()
      .duration(this.default_time)
      .attr('x1', this.xScale(0))
      .attr('x2', this.xScale(0))
      .attr('y1', 0)
      .attr('y2', this.height);
  }
  private getLegendFromKeys = (arr) => {
    let arrL = arr.length;
    let i = 0;
    let legendFromKeys = [];

    for (i; i < arrL; i++) {
      legendFromKeys.push({
        value: arr[i],
        index: i,
        name: this.capitalize(arr[i]),
      });
    }
    return legendFromKeys;
  };

  private toogleLegendStatud = () => {
    this.singleLegendOn
      ? (this.singleLegendOn = false)
      : (this.singleLegendOn = true);
  };
  private drawLegendGroups() {
    // Create a group to store the 'nodes'
    let legendGroups = this.chartLegend
      .selectAll('g.legendGroup')
      .data(this.legendKeys);

    // Exit the nodes
    legendGroups
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendGroups
      .enter()
      .append('svg:g')
      .attr('class', 'legendGroup')
      .attr('transform', (d) => {
        return 'translate(' + this.legendScale(d.value) + ', 0)';
      });

    legendGroups = this.chartLegend.selectAll('g.legendGroup');
    legendGroups
      .transition()
      .duration(this.default_time)
      .attr('transform', (d) => {
        return 'translate(' + this.legendScale(d.value) + ',0)';
      });
  }
  private drawChartLegend() {
    let legendBar = this.chartLegend
      .selectAll('g.legendGroup')
      .selectAll('rect.legendBar')
      .data((d) => {
        return [d];
      });

    legendBar
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendBar
      .enter()
      .append('svg:rect')
      .attr('class', 'legendBar')
      .attr('transform', (d) => {
        return 'translate(' + 0 + ', 0)';
      })
      .attr('x', 0)
      .attr('y', 2)
      .attr('width', 16)
      .attr('height', 16)
      .attr('fill', (d, i) => {
        // console.log('colorsRect:::::', d);
        return this.colorScale(d.value);
      })
      .style('cursor', 'pointer');

    legendBar = this.chartLegend
      .selectAll('g.legendGroup')
      .selectAll('rect.legendBar');
    legendBar
      .transition()
      .duration(this.default_time)
      .on('start', (d, i, arr) => {
        d3.select(arr[i])
          .on('mouseover', null)
          .on('mousemove', null)
          .on('mouseout', null)
          .on('click', null);
      })
      .attr('transform', (d) => {
        return 'translate(' + 8 + ',0)';
      })
      .attr('fill', (d, i) => {
        // console.log('colorsRect:::::', d);
        return this.colorScale(d.value);
      })
      .on('end', (d, i, arr) => {
        d3.select(arr[i])
          .on('mouseover', () => {
            d3.selectAll('.bar' + '.' + this.class)
              .filter((bar) => {
                return bar.key !== d.value;
              })
              .style('opacity', 0.1);
          })
          .on('mousemove', null)
          .on('mouseout', () => {
            d3.selectAll('.bar' + '.' + this.class)
              .filter((bar) => {
                return bar.key !== d.value;
              })
              .style('opacity', 1);
          })
          .on('click', () => {
            d3.selectAll('.bar').style('opacity', 1);
            this.reshapedata(d.value);
            this.toogleLegendStatud();
          });
      });
    let legendText = this.chartLegend
      .selectAll('g.legendGroup')
      .selectAll('text.legendText')
      .data((d) => {
        return [d];
      });

    legendText
      .exit()
      .attr('class', 'exit')
      .transition()
      .duration(this.default_time / 2)
      .remove();

    legendText
      .enter()
      .append('svg:text')
      .attr('class', 'legendText')
      .attr('transform', (d) => {
        return 'translate(' + 8 + ', 0)';
      })
      .attr('x', 24)
      .attr('y', 16)
      .attr('dy', '0em')
      .attr('dx', '0.1em')
      .text((d, i) => {
        return this.getDictionaryName(
          this.dictionary,
          this.dictionary[0].key,
          d.value
        ); //return the array data
      });

    legendText = this.chartLegend
      .selectAll('g.legendGroup')
      .selectAll('text.legendText');
    legendText
      .transition()
      .duration(this.default_time)
      .attr('transform', (d) => {
        return 'translate(' + 8 + ',0)';
      })
      .text((d, i) => {
        return this.getDictionaryName(
          this.dictionary,
          this.dictionary[0].key,
          d.value
        ); //return the arr;
      });
  }

  /********************************* */
  // THIS IS THE NEW CHART
  /********************************* */
  private drawTooltip = (): void => {
    this.tooltip = this.vBody
      .append('div')
      .attr('class', 'tooltip')
      .attr('id', this.tooltipID)
      .style('z-index', this.tooltipStyle.z_index)
      .style('font-size', this.tooltipStyle.font_size)
      .style('background-color', this.tooltipStyle.background_color)
      .style('padding', this.tooltipStyle.padding)
      .style('position', this.tooltipStyle.position)
      .style('opacity', 0); //  --> tooltip
  };
  private destroyTooltip() {
    this.tooltip = null;
    this.vBody
      .select('#' + this.tooltipID)
      .html('')
      .remove();
  }
  private destroyChart = (): void => {
    d3.select('#' + this.chartId).html('');
    // Stop resize events
    d3.select(this.win).on('resize', null);
  };

  private init(): void {
    this.destroyTooltip();
    this.destroyChart();
    this.runAll();
  }

  ngAfterViewInit(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.init();
      this.barChart['change'] = 'first load';
      this.afterViewInInit = true;
    }, this.resize_delay);
  }
  /********************************* */
  // THIS IS THE NEW CHART
  /********************************* */
  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    //console.log('changes', changes, this.svg, this.chartSelected, this.chartId, this.margin);
    let changeObj = Object.keys(changes);

    this.vBody = d3.select('body');
    this.chartId = this.barChart.key;
    this.class = this.barChart.class;
    this.ySegment = this.barChart.ySegment;
    this.xSegment = this.barChart.xSegment;
    this.getDictionaryName = this.wordingService.getDictionaryName;
    this.formatRound_1f = this.localeEsService.formatRound_1f; // one decimals
    this.formatRound_2f = this.localeEsService.formatRound_2f; // one decimals

    this.formatThousands = this.localeEsService.formatThousands;
    this.default_time = this.defaultVarsService.default_time;
    this.resize_delay = this.defaultVarsService.resize_delay;
    this.getDimensions = this.dimensionsService.getDimensions;
    this.switchTitle = this.axisTitleService.switchTitle;
    this.heightFactor = this.barChart.design.heightFactor;
    this.height = this.barChart.design.height;
    this.capitalize = this.wordingService.capitalize;
    this.margin = this.barChart.design.margin;
    this.style = this.barChart.design.style;
    this.xVal = this.barChart.xVal;
    this.yVal = this.barChart.yVal;
    this.dictionary = this.barChart.dictionary;
    this.viewChartLegend = this.barChart.viewChartLegend;
    this.reshapeService = this.groupedHorizontalBarchartReshapeService.reshape;
    this.tooltipID = this.barChart.tooltip.id;
    this.tooltipHeader = this.barChart.tooltip.header;
    this.tooltipText = this.barChart.tooltip.text;
    this.tooltipStyle = this.barChart.tooltip.style;
    this.measure = this.barChart.yVal[0].key;

    if (this.afterViewInInit) {
      this.barChart['change'] = 'selects';
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.reshapedata();
      }, this.resize_delay / 4);
    }
  }

  ngOnInit(): void { }
}
