import { Component } from '@angular/core';
import { SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { EgmAimcStatusService } from '@compass/egm-aimc/data-access';
import { AimcService } from '@compass/aimc/data-access';

@Component({
  selector: 'glo-stage-egm',
  templateUrl: './stage-egm.component.html',
  styleUrls: ['./stage-egm.component.scss'],
})
export class StageEgmComponent {
  activeTableLevel: number;
  parentIndex: number = 0;
  childIndex: number = 0;

  media = [
    {
      key: 'egm',
      value: 'EGM',
      icon: 'pie_chart',
      children: [
        {
          key: 'audiencia_general',
          value: 'Audiencia General de Medios',
          tipologia: 'AUDIENCIA GENERAL DE MEDIOS U.P.',
        },
        {
          key: 'radio',
          value: 'Radio',
          tipologia: 'AUDIENCIA DÍA DE AYER CADENAS(EMISORAS CON PROGR.COMÚN)',
        },
        {
          key: 'diarios',
          value: 'Diarios',
          tipologia: 'LECTORES DE DIARIOS U.P.',
        },
        {
          key: 'revistas_bimestrales',
          value: 'Revistas bimestrales',
          tipologia: 'LECTORES U.P. DE REVISTAS BIMESTRALES',
        },
        {
          key: 'revistas_mensuales',
          value: 'Revistas mensuales',
          tipologia: 'LECTORES U.P. DE REVISTAS MENSUALES',
        },
        {
          key: 'revistas_quincenales',
          value: 'Revistas quincenales',
          tipologia: 'LECTORES U.P. DE REVISTAS QUINCENALES',
        },
        {
          key: 'revistas_semanales',
          value: 'Revistas semanales',
          tipologia: 'LECTORES U.P. DE REVISTAS SEMANALES',
        },
        {
          key: 'suplementos',
          value: 'Suplementos',
          tipologia: 'LECTORES U.P. DE SUPLEMENTOS',
        },
        { key: 'internet', value: 'Internet', tipologia: 'SITIOS AYER' },
        {
          key: 'exterior',
          value: 'Soportes de exterior',
          tipologia: 'SOPORTES EN LOS QUE HA VISTO PUBLICIDAD ÚLTIMA SEMANA',
        },
      ],
    },
    {
      key: 'aimc',
      value: 'AIMC',
      icon: 'bar_chart',
      children: [
        { key: 'aimc_matrix', value: 'Matriz de calor', tipologia: '' },
        {
          key: 'medio_indispensable',
          value: 'Medio indispensable',
          tipologia:
            'COMO CONCLUSIÓN, EL MEDIO DE COMUNICACIÓN INDISPENSABLE PARA MÍ ES:',
        },
        {
          key: 'atencion_anuncios',
          value: 'Atención a los anuncios',
          tipologia: 'DÓNDE LE PRESTA USTED MÁS ATENCIÓN A LOS ANUNCIOS',
        },
        {
          key: 'decision_compra',
          value: 'Decisión de compra',
          tipologia:
            'DÓNDE LE SON MÁS ÚTILES LOS ANUNCIOS PARA AYUDARLE A DECIDIR SUS COMPRAS',
        },
        {
          key: 'distraer',
          value: 'Función: Distraer',
          tipologia:
            'MEDIO QUE CUMPLE MEJOR LA FUNCIÓN:DISTRAER, DIVERTIR, ENTRETENER',
        },
        {
          key: 'informacion_practica',
          value: 'Función: Información práctica',
          tipologia:
            'MEDIO QUE CUMPLE MEJOR LA FUNCIÓN:ENCONTRAR INFORMACIONES PRÁCTICAS, CONSEJOS',
        },
        {
          key: 'alimentacion',
          value: 'Alimentación y bebidas',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE ALIMENTACIÓN Y BEBIDAS',
        },
        {
          key: 'automoviles',
          value: 'Automóviles',
          tipologia: 'MEDIO UTILIZADO PARA INFORMARSE SOBRE AUTOMÓVILES',
        },
        {
          key: 'electrodomesticos',
          value: 'Electrodomésticos',
          tipologia: 'MEDIO UTILIZADO PARA INFORMARSE SOBRE ELECTRODOMÉSTICOS',
        },
        {
          key: 'establecimientos_comerciales',
          value: 'Establecimientos comerciales',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE ESTABLECIMIENTOS COMERCIALES',
        },
        {
          key: 'informatica',
          value: 'Informática y telefonía',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE INFORMÁTICA Y TELEFONÍA',
        },
        {
          key: 'moda',
          value: 'Moda, ropa, calzado',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE MODA, ROPA Y CALZADO',
        },
        {
          key: 'ocio',
          value: 'Ocio y espectáculos',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE OCIO Y ESPECTÁCULOS',
        },
        {
          key: 'belleza',
          value: 'Belleza y cosméticos',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE BELLEZA Y COSMÉTICOS',
        },
        {
          key: 'decoracion',
          value: 'Decoración y hogar',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE DECORACIÓN Y HOGAR',
        },
        {
          key: 'limpieza',
          value: 'Productos limpieza',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS DE LIMPIEZA',
        },
        {
          key: 'farmacia',
          value: 'Productos farmaceúticos',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS FARMACÉUTICOS',
        },
        {
          key: 'finanzas',
          value: 'Productos financieros',
          tipologia:
            'MEDIO UTILIZADO PARA INFORMARSE SOBRE PRODUCTOS FINANCIEROS',
        },
        {
          key: 'viajes',
          value: 'Viajes',
          tipologia: 'MEDIO UTILIZADO PARA INFORMARSE SOBRE VIAJES',
        },
      ],
    },
  ];

  constructor(
    private sidenavPoisLevelsService: SidenavPoisLevelsService,
    private egmAimcStatusService: EgmAimcStatusService,
    private aimcService: AimcService
  ) {
    this.sidenavPoisLevelsService
      .getActiveTableLevel()
      .subscribe((value: number) => {
        this.activeTableLevel = value;
      });
  }
  onClickMedia(item, index, event) {
    window.scroll(0, 0);
    if (item.key === 'egm') {
      this.egmAimcStatusService.setEgmOn(true);
      this.egmAimcStatusService.setAimcOn(false);
    }
    if (item.key === 'aimc') {
      this.egmAimcStatusService.setEgmOn(false);
      this.egmAimcStatusService.setAimcOn(true);
    }

    this.parentIndex = index;
    if (event.target.classList.contains('parent')) {
      this.childIndex = 0;
      this.updateObservable(item.key, this.media[index]['children'][0]);
    }
    return false;
  }
  onClickChild(type, index, parent) {
    window.scroll(0, 0);
    this.updateObservable(parent, type);
    this.childIndex = index;
    return false;
  }

  updateObservable(parent: string, type: any) {
    if (parent === 'aimc') {
      this.aimcService.setSurveyType(type);
    } else {
      this.egmAimcStatusService.setSurveyType(type);
    }
  }
}
