import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavStatusService } from './sidenav-status.service';
import { SideNavStagesService } from './sidenav-stages.service';
import { MarkerOptionsApiService } from './marker-options-api.service';
import { SideNavPlacesService } from './sidenav-places.service';
import { CrudMenuService } from './crud-menu.service';
import { SideNavIndicatorsService } from './sidenav-indicators.service';
import { SidenavTransitService } from './sidenav-transit.service';
import { SideNavMatTabChangeService } from './sidenav-mat-tab-change.service';
import { CatastroStatusService } from './catastro-status.service';
import { OnclickStageShapeService } from './onclick-stage-shape.service';
import { GeoCoordsService } from '@compass/geo-coords/data-access';

@NgModule({
  imports: [CommonModule],
  providers: [
    SideNavStatusService,
    SideNavStagesService,
    MarkerOptionsApiService,
    SideNavPlacesService,
    CrudMenuService,
    SideNavIndicatorsService,
    SidenavTransitService,
    SideNavMatTabChangeService,
    CatastroStatusService,
    OnclickStageShapeService,
    GeoCoordsService,
  ],
})
export class UtilsNavigationModule { }
