import { Component, Injectable, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BestTimesService } from '@compass/best-times';
import { PoisComparatorService } from '@compass/pois/comparator';
import { PoiBasketService } from '@compass/shared/poi-basket';

@Component({
  selector: 'compass-pois-extended-data-preview',
  templateUrl: './pois-extended-data-preview.component.html',
  styleUrls: ['./pois-extended-data-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({ providedIn: 'root' })
export class PoisExtendedDataPreviewComponent implements OnChanges {
  @Input() marker;
  openingDays: any;
  inComparator: boolean = false;
  inBasket: boolean = false;

  constructor(
    public bestTimeService: BestTimesService,
    public comparatorService: PoisComparatorService,
    public basketService: PoiBasketService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.marker) {
      this.openingDays = this.setOpeningHour();
      this.inComparator = this.comparatorService.isSelected(this.marker.actualMarker.info);
      this.inBasket = this.basketService.inBasket(this.marker.actualMarker.info);

      if (this.openingDays) {
        while (this.openingDays[0][0] != 'Lunes') {
          this.openingDays.unshift(this.openingDays.pop());
        }
      }
    }
  }

  roundNumber(number:number){
    return Math.round(number);
  }

  toggleComparator() {
    const poi = this.marker?.actualMarker?.info;

    if(poi) {
      this.comparatorService.toggle(poi);
      this.inComparator = this.comparatorService.isSelected(poi);
    }
  }

  toggleBasket() {
    const poi = this.marker?.actualMarker?.info;

    if(poi) {
      if(this.inBasket) {
        this.basketService.remove(poi);
      } else {
        this.basketService.add(poi);
      }

      this.inBasket = this.basketService.inBasket(poi);
    }
  }

  getRatingStars(n) {
    let response;

    switch (Math.round(n)) {
      case 0:
        response = '';
        break;
      case 1:
        response = '⭐';
        break;
      case 2:
        response = '⭐⭐';
        break;
      case 3:
        response = '⭐⭐⭐';
        break;
      case 4:
        response = '⭐⭐⭐⭐';
        break;
      case 5:
        response = '⭐⭐⭐⭐⭐';
        break;
      default:
        response = 'Sin Puntuación';
        break;
    }

    return response;
  }

  setOpeningHour() {
    let result = undefined;
    if (this.marker.actualMarker.info.properties.goo?.horarios_text) {
      let split;
      result = [];

      this.marker.actualMarker.info.properties.goo.horarios_text.forEach((day: any) => {
        split = day.trim().split(',');
        split[0] = split[0].charAt(0).toUpperCase() + split[0].slice(1);
        split[1] = split[1]?.split('.')[0] ?? '';
        split[2] = split[2]?.split('.')[0] ?? '';

        result.push(split);
      });
    }
    return result;
  }
}
