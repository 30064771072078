import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { StageBreadcrumbsComponent } from './stage-breadcrumbs/stage-breadcrumbs.component';
import { PoisAccordionComponent } from "./pois-accordion/pois-accordion.component";
import { StudiesToggleComponent } from "./studies-toggle/studies-toggle.component";
import { StudiesCrudMenuComponent } from './studies-crud-menu/studies-crud-menu.component';
import { SlideToggleService } from './slide-toggle/slide-toogle.service';
import { MaterialModule } from '@compass/material'
import { FormsModule } from '@angular/forms';
import { SelectLayerLoaderComponent } from './select-layer-loader/select-layer-loader.component';
import { SelectLayerGroupComponent } from './select-layer-group/select-layer-group.component';
import { SelectLayerGroupService } from './select-layer-group/select-layer-group.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FullScreenVideoComponent } from './full-screen-video/full-screen-video.component';
@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [SlideToggleComponent, StageBreadcrumbsComponent, PoisAccordionComponent, StudiesToggleComponent, SelectLayerLoaderComponent, SelectLayerGroupComponent, StudiesCrudMenuComponent, FullScreenVideoComponent],
  exports: [SlideToggleComponent, StageBreadcrumbsComponent, PoisAccordionComponent, StudiesToggleComponent, SelectLayerLoaderComponent, SelectLayerGroupComponent, StudiesCrudMenuComponent, FullScreenVideoComponent],
  providers: [SlideToggleService, SelectLayerGroupService]
})
export class SharedWidgetsModule { }
