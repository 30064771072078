import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@compass/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { InfluencersLoaderComponent } from './influencers-loader/influencers-loader.component';
import { InfluencersFormComponent } from './influencers-form/influencers-form.component';
import { InfluencersAccordionComponent } from './influencers-accordion/influencers-accordion.component';
import { InfluencersApiService } from './influencers-loader/influencers-api.service';
import { InfluencersReshapeService } from './influencers-reshape.service';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [InfluencersLoaderComponent, InfluencersFormComponent, InfluencersAccordionComponent],
  exports: [InfluencersLoaderComponent, InfluencersFormComponent, InfluencersAccordionComponent],
  providers: [InfluencersApiService, InfluencersReshapeService]
})
export class InfluencersModule { }
