import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'compass-presence',
  templateUrl: './presence.component.html',
  styleUrls: ['./presence.component.scss']
})
export class PresenceComponent {
  @Input() cards: any = [];

  constructor() {
  }



}
