import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//import { BestTimesListComponent } from './best-times-list/best-times-list.component';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PoiService } from '@compass/pois/data-access-poi';
import { LocationService } from '@compass/utils/misc';
import { BestTimesListComponent } from './best-times-list/best-times-list.component';

@Injectable({
  providedIn: 'root'
})
export class BestTimesService {
  options$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectedPoi$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialogClosed$: EventEmitter<any> = new EventEmitter<any>();
  updated$: EventEmitter<any> = new EventEmitter<any>();

  oppenedDialog: any;
  loading: boolean = false;

  scriptAdded = false;

  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
    public poiService: PoiService,
    public locationService: LocationService
  ) {
  }


  public loadAutocompleteImportScript() {
    if (!this.scriptAdded) {
      let chatScript = document.createElement('script');
      chatScript.type = 'text/javascript';
      chatScript.async = true;
      chatScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBZ7Sjr9Ah4SEJpoHD-kMQHjrds_SIiCUI&libraries=places&solution_channel=GMP_QB_addressselection_v1_cA';
      document.body.appendChild(chatScript);
    }

    this.scriptAdded = true;
  }

  /**
   * Deletes all residual containers generated by google autocomplete api
   */
  public deleteAutocompleteList() {
    const elements: any = document.getElementsByClassName('pac-container');
    for (let el of elements) {
      el.remove();
    }
  }

  showOptionsFor(poi: any) {
    this.selectedPoi$.next(poi);
    this.oppenedDialog = this.dialog.open(BestTimesListComponent, {
      minWidth: '480px',
      maxWidth: '100%'
    });

    this.oppenedDialog.afterClosed().subscribe(() => {
      this.options$.next([]);
      this.oppenedDialog = null;
      this.dialogClosed$.emit();
    });
  }

  closeDialog() {
    this.oppenedDialog.close();
    this.dialogClosed$.emit();
  }

  loadList() {
    this.loading = true;
    const poi = this.selectedPoi$.value;
    const address = `${poi.properties.nombre} - ${poi.properties.direccion}`;

    return this.locationService.getApiAddress(address).pipe(
      map((response: any) => {
        const results = response?.results ?? [];
        this.loading = false;
        return results.filter(data => {
          return data.types.includes('point_of_interest') || data.types.includes('establishment'); // if is not only adress
        });
      })
    );
  }

  updatePoi(poi: any) {
    delete poi._id;
    return this.http.put(`/compass-api/api/popular_time/point_poi`, poi);
  }

  updatePopularTime(id: any) {
    const request = this.http.post('/compass-api/api/popular_time/update_popular_times', {
      'id': id,
      'app': 'glocally',
      'collection_name': 'point_poi'
    }).pipe(map((res: any) => {
      this.fixBesttimesData(res?.properties?.id ? res : null);
      return res;
    }));

    return request;
  }

  fixBesttimesData(data: any) {
    if (data?.properties?.id) {
      this.loading = true;

      data.properties.goo.popular_time.days = this.getSortedDays(data);
      data.properties.besttime_fixed = true;

      this.updatePoi(data).subscribe(res => {
        this.updated$.emit(data);
        this.loading = false;
        this.closeDialog();
      }, error => {
        console.error(error);
        this.loading = false;
      });
    }
  }

  public getCleanDirection(name: string, direction: string) {
    // remove stuff in brackets
    direction = direction.replace(/\((.+?)\)/g, '');
    name = name.replace(/\((.+?)\)/g, '');

    // to lower case
    direction = direction.toLowerCase();

    // remove special chars
    direction = direction.replace(/[^a-z0-9 ]/gi, '');

    // remove street type name
    direction = this.removeFromString(direction, ['avenida', 'avda', 'calle', 'cl', 'plaza', 'plz', 'pl']);

    // remove prepositions and articles
    direction = this.removeFromString(direction, ['a', 'con', 'de', 'desde', 'en']);
    direction = this.removeFromString(direction, ['el', 'los', 'la', 'las', 'un', 'unos', 'una', 'unas']);

    direction = name + ' ' + direction;
    direction = direction.replace(/\s{2,}/g, ' ');
    direction = direction.trim();

    return direction;
  }

  private removeFromString(str, arr) {
    let regex = new RegExp('\\b' + arr.join('|') + '\\b', 'gi');
    return str.replace(regex, '');
  }

  private getSortedDays(d) {
    var daysArray = this.objectToArray(d.properties.goo.popular_time.days);

    // sort days
    // daysArray.push(daysArray.shift());
    daysArray = [...daysArray.slice(daysArray.length - 1), ...daysArray.slice(0, daysArray.length - 1)];

    // sort hours (+6h more)
    daysArray = daysArray.map((d) => {
      var hoursArray = this.objectToArray(d);
      var sortedHours = [];
      sortedHours = sortedHours.concat(hoursArray.slice(18, hoursArray.length));
      sortedHours = sortedHours.concat(hoursArray.slice(0, 18));

      return this.arrayToObject(sortedHours);
    });

    return this.arrayToObject(daysArray);
  }

  // reconvert to object sorted days
  private arrayToObject(array) {
    var object = {};
    array.forEach(function(d, i) {
      object[i] = d;
    });

    return object;
  }

  // convert index object to array
  private objectToArray(object) {
    const array = [];
    Object.keys(object).forEach(function(key) {
      array.push(object[key]);
    });

    return array;
  }

}
