import { Component } from '@angular/core';
import { BricksApiService } from '@compass/brick-api';
import { Poi, PoiService, SidenavPoisLevelsService } from '@compass/pois/data-access-poi';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'glo-stage-pois',
  templateUrl: './stage-pois.component.html',
  styleUrls: ['./stage-pois.component.css']
})
export class StagePoisComponent {
  accordionSetup = {
    title: 'Grupos/POI\'s',
    opened: true,
    closed: false,
    expanded: true
  };
  selection:any;

  constructor(
    private bricksApiService: BricksApiService,
    private poiService: PoiService,
    public sidenavPoisLevelsService: SidenavPoisLevelsService
  ) {
    this.poiService.getAreaGlobal(this.bricksApiService.fullStages$.value);
    this.selection = new SelectionModel<Poi>(true, []);

  }
  getSearchSelection(event: any) {
    this.selection = event
  }
}
