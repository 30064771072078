<button
  mat-flat-button
  class="button--xl"
  type="cancel"
  [disabled]="this.createStudyService.creating$ | async"
  (click)="onClickCancelButton()"
>
  <mat-icon>cancel</mat-icon>
  Cancelar
</button>
