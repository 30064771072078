import { Injectable } from "@angular/core";
import { TooltipStyleService } from "../tooltipStyle.service";
import { ColorsService } from "@compass/utils/misc";


@Injectable()
export class GroupedHorizontalBarLayoutService {

    colors: any = this.colorsService.colors;
    tooltipValues: any = this.tooltipStyleService.tooltipValues;
    layoutSelected: any;

    constructor(
        private colorsService: ColorsService,
        private tooltipStyleService: TooltipStyleService
    ) {

    }

    public layouts: any[] = [
        {
            // vertical bar chart
            key: "horizontal_bars",
            class: "media-egm",
            section: "egm",
            design: {
                height: 78,
                width: null,
                heightFactor: 24,
                margin: { top: 36, right: 16, bottom: 42, left: 184 },
                stroke: {
                    width: 0.2,
                    color: this.colors.black,
                },
                style: {
                    colors: [
                        this.colors.purple,
                        this.colors.gray_light,
                        this.colors.sky,
                        this.colors.red
                    ],
                    paddingInner: 0.2,
                    padding: 0.05,
                    align: 0.1,
                    legend: {
                        fontSize: "10px",
                    },
                    averages: {},
                },
            },
            viewChartLegend: true,
            xVal: {
                name: null,
            },
            yVal: [
                { key: "perf", name: "Porcentaje", value: "% variación" },
            ],
            dictionary: [
                {
                    values: null,
                    key: "long_title",
                },
            ],
            tooltip: {
                id: "horizontal_bars_tooltip",
                style: this.tooltipValues,
                header: true,//   key [string, "personas_hogar"], value [number], category[string, "Resto"] or index[string, "tienda_3_Resto"]
                text: null
            },
        }
    ];

    public getLayout(key: string): any {
        let layout = this.layouts.filter((l) => {
            return l.key === key;
        });
        return layout;
    }
}
