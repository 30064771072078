import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable({ providedIn: 'root' })
export class StartLeftSidenavService {

  // see start-left-button-open
  public compassIsOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // sidenav Opens
  public sidenavIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(private http: HttpClient) {
    this.compassIsOn.next(JSON.parse(localStorage.getItem('compassIsOn')));
    this.sidenavIsOpen.next(JSON.parse(localStorage.getItem('sidenavIsOpen')));
  }

  /* MAIN STATUS FUNCTIONS */

  // see start-left-button-open
  setCompassStatus(status) {
    if (typeof status === 'boolean') {
      localStorage.setItem('compassIsOn', JSON.stringify(status));
      this.compassIsOn.next(status);
    }
  }

  getCompassStatus(): Observable<boolean> {
    return this.compassIsOn.asObservable();
  }

  /** App knows a new Study has started, open start left Menu*/
  setSidenavIsOpen(status) {
    if (typeof status === 'boolean') {
      localStorage.setItem('sidenavIsOpen', JSON.stringify(status));
      this.sidenavIsOpen.next(status);
    }
  }

  getSidenavIsOpen(): Observable<boolean> {
    return this.sidenavIsOpen.asObservable();
  }

  open() {
    this.sidenavIsOpen.next(true);
  }

  close() {
    this.sidenavIsOpen.next(false);
  }

  toggle(status: boolean) {
    this.sidenavIsOpen.next(status);
  }
}
