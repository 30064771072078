import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineTransitComponent } from './line-transit/line-transit.component';
import { MaterialModule } from '@compass/material';
import { LineTransitService } from '@compass/line-transit/data-access';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [LineTransitComponent],
  exports: [LineTransitComponent],
  providers: [LineTransitService],
})
export class LineTransitFeatureDrawerModule {}
