import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glo-stage-popular-times',
  templateUrl: './stage-popular-times.component.html',
  styleUrls: ['./stage-popular-times.component.scss']
})
export class StagePopularTimesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
