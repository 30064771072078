import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import * as L from 'leaflet';
import {FormControl, FormGroup} from '@angular/forms';
import {CompassMapWrapperService} from '@compass/utils/leaflet';
import {SidenavPoisLevelsService} from '@compass/pois/data-access-poi';
import {PopularTimesReshapeService} from '../services/popular-times-reshape.service';
import {PopularTimesFormService} from '../services/popular-times-form.service';


function onClickVenueMarker(event) {
  console.log(event);
  return false;
}

@Component({
  selector: 'compass-popular-times-form',
  templateUrl: './popular-times-form.component.html',
  styleUrls: ['./popular-times-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopularTimesFormComponent implements OnDestroy {

  choices_days = this.popularTimesReshapeService.choices_days;
  choices_hours = this.popularTimesReshapeService.choices_hours;

  form: FormGroup;
  map: any;
  pois: any;
  filteredPois: any;
  venuemarkers: L.LayerGroup = new L.LayerGroup(); //new Array();

  dataTimeline: any = {
    day: 0,
    startHour: 0,
    endHour: 23,
    speed: 500,
    minRating: 0,
    maxRating: 5
  };

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private sidenavPoisLevelService: SidenavPoisLevelsService,
    private popularTimesReshapeService: PopularTimesReshapeService,
    private popularTimesFormService: PopularTimesFormService,
  ) {
    this.form = new FormGroup({
      timeline: new FormControl('0'),
      day: new FormControl('1'), // by hours
      days: new FormControl(['1']), // by days, multiple selection
      startHour: new FormControl('0'),
      endHour: new FormControl('23'),
      hidePois: new FormControl(true),
      maxRating: new FormControl('5'),
      minRating: new FormControl('0'),
      review: new FormControl(),
      velocity: new FormControl('500')
    });
    this.pois = this.sidenavPoisLevelService.getSelectedPlainNodes().filter(poi => poi.properties?.goo?.popular_time);
    this.filteredPois = this.pois;

    this.compassMapWrapperService.getMap().subscribe(map => {
      this.map = map;
    });

    this.form.valueChanges.subscribe((f) => {
      if (this.dataTimeline.maxRating != this.form.controls.maxRating.value
        || this.dataTimeline.minRating != this.form.controls.minRating.value) {

        this.filterPois();
      }

      this.dataTimeline = {
        timeline: +f.timeline,
        day: +f.day,
        days: f.days,
        maxRating: +f.maxRating,
        minRating: +f.minRating,
        startHour: +f.startHour,
        endHour: +f.endHour,
        speed: +f.velocity,
      };
      this.popularTimesFormService.setForm(this.dataTimeline);

    });

    this.form.controls.days.valueChanges.subscribe((v) => {
      if (v.length < 2) {
        // the relationship between data array and the week day is on the form control day array.
        // zero is Sunday, but from now, it will be at last position of the data array
        this.form.controls.days.setValue(['1', '2', '3', '4', '5', '6', '0'], {emitEvent: false});
        return false;
      }

      const result = [];
      const start = +v[0];
      const end = +v[v.length - 1] === 0 ? 7 : +v[v.length - 1];
      const blanks = end - start;

      for (let i = 0; i <= blanks; i++) {
        if ((+v[0] + i) === 7) {
          result.push('0')
        } else {
          result.push((+v[0] + i).toString());
        }

      }

      this.form.controls.days.setValue(result, {emitEvent: false});

    })

    this.form.controls.hidePois.valueChanges.subscribe(() => {
      this.hideLayers();
    });
    this.hideLayers();

  }

  filterPois() {
    this.filteredPois = this.pois.filter(poi => {
      if (poi.properties.goo.rating) {
        return Math.round(poi.properties.goo.rating) <= this.form.controls.maxRating.value
          && Math.round(poi.properties.goo.rating) >= this.form.controls.minRating.value;
      }
      return false;
    });

  }

  ngOnDestroy(): void {
    this.form.reset({
      timeline:  '0',
      day:  '1', // by hours
      days:  ['1'], // by days, multiple selection
      startHour:  '0',
      endHour:  '23',
      hidePois:  true,
      maxRating:  '5',
      minRating:  '0',
      velocity:  '500'

    })
    if(this.map.pois.global_pois){
      this.map.box.addLayer(this.map.pois.global_pois);
    }
    if (this.map.pois.BASKET) {
      this.map.box.addLayer(this.map.pois.BASKET);
    }
  }

  hideLayers() {
    if (this.form.controls.hidePois.value) {
      if (this.map.box.hasLayer(this.map.pois.global_pois))
        this.map.box.removeLayer(this.map.pois.global_pois);
      if (this.map.box.hasLayer(this.map.pois.BASKET))
        this.map.box.removeLayer(this.map.pois.BASKET);
    } else {
      this.map.box.addLayer(this.map.pois.global_pois);
      this.map.box.addLayer(this.map.pois.BASKET);
    }
  }


  addVenueMarkers(pois) {
    let marker;
    pois.forEach((poi: any) => {
      marker = L.layerGroup([L.marker([poi.geometry.coordinates[1], poi.geometry.coordinates[0]],
        {
          opacity: 100
          // title: tabledata[i].venue_name + ", " + tabledata[i].venue_address
        }).on('click', onClickVenueMarker)]);
      marker.addLayer(this.venuemarkers).addTo(this.map.box);

    });

  };



  reshapeData(data) {

    // return only lat, lng and days
    const googleDays = data.map((d: any) => {
      return {
        lat: d.geometry.coordinates[1],
        lng: d.geometry.coordinates[0],
        days: d.properties.goo.popular_time.days
      };

    });
    // return [ DAY [ HOURS [ POIS ] ] ]
    // DAYS start at 0 is SUNDAY
    // [ HOURS [ POIS ] ] is used to animate map
    // HOURS start at 0 is 6:00am.
    const daysData = this.getAllDaysDataByHours(googleDays);
    const weekData = this.sumWeekDaysData(daysData);
    const weekMediaData = this.mediaWeekDaysData(weekData);
    daysData.push(weekMediaData); // 7 index is the sum of all week day and divided by 7
    return daysData; // all 7 days week [0 Sunday - 6 Saturday] data by hours [0-23] and pois [lat, lgn, count]

  }


  getAllDaysDataByHours(data: any) {
    const temp: Array<Array<Array<any>>> = []; // day,hour, pois

    this.choices_days.forEach((day: any, day_index: number) => {
      temp.push([]); // one day
      this.choices_hours.forEach((hour: any, hour_index) => {
        temp[day_index].push([]); // 24 hours

        data.forEach((d: any) => {

          temp[day_index][hour_index].push({
            lat: d.lat,
            lng: d.lng,
            count: this.isPrimitive(d.days[day_index]) ? 0 : d.days[day_index][hour[2]] ? d.days[day_index][hour[2]] : 0
          });
        });
      });
    });
    return temp;
  }

  isPrimitive(o: any) {
    return typeof o !== 'object' || null;
  };

  sumWeekDaysData(data: any) {
    const result: any = [];

    data.forEach((day: [][], day_index: number) => {
      day.forEach((hour: [], hour_index: number) => {
        if (day_index === 0) {
          result.push([]);
        }
        hour.forEach((poi: any, poi_index: number) => {
          if (day_index === 0) {
            result[hour_index].push(
              {
                lat: data[day_index][hour_index][poi_index].lat,
                lng: data[day_index][hour_index][poi_index].lng,
                count: data[day_index][hour_index][poi_index].count
              }
            );
          } else {
            result[hour_index][poi_index].count += data[day_index][hour_index][poi_index].count;
          }
        });
      });
    });

    return result;
  }

  mediaWeekDaysData(hours: [][]) {
    return hours.map((hour: any[]) => {
      hour.map((poi) => {
        poi.count = Math.round(poi.count / 7);
        return poi;
      });
      return hour;
    });
  }

  getDayIndex(configuration: any) {
    return configuration.day_window_start_int === 0 && configuration.day_window_end_int === 6 ? 7 : configuration.day_window_start_int;
  }

}
