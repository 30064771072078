import { Component, OnInit, Input } from '@angular/core';
import { SearchboxService } from './searchbox.service'

@Component({
  selector: 'compass-map-searchbox',
  templateUrl: './map-searchbox.component.html',
  styleUrls: ['./map-searchbox.component.scss']
})
export class MapSearchboxComponent implements OnInit {
  @Input() setup: any;
  searchQuery: string;

  constructor(private searchboxService: SearchboxService) { }

  searchboxDescriptive: any = () => {
    //return false;    
    let params = this.searchQuery;
    this.searchboxService.searchboxDescriptive(params);
  };


  onKeydown(event: any) {
    console.log(event);
  }

  ngOnInit(): void { }

}
