import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@compass/shared/feature-auth';
import { RoleGuard } from '@compass/shared/data-access-role-guard';

const routes: Routes = [
  { path: '', redirectTo: '/estudio', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'theme-demo',
    loadChildren: () =>
      import('./sass/theme-demo/theme-demo.module').then(
        (m) => m.ThemeDemoModule
      ),
  },
  {
    path: 'estudio',
    loadChildren: () =>
      import('./estudio/estudio.module').then((m) => m.EstudioModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'estudios',
    loadChildren: () =>
      import('./storage/crud-studies/crud-studies.module').then(
        (m) => m.CrudStudiesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pois',
    loadChildren: () => import('./pois/pois.module').then((m) => m.PoisModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'categorias',
    loadChildren: () =>
      import('./storage/crud-categories/crud-categories.module').then(
        (m) => m.CrudCategoriesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('./usuarios/usuarios.module').then((m) => m.UsuariosModule),
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'pdf_report',
    loadChildren: () =>
      import('./pdf-report/pdf-report.module').then((m) => m.PdfReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patrimonios',
    loadChildren: () =>
      import('./patrimonios/patrimonios.module').then(
        (m) => m.PatrimoniosModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
