import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class IdealistaReshapePoisService {
  public reshape(formControlValues, poisTableList) {
    let keys = Object.keys(formControlValues);
    let result = poisTableList;

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'type') {
        result = result.filter(
          (poi) => poi.properties.type.toLowerCase() === formControlValues.type.toLowerCase()
        );
      }

      if (keys[i] === 'agency') {
        result = result.filter((poi) => {
          if (formControlValues.agency === 3) {
            // todos
            return true;
          }
          if (formControlValues.agency === 2) {
            // Particular
            if (
              !poi.properties.profesional ||
              poi.properties.profesional === ''
            ) {
              return true;
            }
            return false;
          }
          if (formControlValues.agency === 1) {
            // profesional
            if (
              poi.properties.profesional ||
              poi.properties.profesional !== ''
            ) {
              return true;
            }
            return false;
          }
        });
      }

      if (keys[i] === 'category') {
        result = result.filter((poi) => {
          if (
            poi.properties.category.toLowerCase() ===
              formControlValues.category ||
            formControlValues.category === 'all'
          ) {
            return true;
          }
          return false;
        });
      }

      if (keys[i] === 'price_min') {
        result = result.filter((poi) => {
          if (poi.properties.price >= formControlValues.price_min) {
            return true;
          }
          return false;
        });
      }

      if (keys[i] === 'price_max') {
        result = result.filter((poi) => {
          if (poi.properties.price <= formControlValues.price_max) {
            return true;
          }
          return false;
        });
      }

      if (keys[i] === 'size_min') {
        result = result.filter((poi) => {
          if (poi.properties.size >= formControlValues.size_min) {
            return true;
          }
          return false;
        });
      }

      if (keys[i] === 'size_max') {
        result = result.filter((poi) => {
          if (poi.properties.size <= formControlValues.size_max) {
            return true;
          }
          return false;
        });
      }

      if (keys[i] === 'rooms') {
        result = result.filter((poi) => {
          if (
            (formControlValues.rooms < 6 &&
              formControlValues.rooms === poi.properties.rooms) ||
            formControlValues.rooms === 'all'
          ) {
            return true;
          }
          if (
            formControlValues.rooms === 6 &&
            poi.properties.rooms >= formControlValues.rooms
          ) {
            return true;
          }
          return false;
        });
      }
    }

    return result;
  }
}
