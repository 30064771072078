import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'compass-start-left-button-close',
  templateUrl: './start-left-button-close.component.html',
  styleUrls: ['./start-left-button-close.component.scss']
})
export class StartLeftButtonCloseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
