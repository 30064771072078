<div *ngIf="hour && chartLayout">

    <app-grouped-vertical-barchart [hour]="hour" [chartLayout]="chartLayout">
    </app-grouped-vertical-barchart>
</div>

<div *ngIf="hour && circlesLayout">
    <p class="mb-2"><strong>Ingresos </strong>(Volúmen en %)</p>
    <app-inline-circles-chart [hour]="hour" [circlesLayout]="circlesLayout"></app-inline-circles-chart>
</div>
