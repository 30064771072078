<div class="padding__basic">
  <div class="indicator" *ngFor="let indicator of indicators" fxLayout="column">
    <mat-checkbox
      [(ngModel)]="indicator.active"
      class="indicator-title"
      (ngModelChange)="toggleIndicator(indicator)"
      [disabled]="indicator.active"
      [matTooltip]="indicator.name"
      matTooltipPosition="right"
    >
      <strong>{{ indicator.screen_name }}</strong>
    </mat-checkbox>

    <div fxFlex="95" fxFlexOffset="2.5" fxLayout="row">
      <div fxFlex="60" fxLayout="row" fxFlexAlign="center" style="flex-wrap: wrap">
        <div class="text__align--center">
          De
          <input
            class="range-input"
            matInput
            type="number"
            [step]="1"
            size="20"
            min="{{ indicator.values.floor }}"
            max="{{ indicator.values.ceil }}"
            [(ngModel)]="indicator.minValue"
            (ngModelChange)="showAnalytics(indicator)"
          />
        </div>

        <div class="text__align--center">
          a
          <input
            class="range-input"
            matInput
            type="number"
            [step]="1"
            min="{{ indicator.minValue }}"
            max="{{ indicator.values.ceil }}"
            [(ngModel)]="indicator.maxValue"
            (ngModelChange)="showAnalytics(indicator)"
          />
        </div>
      </div>
      <div
        fxFlex="40"
        class="text__align--center"
        fxFlexAlign="center"
        fxLayout="column"
      >
        <strong>Media</strong>
        <strong class="average">
          {{indicator.average | number: '1.2-2': 'es-ES'}}</strong>
      </div>
    </div>

    <div fxFlex="100">
      <compass-analytics-chart [data]="indicator?.chartData || []"></compass-analytics-chart>
    </div>
  </div>
</div>
