import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { ViewChild } from '@angular/core';
import { SideNavMatTabChangeService } from '@compass/utils/navigation';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'glo-stages-menu',
  templateUrl: './stages-menu.component.html',
  styleUrls: ['./stages-menu.component.scss'],
})
export class StagesMenuComponent implements OnInit, OnChanges {
  @ViewChild('tabGroup') tabGroup;
  @Input() stagesList;
  //stagesList$ = this.sideNavStagesService.stagesList$;

  //stagesList$ = this.sideNavStagesService.stagesList$;

  selectedIndex: number;
  env = environment;
  intelligenceSetup = {
    img: {
      src: '/assets/img/brand/intelligence-icon.svg',
    },
    stagesList: {},
    env: environment,
  };

  precisionSetup = {
    img: {
      src: '/assets/img/brand/precision-icon.svg',
    },
    stagesList: {},
    env: environment,
  };

  constructor(private sideNavMatTabChangeService: SideNavMatTabChangeService) {
    this.sideNavMatTabChangeService.getSelectedIndex().subscribe((index) => {
      this.selectedIndex = index;
    });
  }

  public tabChanged(e) {
    this.sideNavMatTabChangeService.setSelectedIndex(e.index);
  }
  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let changeObj: string[] = Object.keys(changes);
    this.intelligenceSetup.stagesList = this.stagesList.filter(
      (stage) => stage.branch === 'intelligence'
    );
    this.precisionSetup.stagesList = this.stagesList.filter(
      (stage) => stage.branch === 'precision'
    );
  }
}
