import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import * as L from 'leaflet';
import classyBrew from 'classybrew';
import {CompassMapWrapperService, Map} from './compass-map-wrapper.service';
import {BricksApiService} from '@compass/brick-api';
import {
  Indicator,
  IndicatorService,
  OnclickStageShapeService,
  SideNavIndicatorsService
} from '@compass/utils/navigation';
import {EndRightSidenavService} from '@compass/page-navigation/end-right-sidenav';
import {CompassMapDrawerService} from './compass-map-drawer.service';
import {ColorsService, Mathematics, ThematicKeySymbol} from '@compass/utils/misc';
import {PoiBasketService} from '@compass/shared/poi-basket';
import * as chroma from 'chroma-js';
import { BRICK_DATA_ATTR } from '../../../../brick-api/src/lib/bricks-api.service';

@Injectable({
  providedIn: 'root'
})
export class CompassMapIndicatorsService {
  private activeIndicator: Indicator;
  public map: Map;
  public map$: BehaviorSubject<Map> = new BehaviorSubject<Map>(undefined);

  public bricks;
  public propertiesDB: string = BRICK_DATA_ATTR;

  public activeIndicator$: BehaviorSubject<Indicator> = new BehaviorSubject<Indicator>(
    undefined
  );

  public brickEventToBounce$: BehaviorSubject<Event> = new BehaviorSubject<Event>(
    undefined
  );

  public activeShape: string;

  setMapCenter = this.compassMapWrapperService.setMapCenter;
  setView = this.compassMapDrawerService.setView;
  fitGeojsonBounds = this.compassMapDrawerService.fitGeojsonBounds;
  removeGeojsonLayer = this.compassMapDrawerService.removeGeojsonLayer;
  addSeparatorsNF = this.mathematics.addSeparatorsNF;
  getKeySymbol = this.thematicKeySymbol.getKeySymbol;
  setAnalyticsIndicators = this.indicatorService.setAnalyticsIndicators;
  mathCeil10 = this.mathematics.MathCeil10;
  mathFloor10 = this.mathematics.MathFloor10;

  dic = this.sideNavIndicatorsService.indicatorsDictionary$.value;

  formIndicators: any;
  brewClasses: number = 6;

  constructor(
    private compassMapWrapperService: CompassMapWrapperService,
    private compassMapDrawerService: CompassMapDrawerService,
    private endRightSidenavService: EndRightSidenavService,
    private bricksApiService: BricksApiService,
    private indicatorService: IndicatorService,
    private colorsService: ColorsService,
    private mathematics: Mathematics,
    private thematicKeySymbol: ThematicKeySymbol,
    private sideNavIndicatorsService: SideNavIndicatorsService,
    private onclickStageShapeService: OnclickStageShapeService,
    private poiBasketService: PoiBasketService
  ) {
    this.compassMapWrapperService.map$.subscribe((map: Map) => {
      this.map = map;
    });
  }

  /**
   * Set map before using this shitty service
   * @param properties
   */
  setMap(map) {
    this.compassMapWrapperService.setMap(map);
  }

  /***********************/
  // SCALES
  /***********************/

  // data
  public minMaxIndicatorsScaleExist(): boolean {
    return !!this.map.minMaxScales.find((x) => x.key === this.activeIndicator['key']);

  }

  public resetMinMaxIndicatorScale(): void {
    if (this.map.minMaxScales && this.map.minMaxScales.length) {
      this.map.minMaxScales = [];
      this.map$?.next(this.map);
    }
  }

  public setBrew(series) {
    let seriesLength = series.length === 6 ? 5 : 6;
    let brew = new classyBrew();

    if (series) {
      brew.setSeries(series);
      brew.setNumClasses(seriesLength);
      brew.setColorCode('YlOrRd');
      brew.classify();
    }
    return brew;
  }

  normalizeSeries(
    series: Array<number>,
    steps: number,
    floor: number
  ): Array<number> {
    let l = series.length;
    let p = steps - l;
    let i = 0;
    if (l < this.brewClasses) {
      for (i; i <= p; i++) {
        series.unshift(floor);
      }
    }
    return series;
  }

  public setMinMaxIndicatorScale() {
    let series: Array<number> = [];
    this.activeIndicator = this.activeIndicator ?? this.indicatorService.activeIndicator$.value;
    this.bricks = this.bricks ?? this.bricksApiService.bricks$.value;

    this.bricks = this.bricks.filter((d) => {
      if (Object.keys(d.properties[this.propertiesDB]).length > 0) {
        return d;
      }
    });

    this.bricks.forEach((brick) => {
      const number = brick.properties[this.propertiesDB]?.[this.activeIndicator?.grupo]?.[this.activeIndicator?.key];
      series.push(isNaN(number) ? 0 : number);
    });

    let ceil = Math.max(...series);
    let floor = Math.min(...series);
    let normalizedSeries = this.normalizeSeries(
      series,
      this.brewClasses,
      floor
    );

    let brew = this.setBrew(normalizedSeries);

    this.map.minMaxScales.push({
      key: this.activeIndicator['key'],
      series: normalizedSeries,
      brew: brew,
      values: {
        ceil: Math.ceil(ceil),
        floor: Math.floor(floor)
      },
      steps: []
    });

    this.map$?.next(this.map);

  }

  getMinMaxIndicatorScale(indicator: any = null) {
    return this.map.minMaxScales.filter((x) => {
      return indicator ? x.key === indicator.key : x.key === this.activeIndicator['key'];
    })[0];
  }

  /***********************/
  // STYLE AND COLORS
  /***********************/

  getColor(d: number, indicator: any = null): string {
    let brew: any = this.getMinMaxIndicatorScale(indicator).brew;
    return brew.getColorInRange(d);
  }

  styling(feature: any, category?: string, indicator?: string, range: any = null, excludedIds: any[] = []): any {
    if (category && indicator) {
      let inBasket = this.poiBasketService.inBasket(feature);
      let fillColor = 'transparent';

      // when no color recieved, generate it
      const propCategroy = feature.properties[this.propertiesDB][category];
      if (propCategroy && propCategroy[indicator]) {
        const brickValue = propCategroy[indicator];
        fillColor = this.getColor(brickValue);


        // if range is setted, then check if brick value not in it
        if (range) {
          if (!(range[0] <= brickValue && range[1] >= brickValue)) {
            fillColor = 'rgba(80,80,80,0.2)';
          }
        }

        // if excludedIds setted, check if brick is excluded
        if (excludedIds?.length) {
          if (excludedIds.includes(feature.properties?.id)) {
            fillColor = 'rgba(80,80,80,0.2)';
          }
        }
      }

      return {
        fillColor: fillColor,
        dashArray: '0',
        weight: inBasket ? 5 : 0,
        opacity: inBasket ? 0.8 : 0,
        fillOpacity: inBasket ? 0.8 : 0.7,
        color: inBasket ? '#0036ff' : this.colorsService.colors.orange
      };
    } else {
      return this.map.area.style.default;
    }
  }

  /***********************/
  // COLOR KEYS
  /***********************/

  drawColorKeys(): void {
    this.map['color_key'] = new L.Control({position: 'bottomright'});
    this.map['color_key'].onAdd = () => {
      let div: HTMLElement = L.DomUtil.create('div', 'info legend');

      let grades: Array<number> = this.getMinMaxIndicatorScale().brew.breaks;
      let i: number = 0;

      div.innerHTML += '<div>';
      div.innerHTML += `
          <p class='margin--0'><strong>${this.activeIndicator.screen_name}</strong></p>
          <p class='margin--0'><small>${this.activeIndicator.formato}</small></p>
      `;

      for (i; i < grades.length; i++) {
        div.innerHTML += '<p>';
        div.innerHTML += `<i style='background: ${this.getColor(grades[i])}'></i> `;
        div.innerHTML += this.addSeparatorsNF(Math.round(grades[i]), '.', ',', '.');
        div.innerHTML += this.getKeySymbol(this.activeIndicator['formato']);
        div.innerHTML += grades[i + 1] >= 0 ? ' - ' : '';
        div.innerHTML += !isNaN(Math.round(grades[i + 1]))
          ? this.addSeparatorsNF(Math.round(grades[i + 1]), '.', ',', '.')
          : '';
        div.innerHTML += this.getKeySymbol(this.activeIndicator['formato']);
        div.innerHTML += grades[i + 1] >= 0 ? '' : '+';
        div.innerHTML += '</p>';
      }

      div.innerHTML += '</div>';

      return div;
    };
    this.map['color_key'].addTo(this.map.box);
    this.map$?.next(this.map);

  }

  drawBrick(brick, layer, map = undefined) {
    map = map || this.map;

    L.geoJSON(brick, {
      style: (feature): any => {
        return this.styling(feature, '-', '-');
      },
      onEachFeature: (feature, layer) => {
        if (feature.properties) {
          layer
            .on('mouseover', (e) => {
              layer.bindPopup(`<h3>Brick ${feature.properties.id}</h3>`);
            })
            .on('mouseout', (e) => {
              layer.closePopup();
            });
        }
      }
    }).addTo(map.pois[layer]);

    map.pois[layer].addTo(map.box);
  }

  drawGeojsonLayer(): void {
    this.map.geojsonLayer = L.geoJSON(this.bricks, {
      style: (feature): any => {
        return this.styling(feature, this.activeIndicator['grupo'], this.activeIndicator['key']);
      },
      onEachFeature: this.onEachFeature
    }).addTo(this.map.box);
    this.map$?.next(this.map);

  }

  public filterJson = (feature: any): boolean => {
    if (feature && this.formIndicators) {
      let conditions: Array<boolean> = this.formIndicators.map((indicator) => {
        const indicatorValue = feature.properties[this.propertiesDB][indicator.grupo]?.[indicator.indKey];
        const min = indicator.values.floor;
        const max = indicator.values.ceil;

        return indicatorValue <= max && indicatorValue >= min;


      });

      return conditions.reduce((sum, next) => {
        return sum && next;
      }, true);
    }

    return false;
  };

  drawIndexLayer(bricks: any, range = [0, 100]) {
    const colorScale: any = chroma.scale('YlOrRd').domain(range, 5);
    const _this = this;

    this.removeGeojsonLayer('geojsonLayer');
    this.removeGeojsonLayer('color_key');
    this.removeGeojsonLayer('indexLayer');

    this.map.geojsonLayer = L.geoJSON(bricks, {
      // filter: this.filterJson,
      style: (feature: any): any => {
        let response: any = this.map.area.style.default;
        let inBasket = this.poiBasketService.inBasket(feature);
        let fillColor = feature.properties.active ? colorScale(feature.properties.score).hex() : 'rgba(27,27,27,0.74)';
        const score = feature.properties.score;

        response = {
          fillColor: fillColor,
          dashArray: '0',
          weight: inBasket ? 2 : 1,
          opacity: inBasket ? 0.5 : 0.2,
          fillOpacity: inBasket ? 0.8 : 0.7,
          color: inBasket ? '#0036ff' : this.colorsService.colors.orange
        };

        return response;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(`
          <h4>Brick ${feature.properties.id}</h4>
          <h3>Índice
            <span class='popu-score' style='padding:5px;background:grey;color:white;border-radius: 4px'>
              ${feature.properties.score.toFixed(0)}
            </span>
          </h3>
        `);

        layer.bindTooltip(feature.properties.score.toFixed(0), {
            permanent: true,
            direction: 'center',
            className: 'index-label'
          }
        );

        let mouseMoveTimeout;

        layer
          .on('mousemove', function (e: any) {
            clearTimeout(mouseMoveTimeout);
            mouseMoveTimeout = setTimeout(() => {
              layer.openPopup(e.latlng);
            }, 100);
          })
          .on('mouseout', function () {
            layer.closePopup();
          })
          .on('click', (e: any) => {
            _this.onClickArea(e);
          });
      }
    }).addTo(this.map.box);

  }

  filterGeojsonLayer(indicator: Indicator, indicators: any, excludedBricksIds: any[] = []): void {
    indicator = {...indicator, ...indicators.find(i => i.key === indicator.key)};
    const range = [indicator.minValue, indicator.maxValue];

    const dic = this.sideNavIndicatorsService.indicatorsDictionary$.value;
    this.formIndicators = indicators.map((indicator) => {
      indicator.grupo = dic.find((d: any) => d.key === indicator.key)?.grupo;
      return indicator;
    });

    this.bricks = this.bricksApiService.bricks$.value;
    this.activeIndicator = indicator;
    this.removeGeojsonLayer('geojsonLayer');
    this.removeGeojsonLayer('color_key');
    this.setMapCenter([40.434176, -3.735995]);

    if (!this.minMaxIndicatorsScaleExist()) {
      this.setMinMaxIndicatorScale();
    }

    this.map.geojsonLayer = L.geoJSON(this.bricks, {
      // filter: this.filterJson,
      style: (feature): any => {
        return this.styling(
          feature,
          this.activeIndicator['grupo'],
          this.activeIndicator['key'],
          range,
          excludedBricksIds
        );
      },
      onEachFeature: this.onEachFeature
    }).addTo(this.map.box);

    this.drawColorKeys();
    this.fitGeojsonBounds();
  }

  /***********************/
  // events
  /***********************/

  // string is the property

  setBrickEventToBounce(any) {
    this.brickEventToBounce$.next(any);
  }

  getBrickEventToBounce(): Observable<any> {
    return this.brickEventToBounce$.asObservable();
  }

  onEachFeature = (feature: any, layer: any): void => {
    if (feature.properties) {
      layer
        .on('mouseover', (e) => {
          this.onMouseoverArea(e);
          return false;
        })
        .on('mouseout', (e) => {
          this.onMouseoutArea(e);
          return false;
        })
        .on('click', (e) => {
          this.onClickArea(e);
          return false;
        });
    }
  };

  // AREAS and EVENTS
  onMouseoverArea(event: Event): void {
    //this.setFeatureStyle(event);
    //this.bringFeatureToFront(event);
    //this.updateInfoControl(event);
  }

  onMouseoutArea(event: Event): void {
    //this.setFeatureStyle(event);
    //this.drawInfoControl();
    //this.updateInfoControl(event);
  }

  onClickArea(event: Event): void {
    this.setBrickEventToBounce({
      brick: event,
      list: this.indicatorService.activeIndicator$.value,
      areaPortals: this.bricksApiService.areaPortals$.value
    });
    this.onclickStageShapeService.setShape('indicators');
    this.endRightSidenavService.setSidenavStatus(true);
    this.endRightSidenavService.setTitle('Detalles de Brick');
  }

}
