import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnclickStageShapeService {
  public shapeClicked$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor() {
  }

  setShape(shape) {
    if (this.shapeClicked$.value !== shape) {
      this.shapeClicked$.next(shape);
    }

    return false;
  }

  getShape(): Observable<string> {
    return this.shapeClicked$.asObservable();
  }
}
