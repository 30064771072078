import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { MapBoxService } from '@compass/utils/misc';
import { ZIndexService } from '@compass/utils/misc';
import { Map } from '@compass/utils/leaflet';

export interface MapSetUp {
  id: string;
  theme: string;
  center: Array<any>;
  iconUrl: string;
}

interface MarkerIcon {
  iconUrl: string;
  iconSize: Array<number>;
  iconAnchor: Array<number>;
  popupAnchor: Array<number>;
}

interface MarkerOptions {
  name: string;
  zIndex: number;
  width: number;
}

@Injectable()
export class MapService {
  map: any;

  editableLayers = new L.FeatureGroup();
  drawControl = new L.Control.Draw();
  drawControlEditOnly = new L.Control.Draw();

  constructor(
    private mapBoxService: MapBoxService,
    private zIndexService: ZIndexService) {
    this.map = {
      id: 'plain_map',
      theme: 'light',
      center: [40.416831666, -3.702163858],
      iconUrl: '../../../assets/img/markers/glocally-brick-narrow-purple.svg',
      change: '',
      box: null,
      geojsonLayer: null,
      transitLayer: null,
      studyLayer: null,
      cpsLayer: null,
      bounds: null,
      marker: {
        icon: this.markerIcon,
        options: this.markerOptions,
        l: null,
      },
      properties: {
        center: [42.1357507222081, -0.41074235303953105], // huesca center
        minZoom: 6,
        zoom: 17,
        maxZoom: 18,
        maxBounds: L.latLngBounds([L.latLng(45, 27), L.latLng(4.333333, -25)]),
        dragging: true,
        touchZoom: true,
        scrollWheelZoom: true,
        boxZoom: true,
        zoomControl: false,
        address: 'any',
        locality: 'any',
        province: 'any'
      },
      zoom: {
        position: 'topright',
        zoomInText: '+',
        zoomOutText: '-',
      },
      zoomCtrl: null,
      tileLayer: null,
      attribution: {
        attribution:
          'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
          '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="http://mapbox.com">Mapbox</a>',
      },
    };
  }

  private markerIcon: MarkerIcon = {
    iconUrl: '../../../../assets/img/markers/compass-marker-blue.svg',
    iconSize: [40, 80],
    iconAnchor: [25, 40],
    popupAnchor: [7, 100],
  };

  private markerOptions: MarkerOptions = {
    name: 'marker_pane',
    zIndex: this.zIndexService.$z__index_top,
    width: 80,
  };

  public initMap(): Map {
    this.map.access_token = this.mapBoxService.getMapBoxUrl(
      this.map.theme
    )[0].value;
    return this.map;
  }

  public setMapCenter(center: Array<any>): void {
    this.map.properties.center[0] = center[0];
    this.map.properties.center[1] = center[1];
  }

  public drawMap(): void {
    if (this.map['box'] === null) {
      this.map['box'] = L.map(this.map.id, this.map.properties);
    }
  }
  public drawZoomCtrl(): void {
    this.map['zoomCtrl'] = L.control.zoom(this.map.zoom).addTo(this.map.box);
  }
  public drawAttributionLayer(): void {
    this.map['tileLayer'] = L.tileLayer(
      this.map.access_token,
      this.map.attribution
    ).addTo(this.map.box);
  }
  public fitGeojsonBounds(): void {
    this.map['bounds'] = this.map.geojsonLayer.getBounds();
    this.map.box.fitBounds(this.map.bounds);
  }
  public removeGeojsonLayer(layer): void {
    if (this.map && this.map[layer]) {
      this.map[layer].remove();
    }
  }

  public setView(map: Map): void {
    this.map = map;
    this.map.box.setView(this.map.properties.center, this.map.properties.zoom);
  }

  public drawCtrl(draw: boolean, study: any): void {
    const _this = this;
    if (this.drawControl) {
      this.map.box.removeControl(this.drawControl);
    }
    if (this.drawControlEditOnly) {
      this.map.box.removeControl(this.drawControlEditOnly);
    }
    if (this.editableLayers) {
      this.map.box.removeLayer(this.editableLayers);
    }
    if (draw) {
      this.editableLayers = new L.FeatureGroup();
      if (study) {
        var coordenadas = study.properties.areas.manual.geometry.coordinates[0];
        var latlngsGoo = [];
        for (var z = 0; z < coordenadas.length; z++) {
          latlngsGoo.push([coordenadas[z][1], coordenadas[z][0]]);
        }
        var polygon = L.polygon(latlngsGoo, {
          /*color: '#ff5b00'*/
        }).addTo(this.editableLayers);
      }
      this.map.box.addLayer(this.editableLayers);

      this.drawControl = new L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          circlemarker: false,
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            showArea: true,
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong>¡esa forma no es válida!', // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55',
            },
          },
          marker: false,
        },
        edit: {
          featureGroup: this.editableLayers,
        },
      });

      this.drawControlEditOnly = new L.Control.Draw({
        position: 'topright',
        edit: {
          featureGroup: this.editableLayers,
        },
        draw: {
          polyline: false,
          rectangle: false,
          circle: false,
          circlemarker: false,
          polygon: false,
          marker: false,
        },
      });

      this.map.box.addControl(this.drawControl);

      if (study) {
        if (this.drawControl) {
          this.map.box.removeControl(this.drawControl);
        }
        if (this.drawControlEditOnly) {
          this.map.box.removeControl(this.drawControlEditOnly);
        }
        this.map.box.addControl(this.drawControlEditOnly);
      }
    } else {
      if (this.editableLayers) {
        this.map.box.removeLayer(this.editableLayers);
      }
      if (_this.drawControl) {
        _this.map.box.removeControl(_this.drawControl);
      }
      if (_this.drawControlEditOnly) {
        _this.map.box.removeControl(_this.drawControlEditOnly);
      }
    }
  }

  public setMap(map: Map): void {
    this.map = map;
  }
}
