import { Injectable } from "@angular/core";

@Injectable()
export class ThematicKeySymbol {
  constructor() { }

  getKeySymbol(thematic: string): string {
    let title: string = "";

    switch (thematic) {
      case "Volumen":
      case "Fecha":
      case "Media":
      case "Áreas":
        {
          title = "";
        }
        break;
      case "Euros":
        {
          title = "€";
        }
        break;

      case "Indice":
      case "Porcentaje":
        {
          title = "%";
        }
        break;

      default:
        {
          title = "";
        }
        break;
    }
    return title;
  };
};
